import { Button, Stack, Typography } from "@mui/material";
import moment from "moment";
import { useRef, useState } from "react";
import SimpleReactValidator from "simple-react-validator";

export const DeleteCustomDateDiaog = (props) => {
  const { templates, onDeleteTagSelect, onSaveCustomDays } = props;

  const [, forceUpdate] = useState();
  const validator = useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
  );

  return (
    <div>
      <Typography fontWeight={600} fontSize="16px" padding="16px 24px">
        Are you sure you want to remove date "
        {moment(templates.selectedDate).format("DD MMM YYYY")}"?
      </Typography>
      <Typography padding="16px 24px">
        The date will be removed from your production schedule. You can add
        dates again later.
      </Typography>
      <Stack
        direction="row"
        gap="8px"
        padding="8px 24px 16px 24px"
        marginTop="2px"
        sx={{ justifyContent: "flex-end" }}
      >
        <Button variant="outlined" onClick={() => onDeleteTagSelect(false)}>
          Close
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={() => onSaveCustomDays()}
        >
          Delete
        </Button>
      </Stack>
    </div>
  );
};
