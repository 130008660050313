import { useImmer } from "use-immer";

const initDeleteInfo = {
  show: false,
  id: null,
  showTemp: false,
  showDetails: false,
  showTempDetails: false,
};

export const useTempDelete = () => {
  const [deleteInfo, setDeleteInfo] = useImmer(initDeleteInfo);

  const onDelete = (row) => {
    const id = row.template_id;
    if (row?.scheduled === 0 && row?.stores?.length === 0) {
      setDeleteInfo((draft) => {
        draft = { show: !draft?.show, id };
        return draft;
      });
    } else {
      setDeleteInfo((draft) => {
        draft = { showTemp: !draft?.showTemp, id };
        return draft;
      });
    }
  };

  const onDeleteDetails = (id, list, productionList) => {
    const listData = list?.assignedStoreList;
    const productionListData = productionList?.data;
    if (listData.length === 0 && productionListData === null) {
      setDeleteInfo((draft) => {
        draft = { showDetails: !draft?.showDetails, id };
        return draft;
      });
    } else {
      setDeleteInfo((draft) => {
        draft = { showTempDetails: !draft?.showTempDetails, id };
        return draft;
      });
    }
  };
  const resetDeleteInfo = () => {
    setDeleteInfo((draft) => {
      draft = initDeleteInfo;
      return draft;
    });
  };

  return {
    deleteInfo,
    onDelete,
    resetDeleteInfo,
    onDeleteDetails,
  };
};
