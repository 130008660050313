import { useMemo } from "react";
import { useImmer } from "use-immer";

export const useStatusSelect = ({onChangeStatusHandler}) =>
{
    
    const statusOptions = useMemo(()=>
         [{id:1,name:"Active"},{id:0,name:"Inactive"}]
    ,[]);

    const statusValueChangeHandler = (option) =>
    {
        const selectedOption = option ? option.id : "";
        onChangeStatusHandler(selectedOption);
    }
    return {statusOptions,statusValueChangeHandler};
}