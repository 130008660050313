import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';

export const PageHeader = ({ headerText, rightContent, isChanged }) => {
  return (
    <Stack sx={{
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      height: "62px",
      py: "11px",
      px: "16px",
      bgcolor: isChanged ? "#000" : "#fff",
      position: "sticky",
      top: 0,
      zIndex: 99,
    }}>
      <Typography
        sx={{
          fontWeight: 600,
          fontSize: "24px",
          lineHeight: "29px",
          color: isChanged ? "#fff" : "#000"
        }}
      >
        {headerText}
      </Typography>

      <Typography
        sx={{ display: "flex", fontWeight: 400, fontSize: "14px", lineHeight: "17px", color: "#F8EFA4" }}
      >
        {!isChanged ? " " : (
          <>
            <WarningAmberRoundedIcon sx={{ height: "18px", color: "" }} /> Some fields are changed, need to save
          </>
        )}
      </Typography>
      <Box>
        {rightContent}
      </Box>
    </Stack>
  );
};
