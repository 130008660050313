export const useRecipeKeys = () =>
{
    const recipeKeys = {
      all: ["recipes"],
      list: () => [...recipeKeys.all, "recipelist"],
      recipelist: (filters) => [...recipeKeys.list(), { filters }],
      detail: (id) => [...recipeKeys.recipelist(), id],
      detailBykEY: (key, id) => [...recipeKeys.detail(id), key, id],
    };
      return {recipeKeys};
}