export const useArchivedRecipeKeys = ()=>
{
    const archiveRecipeKeys = {
        all: ["archiveRecipes"],
        list: () => [...archiveRecipeKeys.all, "archiveRecipelist"],
        recipelist: (filters) => [...archiveRecipeKeys.list(), { filters }],
        detail: (id) => [...archiveRecipeKeys.recipelist(), id],
        detailBykEY: (key,id) => [key, id],
      };
      return {archiveRecipeKeys};
}