import { useImmer } from "use-immer";
import { useArchivedRecipeListQueries } from "./useArchivedRecipeListQueries";
import { useDebounce } from "use-debounce";
import { useEffect, useState } from "react";
import { useUnArchiveRecipe } from "./useUnArchiveRecipe";

export const useArchivedRecipeList = () => {
  const [recipe, setRecipe] = useImmer({
    search: "",
    recipeList: [],
  });

  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 1,
    pageSize: 50,
  });

  const [debouncedText] = useDebounce(recipe.search, 1000);
  const { archivedRecipeQuery } = useArchivedRecipeListQueries({
    debouncedText,
    pagination,
    sorting,
  });

  const { onUnArchiveRecipe } = useUnArchiveRecipe();

  useEffect(() => {
    if (archivedRecipeQuery.data) {
      setPagination(archivedRecipeQuery.data.pagination);
    }
  }, [archivedRecipeQuery.data]);

  const onSearchHandler = (search) => {
    setRecipe((draft) => {
      draft.search = search;
    });
  };

  const onClearSearchHandler = () => {
    setRecipe((draft) => {
      draft.search = "";
    });
  };

  return {
    recipe,
    onUnArchiveRecipe,
    onSearchHandler,
    archivedRecipeQuery,
    sorting,
    setSorting,
    pagination,
    setPagination,
    onClearSearchHandler
  };
};
