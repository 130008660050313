import {
    useQueryClient,
    useMutation,
  } from "@tanstack/react-query";
import { errorMessage, successMessage } from "../../../utils/helper";
import { useStoreKeys } from "./useStoreKeys";
import { deleteStoreFromRecipe } from "../api";

export const useDeleteStoreFromRecipeQueries = ({resetDeleteInfo}) =>
{
    const {storeKeys} = useStoreKeys();
    const queryClient = useQueryClient();
    const mutateDeleteRecipeStore = useMutation({
        mutationFn: deleteStoreFromRecipe,
        onError: (error) => {
          errorMessage(error.response.data.message);
        },
        onSuccess: (res) => {
          if (res.success) {
            queryClient.invalidateQueries({
              queryKey: storeKeys.list(),
            });
            successMessage(res.message);
            resetDeleteInfo();
          } else if (res.success === false) {
            errorMessage(res.message);
          }
        },
      });

      return {mutateDeleteRecipeStore}
    
}