import { Grid, Stack, Typography } from "@mui/material";
import { CustomDetailsList, Loader } from "../../../shared";
import { IngredientsTab } from "../../Ingredients/components/IngredientsTab";
import { useChartSettings } from "../hooks";

export const ChartSettingsView = ({ recipeId }) => {
  const { chartSettingsQuery } = useChartSettings({ recipeId });

  if (chartSettingsQuery.isLoading) {
    return <Loader />;
  }

  const bmscData = {
    front_roller_setting_no: chartSettingsQuery.data.front_roller_setting_no,
    bread_moulder_settings_chart:
      chartSettingsQuery.data.bread_moulder_setting_chart,
  };

  const bmscColumns = [
    bmscData?.front_roller_setting_no
      ? {
          title: "Front Roller Setting No",
          accessor: (val) => {
            return (
              <Typography variant="subtitle2" fontWeight={500}>
                {val.front_roller_setting_no}
              </Typography>
            );
          },
        }
      : null,
    bmscData?.bread_moulder_settings_chart
      ? {
          title: "Bread Moulder Settings Chart",
          accessor: (val) => {
            return (
              <Typography variant="subtitle2" fontWeight={500}>
                {val.bread_moulder_settings_chart}
              </Typography>
            );
          },
        }
      : null,
  ].filter(Boolean);

  const ottcData = {
    baking_time_min_full_decks: chartSettingsQuery.data.baking_time,
    temperature: chartSettingsQuery.data.temperature,
    steaming_shots: chartSettingsQuery.data.steaming_shots,
    cut: chartSettingsQuery.data.cut,
    decorate: chartSettingsQuery.data.decorate,
    other_settings: (
      <Grid container>
        <Grid item xs={6}>
          Top:{chartSettingsQuery.data.oven_temperature_top}
        </Grid>
        <Grid item xs={6}>
          Bottom:{chartSettingsQuery.data.oven_temperature_bottom}
        </Grid>
      </Grid>
    ),
  };

  const ottcColumns = [
    ottcData?.baking_time_min_full_decks
      ? {
          title: "Baking Time(min) - Full Decks",
          accessor: (val) => {
            return (
              <Typography variant="subtitle2" fontWeight={500}>
                {val.baking_time_min_full_decks}
              </Typography>
            );
          },
        }
      : null,
    ottcData?.temperature
      ? {
          title: "Temperature (°C)",
          accessor: (val) => {
            return (
              <Typography variant="subtitle2" fontWeight={500}>
                {val.temperature}
              </Typography>
            );
          },
        }
      : null,
    ottcData?.steaming_shots
      ? {
          title: "Steaming Shots",
          accessor: (val) => {
            return (
              <Typography variant="subtitle2" fontWeight={500}>
                {val.steaming_shots}
              </Typography>
            );
          },
        }
      : null,
    ottcData?.cut
      ? {
          title: "Cut",
          accessor: (val) => {
            return (
              <Typography variant="subtitle2" fontWeight={500}>
                {val.cut}
              </Typography>
            );
          },
        }
      : null,
    ottcData?.decorate
      ? {
          title: "Decorate",
          accessor: (val) => {
            return (
              <Typography variant="subtitle2" fontWeight={500}>
                {val.decorate}
              </Typography>
            );
          },
        }
      : null,
    ottcData.other_settings.props.children[0].props.children[1] ||
    ottcData.other_settings.props.children[1].props.children[1]
      ? {
          title: "Other Settings",
          accessor: (val) => {
            return (
              <Typography variant="subtitle2" fontWeight={500}>
                {val.other_settings}
              </Typography>
            );
          },
        }
      : null,
  ].filter(Boolean);
  const freezData = (before_baking, before_decorating) => {
    let freeze = "";
    if (before_baking === 1) freeze = "Before baking";
    if (before_decorating) {
      if (freeze !== "") {
        freeze += ", Before decorating";
      } else {
        freeze += "Before decorating";
      }
    }
    return freeze;
  };
  const ottcData1 = {
    time_period: chartSettingsQuery.data.time_period,
    freeze: freezData(
      chartSettingsQuery.data.before_baking,
      chartSettingsQuery.data.before_decorating
    ),
    primary_or_secondary: chartSettingsQuery.data.primary
      ? "Primary"
      : "Secondary",
    mandatoy_or_optional: chartSettingsQuery.data.mandatory
      ? "Mandatoy"
      : "Optional",
  };
  const ottcColumns1 = [
    ottcData1?.time_period
      ? {
          title: "Time Period",
          accessor: (val) => {
            return (
              <Typography variant="subtitle2" fontWeight={500}>
                {val.time_period}
              </Typography>
            );
          },
        }
      : null,
    ottcData1?.freeze
      ? {
          title: "Freeze",
          accessor: (val) => {
            return (
              <Typography variant="subtitle2" fontWeight={500}>
                {val.freeze}
              </Typography>
            );
          },
        }
      : null,

    ottcData1?.primary_or_secondary
      ? {
          title: "Primary or Secondary",
          accessor: (val) => {
            return (
              <Typography variant="subtitle2" fontWeight={500}>
                {val.primary_or_secondary}
              </Typography>
            );
          },
        }
      : null,
    ottcData1?.mandatoy_or_optional
      ? {
          title: "Mandatoy or Optional",
          accessor: (val) => {
            return (
              <Typography variant="subtitle2" fontWeight={500}>
                {val.mandatoy_or_optional}
              </Typography>
            );
          },
        }
      : null,
  ].filter(Boolean);
  const rmscData = {
    settings_no: chartSettingsQuery.data.roll_moulder_setting_no,
    rounding_time: chartSettingsQuery.data.roll_moulder_rounding_time,
  };
  const rmscColumns = [
    rmscData?.settings_no
      ? {
          title: "Settings No",
          accessor: (val) => {
            return (
              <Typography variant="subtitle2" fontWeight={500}>
                {val.settings_no}
              </Typography>
            );
          },
        }
      : null,

    rmscData?.rounding_time
      ? {
          title: "Rouding Time",
          accessor: (val) => {
            return (
              <Typography variant="subtitle2" fontWeight={500}>
                {val.rounding_time}
              </Typography>
            );
          },
        }
      : null,
  ].filter(Boolean);

  const ptcData = {
    proving_time: chartSettingsQuery.data.proving_time,
  };

  const ptcColumns = [
    ptcData?.proving_time
      ? {
          title: "Proving Time",
          accessor: "proving_time",
        }
      : null,
  ];
  const pssData = {
    pastry_sheeter_setting_no:
      chartSettingsQuery.data.pastry_sheeter_setting_no,
    pastry_sheeter_amount_of_turns:
      chartSettingsQuery.data.pastry_sheeter_amount_of_turns,
    time_through_pastry_sheeter_final_settings:
      chartSettingsQuery.data.time_through_pastry_sheeter_final_settings,
    pastry_sheeter_bottom_weight:
      chartSettingsQuery.data.pastry_sheeter_bottom_weight,
    pastry_sheeter_top_weight:
      chartSettingsQuery.data.pastry_sheeter_top_weight,
    pastry_sheeter_filling_weight:
      chartSettingsQuery.data.pastry_sheeter_filling_weight,
    pastry_sheeter_unbacked_weight:
      chartSettingsQuery.data.pastry_sheeter_unbacked_weight,
  };
  const pssColumns = [
    pssData?.pastry_sheeter_setting_no
      ? {
          title: "Sheeter Setting No",
          accessor: (val) => {
            return (
              <Typography variant="subtitle2" fontWeight={500}>
                {val.pastry_sheeter_setting_no}
              </Typography>
            );
          },
        }
      : null,

    pssData?.pastry_sheeter_amount_of_turns
      ? {
          title: "Amount Of turns",
          accessor: (val) => {
            return (
              <Typography variant="subtitle2" fontWeight={500}>
                {val.pastry_sheeter_amount_of_turns}
              </Typography>
            );
          },
        }
      : null,

    pssData?.time_through_pastry_sheeter_final_settings
      ? {
          title: "Time through sheeter at final settings",
          accessor: (val) => {
            return (
              <Typography variant="subtitle2" fontWeight={500}>
                {val.time_through_pastry_sheeter_final_settings}
              </Typography>
            );
          },
        }
      : null,

    pssData?.pastry_sheeter_bottom_weight
      ? {
          title: "Bottom Weight",
          accessor: (val) => {
            return (
              <Typography variant="subtitle2" fontWeight={500}>
                {val.pastry_sheeter_bottom_weight}
              </Typography>
            );
          },
        }
      : null,

    pssData?.pastry_sheeter_top_weight
      ? {
          title: "Mandatoy or OptionalTop Weight",
          accessor: (val) => {
            return (
              <Typography variant="subtitle2" fontWeight={500}>
                {val.pastry_sheeter_top_weight}
              </Typography>
            );
          },
        }
      : null,

    pssData?.pastry_sheeter_filling_weight
      ? {
          title: "Filling Weight",
          accessor: (val) => {
            return (
              <Typography variant="subtitle2" fontWeight={500}>
                {val.pastry_sheeter_filling_weight}
              </Typography>
            );
          },
        }
      : null,

    pssData?.pastry_sheeter_unbacked_weight
      ? {
          title: "Unbaked Weight",
          accessor: (val) => {
            return (
              <Typography variant="subtitle2" fontWeight={500}>
                {val.pastry_sheeter_unbacked_weight}
              </Typography>
            );
          },
        }
      : null,
  ].filter(Boolean);

  return (
    <Stack sx={{ borderRadius: "4px", bgcolor: "#fff", px: "16px" }}>
      {chartSettingsQuery.data.bread_moulder_enabled &&
      (bmscData?.bread_moulder_settings_chart ||
        bmscData?.front_roller_setting_no) ? (
        <CustomDetailsList
          columns={bmscColumns}
          data={bmscData}
          title="Bread Moulder Settings Chart"
        />
      ) : (
        <></>
      )}
      {chartSettingsQuery.data.proving_time_enabled && ptcData.proving_time ? (
        <CustomDetailsList
          columns={ptcColumns}
          data={ptcData}
          title="Proving Time Chart"
        />
      ) : (
        <></>
      )}
      {chartSettingsQuery.data.oven_time_temp_enabled &&
      (ottcData.baking_time_min_full_decks ||
        ottcData.temperature ||
        ottcData.steaming_shots ||
        ottcData.cut ||
        ottcData.decorate ||
        ottcData.other_settings) ? (
        <CustomDetailsList
          columns={ottcColumns}
          data={ottcData}
          title="Oven Time & Temperature Chart"
        />
      ) : (
        <></>
      )}
      {chartSettingsQuery.data.production_wastage_enabled &&
      (ottcData1.mandatoy_or_optional ||
        ottcData1.primary_or_secondary ||
        ottcData1.freeze ||
        ottcData1.time_period) ? (
        <CustomDetailsList
          columns={ottcColumns1}
          data={ottcData1}
          title="Production & Wastage sheet"
        />
      ) : (
        <></>
      )}
      {chartSettingsQuery.data.roll_moulder_enabled &&
      (rmscData.rounding_time || rmscData.settings_no) ? (
        <CustomDetailsList
          columns={rmscColumns}
          data={rmscData}
          title="Roll Moulder Settings Chart"
        />
      ) : (
        <></>
      )}
      {chartSettingsQuery.data.pastry_sheeter_enabled &&
      (pssData.pastry_sheeter_amount_of_turns ||
        pssData.pastry_sheeter_bottom_weight ||
        pssData.pastry_sheeter_filling_weight ||
        pssData.pastry_sheeter_setting_no ||
        pssData.pastry_sheeter_top_weight ||
        pssData.pastry_sheeter_unbacked_weight ||
        pssData.time_through_pastry_sheeter_final_settings) ? (
        <CustomDetailsList
          columns={pssColumns}
          data={pssData}
          title="Pastry Sheeter Settings Chart"
        />
      ) : (
        <></>
      )}
    </Stack>
  );
};
