import { useQueryClient, useMutation } from "@tanstack/react-query";
import { errorMessage, successMessage } from "../../../utils/helper";
import { editTemplatesStoreApi } from "../api/TemplatesApi";
import { useStoreKeys } from "../../Recipe/hooks/useStoreKeys";

export const useAssignedStoreTemplateUpdateQueries = ({
  setAssignedStoreState,
}) => {
  const queryClient = useQueryClient();
  const { storeKeys } = useStoreKeys();
  const updateTemplateStore = useMutation({
    mutationFn: editTemplatesStoreApi,
    onError: (error) => {
      errorMessage(error.response.data.message);
    },

    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: storeKeys.list(),
      });
      successMessage("Stores added succesfully");
      setAssignedStoreState((draft) => {
        draft.isLoading = false;
      });
      variables.handleModal("store-dialog-add", false);
    },
  });
  return { updateTemplateStore };
};
