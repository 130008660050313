import React from "react";
import { Box, Paper, Typography } from "@mui/material";

export const UnAuthorised = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
    >
      <Paper
        elevation={0}
        sx={{
          padding: "40px",
          textAlign: "center",
          backgroundColor: "#F7FAFC",
          borderRadius: "8px",
          width: "484px",
          height: "278px",
          mt: 2,
        }}
      >
        <img
          src="/icons/SecurityShield.svg"
          sx={{
            fontSize: "60px",
            color: "#4a5f46",
            mb: "20px",
            cursor: "pointer",
          }}
        />
        <Typography
          fontWeight={600}
          alignSelf="center"
          fontSize="24px"
          lineHeight="29px"
        >
          Oops! Access Denied!
        </Typography>
        <Typography
          fontWeight={400}
          alignSelf="center"
          fontSize="16px"
          lineHeight="19px"
          width="452px"
          mt={1}
        >
          You don’t have permission to access this page.
        </Typography>
      </Paper>
    </Box>
  );
};
