import {
  useQueryClient,
  useQuery,
  useMutation,
  keepPreviousData,
} from "@tanstack/react-query";
import qs from "qs";
import {
  ErrorFormatter,
  errorMessage,
  successMessage,
} from "../../../utils/helper";
import { useNavigate } from "react-router-dom";
import {
  assignUsertoRoles,
  getPermissionsList,
  getRolesDetailsById,
  getRolesList,
  getUserList,
  removeRoles,
  removeUserFromRoles,
  saveRoles,
  updateRole,
} from "../api/RolesAndPermissionsApi";

const rolesAndPermissionsKeys = {
  all: ["rolesAndPermissions"],
  lists: () => [...rolesAndPermissionsKeys.all, "rolesAndPermissionslist"],
  rolesAndPermissionslist: (filters) => [
    ...rolesAndPermissionsKeys.lists(),
    filters,
  ],
  detail: (id) => [...rolesAndPermissionsKeys.rolesAndPermissionslist(), id],
};
const permissionsKeys = {
  all: ["permissions"],
  lists: () => [...permissionsKeys.all, "permissionsList"],
  permissionsList: (filters) => [...permissionsKeys.lists()],
};
const usersKeys = {
  all: ["permissions"],
  lists: () => [...usersKeys.all, "usersList"],
  usersList: (filters) => [...usersKeys.lists()],
};
export const useRolesAndPermissionsQuery = ({
  isList,
  debouncedText,
  roleId,
  resetDeleteInfo,
  resetUserDeleteInfo,
}) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  let params = {};
  if (debouncedText) {
    params = { ...params, search: debouncedText };
  }

  params = {
    ...params,
  };
  const query = {
    params,
    paramsSerializer: (params) => qs.stringify(params, { encode: true }),
  };

  const listQuery = useQuery({
    queryKey: rolesAndPermissionsKeys.rolesAndPermissionslist(query),
    queryFn: () => getRolesList(query),
    placeholderData: keepPreviousData,
    enabled: isList,
  });
  const permissionListQuery = useQuery({
    queryKey: permissionsKeys.permissionsList(query),
    queryFn: () => getPermissionsList(query),
    placeholderData: keepPreviousData,
    enabled: !isList,
  });

  const createRoles = useMutation({
    mutationFn: ({ data }) => saveRoles(data),
    onError: (error) => {
      errorMessage(ErrorFormatter(error.response.data.errors));
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: rolesAndPermissionsKeys.lists(),
      });
      successMessage("Role created cuccessfully !!!");
      navigate(`/roles-and-permissions/details/${data.id}`);
    },
  });

  const deleteRoles = useMutation({
    mutationFn: removeRoles,
    onError: (error) => {
      errorMessage(error?.response?.data?.error ?? "Error");
    },
    onSuccess: (res, variables) => {
      if (res.success) {
        successMessage(res.message);
        navigate("/roles-and-permissions");
        variables.to && navigate(variables.to);
        resetDeleteInfo();
      } else if (res.success === false) {
        errorMessage(res.message);
      }
    },
  });
  const rolesAndPermissionsDetails = useQuery({
    queryKey: rolesAndPermissionsKeys.detail(roleId),
    queryFn: () => getRolesDetailsById(roleId),
    enabled: roleId > 0,
  });
  const editRoles = useMutation({
    mutationFn: ({ data }) => updateRole(data, roleId),
    onError: (error) => {
      errorMessage(
        error?.response?.data?.errors?.name
          ? error?.response?.data?.errors?.name
          : error?.response?.data?.message
      );
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: rolesAndPermissionsKeys.lists(),
      });
      navigate(`/roles-and-permissions/details/${roleId}`);
      successMessage("Role updated successfully.");
    },
  });

  const handleDeleteUserFromRoles = useMutation({
    mutationFn: ({ id }) => removeUserFromRoles(id, roleId),
    onError: (error) => {
      errorMessage(ErrorFormatter(error.response.data.errors));
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: rolesAndPermissionsKeys.lists(),
      });
      resetUserDeleteInfo();
      successMessage("Role deleted from user !!!");
    },
  });
  const usersListQuery = useQuery({
    queryKey: usersKeys.usersList(),
    queryFn: () => getUserList(),
    placeholderData: keepPreviousData,
    enabled: roleId > 0,
  });
  const handleAssignUsers = useMutation({
    mutationFn: ({ data }) => assignUsertoRoles(data, roleId),
    onError: (error) => {
      errorMessage(ErrorFormatter(error.response.data.errors));
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: rolesAndPermissionsKeys.lists(),
      });
      successMessage("Role assigned to the user successfully !!!");
      variables.handleClickOpen(false);
    },
  });

  return {
    listQuery,
    permissionListQuery,
    createRoles,
    rolesAndPermissionsDetails,
    editRoles,
    deleteRoles,
    handleDeleteUserFromRoles,
    usersListQuery,
    handleAssignUsers,
  };
};
