import { API } from "../../../utils/api";

export const saveRecipePreapration = async (payload) => {
  const { formData } = payload;
  const res = await API.post("recipes/steps-videos", formData);
  return res.data.data;
};

export const saveImageVideoUrl = async (payload) => {
  const res = await API.post("recipes/media", payload, {
    headers: { "Content-Type": "multipart/form-data" },
  });
  return res.data.url;
};

export const editHowToPrepare = async (data) => {
  const { detailsId, formData } = data;
  const res = await API.post(`recipes/steps-videos/${detailsId}`, formData);
  return res.data;
};

export const getPreparationDetails = async (id) => {
  const res = await API.get(`recipes/steps-videos/${id}`);
  return res.data.data;
};
