import * as React from "react";
import { Box, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { useLocation, Link } from "react-router-dom";
import Bullet from "../../../assets/images/Icons/ic_bulletpoint.svg";

export const SidebarSubMenu = (props) => {
  const { item, index } = props;
  const [open, setOpen] = React.useState(false);
  const { pathname } = useLocation();
  const isStoreAdmin = pathname.includes("store-admin");
  const basepath = isStoreAdmin
    ? "/" + pathname.split("/")[2]
    : "/" + pathname.split("/")[1];

  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <Box
      key={index}
      sx={{
        alignItems: "center",
        cursor: "pointer",
        display: "flex",
        justifyContent: "space-between",
        mx: "8px",
      }}
      className={`${basepath === item.href ? "sidebar-link-active" : ""}`}
    >
      {item?.openInNewTab ? (
        <a
          className="sidebar-link"
          href={`${item.href}`}
          target="_blank"
          rel="noreferrer"
        >
          <ListItemButton
            key={item.title}
            sx={{ p: 0, minHeight: 32 }}
            onClick={() => handleClick(index)}
          >
            <ListItemIcon className="sidebar-icon"><img alt="dot" src={Bullet} /></ListItemIcon>
            <ListItemText
              primaryTypographyProps={{
                fontSize: 14,
                color: "#fffceb",
              }}
            >
              {item.title}
            </ListItemText>
          </ListItemButton>
        </a>
      ) : (
        <Link
          to={isStoreAdmin ? `/store-admin${item.href}` : `${item.href}`}
          className="sidebar-link"
        >
          <ListItemButton
            key={item.title}
            sx={{ p: 0, minHeight: 32 }}
            onClick={() => handleClick(index)}
          >
            <ListItemIcon className="sidebar-icon"><img alt="dot" src={Bullet} /></ListItemIcon>
            <ListItemText
              primaryTypographyProps={{
                fontSize: 14,
                color: "#fffceb",
              }}
            >
              {item.title}
            </ListItemText>
          </ListItemButton>
        </Link>
      )}
    </Box>
  );
};
