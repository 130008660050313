import React from "react";
import { Route, Routes } from "react-router-dom";
import { Login } from "./containers";
import { AuthProtectedRoute } from "../../shared";

export const Authentication = () => {
  return (
    <>
      <Routes>
        <Route element={<AuthProtectedRoute />}>
          <Route path="/login" element={<Login />} />
        </Route>
      </Routes>
    </>
  );
};
