import { Stack, Typography } from "@mui/material";
import * as React from "react";

export const StatsCards = (props) => {
  const { data } = props;
  const colors = ["#A04D3D", "#5E7360", "#564845"];
  const image = [
    "/icons/ingredient_ic.png",
    "/icons/recipe_ic.png",
    "/icons/template_ic.png",
  ];
  return (
    <Stack
      sx={{
        flexDirection: "row",
        rowGap: "24px",
        columnGap: "16px",
        flexWrap: "wrap",
        mb: "24px",
      }}
    >
      {data?.map((value, i) => (
        <Stack
          key={i}
          sx={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: colors[i],
            borderRadius: "8px",
            padding: "16px",
            width: "322px",
            height: "100px",
            color: "#fff",
          }}
        >
          <Stack>
            <Typography
              lineHeight="43px"
              fontWeight={600}
              fontSize="34px"
              mt={3}
            >
              {value?.total}
            </Typography>
            <Typography fontSize="16px" fontWeight={400}>
              {value?.label}
            </Typography>
          </Stack>

          <img
            src={image[i]}
            style={{
              marginTop: "56px",
              width: "24px",
              height: "24px",
            }}
          />
        </Stack>
      ))}
    </Stack>
  );
};
