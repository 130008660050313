import {
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";

export const BasicMuiTableStyled = ({
  data,
  columns,
  tableFooter,
  styleFunc,
}) => {
  return (
    <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead sx={{ backgroundColor: "#F5F8FC" }}>
          <TableRow
            sx={{
              ".css-191a0o-MuiTableCell-root": {
                padding: "10px 8px",
              },
            }}
          >
            {columns.map((item) => (
              <TableCell>{item.header}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item, index) => (
            <TableRow
              key={index}
              sx={{
                ...(styleFunc && styleFunc(item)),
                border: "1px solid #DFE8F2",
                "&:last-child td, &:last-child th": { border: 0 },
              }}
            >
              {columns.map((column) => (
                <>
                  <TableCell
                    sx={{
                      padding: "10px 8px",
                      borderBottom: "1px solid #DFE8F2",
                    }}
                  >
                    {column.cell(item, index)}
                  </TableCell>
                </>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TableFooter>{tableFooter}</TableFooter>
    </TableContainer>
  );
};
