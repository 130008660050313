import { useLocation, Link } from "react-router-dom";
import { SidebarSubMenu } from "./SidebarSubMenu";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import React, { useEffect, useState } from "react";
import {
  Box,
  Collapse,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { AuthContext } from "../../../store";

export const SidebarMenu = (props) => {
  const { item, index, collapse } = props;
  const [open, setOpen] = useState(false);
  const { pathname } = useLocation();
  const isStoreAdmin = pathname.includes("store-admin");
  const { auth } = React.useContext(AuthContext);
  const basepath = isStoreAdmin
    ? "/" + pathname.split("/")[2]
    : "/" + pathname.split("/")[1];

  const handleClick = () => {
    setOpen(!open);
  };

  // const auth = {
  //   data: {
  //     permissions: [{ name: "dashboard-view" }, { name: "category-view" }],
  //   },
  // };

  useEffect(() => {
    if (item.subMenu) {
      setOpen(
        item.subMenu
          .map((data) => {
            return data.href;
          })
          .includes(basepath)
      );
    }
  }, [pathname, basepath, item.subMenu]);
  const hasPermission = auth?.data?.permissions?.find(
    (permission) =>
      permission.name === item.permission ||
      permission.name === item.permission2
  );

  const IsAllPermission = (sub) => {
    if (!sub) return false;

    const array = sub.map((data) => {
      const hasPermission = auth?.data?.permissions?.find(
        (permission) =>
          permission.name === data.permission ||
          permission.name === data.permission2
      );
      if (isStoreAdmin && data.storeAdmin) {
        return hasPermission;
      } else if (!isStoreAdmin && data.superAdmin !== false) {
        return hasPermission;
      } else {
        return false;
      }
    });
    return !array.every((currentValue) => currentValue === undefined);
  };

  const IsALLStoreAdmin = (sub) => {
    if (!isStoreAdmin) {
      return true;
    } else if (sub) {
      const array = sub.map((data) => {
        return data.storeAdmin ? true : false;
      });
      return !array.every((v) => v === false);
    }
  };

  return (
    <>
      {item.subMenu &&
        IsAllPermission(item.subMenu) &&
        IsALLStoreAdmin(item.subMenu) ? (
        <>
          <Box
            sx={{
              background: open && "#00000014",
              borderRadius: "8px",
            }}
          >
            <Box
              key={index}
              sx={{
                alignItems: "center",
                cursor: "pointer",
                display: "flex",
                justifyContent: "space-between",
                // py: "8px",
              }}
            >
              <ListItemButton
                key={item.title}
                sx={{ p: "4px 6px 4px 8px ", height: 32, justifyContent: "center" }}
                onClick={handleClick}
              >
                <ListItemIcon className="sidebar-icon">
                  {item.icon}
                </ListItemIcon>
                {collapse ? (
                  <>
                    <ListItemText
                      primaryTypographyProps={{
                        fontSize: 14,
                        color: "#fffceb",
                      }}
                    >
                      {item.title}
                    </ListItemText>
                    <ListItemIcon sx={{ marginLeft: "auto", minWidth: "auto" }}>
                      {open ? (
                        <ExpandLess sx={{ color: "#FFFCEB" }} />
                      ) : (
                        <ExpandMore sx={{ color: "#FFFCEB" }} />
                      )}
                    </ListItemIcon>
                  </>) : ""}
              </ListItemButton>

              {/* </Link> */}
            </Box>
            {collapse && (
              <Collapse in={open} timeout="auto" sx={{ mb: open && "8px" }}>
                {item.subMenu.map((item, index) => {
                  const hasPermission = auth?.data?.permissions?.find(
                    (permission) =>
                      permission.name === item.permission ||
                      permission.name === item.permission2
                  );
                  if (!hasPermission) return false;
                  if (isStoreAdmin) {
                    if (item.storeAdmin) {
                      return (
                        <SidebarSubMenu item={item} index={index} key={index} collapse={collapse} />
                      );
                    } else {
                      return false;
                    }
                  } else if (item.superAdmin !== false) {
                    return (
                      <SidebarSubMenu item={item} index={index} key={index} collapse={collapse} />
                    );
                  } else {
                    return null;
                  }
                })}
              </Collapse>
            )}
          </Box>
        </>
      ) : (hasPermission && isStoreAdmin && item.storeAdmin) ||
        (hasPermission && !isStoreAdmin && item.superAdmin !== false) ? (
        <Box
          key={index}
          sx={{
            alignItems: "center",
            cursor: "pointer",
            display: "flex",
            justifyContent: "space-between",
            // py: "8px",
          }}
          className={`${basepath === item.href ? "sidebar-link-active" : ""}`}
        >
          {item?.openInNewTab ? (
            <a
              className="sidebar-link"
              href={`${item.href}`}
              target="_blank"
              rel="noreferrer"
            >
              <ListItemButton
                key={item.title}
                sx={{ padding: "6px 8px", minHeight: 32, justifyContent: "center" }}
              >
                <ListItemIcon
                  className="sidebar-icon"
                  sx={{ maxWidth: "20px !important" }}
                >
                  {item?.icon}
                </ListItemIcon>
                {collapse ? (
                  <>
                    <ListItemText
                      primaryTypographyProps={{
                        fontSize: 14,
                        color: "#fffceb",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {item.title}
                    </ListItemText>
                  </>) : ""}
              </ListItemButton>
            </a>
          ) : (
            <Link
              to={isStoreAdmin ? `/store-admin${item.href}` : `${item.href}`}
              className="sidebar-link"
            >
              <ListItemButton
                key={item.title}
                sx={{ p: "6px 8px", height: 32, gap: "8px", justifyContent: "center" }}
              >
                <ListItemIcon
                  className="sidebar-icon"
                  sx={{ maxWidth: "20px !important" }}
                >
                  {item.icon}
                </ListItemIcon>
                {collapse ? (
                  <ListItemText
                    primaryTypographyProps={{
                      fontSize: 14,
                      color: "#fffceb",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {item.title}
                  </ListItemText>
                ) : ""}
              </ListItemButton>
            </Link>
          )}
        </Box>
      ) : (
        ""
      )}
    </>
  );
};
