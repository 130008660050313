
import { useQuery, useQueryClient, keepPreviousData } from "@tanstack/react-query";
import qs from "qs";
import { getArchivedRecipeList } from "../api";
import { useArchivedRecipeKeys } from "./useArchivedRecipeKeys";
import { useRecipeKeys } from "./useRecipeKeys";

export const useArchivedRecipeListQueries = ({ debouncedText, pagination, sorting }) => {
  const {archiveRecipeKeys} = useArchivedRecipeKeys();
  const {recipeKeys} = useRecipeKeys();
  
  let params = {};
  if (debouncedText) {
    params = { ...params, search: debouncedText };
  }
  
  if (sorting?.length > 0) {
    const sortByParams = sorting.reduce(
      (obj, item) =>
        Object.assign(obj, { [item.id]: item.desc ? "desc" : "asc" }),
      {}
    );
    params = {
      ...params,
      sort: sortByParams,
    };
  }

  const query = {
    params,
    paramsSerializer: (params) => qs.stringify(params, { encode: true }),
  };

  //list query
  const archivedRecipeQuery = useQuery({
    queryKey: archiveRecipeKeys.recipelist({ query, pagination }),
    queryFn: () => getArchivedRecipeList({ query, pagination }),
    placeholderData: keepPreviousData,
  });
  
  return {archivedRecipeQuery};
}