import {
  Box,
  Chip,
  Stack,
  Typography,
  Link as Links,
  Button,
  Tooltip,
} from "@mui/material";
import React, { useMemo } from "react";
import {
  DeleteModal,
  PageListLayout,
  ServerPaginatedtable,
  SimpleMenu,
} from "../../../shared";
import { createColumnHelper } from "@tanstack/react-table";
import { useNavigate } from "react-router-dom";
import { useTipsAndTricks } from "../hooks/useTipsAndTricks";
import AddIcon from "@mui/icons-material/Add";

export const TipsAndTricksList = () => {
  const navigate = useNavigate();
  const {
    setPagination,
    pagination,
    sorting,
    setSorting,
    listQuery,
    onSearchHandler,
    onDelete,
    confirmDelete,
    resetDeleteInfo,
    deleteInfo,
    isCreateVisible,
    isEditable,
    isDeletable,
    moduleName,
    search,
  } = useTipsAndTricks({
    isList: true,
  });
  const columnHelper = createColumnHelper([]);

  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        header: "Tip Name",
        cell: ({ row }) => {
          return (
            <Box
              sx={{
                wordBreak: "break-all", // Break long words
                overflow: "hidden", // Hide overflow
                maxWidth: "300px", // Set a max width to see the effect
                padding: "8px",
              }}
            >
              {row.original.name}
            </Box>
          );
        },
      }),
      columnHelper.accessor("recipes", {
        header: "Recipes",
        cell: ({ row }) => {
          let recipes = row.original.recipes;
          return recipes.length ? (
            <Box>
              {recipes?.slice(0, 8).map((data, i) => {
                return (
                  <Chip
                    key={i}
                    sx={{
                      mr: 1,
                      mt: 0.5,
                      cursor: "pointer",
                      fontWeight: 500,
                      fontSize: "12px",
                    }}
                    label={data.recipe_name}
                    variant="outlined"
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      navigate(
                        `/recipe/details/${data?.recipe_id}/?basic-details`
                      );
                    }}
                  />
                );
              })}
              {recipes.length > 8 && (
                <Tooltip
                  placement="bottom"
                  arrow
                  title={recipes
                    ?.slice(8, recipes?.length)
                    .map((val) => {
                      return val.recipe_name;
                    })
                    .join(", ")}
                >
                  <Chip
                    sx={{ mr: 1, borderRadius: "15px", height: "20px" }}
                    label={
                      `+${recipes?.length - recipes?.slice(0, 8)?.length}` +
                      " More"
                    }
                    variant="outlined"
                    size="small"
                  />
                </Tooltip>
              )}
            </Box>
          ) : (
            <Typography color="#B54708" fontWeight={500} fontSize="14px">
              No Recipe Tagged
            </Typography>
          );
        },
        enableSorting: false,
        enableSorting: false,
      }),

      columnHelper.display({
        id: "actions",
        cell: (props) => {
          const { row } = props;
          return (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
              gap="8px"
              mr={-1}
            >
              {(isEditable || isDeletable) && (
                <SimpleMenu
                  row={props.row}
                  editAction={(e) => navigate(`edit/${e.id}`)}
                  handleDeleteModal={(e) => onDelete(e.id)}
                  isEditable={isEditable}
                  isDeletable={isDeletable}
                />
              )}

              <img alt="details" src="/icons/arrow_forward.png" />
            </Stack>
          );
        },
      }),
    ],
    []
  );

  const breadcrumbs = [
    <Typography key="1" sx={{ fontSize: "14px", fontWeight: 400 }}>
      Tips & Tricks
    </Typography>,
  ];

  return (
    <>
      <PageListLayout
        breadcrumbs={breadcrumbs}
        pageContent={
          <>
            <Stack sx={{ borderRadius: "4px", bgcolor: "#fff" }}>
              <ServerPaginatedtable
                defaultData={listQuery?.data?.tipsandtricks ?? []}
                columns={columns}
                onSearchChange={onSearchHandler}
                search={search}
                sorting={sorting}
                pagination={pagination}
                onRowAction={(e) => {
                  return window.open(e?.images, "_blank");
                }}
                paginationProps={
                  listQuery.data?.pagination || { total: 0, lastPage: 1 }
                }
                setSorting={(callback) =>
                  setSorting({ tableName: moduleName, callback })
                }
                setPagination={(callback) =>
                  setPagination({ tableName: moduleName, callback })
                }
                headerText={"Tips & Tricks"}
                isLoading={listQuery?.isLoading}
                rightContent={
                  <Stack direction="row" gap="8px">
                    {isCreateVisible && (
                      <Button
                        type="button"
                        size="medium"
                        variant="contained"
                        startIcon={<AddIcon />}
                        onClick={() => navigate("create")}
                      >
                        Tips
                      </Button>
                    )}
                  </Stack>
                }
              />
            </Stack>
          </>
        }
      />
      <DeleteModal
        open={deleteInfo.show}
        handleClose={() => resetDeleteInfo()}
        onConfirm={() => confirmDelete()}
      />
    </>
  );
};
