import { Button, Stack, Typography } from "@mui/material";
import React, { useContext } from "react";
import AddIcon from "@mui/icons-material/Add";
import { createColumnHelper } from "@tanstack/react-table";
import DeleteIcon from "../../../assets/images/Icons/TrashIcon.png";
import {
  CustomeDialog,
  DeleteModal,
  ServerPaginatedtable,
} from "../../../shared";
import { TemplateStoreSelectDialog } from "../../../shared/components/TemplateStoreSelectDialog";
import { RegenerateDialog } from "./RegenerateDialog";
import { AuthContext } from "../../../store";

export const TemplateStoreList = (props) => {
  const {
    dialogBoxId,
    state,
    setState,
    assignedStoreState,
    handleModal,
    handleStoreDialogSelect,
    handleStoreToRecipe,
    onSearchHandler,
    pagination,
    setPagination,
    assingedStoreListQueryTemplate,
    confirmDeleteStrores,
    storesDeleteInfo,
    handleDeleteStores,
    clearStoresDeleteInfo,
    sorting,
    setSorting,
    isLoading,
    templates,
    handleAlertClickOpen,
    onChangeStoreEffectiveDate,
    handleStoreDeleteDialog,
    isStoreAdmin,
    onChangeEffectiveDates,
    isStoreAssign,
  } = props;

  const {
    auth: {
      data: { stores },
    },
  } = useContext(AuthContext);

  const { isOpenStoreDialog, selectedStores, alreadySelectedStores } =
    state.selectStoreDialogDetails;

  const handleOnSubmit = () => {
    handleStoreToRecipe();
    handleAlertClickOpen(false, dialogBoxId);
  };

  const columnHelper = createColumnHelper(
    assignedStoreState?.assignedStoreList
  );
  const columns = [
    columnHelper.accessor("state", {
      header: "Store Name",
      cell: (props) => {
        const { row } = props;
        return (
          <>
            <Typography
              sx={{
                color: "#1760D2",
                textTransform: "uppercase",
              }}
            >
              {row?.original?.state}
            </Typography>
            {row.original.storename ? (
              <Stack direction="row" justifyContent="space-between">
                <Typography>{row?.original?.storename}</Typography>
              </Stack>
            ) : (
              []
            )}
          </>
        );
      },
    }),
    columnHelper.display({
      id: "actions",
      cell: (props) => {
        const { row } = props;
        return row.depth && isStoreAdminDelete(row.original.id) ? (
          <Button
            onClick={(e) => {
              handleStoreDeleteDialog(true, row.original.id);
            }}
          >
            <img src={DeleteIcon} />
          </Button>
        ) : (
          ""
        );
      },
    }),
  ];

  function isStoreAdminDelete(id) {
    if (!isStoreAdmin) {
      return true;
    }
    return stores.some((v) => v.id === id);
  }

  return (
    <>
      <ServerPaginatedtable
        defaultData={assignedStoreState?.assignedStoreList}
        columns={columns}
        subRow={"subRows"}
        headerText="Assigned Store"
        onSearchChange={onSearchHandler}
        sorting={sorting}
        setSorting={setSorting}
        isLoading={isLoading}
        pagination={pagination}
        emptyMessage="No stores added yet!."
        search={assignedStoreState?.search}
        paginationProps={
          assingedStoreListQueryTemplate?.data?.pagination || {
            total: 0,
            lastPage: 1,
          }
        }
        setPagination={setPagination}
        rightContent={
          isStoreAssign && (
            <Button
              type="button"
              color="inherit"
              variant="outlined"
              size="small"
              onClick={() => handleModal("store-dialog-add", true)}
              sx={{ borderColor: "#BCC7DB", marginRight: "12px" }}
              startIcon={<AddIcon />}
            >
              Store
            </Button>
          )
        }
        allRowsExpanded={true}
      />
      <CustomeDialog
        open={isOpenStoreDialog}
        PaperProps={{
          sx: { width: "340px", height: "555px", p: "25px" },
        }}
        content={
          <TemplateStoreSelectDialog
            templates={state}
            onChangeStoreEffectiveDate={onChangeStoreEffectiveDate}
            setTemplates={setState}
            selectedStores={selectedStores}
            alreadySelectedStores={alreadySelectedStores}
            onSelect={handleStoreDialogSelect}
            disabled={selectedStores.length === 0}
            isSelected={(id) => selectedStores.includes(id)}
            onSubmit={handleOnSubmit}
            open={templates?.isStoreOpen}
            handleClickOpen={handleAlertClickOpen}
            handleClose={() => handleModal("store-dialog-add", false)}
            assignedStoreState={assignedStoreState}
            id={dialogBoxId}
            assingedStoreListQueryTemplate={assingedStoreListQueryTemplate}
          />
        }
      />
      <DeleteModal
        open={storesDeleteInfo?.show}
        handleClose={() => clearStoresDeleteInfo()}
        onConfirm={() => confirmDeleteStrores()}
      />
      <CustomeDialog
        PaperProps={{
          sx: {
            minWidth: "570px",
            minHeight: "280px",
            borderRadius: "10px",
          },
        }}
        open={state?.isOpen}
        title={
          <Typography
            sx={{
              padding: "20px 20px 8px 20px",
              fontWeight: "500",
              fontSize: "20px",
              lineHeight: "24.2px",
              width: "590px",
            }}
          >
            When do you want to change this update?
          </Typography>
        }
        content={
          <RegenerateDialog
            onSubmit={handleDeleteStores}
            templates={state}
            onChangeEffectiveDate={onChangeEffectiveDates}
            setTemplates={setState}
            handleClose={() => handleStoreDeleteDialog(false)}
            state={state}
          />
        }
      />
    </>
  );
};
