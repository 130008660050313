import {
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  Chip,
  TextField,
  InputAdornment,
  FormHelperText,
} from "@mui/material";
import React, { useState } from "react";
import { CustomeDialog, InputControl } from "../../../shared";
import CloseIcon from "@mui/icons-material/Close";
import { RegenerateDialog } from "./RegenerateDialog";
import IconButton from "@mui/material/IconButton";
import SimpleReactValidator from "simple-react-validator";
import moment from "moment";

export const SceduleDialog = (props) => {
  const {
    onSubmit,
    templates,
    onChangeDate,
    onChangeEffectiveDate,
    RadioButtonChange,
    onChangeSceduler,
    ListofDropdown,
    handleRepeatOptionChange,
    WeekOfMonth,
    weekOfMonthOptionChange,
    MonthDropdown,
    monthOptionChange,
    WeekDays,
    weekDaysOptionChange,
    onChangeYear,
    onChangeHandler,
    DaysOfWeekOptionChange,
    WeekDaysOptionChange,
    OptionChange,
    handleDayClick,
    handleClickOpen,
    handleClickUpdateOpen,
    setTemplates,
    RemoveButtonHandeler,
  } = props;
  const {
    date_range_week,
    month_onday,
    month_onthe,
    year_on,
    year_onthe,
    start_date,
    end_date,
    period,
    date_range_radio,
    date_range_month_radio,
    drop_down_list,
  } = templates.data;
  const [, forceUpdate] = useState();
  const validator = React.useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },
      validators: {
        Item: {
          message: "Each date must be today or in the future.",
          required: true,
          rule: (values) => {
            return (
              values &&
              values?.every(
                (value) =>
                  value && moment(value)?.isSameOrAfter(moment(), "day")
              )
            );
          },
        },
      },
    })
  );
  const handleSubmit = () => {
    if (validator.current.allValid()) {
      if (templates?.id) {
        handleClickUpdateOpen(true, templates?.id);
      } else {
        onSubmit();
      }
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };
  const renderRepeatComponent = () => {
    const dayAbbreviations = {
      Monday: "Mon",
      Tuesday: "Tue",
      Wednesday: "Wed",
      Thursday: "Thu",
      Friday: "Fri",
      Saturday: "Sat",
      Sunday: "Sun",
    };

    switch (drop_down_list) {
      case 2:
        return (
          <Stack direction="row" gap="10px">
            {WeekDays?.map((day, i) => {
              return (
                <Chip
                  key={i}
                  sx={{
                    borderRadius: "53px",
                    width: "45px",
                    height: "45px",
                    "& .MuiChip-label": {
                      fontSize: "10px",
                      lineHeight: "14.52px",
                      fontWeight: "500px",
                      color: date_range_week?.date?.includes(day?.value)
                        ? "white"
                        : "black",
                    },
                  }}
                  label={dayAbbreviations[day?.label]}
                  variant={
                    date_range_week?.date?.includes(day?.value)
                      ? "filled"
                      : "outlined"
                  }
                  color="info"
                  onClick={() => handleDayClick(day?.value)}
                />
              );
            })}
          </Stack>
        );

      case 3:
        return (
          <Stack>
            <FormControl>
              <RadioGroup
                sx={{
                  gap: "18px",
                }}
                value={date_range_month_radio}
                onChange={(event) =>
                  RadioButtonChange(
                    event.target.value,
                    "date_range_month_radio"
                  )
                }
              >
                <Stack direction="row" gap="10px">
                  <FormControlLabel control={<Radio />} label="on" value={4} />

                  <InputControl
                    sx={{ width: "220px" }}
                    value={month_onday.day}
                    type="text"
                    name="day"
                    onChange={onChangeSceduler}
                    disabled={date_range_month_radio === 5}
                    error={
                      date_range_month_radio !== 5 &&
                      validator.current.message(
                        "day",
                        month_onday.day,
                        "required"
                      )
                    }
                    helperText={
                      date_range_month_radio !== 5 &&
                      validator.current.message(
                        "day",
                        month_onday.day,
                        "required"
                      )
                    }
                  />
                </Stack>
                <Stack direction="row" gap="10px">
                  <FormControlLabel
                    control={<Radio />}
                    label="on the"
                    value={5}
                  />

                  <InputControl
                    type="dropdown"
                    fullWidth
                    options={WeekOfMonth}
                    name="weekofmonth"
                    value={
                      WeekOfMonth?.find(
                        (value) => value.value === month_onthe?.weekofmonth
                      ) || null
                    }
                    onChange={(event, option) =>
                      weekOfMonthOptionChange(event, option)
                    }
                    getOptionLabel={(option) => option?.label || ""}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    sx={{
                      width: "242px",
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={
                          date_range_month_radio !== 4 &&
                          validator.current.message(
                            "week of month",
                            month_onthe?.weekofmonth,
                            "required"
                          )
                        }
                        helperText={
                          date_range_month_radio !== 4 &&
                          validator.current.message(
                            "week of month",
                            month_onthe?.weekofmonth,
                            "required"
                          )
                        }
                      />
                    )}
                    disabled={date_range_month_radio === 4}
                  />
                  <InputControl
                    type="dropdown"
                    fullWidth
                    options={WeekDays}
                    name="dayofweek"
                    value={
                      WeekDays?.find(
                        (value) => value.value === month_onthe?.dayofweek
                      ) || null
                    }
                    onChange={(event, option) =>
                      weekDaysOptionChange(event, option)
                    }
                    getOptionLabel={(option) => option?.label || ""}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    sx={{
                      width: "242px",
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={
                          date_range_month_radio !== 4 &&
                          validator.current.message(
                            "day of week",
                            month_onthe?.dayofweek,
                            "required"
                          )
                        }
                        helperText={
                          date_range_month_radio !== 4 &&
                          validator.current.message(
                            "day of week",
                            month_onthe?.dayofweek,
                            "required"
                          )
                        }
                      />
                    )}
                    disabled={date_range_month_radio === 4}
                  />
                </Stack>
              </RadioGroup>
            </FormControl>
          </Stack>
        );

      case 4:
        return (
          <Stack>
            <FormControl>
              <RadioGroup
                sx={{
                  gap: "18px",
                }}
                value={date_range_radio}
                onChange={(event) =>
                  RadioButtonChange(event.target.value, "date_range_radio")
                }
              >
                <Stack direction="row" gap="10px">
                  <FormControlLabel
                    control={<Radio />}
                    label="on day"
                    value={6}
                  />

                  <InputControl
                    type="dropdown"
                    fullWidth
                    options={MonthDropdown}
                    name="month"
                    value={
                      MonthDropdown?.find(
                        (value) => value.value === year_on?.month
                      ) || null
                    }
                    onChange={(event, option) =>
                      monthOptionChange(event, option)
                    }
                    getOptionLabel={(option) => option?.label || ""}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    sx={{
                      width: "250px",
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={
                          date_range_radio !== 7 &&
                          validator.current.message(
                            "month",
                            year_on?.month,
                            "required"
                          )
                        }
                        helperText={
                          date_range_radio !== 7 &&
                          validator.current.message(
                            "month",
                            year_on?.month,
                            "required"
                          )
                        }
                      />
                    )}
                    disabled={date_range_radio === 7}
                  />
                  <InputControl
                    sx={{ width: "220px" }}
                    value={year_on?.day}
                    type="text"
                    name="day"
                    onChange={onChangeYear}
                    error={
                      date_range_radio !== 7 &&
                      validator.current.message("day", year_on?.day, "required")
                    }
                    helperText={
                      date_range_radio !== 7 &&
                      validator.current.message("day", year_on?.day, "required")
                    }
                    disabled={date_range_radio === 7}
                  />
                </Stack>
                <Stack direction="row" gap="10px" alignItems="center">
                  <FormControlLabel
                    control={<Radio />}
                    label="on the"
                    value={7}
                  />
                  <InputControl
                    type="dropdown"
                    fullWidth
                    options={WeekOfMonth}
                    name="dayofweek"
                    value={
                      WeekOfMonth?.find(
                        (value) => value.value === year_onthe?.dayofweek
                      ) || null
                    }
                    onChange={(event, option) =>
                      DaysOfWeekOptionChange(event, option)
                    }
                    getOptionLabel={(option) => option?.label || ""}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={
                          date_range_radio !== 6 &&
                          validator.current.message(
                            "day of week",
                            year_onthe?.dayofweek,
                            "required"
                          )
                        }
                        helperText={
                          date_range_radio !== 6 &&
                          validator.current.message(
                            "day of week",
                            year_onthe?.dayofweek,
                            "required"
                          )
                        }
                      />
                    )}
                    disabled={date_range_radio === 6}
                  />
                  <InputControl
                    type="dropdown"
                    fullWidth
                    options={WeekDays}
                    name="date"
                    value={
                      WeekDays?.find(
                        (value) => value.value === year_onthe?.date
                      ) || null
                    }
                    onChange={(event, option) =>
                      WeekDaysOptionChange(event, option)
                    }
                    getOptionLabel={(option) => option?.label || ""}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={
                          date_range_radio !== 6 &&
                          validator.current.message(
                            "date",
                            year_onthe?.date,
                            "required"
                          )
                        }
                        helperText={
                          date_range_radio !== 6 &&
                          validator.current.message(
                            "date",
                            year_onthe?.date,
                            "required"
                          )
                        }
                      />
                    )}
                    disabled={date_range_radio === 6}
                  />
                  <Typography>of</Typography>
                  <InputControl
                    type="dropdown"
                    fullWidth
                    options={MonthDropdown}
                    name="month"
                    value={
                      MonthDropdown?.find(
                        (value) => value.value === year_onthe?.month
                      ) || null
                    }
                    onChange={(event, option) => OptionChange(event, option)}
                    getOptionLabel={(option) => option?.label || ""}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={
                          date_range_radio !== 6 &&
                          validator.current.message(
                            "month",
                            year_onthe?.month,
                            "required"
                          )
                        }
                        helperText={
                          date_range_radio !== 6 &&
                          validator.current.message(
                            "month",
                            year_onthe?.month,
                            "required"
                          )
                        }
                      />
                    )}
                    disabled={date_range_radio === 6}
                  />
                </Stack>
              </RadioGroup>
            </FormControl>
          </Stack>
        );
      default:
        return null;
    }
  };

  return (
    <Stack
      gap="10px"
      sx={{
        padding: "12px 20px",
      }}
    >
      {validator.current.purgeFields()}

      <Stack gap="16px" marginTop="25px">
        <Typography>Set Recurring:</Typography>
        <InputControl
          sx={{ width: "100%" }}
          name="start_date"
          label="Start Date"
          value={start_date ? start_date : null}
          type="datePicker"
          disablePast
          required
          inputFormat="MM/dd/yyyy"
          onKeyDown={(e) => {
            e.preventDefault();
          }}
          onChange={(dateValue) => onChangeDate("start_date", dateValue)}
          error={validator.current.message(
            "Start Date",
            start_date,
            "required"
          )}
          helperText={validator.current.message(
            "Start Date",
            start_date,
            "required"
          )}
        />
        <Stack direction="row" gap="10px" alignItems="center">
          <Typography sx={{ width: "200px" }}>Repeat Every</Typography>
          <InputControl
            sx={{ width: "220px" }}
            value={period}
            type="text"
            name="period"
            onChange={onChangeHandler}
            error={validator.current.message("period", period, "required")}
          />
          <InputControl
            type="dropdown"
            fullWidth
            options={ListofDropdown}
            name="drop_down_list"
            value={
              ListofDropdown?.find((value) => value.value === drop_down_list) ||
              null
            }
            onChange={(event, option) =>
              handleRepeatOptionChange(event, option)
            }
            getOptionLabel={(option) => option?.label || ""}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            sx={{
              minWidth: "130px",
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                name="drop_down_list"
                required
                error={validator.current.message(
                  "drop_down_list",
                  drop_down_list,
                  "required"
                )}
              />
            )}
          />
        </Stack>
        <FormHelperText sx={{ color: "#d32f2f" }}>
          {validator.current.message(
            "Repeat Every",
            period && drop_down_list,
            "required"
          )}
        </FormHelperText>

        {renderRepeatComponent()}

        {end_date ? (
          <InputControl
            sx={{ width: "250px" }}
            name="end_date"
            label="End Date"
            value={end_date ? end_date : null}
            type="datePicker"
            disablePast
            inputFormat="MM/dd/yyyy"
            onKeyDown={(e) => {
              e.preventDefault();
            }}
            onChange={(dateValue) => onChangeDate("end_date", dateValue)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="clear input"
                    onClick={RemoveButtonHandeler}
                    edge="end"
                    disabled={!end_date}
                  >
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        ) : (
          <InputControl
            sx={{ width: "100%" }}
            name="end_date"
            label="End Date"
            value={end_date ? end_date : null}
            type="datePicker"
            disablePast
            inputFormat="MM/dd/yyyy"
            onKeyDown={(e) => {
              e.preventDefault();
            }}
            onChange={(dateValue) => onChangeDate("end_date", dateValue)}
            handleClear={RemoveButtonHandeler}
          />
        )}
      </Stack>

      <Stack
        direction="row"
        gap="6px"
        padding="4px"
        sx={{ justifyContent: "flex-end" }}
      >
        <Button
          variant="outlined"
          width="78px"
          color="gray2"
          onClick={() => handleClickOpen(false)}
        >
          Cancel
        </Button>

        <Button
          variant="contained"
          width="78px"
          disabled={false}
          onClick={handleSubmit}
        >
          {templates?.id ? "Update" : "Save"}
        </Button>

        {templates.isScheduleUpdateOpen && (
          <CustomeDialog
            PaperProps={{
              sx: {
                minWidth: "570px",
                minHeight: "280px",
                borderRadius: "10px",
              },
            }}
            open={templates?.isScheduleUpdateOpen}
            handleClose={() => handleClickUpdateOpen(false, templates?.id)}
            title={
              <Typography
                sx={{
                  padding: "20px 20px 8px 20px",
                  fontWeight: "500",
                  fontSize: "20px",
                  lineHeight: "24.2px",
                  width: "590px",
                }}
              >
                When do you want to change this update?
              </Typography>
            }
            content={
              <RegenerateDialog
                onSubmit={onSubmit}
                templates={templates}
                onChangeEffectiveDate={onChangeEffectiveDate}
                setTemplates={setTemplates}
                handleClose={() => handleClickUpdateOpen(false)}
                state={templates}
              />
            }
          />
        )}
      </Stack>
    </Stack>
  );
};
