import React from "react";
import ReactDOM from "react-dom/client";
import { Master } from "./master/Master";
import { BrowserRouter } from "react-router-dom";
import "./assets/scss/_common.scss";
import "./assets/scss/_general.scss";
import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Master />
  </BrowserRouter>
);
