import React from "react";
import { Route, Routes } from "react-router-dom";
import {
  AddorEditRoles,
  RoleList,
  RolesAndPermissionDetails,
} from "./containers";

export const RolesAndPermissions = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<RoleList />} />
        <Route>
          <Route path="/create" element={<AddorEditRoles />} />
        </Route>
        <Route>
          <Route path="/edit/:id" element={<AddorEditRoles />} />
        </Route>
        <Route>
          <Route path="/details/:id" element={<RolesAndPermissionDetails />} />
        </Route>
      </Routes>
    </>
  );
};
