import { Box, Typography } from "@mui/material";
import React from "react";

export const CustomTabPanel = (props) => {
  const { children, value, index, border = true, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            margin: "8px",
            borderRadius: "4px",
            border: border ? "1px solid #DFE8F2" : 0,
          }}
        >
          {children}
        </Box>
      )}
    </div>
  );
};
