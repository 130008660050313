import { TableSortLabel, Typography } from "@mui/material";
import ascendingIcon from "../../assets/images/Icons/ascending.png";
import descendingIcon from "../../assets/images/Icons/descending.png";

/**
 * A sortable table header component that manages sorting state and displays the current sort direction.
 *
 * @param {Object} props - The properties for the SortableTableHeader component.
 * @param {boolean} props.isSortActive - Indicates if the current sorting is active.
 * @param {string} props.sort - The current sorting direction ('asc' or 'desc').
 * @param {string} props.headerId - The ID of the header for sorting.
 * @param {string} props.headerName - The display name of the header.
 * @param {string} props.headerHover - The ID of the currently hovered header.
 * @param {function} props.onSortChange - Function to call when the sort order changes.
 * @returns {JSX.Element} The rendered sortable table header.
 */
export const SortableTableHeader = ({
  isSortActive,
  sort,
  headerId,
  headerName,
  headerHover,
  onSortChange,
}) => {
  return (
    <TableSortLabel
      active={isSortActive}
      direction={isSortActive ? sort : undefined}
      onClick={onSortChange}
      IconComponent={() => (
        <CustomSortIcon
          direction={sort}
          headerId={headerId}
          hover={headerHover}
        />
      )}
    >
      <Typography
        sx={{
          fontWeight: 600,
          fontSize: "12px",
          lineHeight: "14px",
        }}
      >
        {headerName}
      </Typography>
    </TableSortLabel>
  );
};

/**
 * Custom sort icon component that displays different icons based on sorting direction and hover state.
 *
 * @param {Object} props - The properties for the CustomSortIcon component.
 * @param {string} props.headerId - The ID of the header.
 * @param {string} props.direction - The current sorting direction ('asc', 'desc', or undefined).
 * @param {string} props.hover - The ID of the currently hovered header.
 * @returns {JSX.Element|null} The rendered icon or null.
 */
const CustomSortIcon = ({ headerId, direction, hover }) => {
  switch (direction) {
    case "asc":
      return <img src={ascendingIcon} alt="ascending" width={14} height={14} />;
    case "desc":
      return (
        <img src={descendingIcon} alt="descending" width={14} height={14} />
      );
    default:
      return (
        <img
          src={ascendingIcon}
          alt="ascending"
          width={14}
          height={14}
          style={{
            opacity: 0.5,
            visibility: hover === headerId ? "visible" : "hidden",
          }}
        />
      );
  }
};
