import {
  useQuery,
  useQueryClient,
  keepPreviousData,
  useMutation,
} from "@tanstack/react-query";
import qs from "qs";
import {
  editSceduler,
  getDropDownLists,
  getProductionDaysList,
  getTemplatesDetails,
  getTemplatesList,
  saveProductionDays,
  updateName,
  updateStatus,
  copycreateTemplate,
  createTemplate,
  getRecipeLists,
  removeTemplate,
  updateTemplate,
  getCategoryLists,
  createName,
  addCustomDays,
  removeRecurringDate,
} from "../api/TemplatesApi";
import {
  ErrorFormatter,
  errorMessage,
  navigateBasedOnUserType,
  successMessage,
} from "../../../utils/helper";
import { useImmer } from "use-immer";
import { useNavigate } from "react-router-dom";

const TemplateListKeys = {
  all: ["templates"],
  lists: () => [...TemplateListKeys.all, "templatelist"],
  templatelist: (filters) => [...TemplateListKeys.lists(), filters],
  detail: (id) => [...TemplateListKeys.templatelist(), id],
};

const productiondaysKeys = {
  all: ["productiondays"],
  list: () => [...productiondaysKeys.all, "productiondayslist"],
  productiondayslist: (filters) => [...productiondaysKeys.list(), { filters }],
  detail: (id) => [...productiondaysKeys.productiondayslist(), id],
};

const dropdownKeys = {
  all: ["days"],
  list: () => [...dropdownKeys.all, "dropdownlist"],
  dropdownlist: (filters) => [...dropdownKeys.list(), { filters }],
  detail: (id) => [...dropdownKeys.dropdownlist(), id],
};
const RecipeListKeys = {
  all: ["recipe"],
  lists: () => [...RecipeListKeys.all, "recipelist"],
  recipelist: (filters) => [...TemplateListKeys.lists(), filters],
  detail: (id) => [...RecipeListKeys.recipelist(), id],
};
const RecipeListsKeys = {
  all: ["recipe"],
  lists: () => [...RecipeListsKeys.all, "recipeslist"],
  recipeslist: (filters) => [...RecipeListsKeys.lists(), filters],
  detail: (id) => [...RecipeListsKeys.recipeslist(), id],
};

export const useTemplateQueries = ({
  debouncedText,
  sorting,
  pagination,
  status = -1,
  schedule = -1,
  resetDeleteInfo,
  details_id,
  dropdownlist,
  filterApplied,
  tempId,
  updateId,
  list,
  setTemplates,
  templates,
  isStoreAdmin,
  selectedDate,
  isList,
}) => {
  const [state, setState] = useImmer({
    templateDetails: {},
    isCreate: false,
    isDuplicate: false,
    errorMessage: "",
    isErrorMessage: false,
  });
  const queryClient = useQueryClient();

  const storeId = filterApplied.map((item) => item?.id);
  let params = {};
  const navigate = useNavigate();
  if (debouncedText) {
    params = { ...params, search: debouncedText };
  }
  if (storeId) {
    params = { ...params, stores: storeId };
  }

  if (sorting?.length > 0) {
    const sortByParams = sorting.reduce(
      (obj, item) =>
        Object.assign(obj, { [item.id]: item.desc ? "desc" : "asc" }),
      {}
    );
    params = {
      ...params,
      sort: sortByParams,
    };
  }

  const query = {
    params,
    paramsSerializer: (params) => qs.stringify(params, { encode: true }),
  };

  //list query
  const listQuery = useQuery({
    queryKey: TemplateListKeys.templatelist({
      query,
      pageIndex: pagination.pageIndex,
      pageSize: pagination.pageSize,
      status,
      schedule,
    }),
    queryFn: () =>
      getTemplatesList({
        query,
        pagination,
        status,
        storeId,
        schedule,
      }),
    placeholderData: keepPreviousData,
  });

  //dropdownList
  const dropdownListQuery = useQuery({
    queryKey: dropdownKeys.dropdownlist(),
    queryFn: () => getDropDownLists(),
    placeholderData: keepPreviousData,
    enabled: dropdownlist,
  });

  //delete template
  const deleteTemplate = useMutation({
    mutationFn: removeTemplate,
    onError: (error) => {
      errorMessage(error.response.data.message);
    },
    onSuccess: (res, variables) => {
      if (res.success) {
        if (isList) {
          queryClient.invalidateQueries({
            queryKey: TemplateListKeys.lists(),
          });
        }

        successMessage(res.message);
        resetDeleteInfo();
        navigateBasedOnUserType("/templates", isStoreAdmin, navigate);
      } else if (res.success === false) {
        errorMessage(res.message);
      }
    },
  });

  //template details
  const templateDetails = useQuery({
    queryKey: TemplateListKeys.detail(tempId ? tempId : details_id),
    queryFn: () => getTemplatesDetails(tempId ? tempId : details_id),
    enabled: details_id > 0 || tempId > 0 || templates.scheduleOpen,
  });

  const categorylistQuery = useQuery({
    queryKey: RecipeListKeys.recipelist(),
    queryFn: () => getCategoryLists(),
    placeholderData: keepPreviousData,
    enabled: list,
  });

  const recipelistQuery = useQuery({
    queryKey: RecipeListsKeys.recipeslist(),
    queryFn: () => getRecipeLists("finishedproduct"),
    enabled: true,
  });

  const generateBatch = useMutation({
    mutationFn: ({ variable, data }) => createTemplate(data),
    onError: (error) => {
      errorMessage(error.response.data.message);
    },
    onSuccess: (data, variables) => {
      setState((draft) => {
        draft.templateDetails = data;
        draft.isCreate = true;
      });
      queryClient.invalidateQueries({
        queryKey: TemplateListKeys.lists(),
      });
      successMessage(data?.message);
      return variables.handleClose();
    },
  });

  const reGenerateBatch = useMutation({
    mutationFn: ({ _, data }) => updateTemplate(updateId, data),
    onError: (error) => {
      errorMessage(ErrorFormatter(error.response.data.errors));
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: TemplateListKeys.templatelist(),
      });
      queryClient.invalidateQueries({
        queryKey: TemplateListKeys.detail(tempId),
      });
      successMessage(_.message);
      variables.handleRegenerateOpenClick();
    },
  });
  const copyBatch = useMutation({
    mutationFn: ({ variable, data }) => copycreateTemplate(tempId, data),
    onError: (error) => {
      errorMessage(error.response.data.message);
    },
    onSuccess: (data, variables) => {
      setState((draft) => {
        draft.templateDetails = data;
        draft.isCreate = true;
        draft.isDuplicate = true;
      });

      queryClient.invalidateQueries({
        queryKey: TemplateListKeys.lists(),
      });
      successMessage("Template copy created successfully!!");
      return variables.handleClose();
    },
  });
  //template production days details
  const templateProductionDaysDetails = useQuery({
    queryKey: productiondaysKeys.detail(details_id),
    queryFn: () => getProductionDaysList(details_id),
    enabled: details_id > 0 || templates?.scheduleOpen,
  });

  //template scedule creation
  const createScedule = useMutation({
    mutationFn: ({ _, data }) => saveProductionDays(data),
    onError: (error) => {
      errorMessage(error.response.data.message);
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: TemplateListKeys.lists(),
      });
      successMessage("Production days created successfully");
      variables.handleScheduleOpen();
      setTemplates((draft) => {
        draft.isLoading = false;
      });
      queryClient.invalidateQueries({
        queryKey: productiondaysKeys.detail(details_id),
      });
    },
  });

  // edit scheduler
  const updateSceduler = useMutation({
    mutationFn: editSceduler,
    onError: (error) => {
      errorMessage(error.message);
    },
    onSuccess: (res, variables) => {
      if (res.success) {
        queryClient.invalidateQueries({
          queryKey: productiondaysKeys.list(),
        });
        successMessage(res?.message);
        variables.handleClickUpdateOpen();
        variables.handleScheduleOpen();
        setTemplates((draft) => {
          draft.isLoading = false;
        });
        queryClient.invalidateQueries({
          queryKey: productiondaysKeys.detail(details_id),
        });
      } else {
        errorMessage(res.message);
      }
    },
  });

  //update status
  const updateTemplateStatus = useMutation({
    mutationFn: updateStatus,
    onError: (error) => {
      setState((draft) => {
        draft.errorMessage = error?.response?.data?.error;
        draft.isErrorMessage = true;
      });
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: TemplateListKeys.lists(),
      });
      successMessage(_.message);
      setTemplates((draft) => {
        draft.isLoading = false;
      });
      variables.handleClickOpen();
    },
  });

  //edit name

  const createTemplateName = useMutation({
    mutationFn: createName,
    onError: (error) => {
      errorMessage(ErrorFormatter(error.response.data.errors));
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: TemplateListKeys.lists(),
      });
      successMessage(data?.message);
      variables.handleOpenClick(false);
      variables.handleOpenClickDetailCopy(false);
      navigateBasedOnUserType(
        `/templates/details/${state?.templateDetails?.data?.template_id}?settings`,
        isStoreAdmin,
        navigate
      );
    },
  });

  const updateTemplateName = useMutation({
    mutationFn: updateName,
    onError: (error) => {
      ErrorFormatter(
        errorMessage(error?.response?.data?.errors?.template_name)
      );
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: TemplateListKeys.lists(),
      });
      successMessage(_.message);
      variables.handleNameOpen();
    },
  });
  const handleAddCustomDays = useMutation({
    mutationFn: ({ data }) => addCustomDays(data),
    onError: (error) => {
      errorMessage(error.response.data.message);
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: TemplateListKeys.lists(),
      });

      setTemplates((draft) => {
        draft.isDateSaveButton = true;
        draft.data.custom_dates_view = templates.data.custom_dates_view.filter(
          (value) => {
            return value !== selectedDate;
          }
        );
      });
      successMessage(data?.message);
      variables.onDeleteTagSelect(false);
    },
  });
  //template recurring date delete
  const deleteRecurringDate = useMutation({
    mutationFn: removeRecurringDate,
    onError: (error) => {
      if (error?.response?.data?.message) {
        errorMessage(error.response.data.message);
      } else {
        errorMessage("An unknown error occurred");
      }
    },

    onSuccess: (res, variables) => {
      if (res?.success) {
        queryClient.invalidateQueries({
          queryKey: TemplateListKeys.templatelist(),
        });
        successMessage(res.message);
        setTemplates((draft) => {
          draft.isRecurringDateDelete = 1;
        });
        window.location.reload();

        if (typeof variables?.handleRemoveRecurringDate === "function") {
          variables.handleRemoveRecurringDate(false);
        }
      } else {
        errorMessage(
          res?.message || "An error occurred while removing the recurring date."
        );
      }
    },
  });

  return {
    listQuery,
    deleteTemplate,
    templateDetails,
    templateProductionDaysDetails,
    createScedule,
    dropdownListQuery,
    updateSceduler,
    updateTemplateStatus,
    categorylistQuery,
    recipelistQuery,
    generateBatch,
    state,
    reGenerateBatch,
    copyBatch,
    updateTemplateName,
    setState,
    createTemplateName,
    handleAddCustomDays,
    deleteRecurringDate,
  };
};
