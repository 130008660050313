import React, { useContext } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { AuthContext } from "../../store";

export const ProtectedRoute = ({ allowedRoute, showOnStoreAdmin = true }) => {

  const { pathname } = useLocation();
  const isStoreAdmin = pathname.includes("store-admin");
  const { auth } = useContext(AuthContext);

  let allowed = true;
  const hasPermission = auth.data.permissions?.find((item) =>
    allowedRoute?.includes(item.name)
  );
  if (!hasPermission) {
    allowed = false;
  }
  if (isStoreAdmin) {
    if (showOnStoreAdmin) {
      allowed = true;
    } else {
      allowed = false;
    }
    if (!hasPermission) {
      allowed = false;
    }
  }

  return (
    !auth.isInitialLoad &&
    (allowed ? (
      <Outlet />
    ) : auth.data.id ? (
      <Navigate
        to={isStoreAdmin ? "/store-admin/unauthorized" : "/unauthorized"}
        replace
      />
    ) : (
      <Navigate to="/authentication/login" replace />
    ))
  );
};
