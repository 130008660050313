import { useUnArchiveRecipeQueries } from "./useUnArchiveRecipeQueries";

export const useUnArchiveRecipe = () =>
{
    const {mutateUnArchiveRecipe} = useUnArchiveRecipeQueries();
    const onUnArchiveRecipe = (recipeId) =>
    {
        mutateUnArchiveRecipe.mutate(recipeId);
    }
    return {onUnArchiveRecipe};
}