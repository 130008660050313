import { useImmer } from "use-immer";
import { useStoreSelectQueries } from "./useStoreSelectQueries";

export const useStoreSelect = ({storelist=false,onChangeStateHandler})=>
{
    
      const StoreValuesHandler = (option) => {
        const selectedOption = option ? option.id : 0;
        onChangeStateHandler(selectedOption);
      };
      const {
        listQuery,
      } = useStoreSelectQueries({
        storelist
      });
    return {listQuery,StoreValuesHandler}
}