import { useArchiveRecipeQueries } from "./useArchiveRecipeQueries";

export const useArchiveRecipe = () =>
{
    const {mutateCreateArchiveRecipe} = useArchiveRecipeQueries();
    const onArchiveRecipeCreate = (recipeId) =>
    {
        mutateCreateArchiveRecipe.mutate(recipeId);
    }
    return {onArchiveRecipeCreate};
}