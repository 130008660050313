import {
  useQueryClient,
  useQuery,
  useMutation,
  keepPreviousData,
} from "@tanstack/react-query";
import qs from "qs";
import {
  editTemplates,
  getFutureChartList,
  getProductionChart,
  getProductionChartList,
  getProductionChartTemplateList,
  getStores,
} from "../api/useProductionApi";
import { format } from "date-fns";
import { getCategoryLists, getRecipeLists } from "../../Templates/api";
import { errorMessage, successMessage } from "../../../utils/helper";

const productionChartKeys = {
  all: ["productionChart"],
  lists: () => [...productionChartKeys.all, "productionChartlist"],
  chartlist: (filters) => [...productionChartKeys.lists(), filters],
  futureChart: (filters) => [
    ...productionChartKeys.lists(),
    filters,
    "futureList",
  ],
  detail: (id) => [...productionChartKeys.lists(), id],
  templates: (id) => [...productionChartKeys.lists(), id, "tempalteList"],
  category: (id) => [...productionChartKeys.lists(), id, "categoryList"],
  recipe: (id) => [...productionChartKeys.lists(), id, "recipeList"],
  stores: (id) => [...productionChartKeys.lists(), id, "storesList"],
};

export const useProductionChartQuery = ({
  list,
  futureList,
  detId,
  pagination,
  debouncedText,
  sorting,
  storeId,
  dates,
  isRecipe,
}) => {
  const queryClient = useQueryClient();
  let params = {};
  let storeIds = storeId.map((v) => v.id);
  if (debouncedText) {
    params = { ...params, search: debouncedText };
  }

  if (storeIds.length > 0) {
    params = { ...params, store_id: storeIds };
  }
  if (dates.length > 0) {
    params = {
      ...params,
      start_date: format(dates[0].startDate, "yyyy-MM-dd"),
      end_date: format(dates[0].endDate, "yyyy-MM-dd"),
    };
  }
  if (futureList && storeIds.length > 0) {
    params = { ...params, stores: storeIds };
  }

  if (sorting?.length > 0) {
    const sortByParams = sorting.reduce(
      (obj, item) =>
        Object.assign(obj, { [item.id]: item.desc ? "desc" : "asc" }),
      {}
    );
    params = {
      ...params,
      sort: sortByParams,
    };
  }

  const query = {
    params,
    paramsSerializer: (params) => qs.stringify(params, { encode: true }),
  };

  //list query
  const listQuery = useQuery({
    queryKey: productionChartKeys.chartlist({ query, pagination }),
    queryFn: () => getProductionChartList({ query, pagination }),
    placeholderData: keepPreviousData,
    enabled: list,
  });

  const futureListQuery = useQuery({
    queryKey: productionChartKeys.futureChart({ query, pagination }),
    queryFn: () => getFutureChartList({ payload: params, pagination }),
    placeholderData: keepPreviousData,
    enabled: futureList,
  });

  const productionChartDetails = useQuery({
    queryKey: productionChartKeys.detail(detId),
    queryFn: () => getProductionChart({ date: detId, isRecipe }),
    enabled: !!detId || (isRecipe === 0 && isRecipe === 1),
    refetchOnWindowFocus: false,
  });

  const templateList = useQuery({
    queryKey: productionChartKeys.templates(detId),
    queryFn: () => getProductionChartTemplateList(detId),
    // enabled: !!detId,
  });
  const categorylistQuery = useQuery({
    queryKey: productionChartKeys.category(),
    queryFn: () => getCategoryLists(),
    placeholderData: keepPreviousData,
    enabled: true,
  });
  const recipelistQuery = useQuery({
    queryKey: productionChartKeys.recipe(isRecipe),
    queryFn: () => getRecipeLists("finishedproduct"),
    enabled: (isRecipe === 0 && true) || (isRecipe === 1 && true),
  });

  const editTemplate = useMutation({
    mutationFn: ({ _, payload }) => editTemplates(payload),
    onError: (error) => {
      errorMessage(error.response.data.message);
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: productionChartKeys.lists(),
      });
      successMessage(_.message);
      return variables.handleModal();
    },
  });
  return {
    listQuery,
    productionChartDetails,
    futureListQuery,
    templateList,
    categorylistQuery,
    recipelistQuery,
    editTemplate,
  };
};
