import {
  useQueryClient,
  useQuery,
  useMutation,
  keepPreviousData,
} from "@tanstack/react-query";
import {
  getWastageList,
  updateWastage,
  getCategoryList,
} from "../api/WastageApi";
import { errorMessage, successMessage } from "../../../utils/helper";


const wastageKeys = {
  all: ["wastege"],
  lists: () => [...wastageKeys.all, "wastegelist"],
  wastegelist: (filters) => [...wastageKeys.lists(), filters],
  categoriesList: () => ["categoriesFilterList"],
}

export const useWastageQuery = ({ }) => {
  const queryClient = useQueryClient();

  const listQuery = useQuery({
    queryKey: wastageKeys.wastegelist({}),
    queryFn: () => getWastageList({}),
    placeholderData: keepPreviousData,
  });

  const categoriesListQuery = useQuery({
    queryKey: wastageKeys.categoriesList(),
    queryFn: () => getCategoryList(),
    enabled: true,
  });

  const updateWastageValues = useMutation({
    mutationFn: ({ _, data }) => updateWastage(data),
    onError: (error) => {
      errorMessage(error.response.data.message);
    },
    onSuccess: (_, variables) => {
      // queryClient.invalidateQueries({
      //   // queryKey: wastageKeys.lists(),
      // });
      successMessage(_.message);
      variables.afterOnSave();
    },
  });

  return {
    listQuery,
    categoriesListQuery,
    updateWastageValues,
  };
};
