import {
  Button,
  Grid,
  Stack,
  TextField,
  Typography,
  Link,
} from "@mui/material";
import React from "react";
import { InputControl, PageListLayout } from "../../../shared";
import { useNavigate } from "react-router-dom";
import { useAdminUsers } from "../hooks";

export const AddOrEditAdminUsers = () => {
  const {
    state,
    onChange,
    validator,
    storeLists,
    onSubmit,
    userListQuery,
    rolesListQuery,
  } = useAdminUsers({
    isAdd: true,
  });
  const navigate = useNavigate();

  const breadcrumbs = [
    <Link
      underline="none"
      key="1"
      color="#1760D2"
      onClick={(e) => navigate("/manage-users")}
      sx={{ fontSize: "14px", fontWeight: 400 }}
    >
      Manage Users
    </Link>,
    <Typography
      key="2"
      color="inherit"
      sx={{ fontSize: "14px", fontWeight: 400 }}
    >
      Add User
    </Typography>,
  ];

  return (
    <>
      <PageListLayout
        breadcrumbs={breadcrumbs}
        headerText={"Add User"}
        isChanged={false}
        rightContent={
          <Stack direction="row" gap="8px">
            <Button
              type="button"
              size="small"
              variant="contained"
              color="inherit"
              onClick={() => navigate(-1)}
            >
              Cancel
            </Button>
            <Button
              type="button"
              size="small"
              variant="contained"
              onClick={onSubmit}
            >
              Save
            </Button>
          </Stack>
        }
        pageContent={
          <>
            <Stack sx={{ borderRadius: "4px", bgcolor: "#fff" }}>
              <Stack
                sx={{
                  py: "32px",
                  borderBottom: "1px solid #DFE8F2",
                  mx: "20px",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4}>
                    <Typography
                      variant="subtitle1"
                      fontWeight={600}
                      sx={{ width: "150px" }}
                    >
                      User Details
                    </Typography>
                  </Grid>
                  <Grid item sm={8}>
                    <Stack width={"100%"} gap="15px">
                      <InputControl
                        required
                        sx={{
                          width: "100%",
                        }}
                        type="dropdown"
                        disableClearable
                        options={rolesListQuery.data ?? []}
                        getOptionLabel={(option) => option?.display_name}
                        value={
                          (rolesListQuery.data?.length > 0 &&
                            state?.addData?.role_id &&
                            rolesListQuery.data?.find(
                              (c) => c.id === state?.addData?.role_id
                            )) ||
                          null
                        }
                        onChange={(e, newValue) => {
                          onChange({
                            target: {
                              name: "role_id",
                              value: newValue?.id,
                            },
                          });
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Role"
                            required
                            error={validator.current.message(
                              "Role",
                              state?.addData?.role_id,
                              "required"
                            )}
                            helperText={validator.current.message(
                              "Role",
                              state?.addData?.role_id,
                              "required"
                            )}
                          />
                        )}
                      />

                      <InputControl
                        required
                        sx={{
                          width: "100%",
                        }}
                        type="dropdown"
                        disableClearable
                        options={userListQuery?.data ?? []}
                        getOptionLabel={(option) => option?.first_name}
                        value={
                          (userListQuery?.data?.length > 0 &&
                            state?.addData?.user_id &&
                            userListQuery?.data?.find(
                              (c) => c.id === state?.addData?.user_id
                            )) ||
                          null
                        }
                        onChange={(e, newValue) => {
                          onChange({
                            target: {
                              name: "user_id",
                              value: newValue?.id,
                            },
                          });
                        }}
                        renderOption={(props, option, { selected }) => (
                          <>
                            <Stack
                              sx={{
                                alignItems: "flex-start !important",
                              }}
                              {...props}
                              key={option.id}
                            >
                              <div>{option.first_name}</div>
                              <Typography variant="body2" color="#666666">
                                {option.email}
                              </Typography>
                            </Stack>
                          </>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="User"
                            required
                            error={validator.current.message(
                              "User",
                              state?.addData?.user_id,
                              "required"
                            )}
                            helperText={validator.current.message(
                              "User",
                              state?.addData?.user_id,
                              "required"
                            )}
                          />
                        )}
                      />
                    </Stack>
                  </Grid>
                </Grid>
              </Stack>
            </Stack>
          </>
        }
      />
    </>
  );
};
