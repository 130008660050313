import { useMemo } from "react";

export const usePairedStatus = ({ onChangePiredHandler }) => {
  const pairedOptions = useMemo(
    () => [
      { id: 1, name: "Paired" },
      { id: 0, name: "Unpaired" },
    ],
    []
  );

  const pairedValueChangeHandler = (option) => {
    const selectedOption = option ? option.id : "";
    onChangePiredHandler(selectedOption);
  };
  return { pairedOptions, pairedValueChangeHandler };
};
