import { Stack } from "@mui/material";
import { CustomDetailsList, Loader } from "../../../shared";
import { RecipeWeight } from "./RecipeWeight";

export const BasicDetailsView = ({
  basicDetailsQuery,
  alternative_uom = [],
  total_weight,
  optionsTaxGroup,
  selling_unit_name,
  total_weight_unit,
}) => {
  if (basicDetailsQuery.isLoading) {
    return <Loader />;
  }
  const bsData = {
    id: 4,
    bowl_capacity: basicDetailsQuery.data.bowl_capacity,
  };

  const bdData = {
    id: 3,
    category: basicDetailsQuery.data.category_name,
    sub_category: basicDetailsQuery.data.subcategory_name,
    type: basicDetailsQuery.data.type,
    plu: basicDetailsQuery.data.plu,
    tax_group_id: basicDetailsQuery.data.tax_group_id,
    selling_unit_name: selling_unit_name,
  };

  const bdColumns = [
    {
      title: "PLU Code",
      accessor: "plu",
      hidden: (val) => val.type === "recipe",
    },
    {
      title: "Category",
      accessor: "category",
    },
    {
      title: "Sub Category",
      accessor: "sub_category",
    },
    {
      title: "Type",
      accessor: (val) =>
        `${val.type === "finishedproduct" ? "Finished Product" : "Recipe"}`,
    },
    {
      title: "Tax Group",
      accessor: (val) =>
        optionsTaxGroup?.find((i) => i.value === val?.tax_group_id)?.label,
    },
    {
      title: "Selling Unit",
      accessor: (val) => (val.type !== "recipe" ? val.selling_unit_name : ""),
      hidden: (val) => val.type === "recipe",
    },
  ];

  const bsColumns = [
    {
      title: "Bowl Capacity",
      accessor: (val) => `${val.bowl_capacity} kg`,
    },
  ];
  return (
    <>
      <Stack sx={{ borderRadius: "4px", bgcolor: "#fff", px: "16px" }}>
        <CustomDetailsList
          columns={bdColumns}
          data={bdData}
          title="Basic Details"
        />
        {bsData.bowl_capacity !== 0 && (
          <CustomDetailsList
            columns={bsColumns}
            data={bsData}
            title="Batch Settings"
          />
        )}
      </Stack>
      {alternative_uom && (
        <RecipeWeight
          data={alternative_uom}
          total_weight={total_weight}
          total_weight_unit={total_weight_unit}
        />
      )}
    </>
  );
};
