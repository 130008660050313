import React from "react";
import { CustomModal, DeleteModal } from "../../../shared";
import { CreateOrEditCategory } from "./CreateOrEditCategory";
import { CategoryAndSubCategoryTable } from "./CategoryAndSubCategoryTable";
import { useCategory } from "../hooks/useCategory";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";

export const CategoryAndSubCategory = () => {
  const {
    listQuery: { isLoading, data },
    deleteInfo,
    onDeleteAction,
    resetDeleteInfo,
    confirmDelete,
    onChange,
    onSubmit,
    handleClickOpen,
    handleClose,
    category,
    searchHandler,
    validator,
    onSelectItem,
    updateCategory,
    createCategory,
    CategoryValuesHandler,
    onDragEnd,
    onSubCategoryDragEnd,
    handleProductDialogOpen,
    onCategoryProductOrderChange,
    handleProductSortOrder,
    onSearchChange,
    recipeListQuery,
    deleteCategory,
    isCreateVisible,
    isSubCategoryCreateVisible,
    isSubCategoryEditable,
    isSubCategoryDeletable,
    isEditable,
    isDeletable,
  } = useCategory({ categorylist: true });
  const ColorButton = styled(Button)(({ theme }) => ({
    backgroundColor: "#F5F8FC",
    color: "black",
    width: "87px",
    height: "36px",
    "&:hover": {
      backgroundColor: "#F5F8FC",
    },
  }));

  return (
    <>
      <CategoryAndSubCategoryTable
        data={data}
        handleClickOpen={handleClickOpen}
        onSelectItem={onSelectItem}
        onDelete={onDeleteAction}
        searchHandler={searchHandler}
        category={category}
        isLoading={isLoading}
        onDragEnd={onDragEnd}
        onSubCategoryDragEnd={onSubCategoryDragEnd}
        handleProductDialogOpen={handleProductDialogOpen}
        ColorButton={ColorButton}
        onCategoryProductOrderChange={onCategoryProductOrderChange}
        handleProductSortOrder={handleProductSortOrder}
        onSearchChange={onSearchChange}
        recipeListQuery={recipeListQuery}
        isSubCategoryCreateVisible={isSubCategoryCreateVisible}
        isCreateVisible={isCreateVisible}
        isSubCategoryEditable={isSubCategoryEditable}
        isSubCategoryDeletable={isSubCategoryDeletable}
        isEditable={isEditable}
        isDeletable={isDeletable}
      />
      <CustomModal
        handleClose={() => handleClose(false)}
        open={category.isOpen}
        title={
          (category?.id ? "Edit " : "Create ") +
          (category.isSubItem ? "Sub Category" : "Category")
        }
        onSubmit={onSubmit}
        isEdit={category?.id}
        isDisableSubmit={
          category?.id > 0
            ? updateCategory?.isPending
            : createCategory?.isPending
        }
        content={
          <CreateOrEditCategory
            categoryOptions={data}
            isSubItem={category.isSubItem}
            validator={validator}
            state={category}
            isEdit={category?.id}
            onChange={onChange}
            CategoryValuesHandler={CategoryValuesHandler}
          />
        }
      />
      <DeleteModal
        open={deleteInfo.show}
        deleteCategory={deleteCategory}
        handleClose={() => resetDeleteInfo()}
        onConfirm={() => confirmDelete()}
      />
    </>
  );
};
