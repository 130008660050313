import { API } from "../../../utils/api";

export const getTemplatesList = async ({
  query,
  pagination,
  status,
  schedule,
}) => {
  const { pageIndex, pageSize } = pagination;

  let queryFilters = `page=${pageIndex}&per_page=${pageSize}`;

  if (status > -1) {
    queryFilters += `&status=${status ? 1 : 0}`;
  }

  if (schedule > -1) {
    queryFilters += `&scheduled=${schedule ? 1 : 0}`;
  }

  const res = await API.get(`templates?${queryFilters}`, query);
  return res.data.data;
};

export const removeTemplate = async (id) => {
  const res = await API.delete(`templates/${id}`);
  return res.data;
};

export const getAssignedStoreListTemplate = async ({
  templateId,
  query,
  pagination,
}) => {
  const { pageIndex, pageSize } = pagination;
  const res = await API.get(
    `templates/${templateId}/stores?page=${pageIndex}&per_page=${pageSize}`,
    query
  );
  return res.data.data;
};

export const editTemplatesStoreApi = async (data) => {
  const { templateId, payload } = data;

  const { selectedStores, effective_date, source } = payload;
  const res = await API.put(`templates/${templateId}/stores`, {
    store_id: selectedStores,
    effective_date,
    source,
  });
  return res.data;
};

export const getTemplatesDetails = async (id) => {
  const res = await API.get(`templates/${id}`);
  return res.data.data;
};

export const deleteStoreFromTemplate = async (payload) => {
  const { templateId, data } = payload;
  const { store_id, effective_date } = data;
  const res = await API.delete(`templates/${templateId}/stores`, {
    data: {
      store_id: store_id,
      effective_date: effective_date,
    },
  });
  return res.data;
};

export const getProductionDaysList = async (id) => {
  const res = await API.get(`list-sheduler/${id}`);
  return res.data.data;
};

export const saveProductionDays = async (data) => {
  const res = await API.post("schedule", data);
  return res.data.data;
};

export const getDropDownLists = async () => {
  const res = await API.get("boot");
  return res.data.data;
};

export const updateStatus = async (data) => {
  const { details_id, payload } = data;
  const res = await API.put(
    `templates/${details_id}/template-status-change`,
    payload
  );
  return res.data;
};

export const editSceduler = async (payload) => {
  const res = await API.post("edit_shedule", payload.data);
  return res.data;
};

export const getCategoryLists = async () => {
  const res = await API.get(`categories`);
  return res.data.data;
};

export const getRecipeLists = async (type) => {
  const res = await API.get(`recipes?type=${type}&status=${1}`);
  return res.data.data;
};

export const createTemplate = async (payload) => {
  const res = await API.post(`template-batches`, payload);
  return res.data;
};
export const updateTemplate = async (id, payload) => {
  const res = await API.put(`template-batches/${id}`, payload);
  return res.data;
};
export const copycreateTemplate = async (id, payload) => {
  const res = await API.post(`template-copy/${id}`, payload);
  return res.data;
};

export const updateName = async (data) => {
  const { details_id, payload } = data;
  const res = await API.put(`templates/${details_id}`, payload);
  return res.data;
};
export const createName = async (data) => {
  const { payload } = data;
  const res = await API.post(`templates`, payload);
  return res.data;
};
export const addCustomDays = async (data) => {
  const res = await API.post("add-custom-days", data);
  return res.data.data;
};

export const removeRecurringDate = async ({ id }) => {
  const res = await API.get(`remove-reccuring/${id}`);
  return res.data;
};
