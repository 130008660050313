


export const dataList = {
    "pagination": {
        "total": 13,
        "pageIndex": 1,
        "pageSize": 50,
        "pageCount": 1,
        "hasMorePages": false,
        "lastPage": 1
    },
    "data": [
        {
            "templates": [{
                id: 1,
                name: "MondaySpecial",
            },
            {
                id: 1,
                name: "MondaySpecial",
            }, {
                id: 1,
                name: "Thursdayspecial",
            }, {
                id: 1,
                name: "TuesSpecial",
            }],
            "store_count": 3,
            "date": "30-May-2024",
            "batch_count": "2",
            "is_genararted": false
        },
        {
            "templates": [{
                id: 1,
                name: "MondaySpecial",
            },
            {
                id: 1,
                name: "Thursdayspecial",
            }, {
                id: 1,
                name: "TuesSpecial",
            }],
            "store_count": 3,
            "date": "29-May-2024",
            "batch_count": "2",
            "is_genararted": false

        },
        {
            "templates": [{
                id: 1,
                name: "MondaySpecial",
            },
            {
                id: 1,
                name: "MondaySpecial",
            }, {
                id: 1,
                name: "Thursdayspecial",
            }, {
                id: 1,
                name: "TuesSpecial",
            }],
            "store_count": 3,
            "date": "28-May-2024",
            "batch_count": "2",
            "is_genararted": true

        },
        {
            "templates": [
                {
                    id: 1,
                    name: "MondaySpecial",
                }, {
                    id: 1,
                    name: "Thursdayspecial",
                }, {
                    id: 1,
                    name: "TuesSpecial",
                }],
            "store_count": 3,
            "date": "27-May-2024",
            "batch_count": "2",
            "is_genararted": true

        },
        {
            "templates": [{
                id: 1,
                name: "MondaySpecial",
            },
            ],
            "store_count": 3,
            "date": "26-May-2024",
            "batch_count": "2",
            "is_genararted": true
        },
    ]
}

export const detailsData = {
    "success": true,
    "message": "Template retrieved",
    "data": {
        "date": "2024-05-09",
        "stores": [
            {
                "store_id": 1,
                "store_name": "Coding Hands Test Store",
                "batches": [
                    {
                        "recipe_id": 10,
                        "recipe_name": "Refined Granite Tuna",
                        "priority": 1,
                        "total_weight": 4,
                        "is_batch_group": 1,
                        "ingredients": []
                    },
                    {
                        "recipe_id": 4,
                        "recipe_name": "Small Frozen Soap",
                        "priority": 2,
                        "total_weight": "3.50",
                        "is_batch_group": 1,
                        "ingredients": [
                            {
                                "recipe_id": 10,
                                "recipe_name": "Refined Granite Tuna",
                                "pivot": {
                                    "base_unit_qty": 2,
                                    "base_unit_id": 2,
                                    "base_unit_name": "Kilograms",
                                    "is_main_ingredient": 1,
                                    "group_id": 7
                                }
                            }
                        ]
                    },
                    {
                        "recipe_id": 8,
                        "recipe_name": "Ergonomic Fresh Tuna",
                        "priority": 2,
                        "total_weight": 5,
                        "is_batch_group": 1,
                        "ingredients": []
                    },
                    {
                        "recipe_id": 1,
                        "recipe_name": "Awesome Cotton Computer",
                        "priority": 3,
                        "total_weight": "3.50",
                        "is_batch_group": 1,
                        "ingredients": [
                            {
                                "recipe_id": 4,
                                "recipe_name": "Small Frozen Soap",
                                "pivot": {
                                    "base_unit_qty": 2,
                                    "base_unit_id": 1,
                                    "base_unit_name": "Grams",
                                    "is_main_ingredient": 1,
                                    "group_id": 1
                                }
                            },
                            {
                                "recipe_id": 8,
                                "recipe_name": "Ergonomic Fresh Tuna",
                                "pivot": {
                                    "base_unit_qty": "1.50",
                                    "base_unit_id": 2,
                                    "base_unit_name": "Kilograms",
                                    "is_main_ingredient": 1,
                                    "group_id": 2
                                }
                            }
                        ]
                    }
                ],
                "other_recipes": [
                    {
                        "recipe_id": 2,
                        "recipe_name": "Refined Concrete Bike",
                        "total_weight": "3.50",
                        "quantity": 32,
                        "wastage": 21,
                        "total_quantity": 53,
                        "is_batch_group": 1,
                        "ingredients": [
                            {
                                "recipe_id": 1,
                                "recipe_name": "Awesome Cotton Computer",
                                "pivot": {
                                    "base_unit_qty": 2,
                                    "base_unit_id": 2,
                                    "base_unit_name": "Kilograms",
                                    "is_main_ingredient": 1,
                                    "group_id": 3
                                }
                            },
                            {
                                "ingredient_id": 2,
                                "ingredient_name": "salt",
                                "pivot": {
                                    "base_unit_qty": "1.50",
                                    "base_unit_id": 2,
                                    "base_unit_name": "Kilograms",
                                    "is_main_ingredient": 1,
                                    "group_id": 4
                                }
                            }
                        ]
                    },
                    {
                        "recipe_id": 3,
                        "recipe_name": "Sleek Granite Salad",
                        "total_weight": "3.50",
                        "quantity": 25,
                        "wastage": 5,
                        "total_quantity": 30,
                        "is_batch_group": 1,
                        "ingredients": [
                            {
                                "ingredient_id": 9,
                                "ingredient_name": "ester1",
                                "pivot": {
                                    "base_unit_qty": 2,
                                    "base_unit_id": 2,
                                    "base_unit_name": "Kilograms",
                                    "is_main_ingredient": 1,
                                    "group_id": 5
                                }
                            },
                            {
                                "ingredient_id": 4,
                                "ingredient_name": "third",
                                "pivot": {
                                    "base_unit_qty": "1.50",
                                    "base_unit_id": 2,
                                    "base_unit_name": "Kilograms",
                                    "is_main_ingredient": 1,
                                    "group_id": 6
                                }
                            }
                        ]
                    },
                    {
                        "recipe_id": 4,
                        "recipe_name": "Small Frozen Soap",
                        "total_weight": "3.50",
                        "quantity": 30,
                        "wastage": 8,
                        "total_quantity": 38,
                        "is_batch_group": 1,
                        "ingredients": [
                            {
                                "recipe_id": 10,
                                "recipe_name": "Refined Granite Tuna",
                                "pivot": {
                                    "base_unit_qty": 2,
                                    "base_unit_id": 2,
                                    "base_unit_name": "Kilograms",
                                    "is_main_ingredient": 1,
                                    "group_id": 7
                                }
                            }
                        ]
                    }
                ],
                "templates": [
                    {
                        "id": 10,
                        "name": "SundaySpecial"
                    }
                ]
            },
            {
                "store_id": 3,
                "store_name": "RC Test",
                "batches": [
                    {
                        "recipe_id": 10,
                        "recipe_name": "Refined Granite Tuna",
                        "priority": 1,
                        "total_weight": 4,
                        "is_batch_group": 1,
                        "ingredients": []
                    },
                    {
                        "recipe_id": 4,
                        "recipe_name": "Small Frozen Soap",
                        "priority": 2,
                        "total_weight": "3.50",
                        "is_batch_group": 1,
                        "ingredients": [
                            {
                                "recipe_id": 10,
                                "recipe_name": "Refined Granite Tuna",
                                "pivot": {
                                    "base_unit_qty": 2,
                                    "base_unit_id": 2,
                                    "base_unit_name": "Kilograms",
                                    "is_main_ingredient": 1,
                                    "group_id": 7
                                }
                            }
                        ]
                    },
                    {
                        "recipe_id": 8,
                        "recipe_name": "Ergonomic Fresh Tuna",
                        "priority": 2,
                        "total_weight": 5,
                        "is_batch_group": 1,
                        "ingredients": []
                    },
                    {
                        "recipe_id": 1,
                        "recipe_name": "Awesome Cotton Computer",
                        "priority": 3,
                        "total_weight": "3.50",
                        "is_batch_group": 1,
                        "ingredients": [
                            {
                                "recipe_id": 4,
                                "recipe_name": "Small Frozen Soap",
                                "pivot": {
                                    "base_unit_qty": 2,
                                    "base_unit_id": 1,
                                    "base_unit_name": "Grams",
                                    "is_main_ingredient": 1,
                                    "group_id": 1
                                }
                            },
                            {
                                "recipe_id": 8,
                                "recipe_name": "Ergonomic Fresh Tuna",
                                "pivot": {
                                    "base_unit_qty": "1.50",
                                    "base_unit_id": 2,
                                    "base_unit_name": "Kilograms",
                                    "is_main_ingredient": 1,
                                    "group_id": 2
                                }
                            }
                        ]
                    },
                    {
                        "recipe_id": 3,
                        "recipe_name": "Sleek Granite Salad",
                        "priority": 3,
                        "total_weight": "3.50",
                        "is_batch_group": 1,
                        "ingredients": [
                            {
                                "ingredient_id": 9,
                                "ingredient_name": "ester1",
                                "pivot": {
                                    "base_unit_qty": 2,
                                    "base_unit_id": 2,
                                    "base_unit_name": "Kilograms",
                                    "is_main_ingredient": 1,
                                    "group_id": 5
                                }
                            },
                            {
                                "ingredient_id": 4,
                                "ingredient_name": "third",
                                "pivot": {
                                    "base_unit_qty": "1.50",
                                    "base_unit_id": 2,
                                    "base_unit_name": "Kilograms",
                                    "is_main_ingredient": 1,
                                    "group_id": 6
                                }
                            }
                        ]
                    }
                ],
                "other_recipes": [
                    {
                        "recipe_id": 2,
                        "recipe_name": "Refined Concrete Bike",
                        "total_weight": "3.50",
                        "quantity": 8,
                        "wastage": 5,
                        "total_quantity": 13,
                        "is_batch_group": 1,
                        "ingredients": [
                            {
                                "recipe_id": 1,
                                "recipe_name": "Awesome Cotton Computer",
                                "pivot": {
                                    "base_unit_qty": 2,
                                    "base_unit_id": 2,
                                    "base_unit_name": "Kilograms",
                                    "is_main_ingredient": 1,
                                    "group_id": 3
                                }
                            },
                            {
                                "ingredient_id": 2,
                                "ingredient_name": "salt",
                                "pivot": {
                                    "base_unit_qty": "1.50",
                                    "base_unit_id": 2,
                                    "base_unit_name": "Kilograms",
                                    "is_main_ingredient": 1,
                                    "group_id": 4
                                }
                            }
                        ]
                    },
                    {
                        "recipe_id": 20,
                        "recipe_name": "Licensed Metal Tuna",
                        "total_weight": "1.06",
                        "quantity": 12,
                        "wastage": 10,
                        "total_quantity": 22,
                        "is_batch_group": 1,
                        "ingredients": [
                            {
                                "recipe_id": 3,
                                "recipe_name": "Sleek Granite Salad",
                                "pivot": {
                                    "base_unit_qty": 28,
                                    "base_unit_id": 1,
                                    "base_unit_name": "Grams",
                                    "is_main_ingredient": 1,
                                    "group_id": 128
                                }
                            },
                            {
                                "ingredient_id": 4,
                                "ingredient_name": "third",
                                "pivot": {
                                    "base_unit_qty": 20,
                                    "base_unit_id": 6,
                                    "base_unit_name": "Block",
                                    "is_main_ingredient": 1,
                                    "group_id": 128
                                }
                            }
                        ]
                    }
                ],
                "templates": [
                    {
                        "id": 10,
                        "name": "SundaySpecial"
                    }
                ]
            },
            {
                "store_id": 2,
                "store_name": "hobart",
                "batches": [
                    {
                        "recipe_id": 10,
                        "recipe_name": "Refined Granite Tuna",
                        "priority": 1,
                        "total_weight": 4,
                        "is_batch_group": 1,
                        "ingredients": []
                    },
                    {
                        "recipe_id": 4,
                        "recipe_name": "Small Frozen Soap",
                        "priority": 2,
                        "total_weight": "3.50",
                        "is_batch_group": 1,
                        "ingredients": [
                            {
                                "recipe_id": 10,
                                "recipe_name": "Refined Granite Tuna",
                                "pivot": {
                                    "base_unit_qty": 2,
                                    "base_unit_id": 2,
                                    "base_unit_name": "Kilograms",
                                    "is_main_ingredient": 1,
                                    "group_id": 7
                                }
                            }
                        ]
                    },
                    {
                        "recipe_id": 8,
                        "recipe_name": "Ergonomic Fresh Tuna",
                        "priority": 2,
                        "total_weight": 5,
                        "is_batch_group": 1,
                        "ingredients": []
                    },
                    {
                        "recipe_id": 1,
                        "recipe_name": "Awesome Cotton Computer",
                        "priority": 3,
                        "total_weight": "3.50",
                        "is_batch_group": 1,
                        "ingredients": [
                            {
                                "recipe_id": 4,
                                "recipe_name": "Small Frozen Soap",
                                "pivot": {
                                    "base_unit_qty": 2,
                                    "base_unit_id": 1,
                                    "base_unit_name": "Grams",
                                    "is_main_ingredient": 1,
                                    "group_id": 1
                                }
                            },
                            {
                                "recipe_id": 8,
                                "recipe_name": "Ergonomic Fresh Tuna",
                                "pivot": {
                                    "base_unit_qty": "1.50",
                                    "base_unit_id": 2,
                                    "base_unit_name": "Kilograms",
                                    "is_main_ingredient": 1,
                                    "group_id": 2
                                }
                            }
                        ]
                    },
                    {
                        "recipe_id": 3,
                        "recipe_name": "Sleek Granite Salad",
                        "priority": 3,
                        "total_weight": "3.50",
                        "is_batch_group": 1,
                        "ingredients": [
                            {
                                "ingredient_id": 9,
                                "ingredient_name": "ester1",
                                "pivot": {
                                    "base_unit_qty": 2,
                                    "base_unit_id": 2,
                                    "base_unit_name": "Kilograms",
                                    "is_main_ingredient": 1,
                                    "group_id": 5
                                }
                            },
                            {
                                "ingredient_id": 4,
                                "ingredient_name": "third",
                                "pivot": {
                                    "base_unit_qty": "1.50",
                                    "base_unit_id": 2,
                                    "base_unit_name": "Kilograms",
                                    "is_main_ingredient": 1,
                                    "group_id": 6
                                }
                            }
                        ]
                    }
                ],
                "other_recipes": [
                    {
                        "recipe_id": 2,
                        "recipe_name": "Refined Concrete Bike",
                        "total_weight": "3.50",
                        "quantity": 8,
                        "wastage": 5,
                        "total_quantity": 13,
                        "is_batch_group": 1,
                        "ingredients": [
                            {
                                "recipe_id": 1,
                                "recipe_name": "Awesome Cotton Computer",
                                "pivot": {
                                    "base_unit_qty": 2,
                                    "base_unit_id": 2,
                                    "base_unit_name": "Kilograms",
                                    "is_main_ingredient": 1,
                                    "group_id": 3
                                }
                            },
                            {
                                "ingredient_id": 2,
                                "ingredient_name": "salt",
                                "pivot": {
                                    "base_unit_qty": "1.50",
                                    "base_unit_id": 2,
                                    "base_unit_name": "Kilograms",
                                    "is_main_ingredient": 1,
                                    "group_id": 4
                                }
                            }
                        ]
                    },
                    {
                        "recipe_id": 20,
                        "recipe_name": "Licensed Metal Tuna",
                        "total_weight": "1.06",
                        "quantity": 12,
                        "wastage": 10,
                        "total_quantity": 22,
                        "is_batch_group": 1,
                        "ingredients": [
                            {
                                "recipe_id": 3,
                                "recipe_name": "Sleek Granite Salad",
                                "pivot": {
                                    "base_unit_qty": 28,
                                    "base_unit_id": 1,
                                    "base_unit_name": "Grams",
                                    "is_main_ingredient": 1,
                                    "group_id": 128
                                }
                            },
                            {
                                "ingredient_id": 4,
                                "ingredient_name": "third",
                                "pivot": {
                                    "base_unit_qty": 20,
                                    "base_unit_id": 6,
                                    "base_unit_name": "Block",
                                    "is_main_ingredient": 1,
                                    "group_id": 128
                                }
                            }
                        ]
                    }
                ],
                "templates": [
                    {
                        "id": 10,
                        "name": "SundaySpecial"
                    }
                ]
            }
        ]
    }
}