import { useQueryClient, useMutation } from "@tanstack/react-query";
import { errorMessage, successMessage } from "../../../utils/helper";

import { deleteStoreFromTemplate } from "../api";
import { useStoreKeys } from "../../Recipe/hooks/useStoreKeys";

export const useDeleteStoreFromTemplatesQueries = ({ resetDeleteInfo }) => {
  const { storeKeys } = useStoreKeys();
  const queryClient = useQueryClient();
  const deleteTemplateStores = useMutation({
    mutationFn: deleteStoreFromTemplate,
    onError: (error) => {
      errorMessage(error.response.data.message);
    },
    onSuccess: (res) => {
      if (res.success) {
        queryClient.invalidateQueries({
          queryKey: storeKeys.list(),
        });
        successMessage(res.message);
        resetDeleteInfo();
      } else if (res.success === false) {
        errorMessage(res.message);
      }
    },
  });

  return { deleteTemplateStores };
};
