import { API } from "../../../utils/api";

export const getIngredientList = async ({ query, pagination }) => {
  const { pageIndex, pageSize } = pagination;
  const res = await API.get(
    `ingredients?page=${pageIndex}&per_page=${pageSize}`,
    query
  );
  return res.data.data;
};

export const saveIngredient = async (payload) => {
  const res = await API.post("ingredients", payload);
  return res.data.data;
};

export const getIngredient = async (id) => {
  const res = await API.get(`ingredients/${id}`);
  return res.data.data;
};

export const editIngredient = async (payload, detailsId) => {
  const res = await API.put(`ingredients/${detailsId}`, payload);
  return res.data;
};

export const removeIngredient = async ({ id }) => {
  const res = await API.delete(`ingredients/${id}`);
  return res.data;
};
export const getTaxGroupList = async () => {
  const res = await API.get(`taxgroups`);
  return res.data.data;
};
export const getUnitsLists = async () => {
  const res = await API.get("units");
  return res.data.data;
};
export const getTagGroupList = async () => {
  const res = await API.get(`categories/tags`);
  return res.data.data;
};
