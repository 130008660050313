import * as React from "react";
import { Stack, Modal, Typography, Button, Box } from "@mui/material";

const style = {
  position: "absolute",
  top: "55%",
  left: "50%",
  transform: "translate(-50%, -100%)",
  width: 500,
  bgcolor: "white",
  boxShadow: 24,
  p: "24px 8px 24px 24px",
  borderRadius: "16px",
  outline: "none",
};

export function DeleteRoleFromUserModal(props) {
  const {
    open,
    handleClose,
    onConfirm,
    deleteCategory,
    userName,
    display_name,
  } = props;
  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Stack
            direction="row"
            justifyContent="space-between"
            position="sticky"
            zIndex={999}
            bgcolor="#fff"
            top={0}
          >
            <Stack direction="row" alignItems="center">
              <img src="/icons/ic_pair_dialog.svg" />
              <Typography
                fontWeight={500}
                variant="body1"
                padding="15px"
                fontSize={20}
              >
                Remove Role?
              </Typography>
            </Stack>
          </Stack>

          <Typography fontStyle={400} fontSize="16px">
            Would you like to remove role “{display_name}” from the user “
            {userName}”?
          </Typography>
          <Stack
            direction="row"
            gap="12px"
            justifyContent="flex-end"
            marginTop={3}
          >
            <Button variant="outlined" onClick={handleClose} size="sm">
              Cancel
            </Button>

            <Button
              disabled={deleteCategory?.isPending}
              onClick={onConfirm}
              variant="contained"
              size="sm"
            >
              Delete
            </Button>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
}
