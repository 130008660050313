import { Button, Link, Stack, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { createColumnHelper } from "@tanstack/react-table";
import {
  CustomeDialog,
  DeleteModal,
  Loader,
  ServerPaginatedtable,
} from "../../../shared";
import { useTipsAndTricks } from "../../Tips&Tricks/hooks/useTipsAndTricks";

export const TipsAndTricksDetails = ({ recipeId }) => {
  const {
    setPagination,
    pagination,
    sorting,
    setSorting,
    listQuery,
    tipsAndTricks,
    onSearchHandler,
    onClearSearch,
  } = useTipsAndTricks({
    isList: true,
    recipeId,
  });
  const ActionButtons = ({ row }) => {
    return (
      <div className="action-buttons" onClick={(e) => e.preventDefault()}>
        <Stack
          direction="row"
          gap={1}
          justifyContent="flex-end"
          alignItems="center"
          width="max-content"
          ml="auto"
        >
          <Link key={row.id}>
            <img alt="details" src="/icons/arrow_forward.png" />
          </Link>
        </Stack>
      </div>
    );
  };

  const columnHelper = createColumnHelper([]);

  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        header: "Tips",
      }),

      columnHelper.display({
        id: "actions",
        cell: ({ row }) => <ActionButtons row={row} />,
      }),
    ],
    []
  );

  return (
    <>
      <ServerPaginatedtable
        defaultData={listQuery?.data?.tipsandtricks ?? []}
        columns={columns}
        onSearchChange={onSearchHandler}
        search={tipsAndTricks?.search}
        onClearSearch={onClearSearch}
        sorting={sorting}
        setSorting={setSorting}
        pagination={pagination}
        onRowAction={(e) => {
          return window.open(e?.images, "_blank");
        }}
        paginationProps={
          listQuery.data?.pagination || { total: 0, lastPage: 1 }
        }
        setPagination={setPagination}
        headerText="Recipe Tips"
        isLoading={listQuery?.isLoading}
      />
    </>
  );
};
