import Toastify from "toastify-js";
import "toastify-js/src/toastify.css";
import parse from "html-react-parser";

export const getToken = () => {
  let token = "";
  var localData = JSON.parse(localStorage.getItem("recipe-user-data"));
  token = localData?.token;
  return token;
};

export const getStoreId = () => {
  const isStoreAdmin = window.location.pathname.includes("/store-admin");
  const localData = JSON.parse(localStorage.getItem("recipe-user-data"));
  return isStoreAdmin ? localData?.selectedStoreId : null;
};

export const successMessage = async (text) => {
  if (text === null || text === undefined || text === "")
    text = "Data saved successfully!";
  Toastify({
    text: text,
    duration: 3000,
    close: true,
    gravity: "top",
    position: "right",
    avatar: "/icons/success_toast.png",
    style: {
      background: "#388e3c",
    },
  }).showToast();
};
export const successRedMessage = async (text) => {
  if (text === null || text === undefined || text === "")
    text = "Data saved successfully!";
  Toastify({
    text: text,
    duration: 3000,
    close: true,
    gravity: "top",
    position: "right",
    avatar: "/icons/icon_not.svg",
    style: {
      background: "#FEB019",
    },
  }).showToast();
};
export const infoMessage = async (text) => {
  if (text === undefined || text === "") return;
  Toastify({
    text: text,
    duration: 3000,
    close: true,
    gravity: "top",
    position: "right",
    avatar: "/icons/error_toast.png",
    style: {
      background: "#388e3c",
    },
    selector: "toastMessage",
  }).showToast();
};

export const errorMessage = async (text) => {
  if (text === null || text === undefined || text === "") text = "Error";
  Toastify({
    text: text,
    duration: 3000,
    close: true,
    gravity: "top",
    position: "right",
    avatar: "/icons/error_toast.png",
    style: {
      background: "#d32f2f",
    },
  }).showToast();
};
export const ErrorFormatter = (error) => {
  console.log("error", error);
  let errorString = "";

  if (error) {
    for (const field in error) {
      if (Array.isArray(error[field])) {
        error[field].forEach((err) => {
          errorString += `${field}: ${err}</br>`;
        });
      } else {
        errorString += `${field}: ${error[field]}</br>`;
      }
    }
  }

  if (error.response) {
    if (error.response.data.message) {
      errorString += `${error.response.data.message}</br>`;
    }

    const errors = error.response.data.errors;
    if (errors) {
      for (const field in errors) {
        if (Array.isArray(errors[field])) {
          errors[field].forEach((err) => {
            errorString += `${field}: ${err}</br>`;
          });
        } else {
          errorString += `${field}: ${errors[field]}</br>`;
        }
      }
    }
  }
  // else {
  //   errorString += "An error has occurred!";
  // }

  return parse(errorString);
};

export const arraySort = (data, key) => {
  return [...data].sort(function (a, b) {
    return a[key]?.toLowerCase()?.localeCompare(b[key]?.toLowerCase());
  });
};

export const navigateBasedOnUserType = (
  url = "",
  isStoreAdmin = false,
  navigate
) => {
  return navigate(isStoreAdmin ? `/store-admin${url}` : url);
};

export const formatWeightWithUnits = (
  weightInGram,
  unitSymbol,
  roundoffValue
) => {
  const weightInGrams = Number(weightInGram);
  let weight, unit;

  if (weightInGrams >= 1000 && (unitSymbol === "gm" || unitSymbol === "ml")) {
    weight = weightInGrams / 1000;
    unit = unitSymbol === "gm" ? "kg" : "ltr";
  } else if (
    weightInGrams < 1 &&
    (unitSymbol === "kg" || unitSymbol === "ltr")
  ) {
    weight = weightInGrams * 1000;
    unit = unitSymbol === "kg" ? "gm" : "ml";
  } else {
    weight = weightInGrams;
    unit = unitSymbol;
  }
  const [wholePart, decimalPart] = weight.toString().split(".");
  let wholePartValue = `${wholePart}.${decimalPart
    ?.slice(0, 4)
    .padEnd(4, "0")}`;
  const formattedWeight = decimalPart
    ? roundoffValue
      ? Number(wholePartValue).toFixed(roundoffValue)
      : wholePartValue
    : `${wholePart}`;
  return `${formattedWeight} ${unit}`;
};
