import { API } from "../../../utils/api";

export const saveIngredients = async (payload) => {
  const { formData } = payload;
  const res = await API.post("recipes/groups-ingredients", formData);
  return res.data.data;
};

export const getIngredientsList = async () => {
  const res = await API.get("recipes/recipe-ingredients");
  return res.data;
};

export const getUnitsList = async () => {
  const res = await API.get("units");
  return res.data.data;
};

export const editIngredients = async (data) => {
  const { detailsId, formData } = data;
  const res = await API.post(
    `recipes/groups-ingredients/${detailsId}`,
    formData
  );
  return res.data;
};

export const getIngredients = async (id) => {
  const res = await API.get(`recipes/groups-ingredients/${id}`);
  return res.data.data;
};

export const getPluList = async (query) => {
  const res = await API.get("plu-products", query);
  return res.data;
};
