import { Button, Stack } from "@mui/material";
import { InputControl } from "../../../shared";

export const NameDialog = (props) => {
  const {
    onChange,
    onSubmit,
    state,
    isEdit,
    validator,
    handleClose,
    isDisabled,
  } = props;
  const { data } = state;
  return (
    <div>
      <InputControl
        sx={{ width: "442px", marginTop: "30px" }}
        autoFocus
        margin="dense"
        label=" Template Name"
        name="template_name"
        required
        onChange={onChange}
        value={state?.data?.template_name}
        error={validator.current.message(
          "name",
          data?.template_name,
          "required"
        )}
        helperText={validator.current.message(
          "name",
          data?.template_name,
          "required"
        )}
      />
      <Stack
        direction="row"
        gap="8px"
        padding="30px 2px 10px 10px"
        marginTop="2px"
        sx={{ justifyContent: "flex-end" }}
      >
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={onSubmit} disabled={isDisabled}>
          Save
        </Button>
      </Stack>
    </div>
  );
};
