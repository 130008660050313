import { useImmer } from "use-immer";

const initDeleteInfo = {
  show: false,
  id: null,
  to: "",
};

export const useDelete = () => {
  const [deleteInfo, setDeleteInfo] = useImmer(initDeleteInfo);

  const onDelete = (id, to = "") => {
    setDeleteInfo((draft) => {
      draft = { show: !draft?.show, id, to };
      return draft;
    });
  };

  const resetDeleteInfo = () => {
    setDeleteInfo((draft) => {
      draft = initDeleteInfo;
      return draft;
    });
  };

  return {
    deleteInfo,
    onDelete,
    resetDeleteInfo,
  };
};
