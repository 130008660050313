import {
  useQueryClient,
  useQuery,
  useMutation,
  keepPreviousData,
} from "@tanstack/react-query";
import qs from "qs";
import {
  getUnitList,
  saveUnit,
  getUnit,
  removeUnit,
  editUnit,
  getUnitListData,
} from "../api/UnitApi";
import { errorMessage, successMessage } from "../../../utils/helper";

const unitKeys = {
  all: ["units"],
  lists: () => [...unitKeys.all, "unitlist"],
  unitlist: (filters) => [...unitKeys.lists(), filters],
  detail: (id) => [...unitKeys.unitlist(), id],
};

export const useUnitsQuery = ({
  detId,
  debouncedText,
  pagination,
  resetDeleteInfo,
  list,
  sorting,
}) => {
  const queryClient = useQueryClient();
  let params = {};
  if (debouncedText) {
    params = { ...params, search: debouncedText };
  }
  if (sorting?.length > 0) {
    const sortByParams = sorting.reduce(
      (obj, item) =>
        Object.assign(obj, { [item.id]: item.desc ? "desc" : "asc" }),
      {}
    );
    params = {
      ...params,
      sort: sortByParams,
    };
  }

  const query = {
    params,
    paramsSerializer: (params) => qs.stringify(params, { encode: true }),
  };

  //list query
  const listQuery = useQuery({
    queryKey: unitKeys.unitlist({
      query,
      pageIndex: pagination.pageIndex,
      pageSize: pagination.pageSize,
    }),
    queryFn: () => getUnitList({ query, pagination }),
    placeholderData: keepPreviousData,
    enabled: list,
    options: unitKeys?.data?.unit?.map((data) => {
      return {
        value: data.id,
        label: data.name,
      };
    }),
  });
  const listQueryData = useQuery({
    queryKey: unitKeys.unitlist({
      query,
    }),
    queryFn: () => getUnitListData({ query }),
    placeholderData: keepPreviousData,
    enabled: list,
    options: unitKeys?.data?.unit?.map((data) => {
      return {
        value: data.id,
        label: data.name,
      };
    }),
  });
  const createUnit = useMutation({
    mutationFn: ({ _, data }) => saveUnit(data),
    onError: (error) => {
      errorMessage(error.response.data.message);
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: unitKeys.lists(),
      });
      successMessage("Unit created successfully!!");
      return variables.handleClose();
    },
  });

  const updateUnit = useMutation({
    mutationFn: ({ _, data }) => editUnit(data, detId),
    onError: (error) => {
      errorMessage(error.response.data.message);
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: unitKeys.lists(),
      });
      successMessage("Unit updated successfully");
      variables.handleClose();
    },
  });

  const unitDetails = useQuery({
    queryKey: unitKeys.detail(detId),
    queryFn: () => getUnit(detId),
    enabled: detId > 0,
  });

  const deleteUnit = useMutation({
    mutationFn: removeUnit,
    onError: (error) => {
      errorMessage(error.response.data.message);
    },
    onSuccess: (res) => {
      if (res.success) {
        queryClient.invalidateQueries({
          queryKey: unitKeys.lists(),
        });
        successMessage(res.message);
        resetDeleteInfo();
      } else if (res.success === false) {
        errorMessage(res.message);
      }
    },
  });

  return {
    listQuery,
    createUnit,
    unitDetails,
    updateUnit,
    deleteUnit,
    listQueryData,
  };
};
