import React, { useContext } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { AuthContext } from "../../store/AuthProvider";

export const AdminProtectedRoute = ({ allowedRole }) => {
  const {
    auth,
    auth: { data },
  } = useContext(AuthContext);
  const { pathname } = useLocation();

  const checkPermission = (stores) => {
    let isAllowed = false;
    const hasRole = data.roles.find((item) => item.name === allowedRole);
    if (hasRole) {
      if (allowedRole === "store-admin" && stores.length) {
        isAllowed = true;
      } else if (allowedRole === "admin") {
        isAllowed = true;
      }
    }
    return isAllowed;
  };
  const hasPermission = checkPermission(data.stores);

  return (
    !auth.isInitialLoad &&
    (hasPermission ? (
      <Outlet />
    ) : auth?.data?.id ? (
      <Navigate to={"/unauth"} replace />
    ) : (
      <Navigate to="/authentication/login" replace state={{ from: pathname }} />
    ))
  );
};
