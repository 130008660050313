import React from "react";
import { Route, Routes } from "react-router-dom";
import {
  AddOrEditIngredient,
  IngredientDetails,
  IngredientsList,
} from "./containers";
import { ProtectedRoute } from "../../shared";

export const Ingredients = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<IngredientsList />} />
        <Route element={<ProtectedRoute allowedRoute={["ingredient-view"]} />}>
          <Route path="/details/:id" element={<IngredientDetails />} />
        </Route>
        <Route
          element={<ProtectedRoute allowedRoute={["ingredient-create"]} />}
        >
          <Route path="/create" element={<AddOrEditIngredient />} />
        </Route>
        <Route
          element={<ProtectedRoute allowedRoute={["ingredient-modify"]} />}
        >
          <Route path="/edit/:id" element={<AddOrEditIngredient />} />
        </Route>
      </Routes>
    </>
  );
};
