import {
  Box,
  InputAdornment,
  OutlinedInput,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import emptyIcon from "../../assets/images/Icons/search-table.png";
import {
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import React, { useEffect, useState } from "react";
import { Loader } from "./Loader";
import { Link } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { SortableTableHeader } from "./SortableTableHeader";

export const CustomTable = (props) => {
  const [headerHover, setHeaderHover] = React.useState(null);

  const {
    defaultData,
    columns,
    headerText,
    rightContent,
    subRow = null,
    allRowsExpanded = false,
    customRowStyle = null,
    tableHeaderContent = null,
    isHeaderStyleChange = false,
    isLoading = false,
    isPagination = true,
    isTableHeader = true,
    isTableHead = true,
    tableFooter,
    tableStyle,
    onSearchChange,
    hideEmptyContent = false,
    showSearchBar = true,
    updateTableData,
    emptyTableMessage,
    isHeaderColor,
    onRowLink = false,
    onRowAction = false,
    rowClickNewTab = false,
    manualPagination = false,
    tableHeaderStyles = {},
    searchText = null,
    defaultSort,
    tableId = null,
  } = props;

  // Fetch the previous state from localStorage
  const previousState = tableId
    ? JSON.parse(localStorage.getItem(`table_${tableId}`))
    : null;

  const table = useReactTable({
    data: defaultData,
    columns,
    getSubRows: (row) => row[subRow] || [],
    getCoreRowModel: getCoreRowModel(),
    onGlobalFilterChange: onSearchChange,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    meta: {
      updateData: updateTableData,
    },
    manualPagination: manualPagination,
  });
  // Initialize the state from localStorage or use default values
  const [state, setState] = useState({
    ...table.initialState,
    pagination: {
      pageIndex: previousState?.pagination?.pageIndex || 0,
      pageSize: previousState?.pagination?.pageSize || 50,
    },
    sorting: previousState?.sorting ? previousState?.sorting : defaultSort,
    globalFilter: previousState?.globalFilter
      ? previousState?.globalFilter
      : searchText,
  });

  // Update localStorage when state changes
  useEffect(() => {
    if (tableId) {
      localStorage.setItem(`table_${tableId}`, JSON.stringify(state));
    }
  }, [state]);
  // Table state setting function to persist state and localStorage
  table.setOptions((prev) => ({
    ...prev,
    state,
    onStateChange: setState,
  }));

  const setGlobalFilter = React.useCallback(
    (value) => {
      setState((prevState) => ({
        ...prevState,
        globalFilter: value,
        // Only update globalFilter without altering pageIndex
        pagination: { ...prevState.pagination },
      }));

      if (onSearchChange) {
        onSearchChange(value);
      }
    },
    [onSearchChange]
  );
  // Handle row expansion
  useEffect(() => {
    if (table && allRowsExpanded) {
      table.toggleAllRowsExpanded(true);
    }
  }, [table, allRowsExpanded]);

  /**
   * Handles mouse hover status over table headers.
   *
   * @param {boolean} status - The hover status to set.
   */
  const handleMouseHover = (status) => setHeaderHover(status);

  return (
    <Stack
      sx={{
        borderRadius: "4px",
        bgcolor: "#fff",
        overflow: "hidden",
        ...tableStyle,
      }}
    >
      {isTableHeader && (
        <TableHeader
          setGlobalFilter={setGlobalFilter}
          value={searchText ? searchText : state.globalFilter}
          headerText={headerText}
          rightContent={rightContent}
          isHeaderStyleChange={isHeaderStyleChange}
          showSearchBar={showSearchBar}
          tableHeaderStyles={tableHeaderStyles}
        />
      )}
      {tableHeaderContent && (
        <TableHeaderContent tableHeaderContent={tableHeaderContent} />
      )}

      {isLoading ? (
        <Box sx={{ padding: "10px" }}>
          <Loader />
        </Box>
      ) : (
        <TableContainer
          component={Paper}
          sx={{ borderRadius: "0 0 4px 4px", overflow: "auto" }}
        >
          <Table>
            {isTableHead && (
              <TableHead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <TableRow key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                      const canSort = header.column.getCanSort();
                      const headerName = flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      );
                      const sort = header.column.getIsSorted();
                      const isSortActive = sort !== false;
                      return (
                        <TableCell
                          key={header.id}
                          colSpan={header.colSpan}
                          align={"right"}
                          sx={{
                            padding: "10px 8px",
                            backgroundColor: isHeaderColor
                              ? "#E6EEF7"
                              : "#F5F8FC",
                          }}
                          onMouseEnter={
                            canSort
                              ? () => handleMouseHover(header.id)
                              : undefined
                          }
                          onMouseLeave={
                            canSort ? () => handleMouseHover(null) : undefined
                          }
                        >
                          {header.isPlaceholder ? null : (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "4px",
                                justifyContent:
                                  header?.column?.columnDef?.align ===
                                    "right" && "flex-end",
                                cursor: canSort ? "pointer" : "",
                              }}
                              {...{
                                onClick:
                                  header.column.getToggleSortingHandler(),
                              }}
                            >
                              {canSort ? (
                                <SortableTableHeader
                                  headerHover={headerHover}
                                  headerId={header.id}
                                  headerName={headerName}
                                  isSortActive={isSortActive}
                                  sort={sort}
                                />
                              ) : (
                                <Typography
                                  sx={{
                                    fontWeight: 600,
                                    fontSize: "12px",
                                    lineHeight: "14px",
                                  }}
                                >
                                  {headerName}
                                </Typography>
                              )}
                            </Box>
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableHead>
            )}

            <TableBody>
              {table.getFilteredRowModel().rows.length > 0 ? (
                table.getRowModel().rows.map((row) => {
                  const style = customRowStyle ? customRowStyle(row) : {};
                  const isCustomRow =
                    row.depth > 0 && row.original.isCustomRow ? true : false;
                  const link =
                    onRowLink && (subRow ? row.depth > 0 : true)
                      ? onRowLink(row.original)
                      : false;
                  return (
                    <TableRow
                      key={row.id}
                      component={link ? Link : null}
                      to={link ? link : undefined}
                      onClick={() => {
                        onRowAction && onRowAction(row.original);
                      }}
                      target={rowClickNewTab ? "_blank	" : ""}
                      rel="noopener noreferrer"
                      sx={{
                        textDecoration: "none",
                        cursor: link || onRowAction ? "pointer" : "default",
                        "&:hover": {
                          backgroundColor:
                            link || onRowAction
                              ? "rgba(55, 65, 81, 0.04)"
                              : false,
                        },
                      }}
                    >
                      {row.getVisibleCells().map((cell) => {
                        return isCustomRow ? (
                          <TableCell
                            key={cell.id}
                            sx={{
                              padding: "4px",
                              borderBottom: "1px solid #DFE8F2",
                              backgroundColor: "#F2F7FC",
                              ...style,
                            }}
                            align={
                              cell.column.columnDef.align ||
                              cell.column.id === "actions"
                                ? "right"
                                : "left"
                            }
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </TableCell>
                        ) : (
                          <TableCell
                            key={cell.id}
                            sx={{
                              padding: "8px",
                              borderBottom: "1px solid #DFE8F2",
                              ...style,
                            }}
                            align={
                              cell.column.columnDef.align ||
                              cell.column.id === "actions"
                                ? "right"
                                : "left"
                            }
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })
              ) : !hideEmptyContent ? (
                <EmptyComponent emptyTableMessage={emptyTableMessage} />
              ) : (
                <></>
              )}
            </TableBody>
          </Table>
          {isPagination && table.getFilteredRowModel().rows.length > 0 && (
            <TablePagination
              rowsPerPageOptions={[8, 10, 20, 30, 40, 50]}
              component="div"
              count={table.getFilteredRowModel().flatRows.length}
              rowsPerPage={state.pagination.pageSize}
              page={state.pagination.pageIndex}
              onPageChange={(e, page) => {
                setState((prevState) => ({
                  ...prevState,
                  pagination: { ...prevState.pagination, pageIndex: page },
                }));
              }}
              onRowsPerPageChange={(e) => {
                setState((prevState) => ({
                  ...prevState,
                  pagination: {
                    ...prevState.pagination,
                    pageSize: Number(e.target.value),
                  },
                }));
              }}
            />
          )}
          {tableFooter && <>{tableFooter}</>}
        </TableContainer>
      )}
    </Stack>
  );
};

const TableHeader = ({
  setGlobalFilter,
  headerText,
  rightContent,
  isHeaderStyleChange,
  showSearchBar,
  value,
  tableHeaderStyles,
}) => {
  return (
    <Stack
      sx={{
        padding: "16px 12px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        bgcolor: isHeaderStyleChange ? "#111C2B" : "#fff",
        color: isHeaderStyleChange && "#fff",
        ...tableHeaderStyles,
      }}
    >
      <Typography
        sx={{
          fontWeight: 500,
          fontSize: "18px",
        }}
      >
        {headerText}
      </Typography>

      {showSearchBar && (
        <OutlinedInput
          onChange={(e) => setGlobalFilter(e.target.value)}
          value={value}
          sx={{
            height: "36px",
            borderRadius: "10px",
            width: "200px",
            backgroundColor: isHeaderStyleChange ? "#313C4C" : "#F2F7FC",
            borderColor: isHeaderStyleChange && "#485364",

            "& input::placeholder": {
              color: isHeaderStyleChange && "#ccc",
            },
            "& input": {
              p: " 12.5px 14px 12.5px 4px !important",
            },
            "&:focus-within": {
              width: "400px",
            },
            transition: "width 0.5s",
          }}
          placeholder="Search"
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon size="small" />
            </InputAdornment>
          }
          endAdornment={
            value && (
              <InputAdornment
                sx={{ cursor: "pointer" }}
                position="end"
                onMouseDown={(e) => {
                  e.preventDefault(); // Prevent default behavior
                  setGlobalFilter("");
                }}
              >
                <CloseIcon size="large" />
              </InputAdornment>
            )
          }
        />
      )}
      {rightContent ? rightContent : <div />}
    </Stack>
  );
};

const EmptyComponent = ({ emptyTableMessage }) => {
  return (
    <TableRow>
      <TableCell
        align="center"
        colSpan={20}
        height={162}
        sx={{ py: 2, padding: "32px 0" }}
      >
        <Stack alignItems="center" gap="8px">
          <img src={emptyIcon} alt="empty" height={56} width={56} />
          <Typography
            sx={{ fontWeight: 400, fontSize: "14px", color: "#485364" }}
          >
            {emptyTableMessage ? emptyTableMessage : "No matches found"}
          </Typography>
        </Stack>
      </TableCell>
    </TableRow>
  );
};

const TableHeaderContent = ({ tableHeaderContent }) => {
  return <Box p="8px 16px">{tableHeaderContent}</Box>;
};
