import { useDebounce } from "use-debounce";
import { useImmer } from "use-immer";
import { useState } from "react";
import { useStoreByStateListQueries } from "./useStoreByStateListQueries";

export const useStoreSelectDialog = () => {
  const [state, setState] = useImmer({
    search: "",
  });

  const [pagination, setPagination] = useState({
    pageIndex: 1,
    pageSize: 50,
  });

  const HandleSearchFilter = (type, text) => {
    if ("search") {
      setState((draft) => {
        draft.search = text;
      });
    } else {
      setState((draft) => {
        draft.search = "";
      });
    }
  };
  const [debouncedText] = useDebounce(state.search, 1000);
  const { listQuery } = useStoreByStateListQueries({
    debouncedText,
    pagination,
  });
  return { listQuery, state, HandleSearchFilter };
};
