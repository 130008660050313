import React from "react";
import { InputControl } from "../../../shared/components/InputControl";
import { Box, TextField } from "@mui/material";

export const CreateOrEditCategory = (props) => {
  const {
    onChange,
    state,
    validator,
    CategoryValuesHandler,
    isSubItem,
    categoryOptions,
  } = props;

  return (
    <>
      <Box
        sx={{
          padding: "20px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "16px",
        }}
      >
        {isSubItem ? (
          <InputControl
            type="dropdown"
            fullWidth
            options={categoryOptions}
            disabled={state?.data?.name}
            value={
              categoryOptions.find(
                (option) => option.id === state.categoryDropDownValue
              ) || null
            }
            onChange={(event, option) => CategoryValuesHandler(event, option)}
            getOptionLabel={(option) => option?.name || ""}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Category"
                error={validator.current.message(
                  "category",
                  state.categoryDropDownValue,
                  "required"
                )}
                helperText={validator.current.message(
                  "category",
                  state.categoryDropDownValue,
                  "required"
                )}
              />
            )}
          />
        ) : (
          ""
        )}

        <InputControl
          type="text"
          onChange={onChange}
          label={state?.isSubItem ? "Sub Category Name" : "Category Name"}
          value={state?.data?.name}
          fullWidth
          required
          error={validator.current.message(
            "name",
            state?.data?.name,
            "required|min:3"
          )}
          helperText={validator.current.message(
            "name",
            state?.data?.name,
            "required|min:3"
          )}
        />
      </Box>
    </>
  );
};
