import { Button, Stack, Typography } from "@mui/material";
import moment from "moment";
import { useRef, useState } from "react";
import SimpleReactValidator from "simple-react-validator";

export const DeleteReccuringDateDialog = (props) => {
  const { templates, handleRemoveRecurringDate, confirmDeleteRecurringDates } =
    props;
  const [, forceUpdate] = useState();
  const validator = useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
  );
  const formatDate = (date) => moment(date).format("DD MMM YYYY");
  return (
    <div>
      <Typography fontWeight={600} fontSize="16px" padding="16px 24px">
        Are you sure you want to remove both the recurring dates and custom
        dates for the template?
      </Typography>
      <Typography padding="16px 24px">
        These dates will be removed from your production schedule. You can add
        dates again later.
      </Typography>
      <Stack
        direction="row"
        gap="8px"
        padding="8px 24px 16px 24px"
        marginTop="2px"
        sx={{ justifyContent: "flex-end" }}
      >
        <Button
          variant="outlined"
          onClick={() => handleRemoveRecurringDate(false)}
        >
          Close
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={() => confirmDeleteRecurringDates()}
        >
          Delete
        </Button>
      </Stack>
    </div>
  );
};
