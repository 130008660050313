import { useQuery } from "@tanstack/react-query";
import { getStoreList } from "../../modules/Recipe/api";

export const useStoreSelectQueries = ({ storeList }) => {
  const listQuery = useQuery({
    queryFn: () => getStoreList(),
    enabled: storeList,
  });
  return { listQuery };
};
