import {
    useQueryClient,
    useQuery,
    keepPreviousData
  } from "@tanstack/react-query";

import qs from "qs";
import { getStoreByStateList } from "../../modules/Recipe/api";

const storeKeys = {
    all: ["stores"],
    lists: () => [...storeKeys.all, "storelist"],
    storelist: (filters) => [...storeKeys.lists(), filters],
    detail: (id) => [...storeKeys.storelist(), id],
  };
  

export const useStoreByStateListQueries = ({debouncedText,pagination}) =>
{
    let params = {};
    if (debouncedText) {
        params = { ...params, search: debouncedText };
    }

    const query = {
        params,
        paramsSerializer: (params) => qs.stringify(params, { encode: true }),
      };

      //list query
  const listQuery = useQuery({
    queryKey: storeKeys.storelist({ query, pagination }),
    queryFn: () => getStoreByStateList({ query, pagination }),
    placeholderData: keepPreviousData,
  });
  return {listQuery};
}