import React from "react";
import { Route, Routes } from "react-router-dom";
import {
  FutureChartList,
  ProductionChartDetails,
  ProductionChartList,
} from "./containers";
import { ProtectedRoute } from "../../shared";

export const ProductionChart = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<ProductionChartList />} />
        <Route
          element={<ProtectedRoute allowedRoute={["future-chart-view"]} />}
        >
          <Route path="/futureChart" element={<FutureChartList />} />
        </Route>
        <Route
          element={<ProtectedRoute allowedRoute={["production-chart-view"]} />}
        >
          <Route path="/details/:date" element={<ProductionChartDetails />} />
        </Route>
      </Routes>
    </>
  );
};
