import { Stack, Typography } from "@mui/material";
import React from "react";
import { createColumnHelper } from "@tanstack/react-table";
import { CustomTable } from "../../../shared";
import StarIcon from "../../../assets/images/Icons/starIcon.svg";
import { formatWeightWithUnits } from "../../../utils";

export const Recipes = (props) => {
  const { selectedOptionRecipe } = props;
  const columnHelper = createColumnHelper(selectedOptionRecipe?.ingredients);

  const IngColumns = [
    columnHelper.accessor("ingredient_name", {
      header: () => "Ingredients",
      cell: (props) => {
        const { row } = props;
        return (
          <Stack direction="row" alignItems="center" gap="10px">
            <Typography>
              {row.original.ingredient_name
                ? row.original.ingredient_name
                : row.original.recipe_name}
            </Typography>
            {row?.original?.pivot?.is_main_ingredient === 1 && (
              <img
                src={StarIcon}
                alt="StarIcon"
                style={{ width: "13px", height: "14px" }}
              />
            )}
          </Stack>
        );
      },
    }),

    columnHelper.accessor("qty", {
      header: () => "Quantity",
      align: "left",
      cell: (props) => {
        const { row } = props;
        return (
          <Typography component="div">
            <span style={{ marginRight: "1px" }}>
              {formatWeightWithUnits(
                row?.original?.pivot?.base_unit_qty,
                row?.original?.pivot?.base_unit_symbol,
                3
              )}
            </span>
          </Typography>
        );
      },
    }),
  ];

  return (
    <>
      {selectedOptionRecipe && (
        <>
          <Typography
            variant="h6"
            fontWeight={600}
            lineHeight="19px"
            minWidth="160px"
            padding="12px"
          >
            {selectedOptionRecipe?.recipe_name} Recipe
          </Typography>
          <CustomTable
            isTableHeader={false}
            defaultData={selectedOptionRecipe?.ingredients ?? []}
            columns={IngColumns}
            isPagination={false}
            showSearchBar={false}
          />
        </>
      )}
    </>
  );
};
