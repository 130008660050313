import { Button, Stack, Typography } from "@mui/material";

export const SuccessDialog = (props) => {
  const { handleClose, onChange, disabled } = props;

  return (
    <>
      <Stack padding="16px 24px 16px 24px" spacing={2}>
        <Typography>
          Your recipe has been successfully created. However, it is currently in
          inactive state. You can activate it later from the recipe's detail
          page.
        </Typography>
        <Typography>Would you like to activate it now?</Typography>
      </Stack>
      <Stack
        direction="row"
        gap="8px"
        padding="8px 24px 16px 24px"
        sx={{
          justifyContent: "flex-end",
          width: "100%",
        }}
      >
        <Button
          variant="filled"
          sx={{ bgcolor: "#DFE8F2" }}
          onClick={handleClose}
        >
          Activate Later
        </Button>
        <Button variant="contained" onClick={onChange} disabled={disabled}>
          Activate Now
        </Button>
      </Stack>
    </>
  );
};
