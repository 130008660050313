import { Button, Chip, Stack } from "@mui/material";
import React, { useMemo } from "react";
import { CustomeDialog, CustomTable, DeleteModal } from "../../../shared";
import AddIcon from "@mui/icons-material/Add";
import { createColumnHelper } from "@tanstack/react-table";
import { useNavigate } from "react-router-dom";
import { AddMemberDialog } from "./AddMemberDialog";
import { assignUsertoRoles } from "../api/RolesAndPermissionsApi";

export const RoleMembersList = (prop) => {
  const {
    state,
    onDelete,
    confirmDeleteUser,
    resetDeleteInfo,
    deleteInfo,
    handleClickOpen,
    HandleFilterUsers,
    isSelectedUser,
    handleUsersSelect,
    handleAssignUser,
    handleSelectAll,
    usersListQuery,
    handleAssignUsers,
    isCreateVisible,
    isDeletable,
  } = prop;
  const navigate = useNavigate();
  const columnHelper = createColumnHelper([]);
  const ActionButtons = ({ deleteAction }) => {
    return (
      <div className="action-buttons" onClick={(e) => e.preventDefault()}>
        {isDeletable && (
          <img
            alt="delete"
            onClick={deleteAction}
            src="/icons/ic_trash_sm.png"
            style={{ width: 20, height: 20 }}
          />
        )}
      </div>
    );
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        header: () => "Name",
      }),
      columnHelper.accessor("email", {
        header: () => "Email",
      }),

      columnHelper.accessor("status", {
        header: () => "Status",
        cell: ({ row }) => {
          return row?.original.status ? (
            <Chip
              size="small"
              color="primary"
              variant="filled"
              label="ACTIVE"
            />
          ) : (
            <Chip size="small" color="gray" label="INACTIVE" />
          );
        },
      }),

      columnHelper.display({
        id: "actions",
        cell: (props) => {
          const { row } = props;
          return (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
              gap="8px"
              mr={-1}
            >
              <ActionButtons deleteAction={(e) => onDelete(row.original.id)} />
            </Stack>
          );
        },
      }),
    ],
    []
  );
  return (
    <>
      <Stack sx={{ borderRadius: "4px", bgcolor: "#fff" }}>
        <CustomTable
          defaultData={state.filteredMembersList ?? []}
          columns={columns}
          headerText="Admin Users"
          onRowLink={(e) => {
            return `/manage-users/details/${e.id}`;
          }}
          rightContent={
            <Stack direction="row" gap="8px">
              {isCreateVisible && (
                <Button
                  sx={{
                    height: "36px",
                    border: "1px solid #BCC7DB",
                    borderRadius: "10px",
                  }}
                  type="button"
                  size="medium"
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={() => handleClickOpen(true)}
                >
                  Member
                </Button>
              )}
            </Stack>
          }
        />
      </Stack>
      <CustomeDialog
        open={state?.open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: { width: "720px", height: "535px", p: "16px" },
        }}
        content={
          <AddMemberDialog
            userList={state.filteredUsers}
            handleClickOpen={handleClickOpen}
            HandleFilterUsers={HandleFilterUsers}
            handleUsersSelect={handleUsersSelect}
            isSelectedUser={isSelectedUser}
            handleAssignUser={handleAssignUser}
            isSelectAllChecked={state?.filteredUsers?.every((item) =>
              state?.selectedUsers?.includes(item.id)
            )}
            onAllSelect={handleSelectAll}
            hasSelectAll={true}
            isSelected={(id) => state.selectedUsers.includes(id)}
            isLoading={
              usersListQuery?.isPending || assignUsertoRoles?.isPending
            }
            disabled={
              state.selectedUsers.length === 0 || handleAssignUsers?.isPending
            }
            value={state.userSearchText}
          />
        }
      />
      <DeleteModal
        open={deleteInfo?.show}
        handleClose={() => resetDeleteInfo()}
        onConfirm={() => confirmDeleteUser()}
      />
    </>
  );
};
