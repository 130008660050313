import { useQuery } from "@tanstack/react-query";
import { useRecipeKeys } from "./useRecipeKeys";
import { getRecipeIngredients } from "../api/RecipeApi";
export const useIngredientsQueries = ({ recipeId }) => {
  const { recipeKeys } = useRecipeKeys();
  const ingredientQuery = useQuery({
    queryKey: recipeKeys.detailBykEY("ingredients", recipeId),
    queryFn: () => getRecipeIngredients(recipeId),
    enabled: recipeId > 0,
  });
  return { ingredientQuery };
};
