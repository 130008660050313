import { useImmer } from "use-immer";
import { useEffect, useRef, useState } from "react";
import SimpleReactValidator from "simple-react-validator";
import { useStoresQueries } from "./useStoresQueries";
import { usePermission } from "../../../shared";

export const useStores = ({ isList, storeId }) => {
  const isStoreAssignMacid = usePermission("store-assign-mac-id");
  const isMacidView = usePermission("store-mac-id-view");
  const isDeviceView = usePermission("store-device-view");
  const isMacidDelete = usePermission("mac-id-delete");
  const [, forceUpdate] = useState();
  const validator = useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
  );
  const [state, setState] = useImmer({
    deleteMacOpen: false,
    selectedMac: null,
    addMacOpen: false,
    macId: "",
    addMacs: [],
    dataList: [],
    allDataList: [],
    searchKey: "",
  });

  const { listQuery, storeDetails, unAssignMac, assignMac } = useStoresQueries({
    isList,
    storeId,
  });

  const onSaveMac = (data) => {
    const payload = {
      mac_id: state.addMacs,
      store_id: storeId,
    };
    assignMac.mutate({
      data: payload,
      handleModal: () => handleModal("add-mac", false),
    });
  };

  const handleModal = (type, state, data) => {
    switch (type) {
      case "add-mac":
        setState((draft) => {
          draft.addMacOpen = state;
          draft.addMacs = [];
          draft.macId = "";
        });
        break;
      case "delete-mac":
        setState((draft) => {
          draft.deleteMacOpen = state;
          draft.selectedMac = data;
        });
        break;
    }
  };

  const removeMac = () => {
    const payload = {
      mac_id: state.selectedMac,
      store_id: storeId,
    };
    unAssignMac.mutate({
      data: payload,
      handleModal: () => handleModal("delete-mac", false, null),
    });
  };

  const onChange = (e) => {
    const { value } = e.target;
    setState((draft) => {
      draft.macId = value;
    });
  };

  const handleAddMacId = () => {
    console.log(state.macId);

    setState((draft) => {
      draft.addMacs = [...draft.addMacs, draft.macId];
      draft.macId = "";
    });
  };

  const handleDeleteMacTag = (mac) => {
    setState((draft) => {
      draft.addMacs = draft.addMacs.filter((v) => v !== mac);
    });
  };

  useEffect(() => {
    if (listQuery?.data) {
      setState((draft) => {
        draft.dataList = listQuery?.data;
        draft.allDataList = listQuery?.data;
      });
    }
  }, [listQuery.data]);

  const onSearchChange = (string) => {
    setState((draft) => {
      draft.searchKey = string;
      draft.dataList = searchStores(string);
    });
  };

  function searchStores(query) {
    return state.allDataList.filter((item) => {
      const nameMatch = item.name.toLowerCase().includes(query.toLowerCase());
      const macMatch = item.mac_id.some((mac) => mac.mac_id.includes(query));
      return nameMatch || macMatch;
    });
  }
  return {
    state,
    listQuery,
    storeDetails,
    handleModal,
    removeMac,
    onChange,
    handleAddMacId,
    handleDeleteMacTag,
    onSaveMac,
    onSearchChange,
    isStoreAssignMacid,
    isMacidView,
    isDeviceView,
    isMacidDelete,
  };
};
