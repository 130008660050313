import React, { useEffect } from "react";
import { useImmer } from "use-immer";
import { useAppScope } from "../shared";

export const AppContext = React.createContext();

export const AppScopeProvider = ({ children }) => {

  const value = useAppScope();

  return (
    <AppContext.Provider
      value={value}
    >
      {children}
    </AppContext.Provider>
  );
};
