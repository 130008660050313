import { API } from "../../../utils/api";

export const getStoreListWithMac = async () => {
  const res = await API.get(`store-mac-list`);
  return res.data.data;
};

export const getStoreById = async (id) => {
  const res = await API.get(`store-details/${id}`);
  return res.data.data;
};

export const assignMactoStore = async (payload) => {
  const res = await API.post(`assign-mac-id`, payload);
  return res.data;
};

export const unAssignMactoStore = async (payload) => {
  const res = await API.post(`un-assign-macId`, payload);
  return res.data;
};

