import { Stack, Typography } from "@mui/material";

/**
 * LimitedTag component displays a tag with a limited number of visible items
 * and an overflow count. It adapts its width based on the provided autocomplete
 * width and has a fixed width for the label.
 *
 * @param {Object} props - The component props.
 * @param {Array} props.value - The array of items to display, where each item
 *   is expected to be an object containing a key defined by `labelKey`.
 * @param {string} props.autocompleteWidth - The total width available for the
 *   autocomplete dropdown.
 * @param {string} [props.labelWidth="130px"] - The fixed width for the label.
 *   Defaults to "130px".
 * @param {string} [props.labelKey="name"] - The key used to access the label
 *   for each item in the `value` array. Defaults to "name".
 *
 * @returns {JSX.Element} The rendered LimitedTag component.
 */
export const LimitedTag = ({
  value,
  autocompleteWidth,
  labelWidth = "130px",
  labelKey = "name",
}) => {
  const overflowCount = value.length - 1;
  const label = value[0][labelKey];
  return (
    <Stack
      flexDirection="row"
      whiteSpace="nowrap"
      lineHeight="16.94px"
      fontSize="14px"
      pl="10px"
      maxWidth={`calc(${autocompleteWidth} - ${labelWidth})`}
    >
      <Typography
        color="#ffffff"
        fontWeight="500"
        sx={{
          flex: 1,
          textOverflow: "ellipsis",
          overflow: "hidden",
        }}
        title={label}
      >
        {label}
      </Typography>
      {overflowCount > 0 && (
        <Typography
          component="span"
          color="#8E99AC"
          pl="8px"
          width="max-content"
        >
          +{overflowCount}
        </Typography>
      )}
    </Stack>
  );
};
