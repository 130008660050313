import { Box, Button, Chip, Divider, Stack, Typography } from "@mui/material";
import React from "react";
import { CustomeDialog } from "../../../shared";
import { CustomDetailsList } from "../../../shared/components";
import { SceduleDialog } from "./SceduleDialog";
import DatePicker from "react-multi-date-picker";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import { DeleteCustomDateDiaog } from "./DeleteCustomDateDiaog";
import { DeleteReccuringDateDialog } from "./DeleteReccuringDateDialog";

export const SettingsView = (props) => {
  const format = "YYYY-MM-DD";
  const {
    dialogBoxId,
    createScedule,
    onSubmit,
    templates,
    onDeleteTagSelect,
    onChangeDate,
    RadioButtonChange,
    onChangeSceduler,
    templateProductionDaysDetails,
    dropdownListQuery,
    handleRepeatOptionChange,
    weekOfMonthOptionChange,
    monthOptionChange,
    weekDaysOptionChange,
    onChangeYear,
    onChangeHandler,
    DaysOfWeekOptionChange,
    WeekDaysOptionChange,
    OptionChange,
    handleDayClick,
    onChangeMultipleDate,
    handleClickUpdateOpen,
    setTemplates,
    RemoveButtonHandeler,
    handleScheduleOpen,
    onChangeEffectiveDate,
    onSaveCustomDays,
    handleAddCustomDays,
    validator,
    globalData,
    handleRemoveRecurringDate,
    confirmDeleteRecurringDates,
  } = props;
  const CustomDateColumns = [
    {
      id: 10,
      title: "Start Date",
      accessor: (val) => {
        return (
          <Chip
            color="gray"
            variant="outlined"
            label={val[0]?.start_date}
          ></Chip>
        );
      },
    },

    {
      id: 1,
      title: "Custom Date",
      accessor: (val) => {
        return (
          <Stack
            sx={{
              flexDirection: "row",
              flexWrap: "wrap",
              gap: "4px",
            }}
          >
            {val[0]?.custom_days?.map((day, index) => (
              <Chip
                key={index}
                label={day}
                style={{ marginRight: "8px", spacing: "2px" }}
              />
            ))}
          </Stack>
        );
      },
    },
  ];
  const dateRangeColumns = [
    {
      id: 1,
      title: "Start Date",
      accessor: (val) => {
        return (
          <Chip
            color="gray"
            variant="outlined"
            label={val[0]?.start_date}
          ></Chip>
        );
      },
    },
    {
      id: 2,
      title: "Repeat",
      accessor: (val) => {
        const dayValue = ListofDropdown?.find(
          (i) => i.value === val[0]?.drop_down_list
        );
        if (val[0]?.sheduled_types === 7) {
          const selectedDay = globalData?.week_values?.filter((day) =>
            val[0]?.date.includes(day?.value)
          );
          const dayValue = globalData?.drop_down_list?.find(
            (i) => i.value === val[0]?.drop_down_list
          );
          const weekOfMonth = WeekOfMonth?.find(
            (i) => i.value === val[0]?.dayofweek
          );
          const weekDays = globalData?.week_values?.find(
            (i) => i.value === val[0]?.date
          );

          const monthValues = MonthDropdown?.find(
            (i) => i.value === val[0]?.month
          );
          return (
            <Stack direction="row" gap="12px" alignItems="center">
              <Typography> Every </Typography>
              <Typography variant="subtitle1" fontWeight={600} color="#636363">
                {val[0]?.period}
              </Typography>
              <Typography variant="subtitle1" fontWeight={600} color="#636363">
                Year
              </Typography>

              <Typography variant="subtitle2" fontWeight={400} color="#636363">
                on the
              </Typography>
              <Typography variant="subtitle1" fontWeight={600} color="#636363">
                {weekOfMonth?.label}
              </Typography>
              <Typography variant="subtitle1" fontWeight={600} color="#636363">
                {selectedDay?.map((day) => day?.label)}
              </Typography>
              <Typography variant="subtitle2" fontWeight={400} color="#636363">
                of
              </Typography>
              <Typography variant="subtitle1" fontWeight={600} color="#636363">
                {monthValues?.label}
              </Typography>
            </Stack>
          );
        }
        if (val[0]?.custom_date === 0 && val[0]?.drop_down_list === 1) {
          return (
            <Stack direction="row" gap="12px" alignItems="center">
              <Typography> Every </Typography>
              <Typography variant="subtitle1" fontWeight={600} color="#636363">
                {val[0]?.period}
              </Typography>
              <Typography variant="subtitle1" fontWeight={600} color="#636363">
                {dayValue?.label}
              </Typography>
            </Stack>
          );
        } else if (val[0]?.custom_date === 0 && val[0]?.drop_down_list === 2) {
          const selectedDays = globalData?.week_values?.filter((day) =>
            val[0]?.date.includes(day?.value)
          );

          return (
            <Stack direction="row" gap="12px" alignItems="center">
              <Typography> Every </Typography>
              <Typography variant="subtitle1" fontWeight={600} color="#636363">
                {val[0]?.period}
              </Typography>
              <Typography variant="subtitle1" fontWeight={600} color="#636363">
                {dayValue?.label}
              </Typography>
              <Typography variant="subtitle2" fontWeight={400} color="#636363">
                on
              </Typography>
              <Typography variant="subtitle1" fontWeight={600} color="#636363">
                {selectedDays?.map((day) => day?.label).join(", ")}
              </Typography>
            </Stack>
          );
        } else if (
          val[0]?.custom_date === 0 &&
          val[0]?.drop_down_list === 3 &&
          val[0]?.sheduled_types === 4
        ) {
          const dayValue = ListofDropdown?.find(
            (i) => i.value === val[0]?.drop_down_list
          );
          return (
            <Stack direction="row" gap="12px" alignItems="center">
              <Typography> Every </Typography>
              <Typography variant="subtitle1" fontWeight={600} color="#636363">
                {val[0]?.period}
              </Typography>
              <Typography variant="subtitle1" fontWeight={600} color="#636363">
                {dayValue?.label}
              </Typography>
              <Typography variant="subtitle2" fontWeight={400} color="#636363">
                on day
              </Typography>
              <Typography variant="subtitle1" fontWeight={600} color="#636363">
                {val[0]?.day}
              </Typography>
            </Stack>
          );
        } else if (
          val[0]?.custom_date === 0 &&
          val[0]?.drop_down_list === 3 &&
          val[0]?.sheduled_types === 5
        ) {
          const weekValue = WeekOfMonth?.find(
            (i) => i.value === val[0]?.weekofmonth
          );
          const dayValue = ListofDropdown?.find(
            (i) => i.value === val[0]?.drop_down_list
          );
          const weekDaysValue = WeekDays?.find(
            (i) => i.value === val[0]?.dayofweek
          );
          return (
            <Stack direction="row" gap="12px" alignItems="center">
              <Typography> Every </Typography>
              <Typography variant="subtitle1" fontWeight={600} color="#636363">
                {val[0]?.period}
              </Typography>
              <Typography variant="subtitle1" fontWeight={600} color="#636363">
                {dayValue?.label}
              </Typography>
              <Typography variant="subtitle2" fontWeight={400} color="#636363">
                on the
              </Typography>
              <Typography variant="subtitle1" fontWeight={600} color="#636363">
                {weekValue?.label}
              </Typography>
              <Typography variant="subtitle1" fontWeight={600} color="#636363">
                {weekDaysValue?.label}
              </Typography>
            </Stack>
          );
        } else if (
          val[0]?.custom_date === 0 &&
          val[0]?.drop_down_list === 4 &&
          val[0]?.sheduled_types === 6
        ) {
          const dayValue = ListofDropdown?.find(
            (i) => i.value === val[0]?.drop_down_list
          );
          const monthValue = MonthDropdown?.find(
            (i) => i.value === val[0]?.month
          );
          return (
            <Stack direction="row" gap="12px" alignItems="center">
              <Typography> Every </Typography>
              <Typography variant="subtitle1" fontWeight={600} color="#636363">
                {val[0]?.period}
              </Typography>
              <Typography variant="subtitle1" fontWeight={600} color="#636363">
                {dayValue?.label}
              </Typography>

              <Typography variant="subtitle2" fontWeight={400} color="#636363">
                on
              </Typography>
              <Typography variant="subtitle1" fontWeight={600} color="#636363">
                {monthValue?.label}
              </Typography>
              <Typography variant="subtitle1" fontWeight={600} color="#636363">
                {val[0]?.day}
              </Typography>
            </Stack>
          );
        } else if (
          val[0]?.custom_date === 0 &&
          val[0]?.drop_down_list === 4 &&
          val[0]?.sheduled_types === 7
        ) {
          const dayValue = ListofDropdown?.find(
            (i) => i.value === val[0]?.drop_down_list
          );
          const weekOfMonth = WeekOfMonth?.find(
            (i) => i.value === val[0]?.dayofweek
          );
          const weekDays = WeekDays?.find((i) => i.value === val[0]?.date);

          const monthValues = MonthDropdown?.find(
            (i) => i.value === val[0]?.month
          );
          return (
            <Stack direction="row" gap="12px" alignItems="center">
              <Typography> Every </Typography>
              <Typography variant="subtitle1" fontWeight={600} color="#636363">
                {val[0]?.period}
              </Typography>
              <Typography variant="subtitle1" fontWeight={600} color="#636363">
                {dayValue?.label}
              </Typography>

              <Typography variant="subtitle2" fontWeight={400} color="#636363">
                on the
              </Typography>
              <Typography variant="subtitle1" fontWeight={600} color="#636363">
                {weekOfMonth?.label}
              </Typography>
              <Typography variant="subtitle1" fontWeight={600} color="#636363">
                {weekDays?.label}
              </Typography>
              <Typography variant="subtitle2" fontWeight={400} color="#636363">
                of
              </Typography>
              <Typography variant="subtitle1" fontWeight={600} color="#636363">
                {monthValues?.label}
              </Typography>
            </Stack>
          );
        } else {
          return null;
        }
      },
    },

    {
      id: 3,
      title: "End Date",
      accessor: (val) => {
        return val[0]?.end_date ? (
          <Chip color="gray" variant="outlined" label={val[0]?.end_date}></Chip>
        ) : (
          ""
        );
      },
    },
  ];

  const ScheduleTable = [
    {
      id: 10,
      title: "Start Date",
      accessor: "start_date",
    },
    {
      id: 1,
      title: "Repeat",
      accessor: "period",
    },
    {
      id: 1,
      title: "End Date",
      accessor: "end_date",
    },
  ];

  const ListofDropdown = dropdownListQuery?.data?.drop_down_list; //day week month

  const WeekOfMonth = dropdownListQuery?.data?.Week_Number_List; //first second...

  const MonthDropdown = dropdownListQuery?.data?.Month_List; //jan feb mar

  const WeekDays = dropdownListQuery?.data?.week_values; // sun mon tue

  const SceduledTypes = dropdownListQuery?.data?.Shedule_Types; //1,2,3,...

  const isReschedule = templateProductionDaysDetails?.data?.length > 0;

  return (
    <>
      {validator.current.purgeFields()}
      {templateProductionDaysDetails?.data &&
      templateProductionDaysDetails?.data[0]?.custom_date === 0 ? (
        <Stack sx={{ borderRadius: "4px", bgcolor: "#fff", px: "16px" }}>
          <CustomDetailsList
            columns={
              templateProductionDaysDetails?.data?.length > 0 &&
              templateProductionDaysDetails?.data[0]?.custom_date === 1
                ? CustomDateColumns
                : templateProductionDaysDetails?.data?.length > 0 &&
                  templateProductionDaysDetails?.data[0]?.custom_date === 0
                ? dateRangeColumns
                : ScheduleTable
            }
            isEmpty={!isReschedule}
            emptyTableMessage="No production days added yet!"
            data={templateProductionDaysDetails?.data || []}
            title="Production Days"
            headerRightContent={
              <Box
                sx={{
                  display: "flex",
                  gap: 2,
                }}
              >
                <Button
                  variant="text"
                  color="error"
                  style={{ textTransform: "none" }}
                  onClick={() => handleRemoveRecurringDate(true)}
                >
                  Remove
                </Button>
                <Button
                  type="button"
                  color="inherit"
                  size="small"
                  variant="outlined"
                  sx={{ borderColor: "#BCC7DB" }}
                  onClick={() => handleScheduleOpen(true, dialogBoxId)}
                >
                  {isReschedule ? "Reschedule" : "Schedule"}
                </Button>
              </Box>
            }
          />
        </Stack>
      ) : (
        <Stack
          direction="row"
          justifyContent="space-between"
          bgcolor="#F7FAFC"
          sx={{
            position: "sticky",
            zIndex: "99px",
            top: "0px",
          }}
        >
          <Box>
            <Stack
              p="20px"
              borderRadius="4px"
              bgcolor="#F7FAFC"
              height="139px"
              spacing={1}
            >
              <Typography variant="h6">Set Recurring Dates?</Typography>
              <Typography fontSize="14px" fontWeight={400}>
                Customize your schedule to automatically repeat this template on
                your preferred dates.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                sx={{ width: "87px" }}
                onClick={() => handleScheduleOpen(true, dialogBoxId)}
              >
                Schedule
              </Button>
            </Stack>
          </Box>
        </Stack>
      )}
      {/* ///////////////////////////////custom dates///////////////////////////////// */}
      <Stack bgcolor="white" padding="6px 8px" spacing={3}>
        <Typography variant="subtitle1" mt={2}>
          Custom Dates
          <Divider />
        </Typography>
        <Stack direction="row" gap={1}>
          <DatePicker
            style={{
              minWidth: "260px",
              minHeight: "50px",
              borderRadius: "10px",
            }}
            name="custom_dates"
            value={templates?.data?.custom_dates}
            multiple
            minDate={moment().format("YYYY-MM-DD")}
            sort
            format={format}
            calendarPosition="left"
            onChange={(e) => onChangeMultipleDate(e)}
            render={(value, openCalendar) => (
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                style={{
                  border: "1px solid #ccc",
                  borderRadius: "10px",
                  padding: "0 8px",
                  cursor: "pointer",
                  minHeight: "50px",
                }}
                onClick={openCalendar}
              >
                <img
                  src="/icons/ic_calendar.png"
                  alt="Calendar Icon"
                  style={{
                    width: "24px",
                    height: "24px",
                    marginRight: "8px",
                  }}
                />
                <input
                  type="text"
                  value={""}
                  readOnly
                  style={{
                    border: "none",
                    outline: "none",
                    flex: 1,
                    padding: "10px 12px",
                    minHeight: "48px",
                    width: "20px",
                  }}
                />
              </Stack>
            )}
          />

          <Button
            variant="contained"
            size="small"
            style={{ margin: "5px" }}
            onClick={() => onSaveCustomDays()}
            disabled={templates?.isDateSaveButton}
          >
            Save
          </Button>
        </Stack>
        <Stack direction="row" gap={1} flexWrap="wrap">
          {(Array.isArray(templates?.data?.custom_dates_view)
            ? templates?.data?.custom_dates_view
            : []
          ).map((data, index) => (
            <Chip
              key={index}
              variant="outlined"
              color="gray"
              label={data}
              deleteIcon={<CloseIcon />}
              onDelete={() => {
                onDeleteTagSelect(data, true);
              }}
              sx={{
                width: "135px",
                backgroundColor: "#F7FAFC",
                "& .MuiChip-deleteIcon": {
                  color: "#263141",
                },
              }}
            />
          ))}
        </Stack>
      </Stack>
      {templates?.scheduleOpen === true && (
        <CustomeDialog
          title={
            <Typography
              sx={{
                padding: "20px 20px 8px",
                fontWeight: "500",
                fontSize: "20px",
                lineHeight: "24.2px",
                width: "590px",
              }}
            >
              Schedule
            </Typography>
          }
          PaperProps={{
            sx: {
              maxWidth: "630px",
              maxHeight: "100%",
              overflowY: "unset ",
            },
          }}
          open={templates?.scheduleOpen}
          content={
            <SceduleDialog
              onChangeDate={onChangeDate}
              createScedule={createScedule}
              onSubmit={onSubmit}
              templates={templates}
              onDeleteTagSelect={onDeleteTagSelect}
              onChangeEffectiveDate={onChangeEffectiveDate}
              RadioButtonChange={RadioButtonChange}
              onChangeSceduler={onChangeSceduler}
              ListofDropdown={ListofDropdown}
              handleRepeatOptionChange={handleRepeatOptionChange}
              WeekOfMonth={WeekOfMonth}
              weekOfMonthOptionChange={weekOfMonthOptionChange}
              MonthDropdown={MonthDropdown}
              monthOptionChange={monthOptionChange}
              WeekDays={WeekDays}
              weekDaysOptionChange={weekDaysOptionChange}
              onChangeYear={onChangeYear}
              onChangeHandler={onChangeHandler}
              DaysOfWeekOptionChange={DaysOfWeekOptionChange}
              WeekDaysOptionChange={WeekDaysOptionChange}
              OptionChange={OptionChange}
              handleDayClick={handleDayClick}
              onChangeMultipleDate={onChangeMultipleDate}
              dialogBoxId={dialogBoxId}
              handleClickOpen={handleScheduleOpen}
              handleClickUpdateOpen={handleClickUpdateOpen}
              setTemplates={setTemplates}
              RemoveButtonHandeler={RemoveButtonHandeler}
              SceduledTypes={SceduledTypes}
              isReschedule={isReschedule}
            />
          }
        />
      )}
      <CustomeDialog
        PaperProps={{
          sx: {
            width: "500px",
          },
        }}
        closeIcon={false}
        open={templates?.isDeleteDialogOpen}
        title={
          <Stack
            direction="row"
            justifyContent="space-between"
            position="sticky"
            zIndex={999}
            bgcolor="#fff"
            top={0}
          >
            <Stack direction="row" alignItems="center" ml={2}>
              <img src="/icons/ic_pair_dialog.svg" />
              <Typography
                fontWeight={500}
                variant="body1"
                padding="15px"
                fontSize={20}
              >
                Remove Date?
              </Typography>
            </Stack>
          </Stack>
        }
        content={
          <DeleteCustomDateDiaog
            templates={templates}
            onDeleteTagSelect={onDeleteTagSelect}
            onSaveCustomDays={onSaveCustomDays}
          />
        }
      />
      <CustomeDialog
        PaperProps={{
          sx: {
            width: "500px",
            minHeight: "252px",
          },
        }}
        closeIcon={false}
        open={templates.isRecurringDialog}
        title={
          <Stack
            direction="row"
            justifyContent="space-between"
            position="sticky"
            zIndex={999}
            bgcolor="#fff"
            top={0}
          >
            <Stack direction="row" alignItems="center" ml={2}>
              <img src="/icons/ic_pair_dialog.svg" />
              <Typography
                fontWeight={500}
                variant="body1"
                padding="15px"
                fontSize={20}
              >
                Remove Recurring & Custom Dates?
              </Typography>
            </Stack>
          </Stack>
        }
        content={
          <DeleteReccuringDateDialog
            templates={templates}
            handleRemoveRecurringDate={handleRemoveRecurringDate}
            confirmDeleteRecurringDates={confirmDeleteRecurringDates}
          />
        }
      />
    </>
  );
};
