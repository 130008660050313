import { API } from "../../../utils/api";

export const getRolesList = async (query) => {
  const res = await API.get(`roles`, query);
  return res.data.data;
};
export const getPermissionsList = async () => {
  const res = await API.get(`permissions`);
  return res.data.data;
};
export const saveRoles = async (data) => {
  const res = await API.post("roles", data);
  return res.data.data;
};

export const getRolesDetailsById = async (id) => {
  const res = await API.get(`roles/${id}`);
  return res.data.data;
};

export const updateRole = async (data, id) => {
  const res = await API.put(`roles/${id}`, data);
  return res.data;
};

export const removeRoles = async ({ id }) => {
  const res = await API.delete(`roles/${id}`);
  return res.data;
};
export const removeUserFromRoles = async (id, roleId) => {
  const res = await API.post(`roles/${roleId}/delete_user`, id);
  return res.data.data;
};
export const getUserList = async () => {
  const res = await API.get(`user_list`);
  return res.data.data;
};
export const assignUsertoRoles = async (data, roleId) => {
  const res = await API.post(`roles/${roleId}/assign_user`, data);
  return res.data.data;
};
