import { Button, Stack } from "@mui/material";
import React, { useMemo } from "react";
import {
  CustomModal,
  DeleteModal,
  ServerPaginatedtable,
  SimpleMenu,
} from "../../../shared";
import AddIcon from "@mui/icons-material/Add";
import { createColumnHelper } from "@tanstack/react-table";
import { AddOrEditUnit } from "./AddOrEditUnit";
import ic_check from "../../../assets/images/Icons/ic_check.png";
import { useUnits } from "../hooks/UseUnits";

export const Units = () => {
  const {
    listQuery: { isLoading, data },
    unit,
    onChange,
    onSubmit,
    createUnit,
    handleDeleteModal,
    validator,
    updateUnit,
    unitDetails,
    handleClose,
    listQuery,
    onSearchChange,
    onPaginationChange,
    pagination,
    setPagination,
    deleteInfo,
    onDelete,
    resetDeleteInfo,
    confirmDelete,
    handleClickOpen,
    sorting,
    setSorting,
    debouncedText,
    isUnitCreate,
    isUnitEditable,
    isUnitDeletable,
    moduleName,
    search,
  } = useUnits({ unitlist: true });
  const columnHelper = createColumnHelper(data?.units ?? []);

  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        header: () => "Name",
      }),
      columnHelper.accessor("symbol", {
        header: () => "UoM Symbol",
      }),

      // columnHelper.accessor("no_of_decimals", {
      //   header: () => "Decimal Places",
      //   cell: (props) => {
      //     return props.row.original.no_of_decimals > 0
      //       ? 1 / Math.pow(10, props.row.original.no_of_decimals)
      //       : "";
      //   },
      // }),

      columnHelper.accessor("ignore", {
        header: "Ignore Calculation",
        cell: (props) =>
          props.row.original.ignore === 1 ? (
            <img src={ic_check} alt="check" />
          ) : null,
      }),

      columnHelper.display({
        id: "actions",
        cell: (props) =>
          (isUnitEditable || isUnitDeletable) && (
            <SimpleMenu
              row={props.row}
              editAction={(e) => handleClickOpen(true, e.id)}
              handleDeleteModal={(e) => onDelete(e.id)}
              isDeletable={isUnitDeletable}
              isEditable={isUnitEditable}
            />
          ),
      }),
    ],
    [listQuery?.data?.unit]
  );
  return (
    <>
      <Stack sx={{ borderRadius: "4px", bgcolor: "#fff" }}>
        <ServerPaginatedtable
          defaultData={listQuery?.data?.units ?? []}
          columns={columns}
          isSearchActive={debouncedText}
          onSearchChange={onSearchChange}
          search={search}
          sorting={sorting}
          pagination={pagination}
          setSorting={(callback) =>
            setSorting({ tableName: moduleName, callback })
          }
          setPagination={(callback) =>
            setPagination({ tableName: moduleName, callback })
          }
          paginationProps={
            listQuery.data?.pagination || { total: 0, lastPage: 1 }
          }
          headerText="Units"
          emptyMessage="There is no unit added yet, You need to add unit first"
          isLoading={isLoading}
          isInTab={true}
          rightContent={
            isUnitCreate && (
              <Button
                type="button"
                size="medium"
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => handleDeleteModal(true, 0)}
              >
                Unit
              </Button>
            )
          }
        />
      </Stack>
      <CustomModal
        open={unit.isOpen}
        title={unit?.id ? unit?.data?.name : "New Unit of Measure"}
        onSubmit={onSubmit}
        isEdit={unit?.id}
        handleClose={() => handleClose(false)}
        isDisableSubmit={
          unit?.id > 0 ? updateUnit?.isPending : createUnit?.isPending
        }
        content={
          <AddOrEditUnit
            validator={validator}
            state={unit}
            createUnit={createUnit}
            updateUnit={updateUnit}
            unitDetails={unitDetails}
            onChange={onChange}
          />
        }
      />

      <DeleteModal
        open={deleteInfo.show}
        handleClose={() => resetDeleteInfo()}
        onConfirm={() => confirmDelete()}
      />
    </>
  );
};
