import React from "react";
import { Route, Routes } from "react-router-dom";
import { TemplatesList } from "./containers";
import { TemplatesDetails } from "./containers/TemplatesDetails";
import { ProtectedRoute } from "../../shared";

export const Templates = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<TemplatesList />} />
        <Route element={<ProtectedRoute allowedRoute={["template-view"]} />}>
          <Route path="/details/:id" element={<TemplatesDetails />} />
        </Route>
      </Routes>
    </>
  );
};
