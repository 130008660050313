import { API } from "../../../utils/api";

export const getTripsAndTricksList = async ({ query, pagination }) => {
  const { pageIndex, pageSize } = pagination;
  const res = await API.get(
    `tips-and-tricks?page=${pageIndex}&per_page=${pageSize}`,
    query
  );
  return res.data.data;
};
export const getRecipes = async () => {
  const res = await API.get(`recipes`);
  return res.data.data;
};
export const removeTipsAndTricks = async ({ id }) => {
  const res = await API.delete(`tips-and-tricks/${id}`);
  return res.data;
};
export const saveTipsAndTricks = async (payload) => {
  const res = await API.post("tips-and-tricks", payload);
  return res.data.data;
};
export const getTipsAndTricks = async (id) => {
  const res = await API.get(`tips-and-tricks/${id}`);
  return res.data.data;
};
export const editTipsAndTricks = async (detailsId, payload) => {
  const res = await API.put(`tips-and-tricks/${detailsId}`, payload);
  return res.data;
};
