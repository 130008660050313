import { useImmer } from "use-immer";
import { useContext, useEffect, useRef, useState } from "react";
import SimpleReactValidator from "simple-react-validator";
import { useTipsAndTricksQuery } from "./useTipsAndTricksQuery";
import { useDebounce } from "use-debounce";
import { useDelete, usePermission } from "../../../shared";
import { AppContext } from "../../../store";

export const useTipsAndTricks = (props) => {
  const isDeletable = usePermission("Tips&Tricks-delete");
  const isCreateVisible = usePermission("Tips&Tricks-create");
  const isEditable = usePermission("Tips&Tricks-edit");
  const { isList = false, recipeId, tipId } = props;
  const [, forceUpdate] = useState();
  const validator = useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
  );
  const [tipsAndTricks, setTipsAndTricks] = useImmer({
    type: 0,
    file: "",
    data: {
      name: "",
      recipe_id: [],
      doc_url: "",
    },
    search: "",
    url: "",
  });
  const { deleteInfo, onDelete, resetDeleteInfo } = useDelete();
  const moduleName = "tipsAndTricks";
  const { tableData, setSorting, setPagination, setSearchState } =
    useContext(AppContext);
  const { sorting, pagination, search } = tableData[moduleName];
  const [debouncedText] = useDebounce(search, 1000);
  const {
    listQuery,
    recipeListQuery,
    deleteTipsAndTricks,
    createTipsAndTricks,
    tipsAndTricksDetails,
    updateTipsAndTricks,
    createImageFileUrl,
  } = useTipsAndTricksQuery({
    isList,
    pagination,
    debouncedText,
    recipeId,
    sorting,
    resetDeleteInfo,
    tipId,
  });
  const confirmDelete = () => {
    deleteTipsAndTricks.mutate({ id: deleteInfo.id, to: deleteInfo.to });
  };
  const onChange = (e, type) => {
    if (type === "type") {
      setTipsAndTricks((draft) => {
        draft.type = tipsAndTricks.type === 1 ? 0 : 1;
      });
    } else {
      const { name, value } = e.target;
      setTipsAndTricks((draft) => {
        draft.data[name] = value;
      });
    }
  };
  useEffect(() => {
    if (tipsAndTricksDetails?.data?.recipe_id.length) {
      setTipsAndTricks((draft) => {
        draft.type = 1;
      });
    }
  }, [tipsAndTricksDetails?.data?.recipe_id]);
  const onSearchHandler = (search) => {
    setSearchState({
      tableName: moduleName,
      key: "search",
      value: search,
    });
  };
  useEffect(() => {
    if (createImageFileUrl?.data) {
      setTipsAndTricks((draft) => {
        draft.url = createImageFileUrl?.data;
      });
    }
  }, [createImageFileUrl?.data]);
  const onSubmit = () => {
    if (validator.current.allValid()) {
      const { name, recipe_id, doc_url } = tipsAndTricks.data;
      let formData = new FormData();

      tipsAndTricks.file && formData.append("doc_url", tipsAndTricks.url);
      formData.append("name", name);
      recipe_id?.map((item, index) => {
        formData.append(`recipe_id[]`, item.id);
      });

      tipId
        ? updateTipsAndTricks.mutate({ tipId, formData })
        : createTipsAndTricks.mutate({
            formData,
          });
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  useEffect(() => {
    setTipsAndTricks((draft) => {
      draft.isLoading = false;
      draft.data = {
        ...tipsAndTricksDetails?.data,
        doc_url: tipsAndTricksDetails?.data?.images,
        recipe_id: tipsAndTricksDetails?.data?.recipe_id?.map((v) => {
          return { id: v.recipe_id, name: v.recipe_name };
        }),
      };
    });
  }, [tipsAndTricksDetails?.data]);
  const onDeleteRecipeSelect = (data) => {
    setTipsAndTricks((draft) => {
      draft.data.recipe_id = tipsAndTricks.data.recipe_id.filter((value) => {
        return value.id !== data.id;
      });
    });
  };

  const handleInputChangeFile = (e, index) => {
    const imageFile = e.target.files[0];
    const type = e?.target?.files[0]?.type?.includes("pdf")
      ? "doc"
      : "tips_video";
    setTipsAndTricks((draft) => {
      draft.file = imageFile;
    });

    let imageFormdata = new FormData();
    imageFormdata.append("type", type);
    imageFormdata.append("media", imageFile);
    createImageFileUrl.mutate(imageFormdata);
  };
  const onDeleteFileSelect = () => {
    setTipsAndTricks((draft) => {
      draft.file = "";
      draft.data.doc_url = "";
    });
  };

  return {
    tipsAndTricks,
    listQuery,
    pagination,
    setPagination,
    onChange,
    validator,
    recipeListQuery,
    onSearchHandler,
    sorting,
    setSorting,
    onDelete,
    confirmDelete,
    deleteInfo,
    resetDeleteInfo,
    onSubmit,
    tipsAndTricksDetails,
    onDeleteRecipeSelect,
    handleInputChangeFile,
    onDeleteFileSelect,
    updateTipsAndTricks,
    createTipsAndTricks,
    createImageFileUrl,
    isCreateVisible,
    isEditable,
    isDeletable,
    moduleName,
    search,
  };
};
