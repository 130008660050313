import { usePermission, useStatusSelect } from "../../../shared";
import { useBasicDetails } from "./useBasicDetails";
import { useIngredients } from "./useIngredients";
import { useRecipeStatusUpdateQueries } from "./useRecipeStatusUpdateQueries";

export const useRecipeDetails = ({ recipeId }) => {
  const isEditable = usePermission("recipe-modify");
  const isStatusChange = usePermission("recipe-status_change");
  const isStoreAssign = usePermission("recipe-assign_store");
  const isRecipeTipsAndTricks = usePermission("recipe-tips_tricks-view");
  const { ingredientQuery } = useIngredients({
    recipeId: recipeId,
  });
  const { basicDetailsQuery } = useBasicDetails({ recipeId });
  const { updateRecipeStatusMuate, errorState, setErrorState } =
    useRecipeStatusUpdateQueries({
      recipeId,
    });

  const onChangeStatusHandler = (value) => {
    updateRecipeStatusMuate.mutate({ recipeId, status: value });
  };
  const selectStatus = useStatusSelect({ onChangeStatusHandler });
  const handleClose = () => {
    setErrorState((draft) => {
      draft.isOpen = false;
    });
  };
  return {
    ingredientQuery,
    basicDetailsQuery,
    selectStatus,
    onChangeStatusHandler,
    updateRecipeStatusMuate,
    isEditable,
    isStatusChange,
    isRecipeTipsAndTricks,
    isStoreAssign,
    errorState,
    handleClose,
  };
};
