import {
    useQuery,
  } from "@tanstack/react-query";
import { useRecipeKeys } from "./useRecipeKeys";
import { getRecipeChartSettings } from "../api";

export const useChartSettingsQueries = ({recipeId}) =>
{
    const {recipeKeys} = useRecipeKeys();
    const chartSettingsQuery = useQuery({
        queryKey: recipeKeys.detailBykEY("chart_settings",recipeId),
        queryFn: () => getRecipeChartSettings(recipeId),
        enabled: recipeId > 0,
      });
      return {chartSettingsQuery};
}