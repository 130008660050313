import {
  Button,
  Checkbox,
  Chip,
  Stack,
  TextField,
  Tooltip,
  Typography,
  createFilterOptions,
} from "@mui/material";
import React from "react";
import {
  InputControl,
  PageListLayout,
  ServerPaginatedtable as ServerPaginatedTable,
  DeleteModal,
  LimitedTag,
} from "../../../shared";
import AddIcon from "@mui/icons-material/Add";
import { createColumnHelper } from "@tanstack/react-table";
import SimpleReactValidator from "simple-react-validator";
import { useNavigate } from "react-router-dom";
import { useAdminUsers } from "../hooks";

export const AdminUsersList = () => {
  const {
    state,
    pagination,
    handlePagination,
    listQuery,
    deleteInfo,
    confirmDelete,
    resetDeleteInfo,
    onSearchHandler,
    storeLists,
    StoreValuesHandler,
    selectStatus,
    onClearFilter,
    disableclearButton,
    isCreateVisible,
  } = useAdminUsers({
    isList: true,
  });
  const [, forceUpdate] = React.useState();
  const validator = React.useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },
      validators: {
        Item: {
          message: "The recipes field is required.",
          required: true,
          rule: (value) => {
            if (value.length <= 0) return false;
            else return true;
          },
        },
      },
    })
  );

  const navigate = useNavigate();

  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor("name", {
      header: () => "Name",
    }),
    columnHelper.accessor("email", {
      header: () => "Email",
    }),
    columnHelper.accessor("status", {
      header: "Status",
      cell: ({ row }) => {
        return row?.original.status ? (
          <Chip size="small" color="primary" variant="filled" label="ACTIVE" />
        ) : (
          <Chip size="small" color="gray" label="INACTIVE" />
        );
      },
    }),
    columnHelper.accessor("store_count", {
      header: "Stores",
      enableSorting: false,
      cell: ({ row }) => {
        return (
          <Tooltip
            placement="bottom"
            arrow
            title={row.original.store_name
              .map((val) => {
                return val;
              })
              .join(", ")}
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <Chip
              size="small"
              color="info"
              variant="outlined"
              label={row?.original.store_name.length + " Stores"}
              sx={{
                borderColor: "#C4CFE4",
                fontWeight: "500",
                width: "fit-content",
              }}
            />
          </Tooltip>
        );
      },
    }),

    columnHelper.display({
      id: "actions",
      cell: (props) => {
        const { row } = props;
        return (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            gap="8px"
            mr={-1}
          >
            <img
              alt="details"
              src="/icons/arrow_forward.png"
              onClick={(e) => navigate(`details/${row?.original?.id}`)}
            />
          </Stack>
        );
      },
    }),
  ];

  const breadcrumbs = [
    <Typography key="3" sx={{ fontSize: "14px", fontWeight: 400 }}>
      Admin Users
    </Typography>,
  ];
  const filter = createFilterOptions();
  return (
    <>
      {validator.current.purgeFields()}
      <PageListLayout
        breadcrumbs={breadcrumbs}
        pageContent={
          <Stack sx={{ borderRadius: "4px", background: "#ffffff" }}>
            <ServerPaginatedTable
              manualSortBy={false}
              defaultData={listQuery?.data?.users ?? []}
              columns={columns}
              headerText="Admin Users"
              search={state?.filter?.search}
              pagination={pagination}
              setPagination={handlePagination}
              paginationProps={
                listQuery.data?.pagination || { total: 0, lastPage: 1 }
              }
              onSearchChange={onSearchHandler}
              isLoading={listQuery?.isLoading}
              onRowLink={(e) => {
                return `details/${e.id}`;
              }}
              tableHeaderContent={
                <>
                  <Stack
                    sx={{
                      flexDirection: "row",
                      gap: "6px",
                      width: "100%",
                    }}
                  >
                    <InputControl
                      type="dropdown"
                      fullWidth
                      variant="filter"
                      limitTags={1}
                      size="small"
                      multiple={true}
                      options={storeLists?.data ?? []}
                      getOptionLabel={(option) => option?.name || ""}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      disableCloseOnSelect
                      sx={{
                        width: 250,
                      }}
                      renderTags={(value) => (
                        <LimitedTag value={value} autocompleteWidth="250px" />
                      )}
                      value={state?.filters?.storefilter}
                      onChange={(event, option) =>
                        StoreValuesHandler(event, option)
                      }
                      renderOption={(props, option, { selected }) => (
                        <li {...props} key={option.id}>
                          <Checkbox
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option?.name}
                        </li>
                      )}
                      filterOptions={filter}
                      renderInput={(params) => (
                        <TextField {...params} placeholder="Store" />
                      )}
                    />
                    <InputControl
                      type="dropdown"
                      variant="filter"
                      size="small"
                      options={selectStatus?.statusOptions}
                      getOptionLabel={(option) => option?.name || ""}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      sx={{
                        width: "180px",
                      }}
                      value={
                        selectStatus?.statusOptions?.find(
                          (status) => status?.id === state?.selectedStatus
                        ) || null
                      }
                      onChange={(event, option) =>
                        selectStatus.statusValueChangeHandler(option)
                      }
                      renderInput={(params) => (
                        <TextField {...params} placeholder="Status" />
                      )}
                    />

                    <Button
                      type="button"
                      size="small"
                      variant="text"
                      sx={{
                        p: "6px 12px 6px 12px",
                        gap: "6px",
                      }}
                      disabled={disableclearButton}
                      onClick={onClearFilter}
                    >
                      Clear All
                    </Button>
                  </Stack>
                </>
              }
              rightContent={
                <Stack direction="row" gap="8px">
                  {isCreateVisible && (
                    <Button
                      sx={{
                        border: "1px solid #BCC7DB",
                        bgcolor: "#F5F8FC",
                      }}
                      type="button"
                      size="small"
                      variant="outlined"
                      color="gray"
                      startIcon={<AddIcon />}
                      onClick={() => navigate("create")}
                    >
                      User
                    </Button>
                  )}
                </Stack>
              }
            />
          </Stack>
        }
      />

      <DeleteModal
        open={deleteInfo?.show}
        handleClose={() => resetDeleteInfo()}
        onConfirm={() => confirmDelete()}
      />
    </>
  );
};
