import React from "react";
import { Route, Routes } from "react-router-dom";
import { TipsAndTricksList } from "./containers/TipsAndTricksList";
import { AddorEditTipsAndTricks } from "./containers";

export const TipsAndTricks = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<TipsAndTricksList />} />
        <Route>
          <Route path="/create" element={<AddorEditTipsAndTricks />} />
        </Route>
        <Route>
          <Route path="/edit/:id" element={<AddorEditTipsAndTricks />} />
        </Route>
      </Routes>
    </>
  );
};
