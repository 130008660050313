import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Box, Stack } from "@mui/material";
import { Loader } from "./Loader";
import ImageIcon from "../../assets/images/Icons/ic_new_image.svg";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  borderWidth: 2,
  borderRadius: 12,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#F2F7FC",
  color: "#485364",
  outline: "none",
  transition: "border .24s ease-in-out",
  height: "150px",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

export const DropzoneComponent = (props) => {
  const { handleDropzone, imgUrl, loading } = props;

  const onDrop = useCallback((acceptedFiles) => {
    handleDropzone(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({ onDrop, accept: { "image/*": [], "video/*": [] } });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  return (
    <div className="container">
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        {loading ? (
          <Stack
            height={150}
            sx={{
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Loader />
          </Stack>
        ) : imgUrl ? (
          <Box
            component={"img"}
            aspect
            sx={{ height: "100%", objectFit: "cover" }}
            src={imgUrl}
          />
        ) : (
          <Stack
            height={150}
            sx={{
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box component="img" src={ImageIcon} height={48} width={48} />
            <p>Drag & Drop image here or Browse image</p>
          </Stack>
        )}
      </div>
    </div>
  );
};
