import { API } from "../../../utils/api";

export const getCategoryList = async (query) => {
  const res = await API.get(`categories?${query}`);
  return res.data.data;
};

export const editCategory = async (data) => {
  const { detailsIds, payload } = data;
  const res = await API.put(`categories/${detailsIds}`, payload);
  return res.data;
};

export const saveCategory = async (data) => {
  const { payload } = data;
  const res = await API.post("categories", payload);
  return res.data;
};

export const removeCategory = async (id) => {
  const res = await API.delete(`categories/${id}`);
  return res.data;
};

export const getCategory = async (id) => {
  const res = await API.get(`categories/${id}`);
  return res.data.data;
};
export const sortOrderCategory = async (filterreorderedData) => {
  const res = await API.post(`categories/change-display-order`, {
    categories: filterreorderedData,
  });
  return res.data;
};
export const sortOrderSubCategory = async ({
  filterItems,
  selectedcategoryId,
}) => {
  const res = await API.post(
    `subcategories/${selectedcategoryId}/change-display-order`,
    {
      subcategories: filterItems,
    }
  );
  return res.data;
};
export const sortOrderCategoryProducts = async ({ filterreorderedData }) => {
  const res = await API.post(`recipes/change-display-order`, {
    recipes: filterreorderedData,
  });
  return res.data;
};
