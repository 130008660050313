import { Stack, Typography } from "@mui/material";
import React from "react";
import { CustomTable } from "../../../shared";
import { createColumnHelper } from "@tanstack/react-table";
import { useBatchGroupsQuery } from "../hooks/UseBatchGroupQuery";

export const BatchGroup = () => {
  const { listQuery } = useBatchGroupsQuery({ list: true });

  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor("name", {
      header: () => "Recipe Name",
    }),
    columnHelper.accessor("bowl_capacity", {
      header: () => "Bowl Capacity",
      cell: ({ row }) => {
        return (
          <Stack direction="row">
            <Typography fontStyle={400} fontSize="14px">
              {row.original.bowl_capacity}
            </Typography>
            <Typography fontStyle={400} fontSize="14px">
              {row.original.bowl_capacity_unit}
            </Typography>
          </Stack>
        );
      },
    }),
  ];
  return (
    <>
      <Stack sx={{ borderRadius: "4px", bgcolor: "#fff" }}>
        <CustomTable
          defaultData={listQuery?.data ?? []}
          columns={columns}
          headerText="Batch Groups"
          isLoading={listQuery?.isLoading}
          emptyTableMessage={
            "There is no batch group added yet,you need to add batch group first"
          }
          onRowLink={(e) => {
            return `/recipe/details/${e.id}?basic-details`;
          }}
          tableId={"batchGroup"}
        />
      </Stack>
    </>
  );
};
