import { useQueryClient, useQuery, useMutation } from "@tanstack/react-query";
import { errorMessage, successMessage } from "../../../utils/helper";
import { useRecipeKeys } from "./useRecipeKeys";

export const useRecipeDeleteQueries = ({resetDeleteInfo}) =>
{
    const {recipeKeys} = useRecipeKeys();
    const queryClient = useQueryClient();
    const mutateDeleteRecipe = useMutation({
    mutationFn: null,
    onError: (error) => {
      errorMessage(error.response.data.message);
    },
    onSuccess: (res) => {
      if(res.success)
      {
        queryClient.invalidateQueries({
          queryKey: recipeKeys.list(),
        });
        successMessage(res.message);
        resetDeleteInfo();
      }
      else if(res.success === false)
      {
        errorMessage(res.message);
      }
    },
  });
  return {mutateDeleteRecipe};
}