import * as React from "react";
import { Stack, Modal, Typography, Button, Box } from "@mui/material";

const style = {
  position: "absolute",
  top: "35%",
  left: "50%",
  transform: "translate(-50%, -100%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  borderRadius: "16px",
  outline: "none",
};

export function DeleteModal(props) {
  const { open, handleClose, onConfirm, deleteCategory } = props;
  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Are you sure you want to delete?
          </Typography>
          <Stack
            direction="row"
            gap="12px"
            justifyContent="flex-end"
            marginTop={3}
          >
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>

            <Button
              disabled={deleteCategory?.isPending}
              onClick={onConfirm}
              variant="contained"
              color="error"
            >
              Delete
            </Button>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
}
