import * as React from "react";

import { Dialog, Typography, Stack, Box, Tabs, Tab } from "@mui/material";

export const CustomDialogTemplate = (props) => {
  const {
    open,
    handleClose,
    content = null,
    PaperProps,
    title,
    size = "sm",
    isHeader = true,
    tableFooter,
    handleBack,
    isBackArroaw,
    middleContent,
  } = props;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={PaperProps}
      sx={{
        maxHeight: "100%",
        "& + .pac-container": {
          zIndex: 18000,
        },
      }}
    >
      {isHeader && (
        <>
          <Stack
            direction="row"
            justifyContent="space-between"
            position="sticky"
            zIndex={999}
            bgcolor="#fff"
            top={0}
          >
            <Stack direction="row" alignItems="center" ml={2}>
              {isBackArroaw ? (
                <img
                  alt="edit"
                  onClick={() => handleBack()}
                  src="/icons/ic_back.png"
                  style={{ cursor: "pointer" }}
                />
              ) : (
                ""
              )}
              <Typography
                fontWeight={500}
                variant="body1"
                padding="15px"
                fontSize={20}
              >
                {title}
              </Typography>
            </Stack>
          </Stack>
          {middleContent ? middleContent : ""}
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              bgcolor: "#fff",
            }}
          />
        </>
      )}

      {content}

      <Stack
        justifyContent="space-between"
        position="sticky"
        zIndex={999}
        bgcolor="#fff"
        bottom={0}
        marginTop="auto"
      >
        {tableFooter}
      </Stack>
    </Dialog>
  );
};
