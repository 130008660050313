import {
  useQueryClient,
  useQuery,
  useMutation,
  keepPreviousData,
} from "@tanstack/react-query";
import {
  ErrorFormatter,
  errorMessage,
  successMessage,
} from "../../../utils/helper";
import {
  assignMactoStore,
  getStoreById,
  getStoreListWithMac,
  unAssignMactoStore,
} from "../api/storesApi";

const storesKeys = {
  all: ["stores"],
  lists: () => [...storesKeys.all, "storeslist"],
  detail: (id) => [...storesKeys.lists(), id],
};

export const useStoresQueries = ({
  isList,
  storeId,
}) => {
  const queryClient = useQueryClient();

  //list useQueryClient
  const listQuery = useQuery({
    queryKey: storesKeys.lists(),
    queryFn: () => getStoreListWithMac(),
    placeholderData: keepPreviousData,
    enabled: isList,
  });

  const assignMac = useMutation({
    mutationFn: ({ _, data }) => assignMactoStore(data),
    onError: (error) => {
      errorMessage(ErrorFormatter(error.response.data.errors));
    },
    onSuccess: (res, variables) => {
      if (res.success) {
        queryClient.invalidateQueries({
          queryKey: storesKeys.detail(storeId),
        });
        successMessage(res.message);
      } else if (res.success === false) {
        errorMessage(res.message);
      }
      variables.handleModal();
    },
  });

  const storeDetails = useQuery({
    queryKey: storesKeys.detail(storeId),
    queryFn: () => getStoreById(storeId),
    enabled: storeId > 0,
  });

  const unAssignMac = useMutation({
    mutationFn: ({ _, data }) => unAssignMactoStore(data),
    onError: (error) => {
      errorMessage(error?.response?.data?.error ?? "Error");
    },
    onSuccess: (res, variables) => {
      if (res.success) {
        queryClient.invalidateQueries({
          queryKey: storesKeys.detail(storeId),
        });
        successMessage(res.message);
      } else if (res.success === false) {
        errorMessage(res.message);
      }
      variables.handleModal();
    },
  });




  return {
    listQuery,
    storeDetails,
    unAssignMac,
    assignMac,
  };
};
