import { Chip, Stack, Typography } from "@mui/material";
import React from "react";

export const SelectChip = ({ key, value, isSelected, onClick, countKey }) => {
  return (
    <Stack
      sx={{
        flexDirection: "row",
        alignItems: "center",
        borderRadius: "32px",
        gap: "8px",
        border: "1px solid #DFE8F2",
        p: "6px 8px 6px 10px",
        bgcolor: isSelected && "#1760D2",
        color: isSelected && "#fff",
        minWidth: "fit-content",
      }}
      key={key}
      onClick={onClick}
    >
      <Typography variant="subtitle2" fontWeight={500} noWrap>
        {value.name}
      </Typography>
      <Chip
        size="small"
        sx={{
          fontSize: "12px",
          fontWeight: 500,
          bgcolor: isSelected ? "#124da8" : "#e1e1e1",
          color: isSelected && "#fff",
        }}
        label={countKey ? value[countKey] : value.recipeCount}
      />
    </Stack>
  );
};
