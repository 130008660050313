import React, { useState } from "react";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { InputControl } from "../../../shared";
import SimpleReactValidator from "simple-react-validator";

export const StoreUpdateDialog = (props) => {
  const {
    onSubmit,
    templates,
    onChangeStoreEffectiveDate,
    setTemplates,
    handleClose,
    state,
    isDisabled,
  } = props;
  const [, forceUpdate] = React.useState();
  const validator = React.useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
  );
  const { alert_radio, effective_date } = templates;
  const onSubmitData = () => {
    if (validator.current.allValid()) {
      onSubmit();
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };
  const today = new Date().toISOString().split("T")[0];
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const tomorrowDate = tomorrow.toISOString().split("T")[0];

  const handleRadioChange = (event) => {
    const value = event.target.value;
    let selectedDate;
    if (value === "1") {
      selectedDate = today;
    } else if (value === "2") {
      selectedDate = tomorrowDate;
    }
    setTemplates((draft) => {
      draft.alert_radio = value;
      draft.effective_date = selectedDate;
    });
  };

  return (
    <Stack sx={{ padding: "20px 20px 8px 20px" }}>
      <FormControl>
        <RadioGroup
          sx={{ gap: "8px" }}
          value={alert_radio}
          onChange={handleRadioChange}
        >
          <FormControlLabel
            control={<Radio />}
            label="Update From Today"
            value="1"
          />
          <FormControlLabel
            control={<Radio />}
            label="Update From Tomorrow"
            value="2"
          />
          <FormControlLabel
            control={<Radio />}
            label="Update From :"
            value="3"
          />
        </RadioGroup>
      </FormControl>
      {alert_radio === "3" && (
        <>
          <InputControl
            sx={{ width: "250px" }}
            name="effective_date"
            value={effective_date}
            type="datePicker"
            disablePast
            inputFormat="MM/dd/yyyy"
            onKeyDown={(e) => {
              e.preventDefault();
            }}
            onChange={(dateValue) => onChangeStoreEffectiveDate(dateValue)}
          />

          <Typography color="#657083" variant="body2" marginLeft="8px">
            Ending on {effective_date}
          </Typography>
        </>
      )}
      <FormHelperText sx={{ color: "#C60808" }}>
        {validator.current.message(
          "Effective Date",
          effective_date,
          "required"
        )}
      </FormHelperText>
      <Stack
        direction="row"
        gap="6px"
        padding="6px, 12px, 6px, 12px"
        sx={{ justifyContent: "flex-end" }}
      >
        <Button variant="outlined" width="78px" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          width="78px"
          onClick={onSubmitData}
          disabled={state?.isLoading || isDisabled || !alert_radio}
        >
          Save
        </Button>
      </Stack>
    </Stack>
  );
};
