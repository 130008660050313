import * as React from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Button,
  Stack,
  InputAdornment,
  Box,
  Checkbox,
  ListItemButton,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import LibraryAddCheckIcon from "@mui/icons-material/LibraryAddCheck";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { InputControl, Loader } from "../../../shared";

export const AddMemberDialog = (props) => {
  const {
    userList,
    HandleFilterUsers,
    handleUsersSelect,
    handleAssignUser,
    disabled,
    value,
    handleClickOpen,
    selectAllText = "All Members",
    isSelectAllChecked,
    onAllSelect,
    hasSelectAll = false,
    isSelected,
    isLoading,
  } = props;
  if (isLoading) {
    return <Loader />;
  }
  return (
    <>
      <Stack
        position="sticky"
        zIndex={999}
        bgcolor="#fff"
        top={0}
        marginBottom="auto"
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography>Add Members</Typography>
        <InputControl
          sx={{ width: "200px", borderRadius: "10px" }}
          type="table-search"
          placeholder="Search"
          value={value}
          size="small"
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon size="large" />
            </InputAdornment>
          }
          endAdornment={
            value && (
              <InputAdornment
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  HandleFilterUsers("search", "");
                }}
                position="end"
              >
                <CloseIcon size="large" />
              </InputAdornment>
            )
          }
          fullWidth
          onChange={(e) => {
            HandleFilterUsers("search", e.target.value);
          }}
        />
      </Stack>
      <Stack
        justifyContent="space-between"
        height="100%"
        gap="8px"
        maxHeight={"calc(100% - 24px)"}
      >
        <Box
          sx={{
            height: "400px",
            overflowY: "auto",
            overflowX: "hidden",
            paddingRight: "8px",
          }}
          fullWidth
        >
          {userList?.length > 0 ? (
            <List sx={{ width: "100%", bgcolor: "background.paper" }}>
              {hasSelectAll && (
                <ListItem key={"all"} disablePadding>
                  <ListItemButton
                    dense
                    sx={{
                      "&:hover": {
                        backgroundColor: "#E6E6E6",
                        borderRadius: "8px",
                      },
                    }}
                    onClick={(e) => onAllSelect(!isSelectAllChecked)}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: "32px",
                      }}
                    >
                      <Checkbox
                        edge="start"
                        icon={<CheckBoxOutlineBlankIcon />}
                        checkedIcon={<LibraryAddCheckIcon />}
                        disableRipple
                        checked={isSelectAllChecked}
                      />
                    </ListItemIcon>
                    <ListItemText primary={selectAllText} />
                  </ListItemButton>
                </ListItem>
              )}

              {userList?.map((data, index) => (
                <ListItem
                  alignItems="flex-start"
                  key={index}
                  sx={{
                    height: "50px",
                    padding: " 1px 8px 8px 1px",
                    cursor: "pointer",
                    ml: 0.5,
                  }}
                  onClick={() => handleUsersSelect(data.id)}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: "32px",
                    }}
                  >
                    <Checkbox
                      edge="start"
                      checked={isSelected(data?.id)}
                      disableRipple
                    />
                  </ListItemIcon>

                  <ListItemText
                    primary={
                      <>
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="subtitle2"
                          color="text.primary"
                        >
                          {data.first_name}
                        </Typography>
                      </>
                    }
                    secondary={
                      <>
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="caption"
                          color="text.secondary"
                          fontWeight={400}
                        >
                          {data.email}
                        </Typography>
                      </>
                    }
                  />
                </ListItem>
              ))}
            </List>
          ) : (
            <Stack justifyContent="center" alignItems="center" height="100%">
              <Typography
                sx={{ display: "inline" }}
                component="span"
                variant="caption"
                color="text.secondary"
                fontWeight={400}
              >
                There is no users found
              </Typography>
            </Stack>
          )}
        </Box>

        <Stack
          position="sticky"
          zIndex={999}
          bgcolor="#fff"
          bottom={0}
          marginTop="auto"
          direction="row"
          alignSelf="end"
          gap={1}
        >
          <Button
            variant="outlined"
            onClick={() => {
              handleClickOpen(false);
            }}
            size="small"
          >
            close
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              handleAssignUser();
            }}
            disabled={disabled}
          >
            Save
          </Button>
        </Stack>
      </Stack>
    </>
  );
};
