import { Box, Stack, Typography } from "@mui/material";
import { IngredientsGroup } from "./IngredientsGroup";

export const IngredientsTab = ({ ingredientGroups = [] }) => {
  return (
    <Stack
      sx={{
        width: "100%",
        gap: "4px",
        p: "10px 8px 10px 8px",
      }}
    >
      <Box
        sx={{
          p: "0 8px 0 8px",
          height: "54px",
        }}
      >
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: "18px",
            lineHeight: "21.78px",
            color: "#111C2B",
            m: "16px 8px",
          }}
        >
          Ingredients
        </Typography>
      </Box>
      <Stack
        sx={{
          p: "0 8px 8px 8px",
          backgroundColor: "#F7FAFC",
          width: "100%",
        }}
      >
        {ingredientGroups?.map((ingredientGroup) => {
          return <IngredientsGroup ingredientGroup={ingredientGroup} />;
        })}
      </Stack>
    </Stack>
  );
};
