import {
  Box,
  Button,
  FormHelperText,
  Grid,
  Stack,
  Typography,
  Link,
  Switch,
  Checkbox,
  TextField,
  Chip,
  Paper,
} from "@mui/material";
import React, { useRef } from "react";
import { InputControl, Loader, PageListLayout } from "../../../shared";
import AddIcon from "@mui/icons-material/Add";
import "react-quill/dist/quill.snow.css";
import { useNavigate, useParams } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { useTipsAndTricks } from "../hooks/useTipsAndTricks";

export const AddorEditTipsAndTricks = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    tipsAndTricks,
    onChange,
    validator,
    recipeListQuery,
    onSubmit,
    onDeleteRecipeSelect,
    handleInputChangeFile,
    onDeleteFileSelect,
    updateTipsAndTricks,
    createTipsAndTricks,
    createImageFileUrl,
  } = useTipsAndTricks({
    isList: false,
    tipId: id,
  });

  const videoRef = useRef();

  const RecipeOptions = recipeListQuery?.data?.map((v) => {
    return { id: v.id, name: v.name };
  });
  const breadcrumbs = [
    <Link
      underline="none"
      key="1"
      color="#1760D2"
      onClick={(e) => navigate("/tips-tricks")}
      sx={{ fontSize: "14px", fontWeight: 400 }}
    >
      Tips & Tricks
    </Link>,
    <Typography
      key="2"
      color="inherit"
      sx={{ fontSize: "14px", fontWeight: 400 }}
    >
      {id ? "Edit Tip" : "Create New Tip"}
    </Typography>,
  ];

  return (
    <>
      <PageListLayout
        breadcrumbs={breadcrumbs}
        headerText={id ? "Edit Tip" : "Create New Tip"}
        isChanged={false}
        rightContent={
          <Stack direction="row" gap="8px">
            <Button
              type="button"
              size="small"
              variant="contained"
              color="inherit"
              onClick={() => navigate(-1)}
            >
              Cancel
            </Button>
            <Button
              type="button"
              size="small"
              variant="contained"
              onClick={onSubmit}
              disabled={
                updateTipsAndTricks.isPending ||
                createTipsAndTricks.isPending ||
                (!tipsAndTricks?.data?.doc_url && !tipsAndTricks?.url)
              }
            >
              {id ? "Update" : "Save"}
            </Button>
          </Stack>
        }
        pageContent={
          <>
            <Stack sx={{ borderRadius: "4px", bgcolor: "#fff" }}>
              <Stack sx={{ borderRadius: "4px", bgcolor: "#fff" }}>
                <Stack
                  sx={{
                    py: "70px",
                    borderBottom: "1px solid #DFE8F2",
                    mx: "20px",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <Typography
                        variant="subtitle1"
                        fontWeight={600}
                        sx={{ width: "150px" }}
                      >
                        Tip Name*
                      </Typography>
                    </Grid>
                    <Grid item sm={8}>
                      <Stack>
                        <InputControl
                          name="name"
                          type="text"
                          label="Tip Name"
                          required
                          error={validator.current.message(
                            "Tip Name",
                            tipsAndTricks?.data?.name,
                            "required"
                          )}
                          helperText={validator.current.message(
                            "Tip Name",
                            tipsAndTricks?.data?.name,
                            "required"
                          )}
                          onChange={onChange}
                          value={tipsAndTricks?.data?.name}
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                </Stack>
                <Stack
                  sx={{
                    py: "32px",
                    borderBottom: "1px solid #DFE8F2",
                    mx: "20px",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <Typography
                        variant="subtitle1"
                        fontWeight={600}
                        sx={{ width: "150px" }}
                      >
                        File*
                      </Typography>
                    </Grid>
                    <Grid item sm={8}>
                      <Paper
                        elevation={3}
                        sx={{
                          padding: "16px",
                          borderRadius: "8px",
                          bgcolor: "#F5F8FC",
                        }}
                      >
                        <Typography variant="subtitle1" gutterBottom>
                          File
                        </Typography>
                        {createImageFileUrl?.isPending ? (
                          <Loader />
                        ) : (
                          <Box
                            sx={{
                              border: "1px solid #E0E0E0",
                              borderRadius: "4px",
                              padding: "16px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              bgcolor: "#FFFFFF",
                            }}
                          >
                            {!tipsAndTricks.data.doc_url &&
                            !tipsAndTricks.file.name ? (
                              <Button
                                variant="text"
                                startIcon={<AddIcon />}
                                component="label"
                              >
                                Add File
                                <input
                                  id="contained-button-file"
                                  type="file"
                                  onChange={(e) => handleInputChangeFile(e)}
                                  onClick={(e) => {
                                    e.target.value = null;
                                  }}
                                  style={{ display: "none" }}
                                  ref={videoRef}
                                />
                              </Button>
                            ) : (
                              <Box
                                sx={{
                                  width: "100%",
                                  border: "1px dashed #00a5b8",
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  padding: "10px",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    width: "70%",
                                  }}
                                >
                                  <img
                                    src="/icons/ic_file.jpg"
                                    style={{
                                      marginRight: "4px",
                                    }}
                                  />

                                  <Typography
                                    variant="body1"
                                    sx={{
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      flexGrow: 1,
                                    }}
                                  >
                                    {tipsAndTricks.file.name
                                      ? tipsAndTricks.file.name
                                      : tipsAndTricks.data.doc_url}
                                  </Typography>
                                </Box>
                                <Button
                                  color="secondary"
                                  sx={{ color: "red" }}
                                  onClick={(e) => {
                                    onDeleteFileSelect(e);
                                  }}
                                >
                                  Remove
                                </Button>
                              </Box>
                            )}
                          </Box>
                        )}
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          sx={{ marginTop: "8px" }}
                        >
                          <Box
                            component="span"
                            sx={{
                              display: "inline-block",
                              verticalAlign: "middle",
                            }}
                          >
                            <img src="/icons/ic_info.svg" />
                          </Box>
                          You can only add one PDF or video. Supported formats
                          are PDF or MP4.
                        </Typography>
                      </Paper>

                      <FormHelperText sx={{ color: "#d32f2f" }}>
                        {validator.current.message(
                          "File",
                          tipsAndTricks?.file || tipsAndTricks?.data?.doc_url,
                          "required"
                        )}
                      </FormHelperText>
                    </Grid>
                  </Grid>
                </Stack>
                <Stack
                  sx={{
                    py: "32px",
                    borderBottom: "1px solid #DFE8F2",
                    mx: "20px",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <Typography
                        variant="subtitle1"
                        fontWeight={600}
                        sx={{ width: "150px" }}
                      >
                        Tag Recipe?
                      </Typography>
                    </Grid>
                    <Grid item sm={8}>
                      <Stack spacing={2}>
                        <Switch
                          name="bread_moulder_enabled"
                          checked={tipsAndTricks.type === 1 ? true : false}
                          value={tipsAndTricks.type}
                          onChange={(e) => onChange(e, "type")}
                        />
                        {tipsAndTricks?.type === 1 && (
                          <Stack spacing={2}>
                            <InputControl
                              type="dropdown"
                              name="recipe_id"
                              multiple={true}
                              options={RecipeOptions ?? []}
                              value={RecipeOptions?.filter((op) =>
                                tipsAndTricks?.data?.recipe_id?.find(
                                  (item) => item?.id === op?.id
                                )
                              )}
                              disableCloseOnSelect
                              disableClearable={true}
                              onChange={(e, v) =>
                                onChange({
                                  target: {
                                    name: "recipe_id",
                                    value: v,
                                  },
                                })
                              }
                              getOptionLabel={(option) => option.name}
                              isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                              }
                              renderTags={(value, getTagProps) =>
                                value.map((option, index) => <></>)
                              }
                              renderOption={(props, option, { selected }) => (
                                <li {...props} key={option.id}>
                                  <Checkbox
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                  />
                                  {option.name}
                                </li>
                              )}
                              filterOptions={(options, { inputValue }) =>
                                options.filter((option) =>
                                  option.name
                                    .toLowerCase()
                                    .includes(inputValue.toLowerCase())
                                )
                              }
                              sx={{ width: 255 }}
                              componentsProps={{
                                paper: {
                                  sx: {
                                    width: "100%",
                                    minWidth: 250,
                                  },
                                },
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Type or Select Recipes"
                                  placeholder="Type or Select Recipes"
                                />
                              )}
                            />
                            <Stack
                              sx={{
                                flexDirection: "row",
                                alignItems: "center",
                                flexWrap: "wrap",
                                gap: "12px",
                                marginTop: 1,
                              }}
                            >
                              {tipsAndTricks?.data?.recipe_id?.length > 0 &&
                                tipsAndTricks?.data?.recipe_id?.map(
                                  (data, index) => (
                                    <Chip
                                      variant="outlined"
                                      color="gray"
                                      label={data?.name}
                                      deleteIcon={<CloseIcon />}
                                      onDelete={() => {
                                        onDeleteRecipeSelect(data);
                                      }}
                                      sx={{
                                        "& .MuiChip-deleteIcon": {
                                          color: "#CCCCCC",
                                        },
                                      }}
                                    />
                                  )
                                )}
                            </Stack>
                          </Stack>
                        )}
                      </Stack>
                    </Grid>
                  </Grid>
                </Stack>
              </Stack>
            </Stack>
          </>
        }
      />
    </>
  );
};
