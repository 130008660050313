import { useImmer } from "use-immer";
import { useAuthQueries } from "./useAuthQueries";
import { useLocation, useNavigate } from "react-router-dom";
import { errorMessage } from "../../../utils";

export const useAuthenticate = () => {
  const [auth, setAuth] = useImmer({
    isBusy: false,
    data: {
      email: "",
      password: "",
      remember_token: false,
      showPassword: false,
    },
  });
  const navigate = useNavigate();
  const { state: location } = useLocation();
  const { login } = useAuthQueries({ data: auth.data });
  const handleLogin = async () => {
    try {
      const payload = {
        email: auth?.data?.email,
        password: auth?.data?.password,
        remember_token: auth?.data?.remember_token ? 1 : 0,
      };
      login.mutate({ data: payload, AfterLogSuccess });
    } catch (error) {
      console.log(error);
    }
  };
  const AfterLogSuccess = (res) => {
    const isSuperAdmin = res.data.roles.find((item) => item.name === "admin");
    const isStoreAdmin = res.data.roles.find(
      (item) => item.name === "store-admin"
    );
    if (location?.from) {
      navigate(location.from, { replace: true });
    } else if (isSuperAdmin) {
      navigateAction(res, "admin");
    } else if (isStoreAdmin) {
      navigateAction(res, "store-admin");
    } else {
      errorMessage("You Dont have any of the permission");
    }
  };

  const navigateAction = (res, adminType) => {
    const { action } = res.data;
    if (action === "dashboard") {
      if (adminType === "admin") {
        navigate("/", { replace: true });
      } else if (adminType === "store-admin") {
        navigate("/store-admin/", { replace: true });
      }
    } else {
      navigate("/authentication/ChangePassword", { replace: true });
    }
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setAuth((draft) => {
      draft.data[name] = value;
    });
    if (name !== "rememberMe") {
      setAuth((draft) => {
        draft.errors = "";
      });
    }
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const toggleShowPassword = (name) => {
    setAuth((draft) => {
      draft.data[name] = !draft.data[name];
    });
  };
  return {
    auth,
    login,
    handleLogin,
    onChange,
    handleMouseDownPassword,
    toggleShowPassword,
  };
};
