import {
  useQueryClient,
  useQuery,
  useMutation,
  keepPreviousData,
} from "@tanstack/react-query";
import qs from "qs";
import {
  ErrorFormatter,
  errorMessage,
  successMessage,
} from "../../../utils/helper";
import { useNavigate } from "react-router-dom";
import {
  editDevices,
  editStatus,
  getDeviceById,
  getDevices,
  removeDevices,
  saveDevices,
} from "../api/devicesApi";
import parse from "html-react-parser";

const devicesKeys = {
  all: ["devices"],
  lists: () => [...devicesKeys.all, "deviceslist"],
  deviceslist: (filters) => [...devicesKeys.lists(), filters],
  detail: (id) => [...devicesKeys.deviceslist(), id],
};

export const useDevicesQueries = ({
  isList,
  pagination,
  sorting,
  deviceId,
  resetDeleteInfo,
  debouncedText,
  filterApplied,
  status,
  url,
}) => {
  const queryClient = useQueryClient();
  let params = {};
  const storeId = filterApplied?.map((item) => item?.id);
  const navigate = useNavigate();
  if (debouncedText) {
    params = { ...params, search: debouncedText };
  }
  if (status !== "") {
    params = { ...params, paird_status: status };
  }
  if (storeId) {
    params = { ...params, stores: storeId };
  }

  if (sorting?.length > 0) {
    const sortByParams = sorting.reduce(
      (obj, item) =>
        Object.assign(obj, { [item.id]: item.desc ? "desc" : "asc" }),
      {}
    );
    params = {
      ...params,
      sort: sortByParams,
    };
  }
  const query = {
    params,
    paramsSerializer: (params) => qs.stringify(params, { encode: true }),
  };

  //list query
  const listQuery = useQuery({
    queryKey: devicesKeys.deviceslist({ query, pagination }),
    queryFn: () => getDevices({ query, pagination }),
    placeholderData: keepPreviousData,
    enabled: isList,
  });
  const createDevices = useMutation({
    mutationFn: ({ _, data }) => saveDevices(data),
    onError: (error) => {
      errorMessage(ErrorFormatter(error.response.data.errors));
    },
    onSuccess: (_, data) => {
      queryClient.invalidateQueries({
        queryKey: devicesKeys.lists(),
      });
      successMessage("Device List successfully retrieved!");
      navigate(`/devices/details/${_.id}?status`);
    },
  });
  const updateDevices = useMutation({
    mutationFn: ({ _, data }) => editDevices(data, deviceId),
    onError: (error) => {
      errorMessage(ErrorFormatter(error.response.data.errors));
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: devicesKeys.lists(),
      });
      successMessage("Device updated successfully");
      navigate(`/devices/details/${deviceId}`);
    },
  });

  const deviceDetails = useQuery({
    queryKey: devicesKeys.detail(deviceId),
    queryFn: () => getDeviceById(deviceId),
    enabled: deviceId > 0,
  });

  const deleteDevices = useMutation({
    mutationFn: removeDevices,
    onError: (error) => {
      errorMessage(error?.response?.data?.error ?? "Error");
    },
    onSuccess: (res, variables) => {
      if (res.success) {
        if (variables.isList) {
          queryClient.invalidateQueries({
            queryKey: devicesKeys.lists(),
          });
        }

        successMessage(res.message);
        navigate("/devices");
        variables.to && navigate(variables.to);
        resetDeleteInfo();
      } else if (res.success === false) {
        errorMessage(res.message);
      }
    },
  });
  const editDevicePairingStatus = useMutation({
    mutationFn: ({ _, data }) => editStatus(deviceId),
    onError: (error) => {
      errorMessage(error.response.data.message);
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: devicesKeys.lists(),
      });
      successMessage("Device Pair Status Updated successfully");
      variables.handleOpenDialog();
      variables.handleAddDeviceDialogOpen();
      url.search = "";
      window.history.replaceState({}, "", url);
    },
  });

  return {
    listQuery,
    createDevices,
    updateDevices,
    deviceDetails,
    deleteDevices,
    editDevicePairingStatus,
  };
};
