import { Box, styled } from "@mui/material";
import { useState } from "react";
import { DashboardSidebar } from "./Sidebar";

const DashboardLayoutRoot = styled("div")(({ theme, collapse }) => ({
  display: "flex",
  flex: "1 1 auto",
  maxWidth: "100%",
  paddingLeft: collapse ? 240 : 65,
  transition: "padding-left .7s ease",
}));

export const DashboardLayout = (props) => {
  const { children } = props;
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  return (
    <>
      <DashboardLayoutRoot collapse={isSidebarOpen}>
        <Box
          sx={{
            display: "flex",
            flex: "1 1 auto",
            flexDirection: "column",
            width: "100%",
            transition: "padding-left .7s ease",
          }}
        >
          {children}
        </Box>
      </DashboardLayoutRoot>
      {/* <DashboardNavbar onSidebarOpen={() => setSidebarOpen(true)} /> */}
      <DashboardSidebar
        onCloseAndOpen={(state) => setSidebarOpen(state)}
        open={isSidebarOpen}
      />
    </>
  );
};
