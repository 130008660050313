import { Button, Stack, Typography } from "@mui/material";
import { PageListLayout, CustomTable } from "../../../shared";
import AddIcon from "@mui/icons-material/Add";
import { createColumnHelper } from "@tanstack/react-table";
import SimpleReactValidator from "simple-react-validator";
import { useNavigate } from "react-router-dom";
import { useRolesAndPermissions } from "../hooks/useRolesAndPermissions";
import React from "react";

export const RoleList = () => {
  const [, forceUpdate] = React.useState();
  const validator = React.useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },
      validators: {
        Item: {
          message: "The recipes field is required.",
          required: true,
          rule: (value) => {
            if (value.length <= 0) return false;
            else return true;
          },
        },
      },
    })
  );
  const {
    rolesAndPermissions,
    listQuery,
    onSearchChange,
    isCreateVisible,
    search,
  } = useRolesAndPermissions({
    isList: true,
    isPermissionList: false,
  });
  const navigate = useNavigate();

  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor("display_name", {
      header: () => "Role Name",
    }),
    columnHelper.accessor("member_count", {
      header: () => "Members",
    }),

    columnHelper.display({
      id: "actions",
      cell: (props) => {
        const { row } = props;
        return (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            gap="8px"
            mr={-1}
          >
            <img
              alt="details"
              src="/icons/arrow_forward.png"
              onClick={(e) => navigate(`details/${row?.original?.id}`)}
            />
          </Stack>
        );
      },
    }),
  ];

  const breadcrumbs = [
    <Typography key="3" sx={{ fontSize: "14px", fontWeight: 400 }}>
      Roles & Permissions
    </Typography>,
  ];
  return (
    <>
      {validator.current.purgeFields()}
      <PageListLayout
        breadcrumbs={breadcrumbs}
        pageContent={
          <>
            <Stack sx={{ borderRadius: "4px", bgcolor: "#fff" }}>
              <CustomTable
                defaultData={listQuery?.data ?? []}
                columns={columns}
                headerText="Roles & Permissions"
                onSearchChange={onSearchChange}
                searchText={search}
                isLoading={listQuery?.isLoading}
                tableId={"recipe"}
                onRowLink={(e) => {
                  return `details/${e.id}`;
                }}
                rightContent={
                  <Stack direction="row" gap="8px">
                    {isCreateVisible && (
                      <Button
                        sx={{
                          height: "36px",
                          border: "1px solid #BCC7DB",
                          borderRadius: "10px",
                        }}
                        type="button"
                        size="medium"
                        variant="contained"
                        startIcon={<AddIcon />}
                        onClick={() => navigate("create")}
                      >
                        New Role
                      </Button>
                    )}
                  </Stack>
                }
              />
            </Stack>
          </>
        }
      />
    </>
  );
};
