import React from "react";

export const UseUoMHelper = () => {
  const filterUnits = (data = [], includeSymbols = []) => {
    return (
      data
        .filter(
          (i) =>
            !i?.ignore && (includeSymbols.includes(i.symbol) || !i.is_default)
        )
        ?.map((item) => ({
          name: item.symbol,
          id: item.id,
        })) || []
    );
  };
  const getUniqueUnitIds = (units) => {
    const unitIds = new Set();

    units.forEach((unit) => {
      unit.base_unit_id && unitIds.add(parseInt(unit.base_unit_id));
      unit.alt_unit_id && unitIds.add(parseInt(unit.alt_unit_id));
    });

    return Array.from(unitIds);
  };

  const filterAlternativeUnits = (units, index = 0, UomArray) => {
    let excludeItems = [];
    UomArray.forEach(
      (i, itemIndex) =>
        i.alt_unit_id && itemIndex !== index && excludeItems.push(i.alt_unit_id)
    );
    return units.filter((i) => !excludeItems.includes(i.id));
  };

  return { getUniqueUnitIds, filterUnits, filterAlternativeUnits };
};
