import { useImmer } from "use-immer";
import { useContext, useEffect, useRef, useState } from "react";
import SimpleReactValidator from "simple-react-validator";
import { useDebounce } from "use-debounce";
import { useDelete, usePermission, useUserDelete } from "../../../shared";
import { useRolesAndPermissionsQuery } from "./useRolesAndPermissionsQuery";
import { AppContext } from "../../../store";

export const useRolesAndPermissions = ({
  isList,
  isPermissionList,
  roleId,
}) => {
  const isDeletable = usePermission("role-delete");
  const isCreateVisible = usePermission("role-create");
  const isEditable = usePermission("role-modify");
  const [, forceUpdate] = useState();
  const validator = useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
  );
  const [rolesAndPermissions, setRolesAndPermissions] = useImmer({
    open: false,
    search: "",
    data: {
      name: "",
      permission_id: [],
      type: "super_admin",
    },
    allPermissionsList: [],
    filteredMembersList: [],
    membersList: [],
    details: {},
    filteredUsers: [],
    userSearchText: null,
    allUsers: [],
    selectedUsers: [],
  });
  const { deleteInfo, onDelete, resetDeleteInfo } = useDelete();
  const { deleteUserInfo, onUserDelete, resetUserDeleteInfo } = useUserDelete();
  const moduleName = "rolesAndPermissions";
  const { tableData, setSearchState } = useContext(AppContext);
  const { search } = tableData[moduleName];
  const [debouncedText] = useDebounce(search, 1000);
  const {
    listQuery,
    permissionListQuery,
    createRoles,
    rolesAndPermissionsDetails,
    editRoles,
    deleteRoles,
    handleDeleteUserFromRoles,
    usersListQuery,
    handleAssignUsers,
  } = useRolesAndPermissionsQuery({
    isList,
    isPermissionList,
    debouncedText,
    roleId,
    resetDeleteInfo,
    resetUserDeleteInfo,
  });
  const onSearchChange = (value) => {
    setSearchState({
      tableName: moduleName,
      key: "search",
      value: value,
    });
  };
  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setRolesAndPermissions((draft) => {
      draft.data[name] = value;
    });
  };
  const handleCheckPermission = (e, row, type) => {
    if (row.depth) {
      if (e.target.checked) {
        setRolesAndPermissions((draft) => {
          draft.data.permission_id = [
            ...draft.data.permission_id,
            row.original.id,
          ];
        });
      } else {
        setRolesAndPermissions((draft) => {
          draft.data.permission_id = draft.data.permission_id.filter(
            (val) => val !== row.original.id
          );
        });
      }
    }
  };
  const onSaveRoles = () => {
    createRoles.mutate({ data: rolesAndPermissions?.data });
  };
  const onUpdateRoels = () => {
    editRoles.mutate({ data: rolesAndPermissions?.data });
  };
  const onSubmit = () => {
    if (validator.current.allValid()) {
      roleId ? onUpdateRoels() : onSaveRoles();
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };
  const handleChecked = (depth, id, subRows) => {
    if (depth === 1) {
      const check = rolesAndPermissions.data.permission_id.some(
        (e) => e === id
      );
      return check;
    } else if (depth === 2) {
      const permission_id_arry = rolesAndPermissions.allPermissionsList.flatMap(
        (e, i) => {
          return e.permissions.map((e) => {
            return e.id;
          });
        }
      );
      return (
        rolesAndPermissions.data.permission_id.length ===
        permission_id_arry.length
      );
    } else {
      const subArray = subRows?.map((e, i) => {
        return e.id;
      });
      return subArray.every((val) =>
        rolesAndPermissions.data.permission_id.includes(val)
      );
    }
  };
  useEffect(() => {
    if (permissionListQuery) {
      setRolesAndPermissions((draft) => {
        draft.allPermissionsList = permissionListQuery?.data;
      });
    }
  }, [permissionListQuery]);

  useEffect(() => {
    if (rolesAndPermissionsDetails?.data) {
      setRolesAndPermissions((draft) => {
        draft.details = rolesAndPermissionsDetails?.data;
        draft.data.name = rolesAndPermissionsDetails?.data?.display_name;
        draft.data.permission_id =
          rolesAndPermissionsDetails.data?.permissions?.map((val) => val.id);
        draft.data.type = rolesAndPermissionsDetails?.data?.type;
        draft.membersList = rolesAndPermissionsDetails?.data?.member;
        draft.filteredMembersList = rolesAndPermissionsDetails?.data?.member;
      });
    }
  }, [rolesAndPermissionsDetails?.data]);
  const confirmDelete = () => {
    deleteRoles.mutate({ id: deleteInfo.id, to: deleteInfo.to });
  };
  const confirmDeleteUser = () => {
    const data = {
      user_id: [deleteUserInfo.id],
    };
    handleDeleteUserFromRoles.mutate({ id: data, to: deleteUserInfo.to });
  };
  const handleClickOpen = (type) => {
    if (type) {
      setRolesAndPermissions((draft) => {
        draft.open = type;
      });
    } else {
      setRolesAndPermissions((draft) => {
        draft.open = type;
        draft.selectedUsers = [];
        draft.userSearchText = "";
      });
    }
  };
  const HandleFilterUsers = (type, text) => {
    const alreadyMembers = [];
    rolesAndPermissions.membersList.map((val) => {
      alreadyMembers.push(val.id);
    });
    const filteredUsersList = rolesAndPermissions?.allUsers?.filter(
      (val) => !alreadyMembers.includes(val.id)
    );
    if (type === "initial") {
      setRolesAndPermissions((draft) => {
        draft.filteredUsers = filteredUsersList;
        return draft;
      });
    } else {
      text = text.toLowerCase();
      setRolesAndPermissions((draft) => {
        draft.userSearchText = text;
        draft.filteredUsers = filteredUsersList?.filter(function (o) {
          return ["first_name", "email"].some(function (k) {
            return o[k].toString().toLowerCase().indexOf(text) !== -1;
          });
        });
        return draft;
      });
    }
  };
  useEffect(() => {
    HandleFilterUsers("initial");
  }, [rolesAndPermissions.allUsers, rolesAndPermissions.membersList]);
  useEffect(() => {
    if (usersListQuery) {
      setRolesAndPermissions((draft) => {
        draft.allUsers = usersListQuery.data;
        return draft;
      });
    }
  }, [usersListQuery]);
  const isSelectedUser = (id) => {
    return rolesAndPermissions.selectedUsers.includes(id);
  };
  const handleUsersSelect = (id) => {
    const isSelcted = rolesAndPermissions.selectedUsers.includes(id);
    if (isSelcted) {
      setRolesAndPermissions((draft) => {
        draft.selectedUsers = draft.selectedUsers.filter((val) => val !== id);
      });
    } else {
      setRolesAndPermissions((draft) => {
        draft.selectedUsers = [...draft.selectedUsers, id];
        return draft;
      });
    }
  };

  const AssignUsersToRolesList = () => {
    const data = {
      user_id: rolesAndPermissions.selectedUsers,
    };
    handleAssignUsers.mutate({ data: data, handleClickOpen });
  };
  const handleSelectAll = (checked) => {
    if (checked) {
      setRolesAndPermissions((draft) => {
        draft.selectedUsers = [...draft.filteredUsers.map((item) => item.id)];
      });
    } else {
      setRolesAndPermissions((draft) => {
        draft.selectedUsers = [];
      });
    }
  };

  return {
    rolesAndPermissions,
    listQuery,
    onSearchChange,
    handleOnChange,
    validator,
    handleCheckPermission,
    onSubmit,
    handleChecked,
    confirmDelete,
    deleteInfo,
    onDelete,
    resetDeleteInfo,
    confirmDeleteUser,
    handleClickOpen,
    HandleFilterUsers,
    isSelectedUser,
    handleUsersSelect,
    AssignUsersToRolesList,
    handleSelectAll,
    usersListQuery,
    deleteUserInfo,
    onUserDelete,
    resetUserDeleteInfo,
    createRoles,
    editRoles,
    rolesAndPermissionsDetails,
    isCreateVisible,
    isEditable,
    isDeletable,
    moduleName,
    search,
  };
};
