import { useQueryClient, useQuery, useMutation } from "@tanstack/react-query";
import qs from "qs";
import {
  editCategory,
  getCategoryList,
  saveCategory,
  removeCategory,
  getCategory,
  sortOrderCategory,
  sortOrderSubCategory,
  sortOrderCategoryProducts,
} from "../api";
import { errorMessage, successMessage } from "../../../utils/helper";
import { getRecipeListData } from "../../Recipe/api";

const categoryKeys = {
  all: ["categories"],
  list: () => [...categoryKeys.all, "categorylist"],
  categorylist: (filters) => [...categoryKeys.list(), { filters }],
  detail: (id) => [...categoryKeys.categorylist(), id],
};
const recipeKeys = {
  all: ["recipes"],
  list: () => [...recipeKeys.all, "recipelist"],
  recipelist: (filters) => [...recipeKeys.list(), { filters }],
  detail: (id) => [...recipeKeys.recipelist(), id],
  detailBykEY: (key, id) => [...recipeKeys.detail(id), key, id],
};
export const useCategoryQueries = ({
  categorylist,
  detailsIds,
  debouncedText,
  resetDeleteInfo,
  category_id,
  onSelectItem,
  subcategoryId,
}) => {
  const queryClient = useQueryClient();
  let params = {};
  if (debouncedText) {
    params = { ...params, search: debouncedText };
  }

  const query = qs.stringify(params, {
    paramsSerializer: (params) => qs.stringify(params, { encode: true }),
  });
  // list query
  const listQuery = useQuery({
    queryKey: categoryKeys.categorylist(query),
    queryFn: () => getCategoryList(query),
  });

  //create category
  const createCategory = useMutation({
    mutationFn: saveCategory,
    onError: (error) => {
      errorMessage(error.response.data.message);
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: categoryKeys.list(),
      });
      successMessage(_.message);
      variables.handleClose();
      if (variables.payload.parent_category_id)
        onSelectItem(variables?.selectedData);
      else onSelectItem(_.data);
    },
  });

  //update category
  const updateCategory = useMutation({
    mutationFn: editCategory,
    onError: (error) => {
      errorMessage(error.response.data.message);
    },

    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: categoryKeys.list(),
      });
      successMessage(_.message);
      variables.handleClose();
    },
  });

  //delete category
  const deleteCategory = useMutation({
    mutationFn: removeCategory,
    onError: (error) => {
      errorMessage(error.response.data.message);
    },
    onSuccess: (res) => {
      if (res.success) {
        queryClient.invalidateQueries({
          queryKey: categoryKeys.list(),
        });
        successMessage(res.message);
        resetDeleteInfo();
      } else if (res.success === false) {
        errorMessage(res.message);
      }
    },
  });

  //category details
  const categoryDetails = useQuery({
    queryKey: categoryKeys.detail(detailsIds),
    queryFn: () => getCategory(detailsIds),
    enabled: detailsIds > 0,
  });
  //sort order category
  const sortOrderCategoryList = useMutation({
    mutationFn: sortOrderCategory,
    onError: (error) => {
      errorMessage(error.response.data.message);
    },
    onSuccess: (_, variables) => {
      successMessage(_.message);
    },
  });
  const sortOrderSubCategoryList = useMutation({
    mutationFn: sortOrderSubCategory,
    onError: (error) => {
      errorMessage(error.response.data.message);
    },
    onSuccess: (_, variables) => {
      successMessage(_.message);
    },
  });

  //list query
  let recipeParams = {};
  if (debouncedText) {
    recipeParams.search = debouncedText;
  }
  if (subcategoryId) {
    recipeParams.subcategory = subcategoryId;
  }

  // Correctly serialize recipeParams to recipeQuery
  const recipeQuery = qs.stringify(recipeParams, { encode: true });

  const recipeListQuery = useQuery({
    queryKey: recipeKeys?.recipelist({
      query: recipeQuery,
    }),
    queryFn: () => getRecipeListData({ query: recipeQuery }), // Pass recipeQuery here
    enabled: !!category_id || !!subcategoryId,
  });

  const sortOrderCategoryProductList = useMutation({
    mutationFn: sortOrderCategoryProducts,
    onError: (error) => {
      errorMessage(error.response.data.message);
    },
    onSuccess: (_, variables) => {
      successMessage(_.message);
      variables.handleProductDialogOpen(false);
    },
  });
  return {
    listQuery,
    updateCategory,
    createCategory,
    deleteCategory,
    categoryDetails,
    sortOrderCategoryList,
    sortOrderSubCategoryList,
    recipeListQuery,
    sortOrderCategoryProductList,
  };
};
