import { useContext } from "react";
import { useDashboardQueries } from "./useDashboardQueries";
import { AuthContext } from "../../../store";

export const useDashboard = ({ isList }) => {
  const { auth } = useContext(AuthContext);
  const { listQuery } = useDashboardQueries({
    isList,
    selectedStoreId: auth.data.selectedStoreId,
  });

  return {
    listQuery,
  };
};
