import { useQuery, keepPreviousData } from "@tanstack/react-query";
import qs from "qs";
import { getDashboard } from "../api";
import { useLocation } from "react-router-dom";

const dashboardKeys = {
  all: ["dashboard"],
  lists: () => [...dashboardKeys.all, "dashboardlist"],
  detail: (id) => [...dashboardKeys.lists(), id],
};

export const useDashboardQueries = ({ isList, selectedStoreId }) => {
  const { pathname } = useLocation();
  const isStoreAdmin = pathname.includes("store-admin");
  let params = {};
  if (isStoreAdmin) {
    params = { ...params, "X-STORE": selectedStoreId };
  }

  const query = {
    params,
    paramsSerializer: (params) => qs.stringify(params, { encode: true }),
  };

  const listQuery = useQuery({
    queryKey: dashboardKeys.lists(),
    queryFn: () => getDashboard(query),
    placeholderData: keepPreviousData,
    enabled: isList,
  });

  return {
    listQuery,
  };
};
