import { API } from "../../../utils/api";

export const getRecipeList = async ({ query }) => {
  const res = await API.get(`recipes`, query);
  return res.data.data;
};

export const getRecipeListData = async ({ query }) => {
  const res = await API.get(`recipes?status=1&&condition=1&&${query}`);
  return res.data;
};
export const getRecipeDetails = async (id) => {
  const res = await API.get(`recipes/${id}`);
  return res.data.data;
};

export const getArchivedRecipeList = async ({ query, pagination }) => {
  const { pageIndex, pageSize } = pagination;
  let queryFilters = `${pageIndex}&per_page=${pageSize}`;
  const res = await API.get(`recipes/archive-list?page=${queryFilters}`, query);
  return res.data.data;
};

export const createArchiveRecipe = async (data) => {
  const res = await API.post(`recipes/${data}/archive`);
  return res.data.data;
};

export const unArchiveRecipe = async (data) => {
  const res = await API.post(`recipes/${data}/unarchive`);
  return res.data.data;
};

export const getRecipeHowToPrepare = async (id) => {
  const res = await API.get(`recipes/steps-videos/${id}`);
  return res.data.data;
};

export const getRecipeChartSettings = async (id) => {
  const res = await API.get(`recipes/chart-settings/${id}`);
  return res.data.data;
};

export const getRecipeBasicDetails = async (id) => {
  const res = await API.get(`recipes/basic-details/${id}`);
  return res.data.data;
};

export const getRecipeIngredients = async (id) => {
  const res = await API.get(`recipes/groups-ingredients/${id}`);
  return res.data.data;
};

export const removeRecipe = async (id) => {
  const res = await API.delete(`categories/${id}`);
  return res.data;
};

export const getStoreByStateList = async ({ query, pagination }) => {
  const { pageIndex, pageSize } = pagination;
  const res = await API.get(
    `stores-by-state?page=${pageIndex}&per_page=${pageSize}`,
    query
  );
  return res.data.data;
};

export const getStoreByState = async (id) => {
  const res = await API.get(`store-data/${id}`);
  return res.data.data;
};

export const getAssignedStoreList = async ({ recipeId, query, pagination }) => {
  const { pageIndex, pageSize } = pagination;
  const res = await API.get(
    `recipes/${recipeId}/stores?page=${pageIndex}&per_page=${pageSize}`,
    query
  );
  return res.data.data;
};

export const editRecipeStoreApi = async (data) => {
  const { recipeId, payload } = data;
  const res = await API.put(`recipes/${recipeId}/stores`, {
    store_id: payload,
  });
  return res.data;
};

export const getStoreList = async () => {
  const res = await API.get(`stores`);
  return res.data.data;
};

export const deleteStoreFromRecipe = async (data) => {
  const { recipeId, storeId } = data;
  const res = await API.delete(`recipes/${recipeId}/stores/${storeId}`);
  return res.data;
};

export const updateRecipeStatus = async (data) => {
  const { recipeId, status } = data;
  const res = await API.put(`recipes/${recipeId}/recipe-status-change`, {
    status: status,
  });
  return res.data;
};
