import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Link,
  FormControl,
  Stack,
  Typography,
  Card,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Alert,
  Button,
} from "@mui/material";
import SimpleReactValidator from "simple-react-validator";
import { InputControl } from "../../../shared";
import BanjosTextLogo from "../../../assets/images/Logo/BanjosTextLogo.svg";
import { useAuthenticate } from "../hooks";

export function Login() {
  const [, forceUpdate] = useState();
  const validator = useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
  );
  const {
    auth,
    login,
    handleLogin,
    onChange,
    handleMouseDownPassword,
    toggleShowPassword,
  } = useAuthenticate();

  const { data } = auth;

  return (
    <>
      <Box
        component="main"
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          height: "95vh",
          backgroundColor: "#FFFFFF",
        }}
      >
        <form
          noValidate
          onSubmit={(e) => {
            e.preventDefault();
            if (validator.current.allValid()) {
              handleLogin(data);
            } else {
              validator.current.showMessages();
              forceUpdate(1);
            }
          }}
        >
          <Box align="center" sx={{ width: "424px", height: "430px" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img
                alt="icon-Dashboard"
                alignment="center"
                src={BanjosTextLogo}
              />
              <Typography sx={{ mt: 1.5 }} variant="h5" gutterBottom>
                Sign In
              </Typography>
            </Box>
            <Box component="input-box" sx={{ display: "flex" }}>
              <FormControl sx={{ mt: 1.5, width: "376px" }}>
                <InputControl
                  label="Email"
                  name="email"
                  placeholder="Enter Email"
                  onChange={onChange}
                  value={auth.data.email || null}
                  error={validator.current.message(
                    "email",
                    auth.data.email,
                    "required|email"
                  )}
                  helperText={validator.current.message(
                    "email",
                    auth.data.email,
                    "required|email"
                  )}
                />
              </FormControl>

              <Stack direction="row" alignItems="center">
                <Box sx={{ flexGrow: 1 }}>
                  <FormControl
                    sx={{ mt: 2, width: "376px" }}
                    variant="outlined"
                  >
                    <InputControl
                      name="password"
                      label="Password"
                      type="password"
                      placeholder="Enter password"
                      showPassword={auth.data.showPassword}
                      onChange={onChange}
                      value={auth.data.password || null}
                      error={validator.current.message(
                        "password",
                        auth.data.password,
                        "required"
                      )}
                      helperText={validator.current.message(
                        "password",
                        auth.data.password,
                        "required"
                      )}
                      onClick={(e) => toggleShowPassword("showPassword")}
                      onMouseDown={handleMouseDownPassword}
                    />
                  </FormControl>
                </Box>
              </Stack>
              <FormControl sx={{ width: "376px" }} variant="outlined">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={auth.data.rememberMe}
                        onChange={(e) =>
                          onChange({
                            target: {
                              name: "remember_token",
                              value: e.target.checked,
                            },
                          })
                        }
                      />
                    }
                    label="Remember Me"
                  />
                </FormGroup>
              </FormControl>

              <Button
                color="primary"
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                disabled={login?.isPending}
                sx={{ width: 376, mt: 2 }}
              >
                Login
              </Button>
              <Box
                sx={{
                  pt: 2,
                  mb: 3,
                  textDecoration: "none",
                  cursor: "pointer",
                  color: "blue",
                }}
              >
                <Link
                  sx={{ mb: 4 }}
                  underline="none"
                  component="button"
                  variant="body2"
                >
                  {/* <b>Forgot password</b> */}
                </Link>
              </Box>
            </Box>
          </Box>
        </form>
      </Box>
    </>
  );
}
