import { useQueryClient, useMutation } from "@tanstack/react-query";

import { errorMessage, successMessage } from "../../../utils/helper";
import { useStoreKeys } from "./useStoreKeys";
import { editRecipeStoreApi } from "../api";

export const useAssignedStoreUpdateQueries = () =>
{
    const queryClient = useQueryClient();
    const {storeKeys} = useStoreKeys();
    const updateRecipeStoreMuate = useMutation({
        mutationFn: editRecipeStoreApi,
        onError: (error) => {
          errorMessage(error.response.data.message);
        },
    
        onSuccess: (_, variables) => {
          queryClient.invalidateQueries({
            queryKey: storeKeys.list(),
          });
          successMessage("Stores added succesfully");
          variables.handleModal("store-dialog-add", false);
        },
      });
      return {updateRecipeStoreMuate};
}