import { Button, Paper, Stack, Typography } from "@mui/material";
import React from "react";
import { Loader } from "../../../shared";

function UnpairDeviceDialog({
  state,
  handleOpenDialog,
  handlePairingStatus,
  deviceDetails,
}) {
  if (deviceDetails?.isLoading) {
    return <Loader />;
  }
  return (
    <div>
      <Stack padding="16px 24px" spacing={1}>
        <Paper
          elevation={3}
          style={{ padding: "16px", backgroundColor: "#F5F8FC" }}
        >
          <Typography
            fontWeight={600}
            fontSize={"19px"}
            lineHeight="19px"
            component="div"
          >
            {state?.name}
          </Typography>
          <Stack spacing={2}>
            <Typography fontWeight={400} fontSize={"16px"} lineHeight="19px">
              {state?.store_name}
            </Typography>
          </Stack>
        </Paper>
        <Typography>
          Would you like to unpair this device? Once you unpair this device,
          access to this device will be removed completely.
        </Typography>
        <Stack direction="row" alignSelf="end" gap={1}>
          <Button
            variant="outlined"
            width="78px"
            color="gray2"
            onClick={() => handleOpenDialog(false)}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            width="78px"
            disabled={false}
            onClick={() => handlePairingStatus()}
          >
            Unpair
          </Button>
        </Stack>
      </Stack>
    </div>
  );
}
export default UnpairDeviceDialog;
