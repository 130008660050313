import { Box, Button, Stack, Tab, Tabs, Typography, Link } from "@mui/material";

import {
  CustomTabPanel,
  CustomTable,
  CustomeDialog,
  DeleteModal,
  Loader,
  PageListLayout,
} from "../../../shared";
import AddIcon from "@mui/icons-material/Add";
import { useLocation, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useStores } from "../hooks";
import { createColumnHelper } from "@tanstack/react-table";
import { AddMacDialog } from "../components";

export const StoreDetail = () => {
  let { id } = useParams();

  const {
    state,
    storeDetails,
    handleModal,
    removeMac,
    onChange,
    handleAddMacId,
    handleDeleteMacTag,
    onSaveMac,
    isMacidView,
    isDeviceView,
    isStoreAssignMacid,
    isMacidDelete,
  } = useStores({
    storeId: id,
  });

  const navigate = useNavigate();
  const { search } = useLocation();
  const handleChange = (event, newValue) => {
    navigate(`/store/details/${id}${newValue}`);
  };

  if (storeDetails?.isLoading) {
    return <Loader />;
  }

  const breadcrumbs = [
    <Link
      underline="none"
      key="1"
      color="#1760D2"
      onClick={(e) => navigate("/store")}
      sx={{ fontSize: "14px", fontWeight: 400 }}
    >
      Stores
    </Link>,
    <Typography
      key="2"
      color="inherit"
      sx={{ fontSize: "14px", fontWeight: 400 }}
    >
      {storeDetails?.data[0]?.name}
    </Typography>,
  ];
  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor("name", {
      header: () => "Device Name",
    }),
  ];

  const columns2 = [
    columnHelper.accessor("mac_id", {
      header: () => "MAC ID",
    }),
    columnHelper.accessor("actions", {
      header: () => "",
      align: "right",
      cell: ({ row }) => {
        return (
          isMacidDelete && (
            <Stack
              justifyContent="end"
              sx={{ cursor: "pointer", width: "fit-content", ml: "auto" }}
              onClick={() =>
                handleModal("delete-mac", true, row.original.mac_id)
              }
            >
              <img alt="delete" src="/icons/ic_trash.svg" />
            </Stack>
          )
        );
      },
    }),
  ];

  return (
    <>
      <PageListLayout
        breadcrumbs={breadcrumbs}
        headerText={
          <Box sx={{ display: "flex", gap: "15px", alignItems: "center" }}>
            <Typography sx={{ flex: 1, fontSize: "24px", fontWeight: 600 }}>
              {storeDetails?.data[0]?.name}
            </Typography>
          </Box>
        }
        pageContent={
          <>
            <Box
              className="sticky-Tab"
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                px: "16px",
                bgcolor: "#fff",
                top: "62px",
              }}
            >
              <Tabs value={search} onChange={handleChange}>
                {isMacidView && <Tab label="MAC IDs" value={""} />}
                {isDeviceView && <Tab label="Devices" value={"?devices"} />}
              </Tabs>
            </Box>

            <CustomTabPanel value={search} index={""}>
              <CustomTable
                defaultData={storeDetails?.data[0]?.mac_id ?? []}
                columns={columns2}
                headerText="MAC IDs"
                showSearchBar={false}
                isPagination={false}
                isLoading={storeDetails?.isLoading}
                rightContent={
                  <Stack direction="row" gap="8px">
                    {isStoreAssignMacid && (
                      <Button
                        sx={{
                          border: "1px solid #BCC7DB",
                          bgcolor: "#F5F8FC",
                        }}
                        type="button"
                        size="small"
                        variant="outlined"
                        color="gray"
                        startIcon={<AddIcon />}
                        onClick={() => handleModal("add-mac", true)}
                      >
                        Assign MAC ID
                      </Button>
                    )}
                  </Stack>
                }
              />
            </CustomTabPanel>
            <CustomTabPanel value={search} index={"?devices"}>
              <CustomTable
                defaultData={storeDetails?.data[0]?.devices ?? []}
                columns={columns}
                headerText="Devices"
                isPagination={false}
                isLoading={storeDetails?.isLoading}
                rightContent={<></>}
              />
            </CustomTabPanel>

            <CustomeDialog
              title="Assign MAC ID"
              PaperProps={{
                sx: {
                  minWidth: "482px",
                  minHeight: "200px",
                  padding: "20px 16px 20px 16px",
                },
              }}
              open={state?.addMacOpen}
              CloseIcons={false}
              content={
                <AddMacDialog
                  onChange={onChange}
                  onSubmit={onSaveMac}
                  state={state}
                  handleClose={() => handleModal("add-mac", false)}
                  handleAddMacId={handleAddMacId}
                  handleDeleteMacTag={handleDeleteMacTag}
                />
              }
            />
            <DeleteModal
              open={state?.deleteMacOpen}
              handleClose={() => handleModal("delete-mac", false, null)}
              onConfirm={() => removeMac()}
            />
          </>
        }
      />
    </>
  );
};
