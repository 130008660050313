import React from "react";
import { Route, Routes } from "react-router-dom";
import {
  AddOrEditAdminUsers,
  AdminUsersDetails,
  AdminUsersList,
} from "./containers";
import { ProtectedRoute } from "../../shared";

export const ManageUsers = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<AdminUsersList />} />
        <Route element={<ProtectedRoute allowedRoute={["user-view"]} />}>
          <Route path="/details/:id" element={<AdminUsersDetails />} />
        </Route>

        <Route element={<ProtectedRoute allowedRoute={["user-add"]} />}>
          <Route path="/create" element={<AddOrEditAdminUsers />} />
        </Route>
      </Routes>
    </>
  );
};
