import { Chip, Stack, Tooltip, Typography } from "@mui/material";
import React from "react";
import { PageListLayout, CustomTable } from "../../../shared";
import { createColumnHelper } from "@tanstack/react-table";
import { useNavigate } from "react-router-dom";
import { useStores } from "../hooks";

export const StoresList = () => {
  const { state, listQuery, onSearchChange, isStoreAssignMacid } = useStores({
    isList: true,
  });

  const navigate = useNavigate();

  const storeList = (stores) => {
    if (stores) {
      if (stores.length < 5) {
        return stores.map((data) => (
          <Chip
            size="small"
            variant="outlined"
            color="gray"
            label={data.mac_id}
          />
        ));
      } else {
        const limitedChips = [];
        const moreStores = [];
        for (var i = 0; i < stores.length; i++) {
          if (i < 4) {
            var data = stores[i];
            const chip = (
              <Chip
                size="small"
                variant="outlined"
                color="gray"
                label={data.mac_id}
              />
            );
            limitedChips.push(chip);
          } else {
            var data = stores[i];
            moreStores.push(data.mac_id);
          }
        }
        const moreChip = (
          <Tooltip
            title={
              <React.Fragment>
                {moreStoreTooltipList(moreStores)}
              </React.Fragment>
            }
          >
            <Chip
              size="small"
              variant="outlined"
              style={{ color: "#1760D2", fontWeight: 500 }}
              label={`+${stores.length - 4} More`}
            />
          </Tooltip>
        );
        limitedChips.push(moreChip);
        return limitedChips;
      }
    }
  };

  const moreStoreTooltipList = (stores) => {
    return (
      <ul>
        {stores.map((item) => (
          <li>{item}</li>
        ))}
      </ul>
    );
  };

  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor("name", {
      header: () => "Store Name",
    }),
    columnHelper.accessor("mac_id", {
      enableSorting: false,
      header: () => "MAC IDs",
      cell: (props) => {
        const { row } = props;
        return (
          <Stack direction="row" gap="8px">
            {storeList(row.original.mac_id)}
          </Stack>
        );
      },
    }),

    columnHelper.display({
      id: "actions",
      cell: (props) => {
        const { row } = props;
        return (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            gap="10px"
            mr={-1}
            onClick={(e) => navigate(`details/${row?.original?.id}`)}
          >
            {isStoreAssignMacid && (
              <Typography
                variant="subtitle2"
                sx={{
                  fontWeight: 500,
                  color: "#1760D2",
                }}
              >
                Assign MAC ID
              </Typography>
            )}
            <img alt="details" src="/icons/arrow_forward.png" />
          </Stack>
        );
      },
    }),
  ];

  const breadcrumbs = [
    <Typography key="3" sx={{ fontSize: "14px", fontWeight: 400 }}>
      Stores
    </Typography>,
  ];
  return (
    <>
      <PageListLayout
        breadcrumbs={breadcrumbs}
        pageContent={
          <>
            <Stack
              sx={{
                borderRadius: "4px",
                p: "0 8px 8px",
                bgcolor: "#F5F8FC",
              }}
            >
              <CustomTable
                defaultData={state.dataList}
                columns={columns}
                headerText="Stores"
                onSearchChange={onSearchChange}
                searchText={state.searchKey}
                isLoading={listQuery?.isLoading}
                tableId={"stores"}
                onRowLink={(e) => {
                  return `details/${e.id}`;
                }}
              />
            </Stack>
          </>
        }
      />
    </>
  );
};
