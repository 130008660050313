import {
  Button,
  Checkbox,
  Stack,
  TextField,
  Typography,
  createFilterOptions,
} from "@mui/material";
import React, { useState } from "react";
import {
  InputControl,
  PageListLayout,
  ServerPaginatedtable,
  DeleteModal,
  SimpleMenu,
  CustomeDialog,
  LimitedTag,
} from "../../../shared";
import AddIcon from "@mui/icons-material/Add";
import { createColumnHelper } from "@tanstack/react-table";
import SimpleReactValidator from "simple-react-validator";
import { useNavigate } from "react-router-dom";
import { useDevices } from "../hooks";
import UnpairDeviceDialog from "../components/UnpairDeviceDialog";

export const DevicesList = () => {
  const {
    devices,
    pagination,
    setPagination,
    sorting,
    setSorting,
    listQuery,
    onDelete,
    deleteInfo,
    confirmDelete,
    resetDeleteInfo,
    handleOpenDialog,
    handlePairingStatus,
    onSearchHandler,
    storeLists,
    StoreValuesHandler,
    selectStatus,
    onClearFilter,
    disableclearButton,
    deviceDetails,
    isCreateVisible,
    isEditable,
    isDeletable,
    isUnpairDevice,
    tableData,
    moduleName,
    search,
  } = useDevices({
    isList: true,
    isCreate: false,
  });
  const [, forceUpdate] = React.useState();
  const validator = React.useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },
      validators: {
        Item: {
          message: "The recipes field is required.",
          required: true,
          rule: (value) => {
            if (value.length <= 0) return false;
            else return true;
          },
        },
      },
    })
  );
  const navigate = useNavigate();

  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor("name", {
      header: () => "Device Name",
    }),
    columnHelper.accessor("code", {
      header: () => "Device Code",
    }),
    columnHelper.accessor("store_id", {
      header: () => "Store Name",
      cell: (props) => {
        const { row } = props;
        return row?.original?.store_name;
      },
    }),
    columnHelper.accessor("paird_status", {
      header: () => "Pairing Status",
      cell: (props) => {
        const { row } = props;
        return (
          <Stack direction="row" gap={1}>
            {row?.original?.paird_status === 0 ? (
              <img src="/icons/ic_device_unpair.svg" />
            ) : (
              <img src="/icons/ic_device_pair.svg" />
            )}
            <Typography>
              {row?.original?.paird_status === 0 ? "Unpaired" : "Paired"}
            </Typography>
          </Stack>
        );
      },
    }),

    columnHelper.display({
      id: "actions",
      cell: (props) => {
        const { row } = props;
        return (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            gap="8px"
            mr={-1}
          >
            <div className="action-buttons" onClick={(e) => e.preventDefault()}>
              {row?.original?.paird_status === 0
                ? ""
                : isUnpairDevice && (
                    <Typography
                      sx={{ cursor: "pointer" }}
                      fontWeight={400}
                      fontSize={"14px"}
                      color="#1560D4"
                      onClick={(e) => handleOpenDialog(true, row?.original?.id)}
                    >
                      Unpair
                    </Typography>
                  )}
            </div>
            {(isEditable || isDeletable) && (
              <SimpleMenu
                row={row}
                editAction={(e) => navigate(`edit/${e.id}`)}
                handleDeleteModal={(e) => onDelete(e.id)}
                isEditable={isEditable}
                isDeletable={isDeletable}
              />
            )}
            <img
              alt="details"
              src="/icons/arrow_forward.png"
              onClick={(e) => navigate(`details/${row?.original?.id}`)}
            />
          </Stack>
        );
      },
    }),
  ];

  const breadcrumbs = [
    <Typography key="3" sx={{ fontSize: "14px", fontWeight: 400 }}>
      Devices
    </Typography>,
  ];
  const filter = createFilterOptions();
  return (
    <>
      {validator.current.purgeFields()}
      <PageListLayout
        breadcrumbs={breadcrumbs}
        pageContent={
          <>
            <Stack sx={{ borderRadius: "4px", bgcolor: "#fff" }}>
              <ServerPaginatedtable
                defaultData={listQuery?.data?.Devices ?? []}
                columns={columns}
                headerText="Devices"
                search={search}
                sorting={sorting}
                pagination={pagination}
                setSorting={(callback) =>
                  setSorting({ tableName: moduleName, callback })
                }
                setPagination={(callback) =>
                  setPagination({ tableName: moduleName, callback })
                }
                paginationProps={
                  listQuery.data?.pagination || { total: 0, lastPage: 1 }
                }
                onSearchChange={onSearchHandler}
                isLoading={listQuery?.isLoading}
                onRowLink={(e) => {
                  return `details/${e.id}`;
                }}
                tableHeaderContent={
                  <>
                    <Stack
                      sx={{
                        flexDirection: "row",
                        gap: "6px",
                        width: "100%",
                      }}
                    >
                      <InputControl
                        type="dropdown"
                        variant="filter"
                        size="small"
                        fullWidth
                        multiple={true}
                        options={storeLists?.data ?? []}
                        getOptionLabel={(option) => option?.name || ""}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        disableCloseOnSelect
                        sx={{
                          width: "250px",
                        }}
                        renderTags={(value) => (
                          <LimitedTag value={value} autocompleteWidth="250px" />
                        )}
                        value={tableData?.devices?.data?.storefilter}
                        onChange={(event, option) =>
                          StoreValuesHandler(event, option)
                        }
                        renderOption={(props, option, { selected }) => (
                          <>
                            <li {...props} key={option.id}>
                              <Checkbox
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option?.name}
                            </li>
                          </>
                        )}
                        filterOptions={filter}
                        renderInput={(params) => (
                          <TextField {...params} placeholder="Store" />
                        )}
                      />
                      <InputControl
                        type="dropdown"
                        variant="filter"
                        size="small"
                        options={selectStatus?.pairedOptions}
                        getOptionLabel={(option) => option?.name || ""}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        sx={{
                          width: "180px",
                          height: "36px",
                        }}
                        value={
                          selectStatus?.pairedOptions?.find(
                            (status) =>
                              status?.id ===
                              tableData?.devices?.data?.selectedStatus
                          ) || null
                        }
                        onChange={(event, option) =>
                          selectStatus.pairedValueChangeHandler(option)
                        }
                        renderInput={(params) => (
                          <TextField {...params} placeholder="Status" />
                        )}
                      />

                      <Button
                        type="button"
                        size="small"
                        variant="text"
                        sx={{
                          p: "6px 12px 6px 12px",
                          gap: "6px",
                        }}
                        disabled={disableclearButton}
                        onClick={onClearFilter}
                      >
                        Clear All
                      </Button>
                    </Stack>
                  </>
                }
                rightContent={
                  <Stack direction="row" gap="8px">
                    {isCreateVisible && (
                      <Button
                        sx={{
                          height: "36px",
                          border: "1px solid #BCC7DB",
                          borderRadius: "10px",
                        }}
                        type="button"
                        size="medium"
                        variant="contained"
                        startIcon={<AddIcon />}
                        onClick={() => navigate("create")}
                      >
                        Device
                      </Button>
                    )}
                  </Stack>
                }
              />
            </Stack>
          </>
        }
      />
      <CustomeDialog
        PaperProps={{
          sx: {
            minWidth: "500px",
          },
        }}
        closeIcon={false}
        open={devices?.open}
        title={
          <Stack
            direction="row"
            justifyContent="space-between"
            position="sticky"
            zIndex={999}
            bgcolor="#fff"
            top={0}
          >
            <Stack direction="row" alignItems="center" ml={2}>
              <img src="/icons/ic_pair_dialog.svg" />
              <Typography
                fontWeight={500}
                variant="body1"
                padding="15px"
                fontSize={20}
              >
                Unpair this device?
              </Typography>
            </Stack>
          </Stack>
        }
        content={
          <UnpairDeviceDialog
            state={devices?.data}
            handleOpenDialog={handleOpenDialog}
            handlePairingStatus={handlePairingStatus}
            deviceDetails={deviceDetails}
          />
        }
      />

      <DeleteModal
        open={deleteInfo?.show}
        handleClose={() => resetDeleteInfo()}
        onConfirm={() => confirmDelete()}
      />
    </>
  );
};
