import { Button, Stack, Typography, Box } from "@mui/material";
import React from "react";

function TemplateStatusChangeDialog({ state, handleClose }) {
  return (
    <Stack padding="16px 24px">
      <Box>
        <Typography fontWeight={400}>{state?.errorMessage}</Typography>
      </Box>
      <Box sx={{ mt: 4 }} alignSelf="end">
        <Button variant="outlined" color="gray2" onClick={handleClose}>
          Close
        </Button>
      </Box>
    </Stack>
  );
}

export default TemplateStatusChangeDialog;
