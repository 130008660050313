import { API } from "../../../utils/api";

export const saveChartSettings = async (data) => {
  const { payload } = data;
  const res = await API.post("recipes/chart-settings", payload);
  return res.data.data;
};

export const editChartSettings = async (data) => {
  const { detailsId, payload } = data;
  const res = await API.post(`recipes/chart-settings/${detailsId}`, payload);
  return res.data;
};

export const getChartSettings = async (id) => {
  const res = await API.get(`recipes/chart-settings/${id}`);
  return res.data.data;
};
