import {
  Box,
  Button,
  FormHelperText,
  Grid,
  InputAdornment,
  Stack,
  Typography,
} from "@mui/material";
import React, { useRef } from "react";
import { CustomDndTable, CustomTable, InputControl } from "../../../shared";
import AddIcon from "@mui/icons-material/Add";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { createColumnHelper } from "@tanstack/react-table";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { StyledDropzone } from "../../../shared/components/StyledDropzone";
import imageIcon from "../../../assets/images/Icons/icons8_video_clip 1.svg";
import { useParams } from "react-router";
import { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";

export const HowtoPrepare = (props) => {
  const { id } = useParams();
  const {
    validator,
    handleQuillChange,
    state,
    handleDropzone,
    addStepChangeHandler,
    stepsHandleDelete,
    handleDropzoneVideo,
    handleDeleteVideos,
    handleKeyDown,
    setVideoUrl,
    updateTableData,
    onClearVideoUrl,
    createVideoUrl,
    onOrderChange,
    removeImage,
  } = props;

  const { steps, videos, method } = state.data;

  const videoRef = useRef();

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
      [
        {
          color: [
            "#3366cc",
            "#dc3912",
            "#ff9900",
            "#109618",
            "#990099",
            "#0099c6",
            "#dd4477",
            "#66aa00",
            "#b82e2e",
            "#316395",
            "#3366cc",
            "#994499",
            "#22aa99",
            "#aaaa11",
            "#6633cc",
            "#e67300",
            "#8b0707",
            "#651067",
            "#329262",
            "#5574a6",
            "#3b3eac",
            "#b77322",
            "#16d620",
            "#b91383",
            "#f4359e",
            "#9c5935",
            "#a9c413",
            "#2a778d",
            "#668d1c",
            "#bea413",
            "#0c5922",
            "#743411",
          ],
        },
      ],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "color",
  ];

  const addVideoHandler = () => {
    videoRef.current.click();
  };

  const TableCell = ({ getValue, row, column, table }) => {
    const initialValue = getValue();
    const [value, setValue] = useState(initialValue);
    useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);
    const onBlur = () => {
      table.options.meta?.updateData(row, column.id, value);
    };
    return (
      <>
        <ReactQuill
          style={{ minHeight: "102px", minWidth: "100%" }}
          theme="snow"
          name="step"
          modules={modules}
          formats={formats}
          value={value}
          onChange={(e) => setValue(e)}
          onBlur={onBlur}
        />
      </>
    );
  };
  const columnHelper = createColumnHelper();

  const columns1 = [
    columnHelper.accessor("image_url", {
      header: () => "Image",
      cell: (info) => {
        const image = info?.row?.original?.imageFile;
        const imageURL = info?.row?.original?.image_url;
        const loadingUrl = info?.row?.original?.loading;
        const stepValue = info.row.original.step;

        return (
          <Stack p={1}>
            <StyledDropzone
              loading={loadingUrl}
              handleDropzone={(event) => handleDropzone(event, info.row.index)}
              imgUrl={image ? URL.createObjectURL(image) : id ? imageURL : ""}
              removeImage={() => removeImage(info.row.index)}
            />
          </Stack>
        );
      },
    }),
    columnHelper.accessor("step", {
      header: () => "Steps",
      cell: TableCell,
    }),
    columnHelper.accessor("id", {
      header: () => "",
      cell: (info) => {
        return (
          <>
            <RemoveCircleIcon
              sx={{
                color: "#B42318",
              }}
              onClick={() => stepsHandleDelete(info?.row?.index)}
            />
          </>
        );
      },
    }),
  ];

  const columns = [
    columnHelper.accessor("video_url", {
      header: () => "Video",
      cell: (info) => {
        const videoUrl = info?.row?.original?.video_url;
        return (
          <Stack borderRadius="10px" direction="row" gap="10px">
            <img src={imageIcon} />
            <div>{videoUrl}</div>
          </Stack>
        );
      },
    }),

    columnHelper.accessor("id", {
      header: () => "",
      cell: (info) => {
        return (
          <Button
            type="button"
            size="small"
            variant="text"
            color="error"
            onClick={() => handleDeleteVideos(info.row.index)}
          >
            remove
          </Button>
        );
      },
    }),
  ];

  console.log("method", method);
  return (
    <Stack sx={{ borderRadius: "4px", bgcolor: "#fff" }}>
      <Stack sx={{ borderRadius: "4px", bgcolor: "#fff" }}>
        <Stack
          sx={{ py: "70px", borderBottom: "1px solid #DFE8F2", mx: "20px" }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Typography
                variant="subtitle1"
                fontWeight={600}
                sx={{ width: "150px" }}
              >
                Method
              </Typography>
            </Grid>
            <Grid item sm={8}>
              <Stack>
                <ReactQuill
                  style={{ height: "290px" }}
                  theme="snow"
                  name="method"
                  modules={modules}
                  formats={formats}
                  value={method}
                  onChange={(value) => handleQuillChange("method", value)}
                />
              </Stack>
            </Grid>
          </Grid>
        </Stack>
        <Stack
          sx={{ py: "32px", borderBottom: "1px solid #DFE8F2", mx: "20px" }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Typography
                variant="subtitle1"
                fontWeight={600}
                sx={{ width: "150px" }}
              >
                How to Prepare
              </Typography>
            </Grid>
            <Grid item sm={8}>
              <Stack width={"100%"} gap="15px" spacing={3}>
                <Box
                  sx={{
                    p: "0 8px 8px",
                    bgcolor: "#F5F8FC",
                  }}
                >
                  <CustomDndTable
                    defaultData={steps}
                    columns={columns1}
                    updateTableData={updateTableData}
                    onRowOrderChange={(data) => onOrderChange(data)}
                    isPagination={false}
                    isTableHeader={false}
                    validator={validator}
                    tableFooter={
                      <>
                        <Button
                          type="button"
                          size="small"
                          variant="text"
                          startIcon={<AddIcon />}
                          onClick={addStepChangeHandler}
                        >
                          Add Step
                        </Button>
                      </>
                    }
                  />
                </Box>
              </Stack>
            </Grid>
          </Grid>
        </Stack>
        <Stack
          sx={{ py: "32px", borderBottom: "1px solid #DFE8F2", mx: "20px" }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Typography
                variant="subtitle1"
                fontWeight={600}
                sx={{ width: "150px" }}
              >
                Recipe Preparation Video
              </Typography>
            </Grid>
            <Grid item sm={8}>
              <Stack width={"100%"} gap="15px">
                <Box
                  sx={{
                    p: "0 8px 8px",
                    bgcolor: "#F5F8FC",
                  }}
                >
                  <CustomTable
                    defaultData={videos}
                    columns={columns}
                    emptyTableMessage="There is no videos added yet, You need to add videos first"
                    isPagination={false}
                    isTableHeader={false}
                    isLoading={createVideoUrl.isPending}
                    tableFooter={
                      <Stack direction="row" alignItems="center" gap={2}>
                        <div>
                          <Button
                            type="button"
                            size="small"
                            variant="text"
                            startIcon={<AddIcon />}
                            onClick={(event) =>
                              addVideoHandler(event, videos.length)
                            }
                          >
                            Add Files
                          </Button>
                          <input
                            id="contained-button-file"
                            type="file"
                            onChange={(e) => handleDropzoneVideo(e)}
                            style={{ display: "none" }}
                            ref={videoRef}
                          />
                        </div>

                        <Typography color="#C4CFE4">/</Typography>

                        <InputControl
                          placeholder="Or add a video link"
                          type="text"
                          variant="filled"
                          name="videoUrl"
                          value={state.videoUrl}
                          onChange={(e) => setVideoUrl(e)}
                          onKeyDown={handleKeyDown}
                          size="small"
                          hiddenLabel
                          sx={{ m: 1, width: "72%" }}
                          InputProps={{
                            sx: { borderRadius: 5, height: "30px" },
                            disableUnderline: true,
                            startAdornment: (
                              <InputAdornment position="start">
                                <img alt="upOrDown" src="/icons/link.svg" />
                              </InputAdornment>
                            ),
                            endAdornment: (
                              <InputAdornment
                                sx={{ cursor: "pointer" }}
                                onClick={onClearVideoUrl}
                                position="end"
                              >
                                <CloseIcon size="large" />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Stack>
                    }
                  />
                </Box>
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      </Stack>
    </Stack>
  );
};
