import { API } from "../../../utils/api";

export const getDevices = async ({ query, pagination }) => {
  const { pageIndex, pageSize } = pagination;
  const res = await API.get(
    `device?page=${pageIndex}&per_page=${pageSize}`,
    query
  );
  return res.data.data;
};

export const saveDevices = async (payload) => {
  const res = await API.post("device", payload);
  return res.data.data;
};

export const getDeviceById = async (id) => {
  const res = await API.get(`device/${id}`);
  return res.data.data;
};

export const editDevices = async (payload, detailsId) => {
  const res = await API.put(`device/${detailsId}`, payload);
  return res.data;
};

export const removeDevices = async ({ id }) => {
  const res = await API.delete(`device/${id}`);
  return res.data;
};
export const editStatus = async (id) => {
  const res = await API.put(`device/${id}/status`);
  return res.data;
};
