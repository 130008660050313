import {
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { InputControl } from "../../../shared";
import React from "react";

export const ChartSettings = (props) => {
  const {
    chartSettings,
    onChange,
    onBreadModularChange,
    onProvingTimeChange,
    onRollMoulderChange,
    onPastrySheeterChange,
    onOvenTimeChange,
    onProductionChange,
    chartRadioButtonChange,
    onButtonChange,
    onBeforeBakingChange,
    onBeforeDecoratingChange,
  } = props;

  const {
    bread_moulder_enabled,
    front_roller_setting_no,
    bread_moulder_setting_chart,
    proving_time_enabled,
    proving_time,
    oven_time_temp_enabled,
    baking_time,
    temperature,
    steaming_shots,
    cut,
    decorate,
    oven_temperature_top,
    oven_temperature_bottom,
    production_wastage_enabled,
    time_period,
    primary,
    mandatory,
    roll_moulder_enabled,
    roll_moulder_setting_no,
    roll_moulder_rounding_time,
    pastry_sheeter_enabled,
    pastry_sheeter_setting_no,
    pastry_sheeter_amount_of_turns,
    time_through_pastry_sheeter_final_settings,
    pastry_sheeter_bottom_weight,
    pastry_sheeter_top_weight,
    pastry_sheeter_filling_weight,
    pastry_sheeter_unbacked_weight,
    before_baking,
    before_decorating,
  } = chartSettings.data;

  return (
    <Stack spacing={3}>
      {/* Bread Moulder Settings Chart */}
      <Grid container>
        <Grid item sm={3}>
          <Typography variant="subtitle1">
            Bread Moulder Settings Chart
          </Typography>
        </Grid>
        <Grid item sm={9}>
          <Stack spacing={2}>
            <Switch
              name="bread_moulder_enabled"
              checked={bread_moulder_enabled ? true : false}
              value={bread_moulder_enabled}
              onChange={onBreadModularChange}
            />
            {bread_moulder_enabled === 1 && (
              <Stack direction="row" spacing={2}>
                <InputControl
                  type="text"
                  fullWidth
                  name="front_roller_setting_no"
                  label="Front Roller Setting No."
                  value={front_roller_setting_no}
                  onChange={onChange}
                />
                <InputControl
                  fullWidth
                  type="text"
                  name="bread_moulder_setting_chart"
                  label="Bread Moulder Settings Chart"
                  value={bread_moulder_setting_chart}
                  onChange={onChange}
                />
              </Stack>
            )}
          </Stack>
        </Grid>
      </Grid>
      <Divider />

      {/* Proving Time Chart */}
      <Grid container>
        <Grid item sm={3}>
          <Typography variant="subtitle1">Proving Time Chart</Typography>
        </Grid>
        <Grid item sm={9}>
          <Stack spacing={2}>
            <Switch
              name="proving_time_enabled"
              checked={proving_time_enabled ? true : false}
              value={proving_time_enabled}
              onChange={onProvingTimeChange}
            />
            {proving_time_enabled === 1 && (
              <InputControl
                fullWidth
                type="text"
                name="proving_time"
                label="Proving Time (in mins)"
                value={proving_time}
                onChange={onChange}
              />
            )}
          </Stack>
        </Grid>
      </Grid>
      <Divider />

      {/* Oven Time & Temperature Chart */}
      <Grid container>
        <Grid item sm={3}>
          <Typography variant="subtitle1">
            Oven Time & Temperature Chart
          </Typography>
        </Grid>
        <Grid item sm={9}>
          <Stack spacing={2}>
            <Switch
              name="oven_time_temp_enabled"
              checked={oven_time_temp_enabled ? true : false}
              value={oven_time_temp_enabled}
              onChange={onOvenTimeChange}
            />
            {oven_time_temp_enabled === 1 && (
              <>
                <Stack>
                  <InputControl
                    type="text"
                    name="baking_time"
                    label="Baking Time (min)"
                    value={baking_time}
                    onChange={onChange}
                  />
                  <Typography variant="body2" color="#657083" marginLeft="15px">
                    Full Decks
                  </Typography>
                </Stack>
                <InputControl
                  type="text"
                  name="temperature"
                  label="Temperature (°C)"
                  value={temperature}
                  onChange={onChange}
                />
                <Stack direction="row" spacing={2}>
                  <InputControl
                    sx={{ minWidth: "216px" }}
                    type="text"
                    name="steaming_shots"
                    label="Steaming Shots"
                    value={steaming_shots}
                    onChange={onChange}
                  />
                  <InputControl
                    sx={{ minWidth: "216px" }}
                    type="text"
                    name="cut"
                    label="Cut"
                    value={cut}
                    onChange={onChange}
                  />
                </Stack>
                <InputControl
                  type="text"
                  name="decorate"
                  label="Decorate"
                  value={decorate}
                  onChange={onChange}
                />

                <Typography>Other Settings</Typography>
                <Stack direction="row" spacing={2}>
                  <InputControl
                    type="text"
                    name="oven_temperature_top"
                    label="Top"
                    fullWidth
                    value={oven_temperature_top}
                    onChange={onChange}
                  />
                  <InputControl
                    type="text"
                    name="oven_temperature_bottom"
                    label="Bottom"
                    fullWidth
                    value={oven_temperature_bottom}
                    onChange={onChange}
                  />
                </Stack>
              </>
            )}
          </Stack>
        </Grid>
      </Grid>
      <Divider />

      {/* Production & Wastage sheet */}
      <Grid container>
        <Grid item sm={3}>
          <Typography variant="subtitle1">
            Production & Wastage sheet
          </Typography>
        </Grid>
        <Grid item sm={9}>
          <Stack spacing={2}>
            <Switch
              name="production_wastage_enabled"
              checked={production_wastage_enabled ? true : false}
              value={production_wastage_enabled}
              onChange={onProductionChange}
            />
            {production_wastage_enabled === 1 && (
              <>
                {" "}
                <InputControl
                  type="text"
                  name="time_period"
                  label="Time Period"
                  value={time_period}
                  onChange={onChange}
                />
                <Divider />
                <Stack direction="row" justifyContent={"space-between"}>
                  <Typography>Freeze?</Typography>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox checked={before_baking ? true : false} />
                      }
                      label="Before Baking"
                      name="before_baking"
                      value={before_baking}
                      onChange={onBeforeBakingChange}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox checked={before_decorating ? true : false} />
                      }
                      label="Before Decorating"
                      name="before_decorating"
                      value={before_decorating}
                      onChange={onBeforeDecoratingChange}
                    />
                  </FormGroup>
                </Stack>
                <Divider />
                <Stack direction="row" justifyContent={"space-between"}>
                  <Typography>Primary or Secondary ?</Typography>
                  <RadioGroup row>
                    <FormControlLabel
                      control={<Radio checked={primary == 1} />}
                      label="Primary"
                      name="primary"
                      value={1}
                      onChange={(event) =>
                        chartRadioButtonChange(event.target.value)
                      }
                    />
                    <FormControlLabel
                      control={<Radio checked={primary == 0} />}
                      label="Secondary"
                      name="primary"
                      value={0}
                      onChange={(event) =>
                        chartRadioButtonChange(event.target.value)
                      }
                    />
                  </RadioGroup>
                </Stack>
                <Divider />
                <Stack direction="row" justifyContent={"space-between"}>
                  <Typography>Mandatory or Optional ?</Typography>
                  <RadioGroup row>
                    <FormControlLabel
                      control={<Radio checked={mandatory == 1} />}
                      label="Mandatory"
                      name="mandatory"
                      value={1}
                      onChange={(event) => onButtonChange(event.target.value)}
                    />
                    <FormControlLabel
                      control={<Radio checked={mandatory == 0} />}
                      label="Optional"
                      name="mandatory"
                      value={0}
                      onChange={(event) => onButtonChange(event.target.value)}
                    />
                  </RadioGroup>
                </Stack>
              </>
            )}
          </Stack>
        </Grid>
      </Grid>
      <Divider />

      {/* Roll Moulder Settings Chart */}
      <Grid container>
        <Grid item sm={3}>
          <Typography variant="subtitle1">
            Roll Moulder Settings Chart
          </Typography>
        </Grid>
        <Grid item sm={9}>
          <Stack spacing={2}>
            <Switch
              name="roll_moulder_enabled"
              checked={roll_moulder_enabled ? true : false}
              value={roll_moulder_enabled}
              onChange={onRollMoulderChange}
            />
            {roll_moulder_enabled === 1 && (
              <Stack direction="row" spacing={2}>
                <InputControl
                  fullWidth
                  type="text"
                  name="roll_moulder_setting_no"
                  label="Settings No."
                  value={roll_moulder_setting_no}
                  onChange={onChange}
                />
                <InputControl
                  fullWidth
                  type="text"
                  name="roll_moulder_rounding_time"
                  label="Rounding Time"
                  value={roll_moulder_rounding_time}
                  onChange={onChange}
                />
              </Stack>
            )}
          </Stack>
        </Grid>
      </Grid>
      <Divider />

      {/* Pastry Sheeter Settings Chart */}
      <Grid container>
        <Grid item sm={3}>
          <Typography variant="subtitle1">
            Pastry Sheeter Settings Chart
          </Typography>
        </Grid>
        <Grid item sm={9}>
          <Stack spacing={2}>
            <Switch
              name="pastry_sheeter_enabled"
              checked={pastry_sheeter_enabled ? true : false}
              value={pastry_sheeter_enabled}
              onChange={onPastrySheeterChange}
            />
            {pastry_sheeter_enabled === 1 && (
              <Stack gap="16px">
                <Stack direction="row" spacing={2}>
                  <InputControl
                    fullWidth
                    type="text"
                    name="pastry_sheeter_setting_no"
                    label="Sheeter settings No"
                    value={pastry_sheeter_setting_no}
                    onChange={onChange}
                  />
                  <InputControl
                    fullWidth
                    type="text"
                    name="pastry_sheeter_amount_of_turns"
                    label="Amount of turns"
                    value={pastry_sheeter_amount_of_turns}
                    onChange={onChange}
                  />
                  <InputControl
                    sx={{ minWidth: "440px" }}
                    type="text"
                    name="time_through_pastry_sheeter_final_settings"
                    label="Time through sheeter at final settings"
                    value={time_through_pastry_sheeter_final_settings}
                    onChange={onChange}
                  />
                </Stack>
                <Stack direction="row" spacing={2}>
                  <InputControl
                    fullWidth
                    type="text"
                    name="pastry_sheeter_bottom_weight"
                    label="Bottom weight"
                    value={pastry_sheeter_bottom_weight}
                    onChange={onChange}
                  />
                  <InputControl
                    fullWidth
                    type="text"
                    name="pastry_sheeter_top_weight"
                    label="Top weight"
                    value={pastry_sheeter_top_weight}
                    onChange={onChange}
                  />
                  <InputControl
                    fullWidth
                    type="text"
                    name="pastry_sheeter_filling_weight"
                    label="Filling weight"
                    value={pastry_sheeter_filling_weight}
                    onChange={onChange}
                  />
                  <InputControl
                    fullWidth
                    type="text"
                    name="pastry_sheeter_unbacked_weight"
                    label="Unbaked weight"
                    value={pastry_sheeter_unbacked_weight}
                    onChange={onChange}
                  />
                </Stack>
              </Stack>
            )}
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
};
