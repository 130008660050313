import {
  Box,
  Divider,
  InputAdornment,
  Radio,
  Switch,
  TextField,
  createFilterOptions,
} from "@mui/material";
import { Button, Stack, Typography } from "@mui/material";
import { createColumnHelper } from "@tanstack/react-table";
import {
  CustomDndTable,
  CustomTable,
  InputControl,
  UseUoMHelper,
} from "../../../shared";
import AddIcon from "@mui/icons-material/Add";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { useEffect, useState } from "react";
import leaf from "../../../assets/images/Icons/ingredient.svg";
import book from "../../../assets/images/Icons/Cookbook.svg";
import { arraySort, formatWeightWithUnits } from "../../../utils";

const filterOptions = createFilterOptions({
  matchFrom: "any",
  stringify: (option) => {
    return option.label;
  },
});

export const AddOrEditRecipeIngredient = (props) => {
  const {
    ingredients,
    alternativeunitList,
    IngredientsChangehandler,
    unitsChangehandler,
    addIngredientsHandle,
    handleRemoveRows,
    addNewGroup,
    onRadioButtonChange,
    altUnitChangeHandler,
    addUnitsHandle,
    onSwitchChange,
    handleDelete,
    updateTableData,
    onIngredientsOrderChange,
    ingredientsValidator,
    ignoredList,
    combinedArray,
    isEditMode,
    sellingUnitChange,
    checkFinishedProduct,
    unitValidator,
  } = props;
  const { getUniqueUnitIds, filterUnits, filterAlternativeUnits } =
    UseUoMHelper();

  const {
    groups,
    is_alternate_UOM,
    alt_units,
    total_weight,
    selling_unit_id,
    total_weight_unit,
  } = ingredients?.data;

  const unitIds = getUniqueUnitIds(alt_units);
  let sellingUnits = is_alternate_UOM
    ? alternativeunitList.filter((item) => unitIds.includes(item.id))
    : alternativeunitList.filter((item) => item.symbol === "kg");

  const AltTableBaseQtyCell = ({ getValue, row, column, table }) => {
    const initialValue = getValue();
    const [value, setValue] = useState(initialValue);
    const type = "AltUOM";
    useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);
    const onBlur = () => {
      table.options.meta?.updateData(row, column.id, value, type);
    };
    return (
      <InputControl
        type="number"
        name={"base_unit_qty"}
        value={value}
        label={"Base Qty"}
        disabled={row?.index === 0 || alt_units.length - 1 !== row.index}
        onChange={(e) => setValue(e.target.value)}
        onBlur={onBlur}
        sx={{
          width: "100%",
        }}
        error={unitValidator.current.message(
          `Base Qty ${row.index}`,
          value,
          "required|numeric"
        )}
        helperText={unitValidator.current.message(
          `Base Qty ${row.index}`,
          value,
          "required|numeric"
        )}
      />
    );
  };

  const AltTableAltQtyCell = ({ getValue, row, column, table }) => {
    const initialValue = getValue();
    const [value, setValue] = useState(initialValue);
    const type = "AltUOM";
    useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);
    const onBlur = () => {
      table.options.meta?.updateData(row, column.id, value, type);
    };
    return (
      <InputControl
        type="number"
        name={"alt_unit_qty"}
        value={value}
        label={"Alt Qty"}
        disabled={alt_units.length - 1 !== row.index}
        onChange={(e) => setValue(e.target.value)}
        onBlur={onBlur}
        sx={{
          width: "100%",
        }}
        error={unitValidator.current.message(
          `Alt Qty ${row.index}`,
          value,
          "required|customNumeric"
        )}
        helperText={unitValidator.current.message(
          `Alt Qty ${row.index}`,
          value,
          "required|customNumeric"
        )}
        // error={unitValidator.current.message(
        //   `Alt Qty ${row.index}`,
        //   value,
        //   "required|numeric|min:1,num"
        // )}
        // helperText={unitValidator.current.message(
        //   `Alt Qty ${row.index}`,
        //   value,
        //   "required|numeric|min:1,num"
        // )}
      />
    );
  };
  //alternative unit table

  const altcolumnHelper = createColumnHelper();
  const altcolumns = [
    altcolumnHelper.accessor("base_unit_qty", {
      header: () => "Base Qty",
      cell: AltTableBaseQtyCell,
    }),

    altcolumnHelper.accessor("base_unit_id", {
      header: () => "Base Unit",
      cell: (info) => {
        const unitIds = getUniqueUnitIds(alt_units || []);
        const baseUnitOptions = filterUnits(alternativeunitList, [
          "kg",
          "ltr",
        ]).filter((i) => unitIds.includes(i.id));

        return (
          <>
            <InputControl
              type="dropdown"
              fullWidth
              options={baseUnitOptions}
              value={
                baseUnitOptions?.find(
                  (value) => value.id === info?.row?.original?.base_unit_id
                ) || null
              }
              onChange={(event, option) =>
                altUnitChangeHandler("base_unit_id", option, info?.row?.index)
              }
              disabled={
                info.row.index < 1 || alt_units.length - 1 !== info.row.index
              }
              getOptionLabel={(option) => option?.name || ""}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              sx={{
                minWidth: "130px",
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Base Unit"
                  error={unitValidator.current.message(
                    `Base Unit ${info.row.index}`,
                    info?.row?.original?.base_unit_id,
                    "required"
                  )}
                  helperText={unitValidator.current.message(
                    `Base Unit ${info.row.index}`,
                    info?.row?.original?.base_unit_id,
                    "required"
                  )}
                />
              )}
            />
          </>
        );
      },
    }),
    altcolumnHelper.accessor("alt_unit_qty", {
      header: () => "Alternative Qty",
      cell: AltTableAltQtyCell,
    }),
    altcolumnHelper.accessor("alt_unit_id", {
      header: () => "Alternative Unit",
      cell: (info) => {
        let altUnitOptions = filterAlternativeUnits(
          filterUnits(alternativeunitList, ["ltr", "ml"]),
          info.row.index,
          alt_units
        );
        const mlId = alternativeunitList.find((i) => i.symbol === "ml")?.id;
        const ltrId = alternativeunitList.find((i) => i.symbol === "ltr")?.id;

        const hasMl = alt_units.some(
          (item, i) => i !== info.row.index && item.alt_unit_id === mlId
        );
        const hasLtr = alt_units.some(
          (item, i) => i !== info.row.index && item.alt_unit_id === ltrId
        );
        if (hasLtr) {
          altUnitOptions = altUnitOptions.filter((i) => i.id !== mlId);
        } else if (hasMl) {
          altUnitOptions = altUnitOptions.filter((i) => i.id !== ltrId);
        }
        return (
          <>
            <InputControl
              type="dropdown"
              fullWidth
              options={arraySort(altUnitOptions, "name") || []}
              disabled={alt_units.length - 1 !== info.row.index}
              value={
                altUnitOptions?.find(
                  (value) => value.id === info?.row?.original?.alt_unit_id
                ) || null
              }
              onChange={(event, option) =>
                altUnitChangeHandler("alt_unit_id", option, info?.row?.index)
              }
              getOptionLabel={(option) => option?.name || ""}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              sx={{
                minWidth: "130px",
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Alternative Unit"
                  error={unitValidator.current.message(
                    `Alt Unit ${info.row.index}`,
                    info?.row?.original?.alt_unit_id,
                    "required"
                  )}
                  helperText={unitValidator.current.message(
                    `Alt Unit ${info.row.index}`,
                    info?.row?.original?.alt_unit_id,
                    "required"
                  )}
                />
              )}
            />
          </>
        );
      },
    }),
    altcolumnHelper.accessor("id", {
      header: () => "",
      cell: (info) => {
        const itemIndex = info.row.index;
        let disabled = false;
        if (itemIndex < alt_units.length - 1 || itemIndex < 1) {
          disabled = true;
        } else {
          disabled = false;
        }
        return (
          <RemoveCircleIcon
            sx={{
              color: disabled ? "#BCC7DB" : "#B42318",
            }}
            onClick={() => !disabled && handleDelete(info?.row?.index)}
          />
        );
      },
    }),
  ];

  //default table
  const TableCell = ({ getValue, row, column, table }) => {
    const initialValue = getValue();
    const [value, setValue] = useState(initialValue);
    const type = "Ingredients";
    useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);
    const onBlur = () => {
      table.options.meta?.updateData(row, column.id, value, type);
    };
    return (
      <InputControl
        sx={{
          minWidth: "100px",
          minHeight: "32px",
        }}
        type="number"
        name="base_unit_qty"
        placeholder="Quantity"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onBlur={onBlur}
        error={ingredientsValidator.current.message(
          "Unit quatity",
          value,
          "required"
        )}
        helperText={ingredientsValidator.current.message(
          "Unit quatity",
          value,
          "required"
        )}
      />
    );
  };

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor("ingredient_id", {
      header: () => "Ingredients",
      cell: (info) => {
        const filteredArray = combinedArray.filter((obj1) => {
          return !groups[info?.row?.original?.groupIndex]?.ingredients.some(
            (obj2) => {
              return (
                obj1.value === obj2.ingredient_id && obj1.type === obj2.type
              );
            }
          );
        });

        const option = combinedArray?.find(
          (value) =>
            value.value === info?.row?.original?.ingredient_id &&
            value.type === info?.row?.original.type
        );
        return (
          <>
            <Stack direction="row">
              <InputControl
                type="dropdown"
                fullWidth
                options={arraySort(filteredArray, "label") || []}
                value={
                  combinedArray && info?.row?.original?.ingredient_id
                    ? combinedArray?.find(
                        (value) =>
                          value.value === info?.row?.original?.ingredient_id &&
                          value.type === info?.row?.original.type
                      )
                    : null
                }
                onChange={(event, option) => {
                  IngredientsChangehandler(
                    event,
                    option,
                    info?.row?.original?.groupIndex,
                    info.row.index
                  );
                }}
                filterOptions={filterOptions}
                getOptionLabel={(option) => option?.label || ""}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderOption={(props, option) => (
                  <li {...props} key={props.id}>
                    <Stack direction="row" alignItems="center">
                      {option?.type === "ingredient" && (
                        <img src={leaf} alt="Leaf" style={{ marginRight: 8 }} />
                      )}
                      {option?.type === "recipe" && (
                        <img src={book} alt="Book" style={{ marginRight: 8 }} />
                      )}
                      {option.label}
                      {option?.is_batch_group === 1 && (
                        <span style={{ marginLeft: 8, color: "blue" }}>
                          Batch-Group
                        </span>
                      )}
                    </Stack>
                  </li>
                )}
                sx={{
                  minWidth: "500px",
                  minHeight: "32px",
                  borderRadius: "15px",
                  padding: "6px",
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={ingredientsValidator.current.message(
                      "Ingredient",
                      info?.row?.original?.ingredient_id,
                      "required"
                    )}
                    helperText={ingredientsValidator.current.message(
                      "Ingredient",
                      info?.row?.original?.ingredient_id,
                      "required"
                    )}
                    placeholder="Ingredient"
                    InputProps={{
                      ...params.InputProps,
                      startAdornment:
                        option?.type === null ? (
                          ""
                        ) : (
                          <InputAdornment position="start">
                            <Box
                              sx={{
                                width: "10px",
                                height: "20px",
                                borderRadius: "3px",
                              }}
                            >
                              {option?.type === "ingredient" && (
                                <img
                                  src={leaf}
                                  alt="Leaf"
                                  style={{ marginRight: 8 }}
                                />
                              )}
                              {option?.type === "recipe" && (
                                <img
                                  src={book}
                                  alt="Book"
                                  style={{ marginRight: 8 }}
                                />
                              )}
                            </Box>
                            <Stack
                              sx={{
                                width: "10px",
                                height: "5px",
                                borderRadius: "3px",
                                marginTop: "12px",
                              }}
                            >
                              {option?.is_batch_group === 1 && (
                                <span
                                  style={{
                                    fontSize: "10px",
                                    color: "blue",
                                    marginLeft: "32px",
                                  }}
                                >
                                  Batch-Group
                                </span>
                              )}
                            </Stack>
                          </InputAdornment>
                        ),
                    }}
                  />
                )}
              />
            </Stack>
          </>
        );
      },
    }),
    columnHelper.accessor("base_unit_qty", {
      header: () => "Base Qty",
      cell: TableCell,
    }),
    columnHelper.accessor("base_unit_id", {
      header: () => "Base Unit",
      cell: (info) => {
        const unitsCollection = info?.row?.original?.units || [];

        return (
          <InputControl
            type="dropdown"
            fullWidth
            options={arraySort(unitsCollection, "alt_unit_name") || []}
            value={
              unitsCollection && info?.row?.original?.base_unit_id
                ? unitsCollection?.find(
                    (value) =>
                      value?.alt_unit_id === info?.row?.original?.base_unit_id
                  )
                : null
            }
            onChange={(event, option) =>
              unitsChangehandler(
                event,
                option,
                info?.row?.original?.groupIndex,
                info?.row?.index
              )
            }
            getOptionLabel={(option) =>
              (option?.alt_unit_symbol ?? option?.alt_unit_name) || ""
            }
            sx={{
              minWidth: "200px",
              minHeight: "32px",
              borderRadius: "15px",
              padding: "6px",
            }}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Unit"
                error={ingredientsValidator.current.message(
                  "Unit",
                  info?.row?.original?.base_unit_id,
                  "required"
                )}
                helperText={ingredientsValidator.current.message(
                  "Unit",
                  info?.row?.original?.base_unit_id,
                  "required"
                )}
              />
            )}
          />
        );
      },
    }),

    columnHelper.accessor("is_main_ingredient", {
      header: () => "Main Ingredient",
      cell: (info) => {
        return (
          <Stack
            sx={{
              flexDirection: "row",
              gap: "2px",
              alignItems: "center",
            }}
            direction="row"
          >
            <Radio
              checked={info?.row?.original?.is_main_ingredient === 1}
              name="is_main_ingredient"
              onClick={() =>
                onRadioButtonChange(
                  info?.row?.index,
                  info?.row?.original?.groupIndex
                )
              }
            />
            <Typography variant="subtitle2">Main Ingredient</Typography>
          </Stack>
        );
      },
    }),
    columnHelper.accessor("id", {
      header: () => "",
      cell: (info) => {
        const itemIndex = info.row.index;
        const groupIndex = info?.row?.original?.groupIndex;
        let disabled = false;
        if (groupIndex < 1) {
          if (itemIndex < 1) {
            disabled = true;
          } else {
            disabled = false;
          }
        } else {
          disabled = false;
        }
        return (
          <RemoveCircleIcon
            sx={{
              color: disabled ? "#BCC7DB" : "#B42318",
            }}
            onClick={() => !disabled && handleRemoveRows(info)}
          />
        );
      },
    }),
  ];

  return (
    <Stack>
      {ingredientsValidator.current.purgeFields()}
      {unitValidator.current.purgeFields()}
      <Typography
        variant="h6"
        sx={{
          p: "16px 8px",
        }}
      >
        Recipe Ingredient
      </Typography>
      <Divider />
      <Stack
        sx={{
          p: "0px 8px",
          bgcolor: "#F7FAFC",
        }}
      >
        {groups.map((group, index) => (
          <>
            <Typography
              className="text-with-line"
              variant="caption"
              fontWeight={600}
            >
              {`Group ${index + 1}`}
            </Typography>
            <CustomDndTable
              key={index}
              defaultData={group?.ingredients}
              columns={columns}
              onRowOrderChange={(data) => onIngredientsOrderChange(data, index)}
              updateTableData={updateTableData}
              isPagination={false}
              isTableHeader={false}
              isTableHead={false}
              tableStyle={{
                border: "1px solid #DFE8F2",
              }}
              tableFooter={
                <>
                  <Button
                    type="button"
                    size="small"
                    variant="text"
                    startIcon={<AddIcon />}
                    onClick={() => addIngredientsHandle(index)}
                    sx={{ padding: "10px", marginLeft: "15px" }}
                  >
                    Add Ingredient
                  </Button>
                </>
              }
            />
          </>
        ))}

        <Divider sx={{ margin: "10px 0" }}>
          <Button
            type="button"
            size="small"
            variant="outlined"
            sx={{
              minWidth: "125px",
              borderColor: "#BCC7DB",
            }}
            color="gray"
            startIcon={<AddIcon />}
            onClick={addNewGroup}
          >
            New Group
          </Button>
        </Divider>

        <Stack>
          <Stack
            sx={{
              flexDirection: "row",
              justifyContent: "space-between",
              gap: "16px",
              alignItems: "center",
              bgcolor: "#3372E5",
              p: "8px 20px 8px 24px",
            }}
          >
            <Typography variant="subtitle1" color="#fff" fontWeight={600}>
              Recipe Total Weight:
            </Typography>
            <Stack
              sx={{
                flexDirection: "row",
              }}
            >
              <Typography variant="h5" color="#fff" fontWeight={600} mr="24px">
                {formatWeightWithUnits(total_weight, total_weight_unit, 3)}
              </Typography>

              <Typography variant="subtitle2" color="#fff" fontWeight={600}>
                Alternative UoM?
              </Typography>
              <Switch
                name="is_alternate_UOM"
                checked={is_alternate_UOM ? true : false}
                value={is_alternate_UOM}
                onChange={onSwitchChange}
                sx={{
                  "& .Mui-checked": {
                    color: "#ffffff",
                  },
                }}
              />
            </Stack>
          </Stack>
          <Stack>
            {is_alternate_UOM === 1 && (
              <CustomTable
                defaultData={alt_units ?? []}
                columns={altcolumns}
                updateTableData={updateTableData}
                isPagination={false}
                isTableHeader={false}
                isTableHead={false}
                tableStyle={{
                  border: "1px solid #DFE8F2",
                }}
                tableFooter={
                  <>
                    <Button
                      type="button"
                      size="small"
                      variant="text"
                      startIcon={<AddIcon />}
                      onClick={() => addUnitsHandle()}
                    >
                      Add Unit
                    </Button>
                  </>
                }
              />
            )}
          </Stack>
        </Stack>
      </Stack>
      {checkFinishedProduct() ? (
        <Stack
          padding={"24px 8px"}
          sx={{ display: "flex", flexDirection: "row", gap: "14px" }}
        >
          <Box>
            <Typography variant="h6">Selling Unit</Typography>
            <Typography sx={{ color: "#657083", fontSize: "14px" }}>
              Add selling unit for product, eg: kg, unit, etc
            </Typography>
          </Box>

          <InputControl
            type="dropdown"
            fullWidth
            options={arraySort(sellingUnits, "symbol") || []}
            value={
              (sellingUnits &&
                selling_unit_id &&
                sellingUnits?.find((value) => value.id === selling_unit_id)) ||
              null
            }
            onChange={(event, option) => sellingUnitChange(event, option)}
            getOptionLabel={(option) => option.symbol}
            sx={{
              width: "320px",
            }}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Selling Unit"
                error={ingredientsValidator.current.message(
                  "Selling Unit",
                  selling_unit_id,
                  "required"
                )}
                helperText={ingredientsValidator.current.message(
                  "Selling Unit",
                  selling_unit_id,
                  "required"
                )}
              />
            )}
          />
        </Stack>
      ) : null}
    </Stack>
  );
};
