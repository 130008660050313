import {
  Button,
  Grid,
  Stack,
  Typography,
  Link,
  Paper,
  Checkbox,
  Box,
  FormControlLabel,
} from "@mui/material";
import React from "react";
import {
  CustomTable,
  InputControl,
  Loader,
  PageListLayout,
} from "../../../shared";
import { useNavigate, useParams } from "react-router-dom";
import { useRolesAndPermissions } from "../hooks";
import { createColumnHelper } from "@tanstack/react-table";

export const AddorEditRoles = () => {
  const { id } = useParams();
  const {
    rolesAndPermissions,
    handleOnChange,
    validator,
    handleCheckPermission,
    onSubmit,
    handleChecked,
    createRoles,
    editRoles,
  } = useRolesAndPermissions({
    isPermissionList: true,
    isList: false,
    roleId: id,
  });
  const navigate = useNavigate();

  const breadcrumbs = [
    <Link
      underline="none"
      key="1"
      color="#1760D2"
      onClick={(e) => navigate("/roles-and-permissions")}
      sx={{ fontSize: "14px", fontWeight: 400 }}
    >
      Roles & Permissions
    </Link>,
    <Typography
      key="2"
      color="inherit"
      sx={{ fontSize: "14px", fontWeight: 400 }}
    >
      {id ? "Edit" : "Add New Role"}
    </Typography>,
  ];
  const columnHelper = createColumnHelper(
    rolesAndPermissions?.allPermissionsList
  );
  const columns = [
    columnHelper.accessor("name", {
      header: "Permission Name",
      cell: (props) => {
        const { row } = props;
        return (
          <>
            <Stack direction="row" gap={1} onClick={() => row.toggleExpanded()}>
              {!row.depth ? (
                <>
                  <img
                    alt="forward"
                    src={
                      row.getIsExpanded()
                        ? "/icons/ic_direction_arrows.svg"
                        : "/icons/Intersect.svg"
                    }
                  />
                  <Typography fontWeight={500} fontSize="16px">
                    {row.original.name}
                  </Typography>
                </>
              ) : (
                <Stack direction="row">
                  <Box width="180px">
                    <Typography ml={3} fontSize="14px" fontWeight={400} mt={1}>
                      {row.original.display_name}
                    </Typography>
                  </Box>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={handleChecked(
                          row.depth,
                          row.original.id,
                          row.originalSubRows
                        )}
                        color="primary"
                        sx={{ "& .MuiSvgIcon-root": { fontSize: 24 } }}
                        onChange={(e) => {
                          handleCheckPermission(e, row, "body");
                        }}
                      />
                    }
                  />
                </Stack>
              )}
            </Stack>
          </>
        );
      },
    }),
  ];
  return (
    <>
      <PageListLayout
        breadcrumbs={breadcrumbs}
        headerText={id ? "Edit Role" : "Add New Role"}
        isChanged={false}
        rightContent={
          <Stack direction="row" gap="8px">
            <Button
              type="button"
              size="small"
              variant="contained"
              color="inherit"
              onClick={() => navigate(-1)}
            >
              Cancel
            </Button>
            <Button
              type="button"
              size="small"
              variant="contained"
              onClick={onSubmit}
              disabled={
                createRoles?.isPending ||
                editRoles?.isPending ||
                !rolesAndPermissions.data.permission_id.length
              }
            >
              {id ? "Update" : "Save"}
            </Button>
          </Stack>
        }
        pageContent={
          <>
            {false ? (
              <Loader />
            ) : (
              <Stack sx={{ borderRadius: "4px", bgcolor: "#fff" }}>
                <Stack
                  sx={{
                    py: "32px",
                    borderBottom: "1px solid #DFE8F2",
                    mx: "20px",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <Typography
                        variant="subtitle1"
                        fontWeight={600}
                        sx={{ width: "150px" }}
                      >
                        Basic Details
                      </Typography>
                    </Grid>
                    <Grid item sm={8}>
                      <Stack width={"100%"} gap="15px">
                        <InputControl
                          name="name"
                          type="text"
                          label="Role Name"
                          required
                          error={validator.current.message(
                            "Role Name",
                            rolesAndPermissions?.data?.name,
                            "required|min:3"
                          )}
                          helperText={validator.current.message(
                            "Role Name",
                            rolesAndPermissions?.data?.name,
                            "required|min:3"
                          )}
                          onChange={(e) => handleOnChange(e)}
                          value={rolesAndPermissions?.data?.name}
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                </Stack>
                <Stack sx={{ p: "18px 20px" }}>
                  <Typography variant="subtitle1" fontWeight={600}>
                    Permissions
                  </Typography>
                  <Paper>
                    <CustomTable
                      columns={columns}
                      defaultData={
                        rolesAndPermissions?.allPermissionsList ?? []
                      }
                      pagination={false}
                      isPagination={false}
                      showSearchBar={false}
                      subRow={"permissions"}
                      manualPagination={true}
                    />
                  </Paper>
                </Stack>
              </Stack>
            )}
          </>
        }
      />
    </>
  );
};
