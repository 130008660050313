import {
  useQueryClient,
  useQuery,
  useMutation,
  keepPreviousData,
} from "@tanstack/react-query";
import qs from "qs";
import {
  getIngredientList,
  saveIngredient,
  getIngredient,
  removeIngredient,
  editIngredient,
  getTaxGroupList,
  getTagGroupList,
} from "../api/IngredientApi";
import {
  ErrorFormatter,
  errorMessage,
  successMessage,
} from "../../../utils/helper";
import { useNavigate } from "react-router-dom";
import { saveImageVideoUrl } from "../../Recipe/api";

const ingredientKeys = {
  all: ["ingredients"],
  lists: () => [...ingredientKeys.all, "ingredientlist"],
  ingredientlist: (filters) => [...ingredientKeys.lists(), filters],
  detail: (id) => [...ingredientKeys.ingredientlist(), id],
};
const tagsKeys = {
  all: ["tags"],
  lists: () => [...tagsKeys.all, "tagslist"],
  tagslist: (filters) => [...tagsKeys.lists(), filters],
};

export const useIngredientQuery = ({
  detId,
  debouncedText,
  pagination,
  resetDeleteInfo,
  isIngredientlist,
  isTaxgrouplist,
  isTaglist,
  sorting,
  status,
  types,
  tags,
}) => {
  const queryClient = useQueryClient();
  let params = {};
  const navigate = useNavigate();
  if (debouncedText) {
    params = { ...params, search: debouncedText };
  }
  if (status !== "") {
    params = { ...params, status: status };
  }
  const typesIds = types?.map((item) => item?.value);
  if (typesIds) {
    params = { ...params, type: typesIds };
  }
  const tagsIds = tags?.map((item) => item?.label);

  if (tagsIds) {
    params = { ...params, category_tags: tagsIds };
  }
  if (sorting?.length > 0) {
    const sortByParams = sorting.reduce(
      (obj, item) =>
        Object.assign(obj, { [item.id]: item.desc ? "desc" : "asc" }),
      {}
    );
    params = {
      ...params,
      sort: sortByParams,
    };
  }
  const query = {
    params,
    paramsSerializer: (params) => qs.stringify(params, { encode: true }),
  };

  //list query
  const listQuery = useQuery({
    queryKey: ingredientKeys.ingredientlist({ query, pagination }),
    queryFn: () => getIngredientList({ query, pagination }),
    placeholderData: keepPreviousData,
    enabled: isIngredientlist,
  });

  const taxListQuery = useQuery({
    queryKey: ingredientKeys.ingredientlist(),
    queryFn: () => getTaxGroupList(),
    placeholderData: keepPreviousData,
    enabled: isTaxgrouplist,
  });
  const tagsListQuery = useQuery({
    queryKey: tagsKeys.tagslist(),
    queryFn: () => getTagGroupList(),
    placeholderData: keepPreviousData,
    enabled: isTaglist,
  });

  const createIngredient = useMutation({
    mutationFn: ({ _, data }) => saveIngredient(data),
    onError: (error) => {
      errorMessage(error.response.data.message);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ingredientKeys.lists(),
      });
      successMessage("Ingredient created successfully");
      navigate(`/ingredients/details/${data.id}`, { replace: true });
      return data.handleClose();
    },
  });

  const updateIngredient = useMutation({
    mutationFn: ({ _, data }) => editIngredient(data, detId),
    onError: (error) => {
      errorMessage(ErrorFormatter(error.response.data.error));
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: ingredientKeys.lists(),
      });
      successMessage("Ingredient updated successfully");
      navigate(`/ingredients/details/${variables?.detId}`, { replace: true });
    },
  });

  const ingredientDetails = useQuery({
    queryKey: ingredientKeys.detail(detId),
    queryFn: () => getIngredient(detId),
    enabled: detId > 0,
  });

  const deleteIngredient = useMutation({
    mutationFn: removeIngredient,
    onError: (error) => {
      errorMessage(error?.response?.data?.error ?? "Error");
    },
    onSuccess: (res, variables) => {
      if (res.success) {
        if (variables.isList) {
          queryClient.invalidateQueries({
            queryKey: ingredientKeys.lists(),
          });
        }
        successMessage(res.message);
        variables.to && navigate(variables.to);
        resetDeleteInfo();
      } else if (res.success === false) {
        errorMessage(res.message);
      }
    },
  });
  const createPdfUrl = useMutation({
    mutationFn: saveImageVideoUrl,
    onError: (error) => {
      errorMessage(error.response.data.message);
    },
  });
  return {
    listQuery,
    createIngredient,
    ingredientDetails,
    updateIngredient,
    deleteIngredient,
    ingredientKeys,
    taxListQuery,
    tagsListQuery,
    createPdfUrl,
  };
};
