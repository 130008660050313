import { useImmer } from "use-immer";
import { useUnitsQuery } from "./UseUnitsQuery";
import { useRef, useState, useEffect, useContext } from "react";
import SimpleReactValidator from "simple-react-validator";
import { useDebounce } from "use-debounce";
import { useDelete, usePermission } from "../../../shared";
import { AppContext } from "../../../store";

export const useUnits = () => {
  const isUnitCreate = usePermission("unit-create");
  const isUnitEditable = usePermission("unit-modify");
  const isUnitDeletable = usePermission("unit-delete");
  const [, forceUpdate] = useState();
  const validator = useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
  );
  const { deleteInfo, onDelete, resetDeleteInfo } = useDelete();
  const moduleName = "units";
  const { tableData, setSorting, setPagination, setSearchState } =
    useContext(AppContext);
  const { sorting, pagination, search } = tableData[moduleName];
  const [unit, setUnit] = useImmer({
    data: {
      id: 0,
      name: "",
      symbol: "",
      ignore: false,
      no_of_decimals: 0,
      include_decimal_places: false,
      is_default: null,
    },
    search: "",
    isOpen: false,
    id: 0,
    del_id: 0,
  });

  const detId = unit.id;
  const [debouncedText] = useDebounce(search, 1000);

  const {
    listQuery,
    deleteUnit,
    createUnit,
    updateUnit,
    unitDetails,
    listQueryData,
  } = useUnitsQuery({
    detId,
    detail_id: unit.data.id,
    pagination,
    debouncedText,
    resetDeleteInfo,
    sorting,
  });

  const handleDeleteModal = (data) => {
    setUnit((draft) => {
      draft.isOpen = !draft.isOpen;
      draft.id = data.id;
    });
  };

  const handleClickOpen = (data, id) => {
    setUnit((draft) => {
      draft.isOpen = data;
      draft.id = id;
    });
  };

  const handleClose = (data) => {
    setUnit((draft) => {
      draft.isOpen = data;
      draft.id = "";
      draft.data = {
        id: 0,
        name: "",
        symbol: "",
        ignore: false,
        no_of_decimals: "",
        include_decimal_places: false,
        is_default: null,
      };
    });
    validator.current.hideMessages();
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setUnit((draft) => {
      draft.data[name] = value;
    });
  };

  useEffect(() => {
    if (unitDetails?.data) {
      setUnit((draft) => {
        draft.data = unitDetails.data;
      });
    }
  }, [unitDetails?.data]);

  const onSubmit = () => {
    if (validator.current.allValid()) {
      detId
        ? updateUnit.mutate({ detId, data: unit.data, handleClose })
        : createUnit.mutate({ data: unit.data, handleClose });
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  const searchHandler = (e) => {
    setUnit((draft) => {
      draft.search = e.target.value;
    });
  };

  const clearSearch = () => {
    setUnit((draft) => {
      draft.search = "";
    });
  };

  const onSearchChange = (value) => {
    setSearchState({
      tableName: moduleName,
      key: "search",
      value: value,
    });
  };

  const confirmDelete = () => {
    deleteUnit.mutate(deleteInfo.id);
  };

  return {
    listQuery,
    createUnit,
    onChange,
    onSubmit,
    updateUnit,
    handleClickOpen,
    handleClose,
    unit,
    unitDetails,
    searchHandler,
    validator,
    clearSearch,
    handleDeleteModal,
    deleteUnit,
    onSearchChange,
    pagination,
    setPagination,
    deleteInfo,
    onDelete,
    resetDeleteInfo,
    confirmDelete,
    sorting,
    setSorting,
    debouncedText,
    listQueryData,
    isUnitCreate,
    isUnitEditable,
    isUnitDeletable,
    moduleName,
    search,
  };
};
