import {
  useQueryClient,
  useQuery,
  useMutation,
  keepPreviousData,
} from "@tanstack/react-query";
import qs from "qs";
import {
  ErrorFormatter,
  errorMessage,
  successMessage,
} from "../../../utils/helper";
import { useNavigate } from "react-router-dom";
import parse from "html-react-parser";
import {
  editTipsAndTricks,
  getRecipes,
  getTipsAndTricks,
  getTripsAndTricksList,
  removeTipsAndTricks,
  saveTipsAndTricks,
} from "../api/TipsAndTricksApi";
import { saveImageVideoUrl } from "../../Recipe/api";

const tipsandtricksKeys = {
  all: ["tipsandtricks"],
  lists: () => [...tipsandtricksKeys.all, "ingredientlist"],
  tipsandtrickslist: (filters) => [...tipsandtricksKeys.lists(), filters],
  detail: (id) => [...tipsandtricksKeys.tipsandtrickslist(), id],
};
const recipesKeys = {
  all: ["recipes"],
  lists: () => [...recipesKeys.all, "recipeslist"],
  recipeslist: (filters) => [...recipesKeys.lists(), filters],
  detail: (id) => [...recipesKeys.recipeslist(), id],
};

export const useTipsAndTricksQuery = ({
  tipId,
  debouncedText,
  pagination,
  resetDeleteInfo,
  sorting,
  isList,
  recipeId,
}) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  let params = {};
  if (debouncedText) {
    params = { ...params, search: debouncedText };
  }
  if (recipeId) {
    params = { ...params, recipe_id: recipeId };
  }

  if (sorting?.length > 0) {
    const sortByParams = sorting.reduce(
      (obj, item) =>
        Object.assign(obj, { [item.id]: item.desc ? "desc" : "asc" }),
      {}
    );
    params = {
      ...params,
      sort: sortByParams,
    };
  }
  const query = {
    params,
    paramsSerializer: (params) => qs.stringify(params, { encode: true }),
  };

  const listQuery = useQuery({
    queryKey: tipsandtricksKeys.tipsandtrickslist({ query, pagination }),
    queryFn: () => getTripsAndTricksList({ query, pagination }),
    placeholderData: keepPreviousData,
    enabled: isList,
  });
  const recipeListQuery = useQuery({
    queryKey: recipesKeys.recipeslist({ query, pagination }),
    queryFn: () => getRecipes({ query, pagination }),
    placeholderData: keepPreviousData,
    enabled: true,
  });

  const createTipsAndTricks = useMutation({
    mutationFn: ({ formData }) => saveTipsAndTricks(formData),
    onError: (error) => {
      errorMessage(ErrorFormatter(error.response.data.errors));
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: tipsandtricksKeys.lists(),
      });
      successMessage("Tips And Tricks  created successfully");
      navigate(`/tips-tricks`);
    },
  });
  const deleteTipsAndTricks = useMutation({
    mutationFn: removeTipsAndTricks,
    onError: (error) => {
      errorMessage(error?.response?.data?.error ?? "Error");
    },
    onSuccess: (res, variables) => {
      if (res.success) {
        queryClient.invalidateQueries({
          queryKey: tipsandtricksKeys.lists(),
        });
        successMessage(res.message);
        variables.to && navigate(variables.to);
        resetDeleteInfo();
      } else if (res.success === false) {
        errorMessage(res.message);
      }
    },
  });
  const tipsAndTricksDetails = useQuery({
    queryKey: tipsandtricksKeys.detail(tipId),
    queryFn: () => getTipsAndTricks(tipId),
    enabled: tipId > 0,
  });
  const updateTipsAndTricks = useMutation({
    mutationFn: ({ formData }) => editTipsAndTricks(tipId, formData),
    onError: (error) => {
      errorMessage(ErrorFormatter(error.response.data.errors));
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: tipsandtricksKeys.lists(),
      });
      successMessage("Tips And Tricks  updated successfully");
      navigate(`/tips-tricks`);
    },
  });
  const createImageFileUrl = useMutation({
    mutationFn: saveImageVideoUrl,
    onError: (error) => {
      errorMessage(ErrorFormatter(error.response.data.errors));
    },
  });

  return {
    listQuery,
    recipeListQuery,
    deleteTipsAndTricks,
    createTipsAndTricks,
    tipsAndTricksDetails,
    updateTipsAndTricks,
    createImageFileUrl,
  };
};
