import { useImmer } from "use-immer";
import { useIngredientQuery } from "./UseIngredientQueries";
import { useRef, useState, useEffect, useContext } from "react";
import SimpleReactValidator from "simple-react-validator";
import { useDebounce } from "use-debounce";
import { useDelete, useStatusSelect, usePermission } from "../../../shared";
import qs from "qs";
import { getUnitList } from "../../MasterData/api/UnitApi";
import { AppContext } from "../../../store";

export const useIngredients = ({
  detId,
  isIngredientlist,
  isTaxgrouplist,
  isTaglist,
  isList,
}) => {
  const [update, forceUpdate] = useState(false);
  const [update2, forceUpdate2] = useState(false);
  const isDeletable = usePermission("ingredient-delete");
  const isCreateVisible = usePermission("ingredient-create");
  const isEditable = usePermission("ingredient-modify");
  const validator = useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
  );

  const unitValidator = useRef(
    new SimpleReactValidator({
      validators: {
        customNumeric: {
          message:
            "The value must be a number greater than 0, and cannot be exactly 0.",
          rule: (val) => {
            return parseFloat(val) > 0;
          },
          required: true,
        },
      },
    })
  );
  const [state, setState] = useImmer({
    data: {
      pdf: [],
    },
  });
  const moduleName = "ingredients";
  const {
    globalData,
    setTableState,
    tableData,
    clearAllFilters,
    setSorting,
    setPagination,
    setSearchState,
  } = useContext(AppContext);
  const { sorting, pagination, search } = tableData[moduleName];

  const { deleteInfo, onDelete, resetDeleteInfo } = useDelete();
  const [ingredient, setIngredient] = useImmer({
    data: {
      id: 0,
      name: "",
      tax_group_id: "",
      type: "",
      status: 1,
      selectedStatus: -1,
      tags: [],
      buying_unit_id: "",
      cost_exc_tax: "",
      cost_inc_tax: "",
      tagslist: [],
      hasUom: 0,
      units: [
        {
          base_unit_id: "",
          base_unit_qty: "",
          alt_unit_id: "",
          alt_unit_qty: "",
        },
      ],
    },
    selectedStatus: null,
    tagslist: [],
    isLoading: true,
    isOpen: false,
    id: 0,
    del_id: 0,
  });
  const [debouncedText] = useDebounce(search, 1000);
  const {
    listQuery,
    deleteIngredient,
    createIngredient,
    updateIngredient,
    ingredientDetails,
    taxListQuery,
    tagsListQuery,
    createPdfUrl,
  } = useIngredientQuery({
    detId,
    detail_id: ingredient.data.id,
    pagination,
    sorting,
    debouncedText,
    resetDeleteInfo,
    isIngredientlist,
    isTaxgrouplist,
    isTaglist,
    status: tableData.ingredients.data.selectedStatus,
    types: tableData.ingredients.data.types,
    tags: tableData.ingredients.data.tagslist,
  });
  const optionsTaxGroup = taxListQuery?.data?.map((item) => ({
    label: item.name,
    value: item.id,
  }));
  const optionsTagsList = tagsListQuery?.data?.map((item) => ({
    label: item.name,
    value: item.id,
  }));
  const optionsTypes = globalData?.Ingredient_types?.map((item) => ({
    label: item.label,
    value: item.value,
  }));

  useEffect(() => {
    const kgId = globalData?.defaultUnits.find((i) => i.label === "kg")?.value;
    if (kgId && !detId) {
      setIngredient((draft) => {
        draft.data.units[0].base_unit_id = kgId;
      });
    }
  }, [globalData]);
  const onChangeStatusHandler = (value) => {
    setTableState({
      tableName: moduleName,
      key: "selectedStatus",
      value: value,
    });
  };
  const selectStatus = useStatusSelect({ onChangeStatusHandler });
  const handleDeleteModal = (type, data) => {
    setIngredient((draft) => {
      draft.isOpen = type;
      draft.id = data;
    });
  };

  const handleClickOpen = (data, id) => {
    setIngredient((draft) => {
      draft.isOpen = data;
      draft.id = id;
    });
  };

  const handleClose = (data) => {
    setIngredient((draft) => {
      draft.isOpen = data;
      draft.id = "";
      draft.data = {
        id: 0,
        name: "",
        type: "",
        status: false,
      };
    });
    validator.current.hideMessages();
  };

  const onChange = (e) => {
    const { name, value } = e?.target;
    const tax = taxListQuery?.data?.find(
      (i) => i.id === ingredient?.data?.tax_group_id
    )?.tax_rate;
    const taxRate = (Number(value) * tax) / 100;
    const taxAmount = (Number(value) * Number(taxRate)) / 100;
    if (name === "cost_exc_tax") {
      setIngredient((draft) => {
        draft.data.cost_exc_tax = value;
        draft.data.cost_inc_tax = (Number(value) + taxRate)?.toFixed(3);
      });
    } else if (name === "cost_inc_tax") {
      setIngredient((draft) => {
        draft.data.cost_inc_tax = value;
        draft.data.cost_exc_tax = (Number(value) - taxRate)?.toFixed(3);
      });
    } else {
      setIngredient((draft) => {
        draft.data[name] = value;
      });
    }
    if (name === "hasUom" && value === 1 && ingredient.data.units.length < 1) {
      const kgId = globalData?.defaultUnits.find(
        (i) => i.label === "kg"
      )?.value;
      if (kgId) {
        setIngredient((draft) => {
          draft.data.units = [
            {
              base_unit_id: kgId,
              base_unit_qty: "",
              alt_unit_id: "",
              alt_unit_qty: "",
            },
          ];
        });
      }
    } else if (name === "hasUom" && value === 0) {
      setIngredient((draft) => {
        draft.data.buying_unit_id = "";
      });
    }
  };
  const onChangeTax = (e) => {
    const { name, value } = e?.target;
    setIngredient((draft) => {
      draft.data.tax_group_id = value;
      draft.data.cost_inc_tax = "";
      draft.data.cost_exc_tax = "";
    });
  };
  const onTablehandleChange = (e, index) => {
    setIngredient((draft) => {
      draft.data.units = [
        ...draft.data.units.map((data, i) =>
          i === index ? { ...data, [e.target.name]: e.target.value } : data
        ),
      ];
    });
  };

  const onTableChange = (e, name, index, type) => {
    setIngredient((draft) => {
      draft.data.units = [
        ...draft.data.units.map((data, i) =>
          i === index + 1
            ? { ...data, ["base_unit_id"]: e?.target?.value?.id }
            : data
        ),
      ];
      draft.data.units = [
        ...draft.data.units.map((data, i) =>
          i === index ? { ...data, [name]: e?.target?.value } : data
        ),
      ];
    });
  };
  useEffect(() => {
    if (ingredientDetails?.data) {
      setState((draft) => {
        draft.data.pdf = ingredientDetails?.data?.specSheets?.map(
          (i) => i.doc_url
        );
      });
      let units = [];
      ingredientDetails?.data?.units?.forEach(
        (i) =>
          !i.is_base && units.push({ ...i, alt_unit_id: i?.alt_unit_id?.id })
      );
      setIngredient((draft) => {
        draft.isLoading = false;
        draft.data = {
          ...ingredientDetails.data,
          type: ingredientDetails?.data?.type?.toLowerCase(),
        };
        draft.data.units = units;
      });
    }
  }, [ingredientDetails?.data]);
  const onSubmit = () => {
    const payload = {
      name: ingredient.data.name,
      buying_unit_id: ingredient.data.buying_unit_id,
      doc_urls: state.data.pdf,
      cost_inc_tax: Number(ingredient.data.cost_inc_tax),
      cost_exc_tax: Number(ingredient.data.cost_exc_tax),
      type: ingredient.data.type,
      hasUom: ingredient.data.hasUom,
      status: ingredient.data.status,
      tags: ingredient.data.tags,
      tax_group_id: ingredient.data.tax_group_id,
      tax_group_name: ingredient.data.tax_group_name,
      units: ingredient.data.hasUom
        ? [
            ...ingredient.data.units.map((i) => {
              return {
                alt_unit_id: i.alt_unit_id,
                base_unit_id: i.base_unit_id,
                base_unit_qty: i.base_unit_qty,
                alt_unit_qty: i.alt_unit_qty,
              };
            }),
          ]
        : [],
    };
    !ingredient.data.hasUom && delete payload.units;

    if (validator.current.allValid() && unitValidator.current.allValid()) {
      detId
        ? updateIngredient.mutate({ detId, data: payload })
        : createIngredient.mutate({ data: payload });
    } else {
      validator.current.showMessages();
      unitValidator.current.showMessages();
      forceUpdate(!update);
      forceUpdate2(!update2);
    }
  };

  const searchHandler = (e) => {
    setSearchState((draft) => {
      draft.search = e.target.value;
    });
  };

  const clearSearch = () => {
    setTableState({
      tableName: moduleName,
      key: "search",
      value: "",
    });
  };

  const onSearchChange = (value) => {
    setSearchState({
      tableName: moduleName,
      key: "search",
      value: value,
    });
  };

  const confirmDelete = () => {
    deleteIngredient.mutate({ id: deleteInfo.id, to: deleteInfo.to, isList });
  };
  const handleProductInputChange = (event, v) => {
    const { value, name } = event.target;
    setIngredient((draft) => {
      draft.data.tags = value;
    });
  };
  const handleAddUnits = (index) => {
    if (unitValidator.current.allValid()) {
      setIngredient((draft) => {
        draft.data.units.push({
          base_unit_id: "",
          base_unit_qty: "",
          alt_unit_id: "",
          alt_unit_qty: "",
        });
      });
    } else {
      unitValidator.current.showMessages();
      forceUpdate2(!update2);
    }
  };
  const handleDelete = (type, index) => {
    setIngredient((draft) => {
      draft.data.units = [...draft.data.units.filter((data, i) => i !== index)];
    });
  };
  const onDeleteTagSelect = (data) => {
    setIngredient((draft) => {
      draft.data.tags = ingredient.data.tags.filter((value) => {
        return value !== data;
      });
    });
  };
  const getAllIngredientList = async ({ search = null, page }) => {
    let params = {};
    let pagination = {
      pageIndex: page,
      pageSize: 2,
    };
    if (search) {
      params = { ...params, search };
    }
    const query = {
      params,
      paramsSerializer: (params) => qs.stringify(params),
    };
    try {
      const res = await getUnitList({ query });
      if (res) {
        const options = res?.units?.map((data) => {
          return {
            id: data?.id,
            name: data?.name,
          };
        });
        return {
          options: options,
          hasMore: res?.pagination?.hasMorePages,
          additional: {
            page: page + 1,
          },
        };
      }
    } catch (err) {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: page,
        },
      };
    }
  };
  const onClearFilter = () => {
    clearAllFilters({ tableName: moduleName });
  };
  const disableclearButton =
    (tableData.ingredients.data.selectedStatus == null ||
      tableData.ingredients.data.selectedStatus === "") &&
    !tableData.ingredients.data.types.length &&
    !tableData.ingredients.data.tagslist.length
      ? true
      : false;

  const typeValuesHandler = (event, option) => {
    const item = option?.map((item) => {
      return { ...item };
    });
    setTableState({ tableName: moduleName, key: "types", value: item });
  };
  const tagValuesHandler = (event, option) => {
    const item = option?.map((item) => {
      return { ...item };
    });
    setTableState({ tableName: moduleName, key: "tagslist", value: item });
  };
  const handleDropzonePdf = (event) => {
    const doc_urls = event.target.files[0];
    let pdfFormdata = new FormData();
    pdfFormdata.append("type", "doc");
    pdfFormdata.append("media", doc_urls);
    createPdfUrl.mutate(pdfFormdata);
  };

  useEffect(() => {
    if (createPdfUrl?.data) {
      const pdfUrl = createPdfUrl?.data;
      if (pdfUrl) {
        if (
          !state.data.pdf.some((obj) => Object.values(obj).includes(pdfUrl))
        ) {
          setState((draft) => {
            draft.data.pdf.push(pdfUrl);
          });
        } else {
          console.log(`${pdfUrl} does not exist in the array of objects.`);
        }
      }
    }
  }, [createPdfUrl?.data]);
  const handleDeletePdf = (index) => {
    setState((draft) => {
      draft.data.pdf = [...draft.data.pdf.filter((data, i) => i !== index)];
    });
  };
  return {
    listQuery,
    createIngredient,
    onChange,
    onSubmit,
    updateIngredient,
    handleClickOpen,
    handleClose,
    ingredient,
    ingredientDetails,
    searchHandler,
    validator,
    clearSearch,
    handleDeleteModal,
    deleteIngredient,
    onSearchChange,
    pagination,
    setPagination,
    deleteInfo,
    onDelete,
    resetDeleteInfo,
    confirmDelete,
    optionsTaxGroup,
    globalData,
    handleProductInputChange,
    onTableChange,
    handleAddUnits,
    handleDelete,
    onSubmit,
    onTablehandleChange,
    tagsListQuery,
    onDeleteTagSelect,
    sorting,
    setSorting,
    getAllIngredientList,
    globalData,
    selectStatus,
    disableclearButton,
    onClearFilter,
    typeValuesHandler,
    optionsTagsList,
    tagValuesHandler,
    optionsTypes,
    onChangeTax,
    handleDropzonePdf,
    state,
    createPdfUrl,
    handleDeletePdf,
    unitValidator,
    isCreateVisible,
    isEditable,
    isDeletable,
    tableData,
    moduleName,
    search,
  };
};
