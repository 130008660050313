import {
  Box,
  Button,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
  Link,
  FormHelperText,
} from "@mui/material";

import {
  CustomTabPanel,
  CustomeDialog,
  DeleteModal,
  InputControl,
  Loader,
  PageListLayout,
} from "../../../shared";
import DeleteIcon from "../../../assets/images/Icons/Vector (11).svg";
import { CommonBatchTabs } from "./CommonBatchTabs";
import editicon from "../../../assets/images/Icons/ic_edit.svg";
import {
  DeleteAlertDialog,
  RegenerateDialog,
  SettingsView,
  TemplateStoreList,
} from "../components";
import { useLocation, useParams } from "react-router-dom";
import { useStoreDetailsTemplates, useTemplates } from "../hooks";
import { CustomDialogTemplate } from "../../../shared/components/CustomDialogTemplate";
import { RegenerateTemplate } from "../components";
import { NameDialog } from "../components/NameDialog";
import { useNavigate } from "react-router-dom";
import { CommonTab } from "./CommonTab";
import { GenerateTemplates } from "./GenerateTemplates";
import copyIcon from "../../../assets/images/Icons/copyImage.svg";
import { navigateBasedOnUserType } from "../../../utils";
import TemplateStatusChangeDialog from "../components/TemplateStatusChangeDialog";

const statusList = [
  {
    id: 1,
    label: "Active",
  },
  {
    id: 0,
    label: "Inactive",
  },
];
export const TemplatesDetails = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const { search, pathname } = useLocation();
  const isStoreAdmin = pathname.includes("store-admin");
  const handleChange = (event, newValue) => {
    if (isStoreAdmin) {
      navigate(`/store-admin/templates/details/${id}${newValue}`);
    } else {
      navigate(`/templates/details/${id}${newValue}`);
    }
  };
  const {
    state,
    assignedStoreState,
    handleModal,
    handleStoreDialogSelect,
    handleStoreToRecipe,
    onSearchHandler,
    pagination,
    setPagination,
    assingedStoreListQueryTemplate,
    confirmDeleteStrores,
    storesDeleteInfo,
    handleDeleteStores,
    clearStoresDeleteInfo,
    sorting,
    setSorting,
    isLoading,
    setState,
    onChangeStoreEffectiveDate,
    handleStoreDeleteDialog,
    onChangeEffectiveDates,
  } = useStoreDetailsTemplates({ templateId: id, isAssignedstoreList: true });
  const {
    state: states,
    templateDetails,
    createScedule,
    templates,
    onDeleteTagSelect,
    onChangeDate,
    RadioButtonChange,
    onChangeSceduler,
    templateProductionDaysDetails,
    dropdownListQuery,
    handleRepeatOptionChange,
    weekOfMonthOptionChange,
    monthOptionChange,
    weekDaysOptionChange,
    onChangeYear,
    onChangeHandler,
    DaysOfWeekOptionChange,
    WeekDaysOptionChange,
    OptionChange,
    handleClickOpen,
    handleDayClick,
    onSubmitStatus,
    handleAlertClickOpen,
    onChangeMultipleDate,
    handleClickSaveOpen,
    handleClickUpdateOpen,
    setTemplates,
    RemoveButtonHandeler,
    statusChangeHandler,
    handleScheduleOpen,
    handleClickUpdateClose,
    categorylistQuery,
    handleOpenClick,
    handleOpenClickDetailCopy,
    updateTableData,
    onChangeFilter,
    handleRecipes,
    onUpdate,
    optionHandleChangeBatches,
    optionHandleChangeRecipies,
    onChangeEffectiveDate,
    handleNameOpen,
    onChange,
    onNameSubmit,
    validator,
    forceUpdate,
    handleRegenerateOpenClick,
    onSearchChange,
    deleteInfo,
    resetDeleteInfo,
    onDeleteDetails,
    confirmDelete,
    filterRecipeList,
    handleCustomTableChange,
    reGenerateBatch,
    searchHandler,
    updateTemplateName,
    handleBack,
    createTemplateName,
    copyBatch,
    generateBatch,
    batchFilter1,
    handleOptionChangeRecipe,
    handleOptionChangeRecipeBatch,
    onSave,
    onNameCreateSubmit,
    onSubmits,
    onSaveCustomDays,
    handleAddCustomDays,
    isDeletable,
    isStatusChange,
    isEditable,
    globalData,
    isCopyTemplate,
    isStoreAssign,
    handleClose,
    handleRemoveRecurringDate,
    confirmDeleteRecurringDates,
  } = useTemplates({
    details_id: id,
    dropdownlist: true,
    list: true,
    updateId: id,
    isList: false,
  });

  const onCreateSubmit = (data) => {
    if (validator.current.allValid()) {
      onSave(data);
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };
  const breadcrumbs = [
    <Link
      underline="none"
      key="1"
      color="#1760D2"
      onClick={(e) =>
        navigateBasedOnUserType(`/templates`, isStoreAdmin, navigate)
      }
      sx={{ fontSize: "14px", fontWeight: 400 }}
    >
      Templates
    </Link>,
    <Typography
      key="2"
      color="inherit"
      sx={{ fontSize: "14px", fontWeight: 400 }}
    >
      {templateDetails?.data?.template_name}
    </Typography>,
  ];

  if (templateDetails?.isLoading) {
    return <Loader />;
  }
  const onSubmit = () => {
    if (validator.current.allValid()) {
      onNameCreateSubmit();
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };
  return (
    <>
      <PageListLayout
        breadcrumbs={breadcrumbs}
        headerText={
          <Box sx={{ display: "flex", gap: "15px", alignItems: "center" }}>
            <Typography sx={{ flex: 1, fontSize: "24px", fontWeight: 600 }}>
              {templateDetails?.data?.template_name}
            </Typography>
            <Stack
              sx={{
                width: "32px",
                height: "32px",
                borderRadius: "35px",
                bgcolor: "#E6EEF7",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                alt="edit"
                onClick={() => handleNameOpen(true)}
                src={editicon}
                style={{ cursor: "pointer" }}
              />
            </Stack>

            <CustomeDialog
              title="Rename Template"
              PaperProps={{
                sx: {
                  minwidth: "482px",
                  minHeight: "240px",
                  padding: "20px 16px 20px 16px",
                },
              }}
              open={templates?.isNameOpen}
              CloseIcons={false}
              content={
                <NameDialog
                  onChange={onChange}
                  onSubmit={onNameSubmit}
                  state={templates}
                  isEdit={id}
                  validator={validator}
                  handleClose={() => handleNameOpen(false)}
                  isDisabled={updateTemplateName.isPending}
                />
              }
            />
          </Box>
        }
        pageContent={
          <>
            <Box
              className="sticky-Tab"
              zIndex={100}
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                px: "16px",
                bgcolor: "#fff",
                top: "62px",
              }}
            >
              <Tabs value={search} onChange={handleChange}>
                <Tab label="Batches" value={""} />
                <Tab label="Settings" value={"?settings"} />
                {!isStoreAdmin && <Tab label="Stores" value={"?stores"} />}
              </Tabs>
            </Box>

            <CustomTabPanel value={search} index={""}>
              <CommonBatchTabs
                templateDetails={templateDetails}
                templates={templates}
                optionHandleChangeBatches={optionHandleChangeBatches}
                optionHandleChangeRecipies={optionHandleChangeRecipies}
              />
            </CustomTabPanel>
            <CustomTabPanel value={search} index={"?settings"}>
              <SettingsView
                dialogBoxId={id}
                createScedule={createScedule}
                onSubmit={onSubmits}
                templates={templates}
                onDeleteTagSelect={onDeleteTagSelect}
                onChangeDate={onChangeDate}
                RadioButtonChange={RadioButtonChange}
                onChangeSceduler={onChangeSceduler}
                templateProductionDaysDetails={templateProductionDaysDetails}
                dropdownListQuery={dropdownListQuery}
                handleRepeatOptionChange={handleRepeatOptionChange}
                weekOfMonthOptionChange={weekOfMonthOptionChange}
                monthOptionChange={monthOptionChange}
                weekDaysOptionChange={weekDaysOptionChange}
                onChangeYear={onChangeYear}
                onChangeHandler={onChangeHandler}
                DaysOfWeekOptionChange={DaysOfWeekOptionChange}
                WeekDaysOptionChange={WeekDaysOptionChange}
                OptionChange={OptionChange}
                handleClickOpen={handleClickOpen}
                handleClickSaveOpen={handleClickSaveOpen}
                handleDayClick={handleDayClick}
                onChangeMultipleDate={onChangeMultipleDate}
                handleClickUpdateOpen={handleClickUpdateOpen}
                setTemplates={setTemplates}
                RemoveButtonHandeler={RemoveButtonHandeler}
                handleScheduleOpen={handleScheduleOpen}
                handleClickUpdateClose={handleClickUpdateClose}
                templateDetails={templateDetails}
                onChangeEffectiveDate={onChangeEffectiveDate}
                onSaveCustomDays={onSaveCustomDays}
                handleAddCustomDays={handleAddCustomDays}
                validator={validator}
                globalData={globalData}
                handleRemoveRecurringDate={handleRemoveRecurringDate}
                confirmDeleteRecurringDates={confirmDeleteRecurringDates}
              />
              {isStoreAdmin && (
                <TemplateStoreList
                  handleStoreDeleteDialog={handleStoreDeleteDialog}
                  dialogBoxId={id}
                  state={state}
                  setState={setState}
                  assignedStoreState={assignedStoreState}
                  handleModal={handleModal}
                  handleStoreDialogSelect={handleStoreDialogSelect}
                  handleStoreToRecipe={handleStoreToRecipe}
                  onSearchHandler={onSearchHandler}
                  pagination={pagination}
                  setPagination={setPagination}
                  assingedStoreListQueryTemplate={
                    assingedStoreListQueryTemplate
                  }
                  confirmDeleteStrores={confirmDeleteStrores}
                  storesDeleteInfo={storesDeleteInfo}
                  clearStoresDeleteInfo={clearStoresDeleteInfo}
                  handleDeleteStores={handleDeleteStores}
                  sorting={sorting}
                  setSorting={setSorting}
                  isLoading={isLoading}
                  createScedule={createScedule}
                  templates={templates}
                  handleClickOpen={handleClickOpen}
                  handleClickSaveOpen={handleClickSaveOpen}
                  handleAlertClickOpen={handleAlertClickOpen}
                  handleClickUpdateClose={handleClickUpdateClose}
                  templateDetails={templateDetails}
                  onChangeStoreEffectiveDate={onChangeStoreEffectiveDate}
                  onChangeEffectiveDates={onChangeEffectiveDates}
                  isStoreAdmin={true}
                />
              )}
            </CustomTabPanel>
            <CustomTabPanel value={search} index={"?stores"}>
              <TemplateStoreList
                handleStoreDeleteDialog={handleStoreDeleteDialog}
                dialogBoxId={id}
                state={state}
                setState={setState}
                assignedStoreState={assignedStoreState}
                handleModal={handleModal}
                handleStoreDialogSelect={handleStoreDialogSelect}
                handleStoreToRecipe={handleStoreToRecipe}
                onSearchHandler={onSearchHandler}
                pagination={pagination}
                setPagination={setPagination}
                assingedStoreListQueryTemplate={assingedStoreListQueryTemplate}
                confirmDeleteStrores={confirmDeleteStrores}
                storesDeleteInfo={storesDeleteInfo}
                clearStoresDeleteInfo={clearStoresDeleteInfo}
                handleDeleteStores={handleDeleteStores}
                sorting={sorting}
                setSorting={setSorting}
                isLoading={isLoading}
                createScedule={createScedule}
                templates={templates}
                handleClickOpen={handleClickOpen}
                handleClickSaveOpen={handleClickSaveOpen}
                handleAlertClickOpen={handleAlertClickOpen}
                handleClickUpdateClose={handleClickUpdateClose}
                templateDetails={templateDetails}
                onChangeStoreEffectiveDate={onChangeStoreEffectiveDate}
                onChangeEffectiveDates={onChangeEffectiveDates}
                isStoreAdmin={false}
                isStoreAssign={isStoreAssign}
              />
            </CustomTabPanel>
          </>
        }
        rightContent={
          <Stack direction="row" gap="12px">
            {isStatusChange && (
              <InputControl
                type="dropdown"
                options={statusList}
                getOptionLabel={(option) => option?.label || ""}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                disableClearable={true}
                style={{
                  width: "160px",
                }}
                value={
                  statusList.find(
                    (option) => option.id === templateDetails?.data?.status
                  ) || null
                }
                renderInput={(params) => (
                  <TextField {...params} placeholder="Status" />
                )}
                onChange={(event, option) => statusChangeHandler(event, option)}
              />
            )}
            {templates?.isStatusOpen && (
              <CustomeDialog
                PaperProps={{
                  sx: {
                    minWidth: "570px",
                    minHeight: "280px",
                    borderRadius: "10px",
                  },
                }}
                open={templates?.isStatusOpen}
                // handleClose={() => handleClickOpen(false)}
                title={
                  <Typography
                    sx={{
                      padding: "20px 20px 8px 20px",
                      fontWeight: "500",
                      fontSize: "20px",
                      lineHeight: "24.2px",
                      width: "590px",
                    }}
                  >
                    When do you want to change this update?
                  </Typography>
                }
                content={
                  <RegenerateDialog
                    onSubmit={onSubmitStatus}
                    templates={templates}
                    onChangeEffectiveDate={onChangeEffectiveDate}
                    setTemplates={setTemplates}
                    handleClose={() => handleClickOpen(false)}
                    state={templates}
                  />
                }
              />
            )}
            {isCopyTemplate && (
              <Box
                component="img"
                src={copyIcon}
                height={20}
                width={20}
                mt={1.7}
                onClick={() => handleOpenClickDetailCopy(true, id)}
                style={{ cursor: "pointer" }}
              />
            )}
            {isDeletable && (
              <img
                alt="delete"
                onClick={() =>
                  onDeleteDetails(
                    id,
                    assignedStoreState,
                    templateProductionDaysDetails
                  )
                }
                src={DeleteIcon}
                style={{ cursor: "pointer" }}
              />
            )}
            {isEditable && (
              <Button
                type="button"
                color="inherit"
                size="small"
                variant="outlined"
                sx={{ borderColor: "#BCC7DB" }}
                onClick={() => handleOpenClick(true, id)}
              >
                Regenerate Batch
              </Button>
            )}

            <CustomDialogTemplate
              open={templates.isOpen}
              PaperProps={{
                sx: {
                  minWidth: "993px",
                  height: "778px",
                },
              }}
              title={
                templateDetails?.data?.template_name
                  ? templateDetails?.data?.template_name
                  : "Generate Template"
              }
              content={
                <RegenerateTemplate
                  handleCustomTableChange={handleCustomTableChange}
                  templates={templates}
                  categorylistQuery={categorylistQuery}
                  handleRecipes={handleRecipes}
                  onChangeFilter={onChangeFilter}
                  updateTableData={updateTableData}
                  onSearchChange={onSearchChange}
                  searchHandler={searchHandler}
                />
              }
              tableFooter={
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  spacing={2}
                  padding="10px"
                  sx={{
                    boxShadow: "0px -2px 8px 2px #0000000F",
                  }}
                >
                  {!batchFilter1.length > 0 && (
                    <Box sx={{ alignContent: "center" }}>
                      <FormHelperText sx={{ color: "#C60808" }}>
                        The recipes field is required.
                      </FormHelperText>
                    </Box>
                  )}
                  <Button
                    variant="outlined"
                    onClick={() => handleOpenClick(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => handleRegenerateOpenClick(true)}
                    disabled={!filterRecipeList?.length >= 1}
                  >
                    Regenerate Batches
                  </Button>
                  {templates.isRegenerateOpen && batchFilter1.length > 0 && (
                    <CustomeDialog
                      PaperProps={{
                        sx: {
                          minWidth: "570px",
                          minHeight: "280px",
                          borderRadius: "10px",
                        },
                      }}
                      open={templates.isRegenerateOpen}
                      handleClose={() => handleRegenerateOpenClick(false)}
                      title={
                        <Typography
                          sx={{
                            padding: "20px 20px 8px 20px",
                            fontWeight: "500",
                            fontSize: "20px",
                            lineHeight: "24.2px",
                            width: "590px",
                          }}
                        >
                          When do you want to change this update?
                        </Typography>
                      }
                      content={
                        <RegenerateDialog
                          onSubmit={onUpdate}
                          templates={templates}
                          onChangeEffectiveDate={onChangeEffectiveDate}
                          setTemplates={setTemplates}
                          handleClose={() => handleRegenerateOpenClick(false)}
                          isDisabled={reGenerateBatch.isPending}
                        />
                      }
                    />
                  )}
                </Stack>
              }
            />
          </Stack>
        }
      />
      <DeleteModal
        open={deleteInfo.showDetails}
        handleClose={() => resetDeleteInfo()}
        onConfirm={() => confirmDelete()}
      />

      <DeleteAlertDialog
        id={deleteInfo.id}
        open={deleteInfo.showTempDetails}
        handleClose={() => resetDeleteInfo()}
        isStoreAdmin={isStoreAdmin}
      />
      <CustomDialogTemplate
        open={templates.isOpenDialog}
        handleBack={() => handleBack()}
        PaperProps={{
          sx: {
            minWidth: "993px",
            height: "654px",
          },
        }}
        title={
          !states.isCreate ? (
            templateDetails?.data?.template_name ? (
              templateDetails?.data?.template_name
            ) : (
              "Generate Template"
            )
          ) : (
            <InputControl
              label="Template Name"
              name="template_name"
              required
              onChange={onChange}
              disabled={states.isDuplicate}
              sx={{ width: "405px" }}
              error={validator.current.message(
                "template Name",
                templates.data.template_name,
                "required"
              )}
              helperText={validator.current.message(
                "Template Name",
                templates.data.template_name,
                "required"
              )}
            />
          )
        }
        isBackArroaw={states.isCreate}
        content={
          !states.isCreate ? (
            <GenerateTemplates
              templates={templates}
              categorylistQuery={categorylistQuery}
              handleRecipes={handleRecipes}
              onChangeFilter={onChangeFilter}
              updateTableData={updateTableData}
              onSearchChange={onSearchChange}
              handleCustomTableChange={handleCustomTableChange}
              searchHandler={searchHandler}
            />
          ) : (
            <CommonTab
              state={states.templateDetails}
              templates={templates}
              handleOptionChangeRecipeBatch={handleOptionChangeRecipeBatch}
              handleOptionChangeRecipe={handleOptionChangeRecipe}
            />
          )
        }
        tableFooter={
          !states.isCreate ? (
            <Stack
              direction="row"
              justifyContent="flex-end"
              spacing={2}
              padding="10px"
              marginTop="auto"
            >
              <Box sx={{ alignContent: "center" }}>
                <FormHelperText sx={{ color: "#C60808" }}>
                  {validator.current.message(
                    "Recipe field",
                    batchFilter1,
                    "Item"
                  )}
                </FormHelperText>
              </Box>
              <Button
                key="cancel"
                variant="outlined"
                onClick={() => handleOpenClickDetailCopy(false)}
              >
                Cancel
              </Button>

              <Button
                key="generate"
                variant="contained"
                disabled={generateBatch?.isPending || copyBatch?.isPending}
                onClick={() => {
                  onCreateSubmit(templates.updateValue);
                }}
              >
                Generate Batches
              </Button>
            </Stack>
          ) : (
            <Stack
              direction="row"
              justifyContent="flex-end"
              spacing={2}
              padding="10px"
            >
              <Button
                key="cancel"
                variant="outlined"
                onClick={() => handleOpenClickDetailCopy(false)}
              >
                Cancel
              </Button>

              <Button
                key="generate"
                variant="contained"
                disabled={createTemplateName?.isPending}
                onClick={() => {
                  onSubmit();
                }}
              >
                Continue
              </Button>
            </Stack>
          )
        }
      />
      <CustomeDialog
        PaperProps={{
          sx: {
            minWidth: "400px",
            minHeight: "200px",
          },
        }}
        closeIcon={false}
        open={states?.isErrorMessage}
        title={
          <Stack
            direction="row"
            justifyContent="space-between"
            position="sticky"
            zIndex={999}
            bgcolor="#fff"
            top={0}
          >
            <Stack direction="row" alignItems="center" ml={2}>
              <img src="/icons/ic_pair_dialog.svg" />
              <Typography
                fontWeight={500}
                variant="body1"
                padding="15px"
                fontSize={20}
              >
                Unable to Change Status
              </Typography>
            </Stack>
          </Stack>
        }
        content={
          <TemplateStatusChangeDialog
            state={states}
            handleClose={handleClose}
          />
        }
      />
    </>
  );
};
