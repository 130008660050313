import React from "react";
import { Route, Routes } from "react-router-dom";
import { StoresList } from "./containers";
import { StoreDetail } from "./containers/StoreDetail";

export const Store = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<StoresList />} />
        <Route>
          <Route path="/details/:id" element={<StoreDetail />} />
        </Route>
      </Routes>
    </>
  );
};
