import {
  Box,
  InputAdornment,
  OutlinedInput,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

import ascendingIcon from "../../assets/images/Icons/ascending.png";
import descendingIcon from "../../assets/images/Icons/descending.png";
import emptyIcon from "../../assets/images/Icons/search-table.png";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import CloseIcon from "@mui/icons-material/Close";
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import React, { useEffect, useState } from "react";
import { Loader } from "./Loader";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useDebouncedCallback } from "use-debounce";
import update from "immutability-helper";

export const CustomDndTable = (props) => {
  const {
    defaultData,
    columns,
    headerText,
    rightContent,
    subRow = null,
    customRowStyle = null,
    tableHeaderContent = null,
    isHeaderStyleChange = false,
    isLoading = false,
    isPagination = true,
    isTableHeader = true,
    isTableHead = true,
    tableFooter,
    tableStyle,
    onSearchChange,
    hideEmptyContent = false,
    updateTableData,
    disableDrag = false,
    onRowOrderChange,
    validator = null,
    searchText = null,
  } = props;

  const [records, setRecords] = React.useState([]);
  useEffect(() => {
    setRecords(defaultData || []);
  }, [defaultData]);
  const getRowId = React.useCallback((row, i) => {
    return row.rowID;
  }, []);
  const table = useReactTable({
    data: records,
    columns,
    getRowId,
    getSubRows: (row) => row[subRow] || [],
    getCoreRowModel: getCoreRowModel(),
    onGlobalFilterChange: onSearchChange,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    meta: {
      updateData: updateTableData,
    },
    manualPagination: true,
  });
  const [state, setState] = useState(table.initialState);
  table.setOptions((prev) => ({
    ...prev,
    state,
    onStateChange: setState,
  }));

  const debouncedRowUpdate = useDebouncedCallback(
    // function
    (value) => {
      onRowOrderChange(value);
    },
    // delay in ms
    1000
  );

  const moveRow = (dragIndex, hoverIndex) => {
    const dragRecord = records[dragIndex];
    const updatedRecords = update(records, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, dragRecord],
      ],
    });
    setRecords(updatedRecords);
    debouncedRowUpdate(updatedRecords);
  };

  return (
    <>
      {validator && validator.current.purgeFields()}
      <Stack
        sx={{
          borderRadius: "4px",
          bgcolor: "#fff",
          overflow: "hidden",
          ...tableStyle,
        }}
      >
        {isTableHeader && (
          <TableHeader
            // setGlobalFilter={(value) =>
            //   setState({ ...state, globalFilter: value })
            // }
            setGlobalFilter={onSearchChange}
            headerText={headerText}
            rightContent={rightContent}
            isHeaderStyleChange={isHeaderStyleChange}
            searchText={searchText}
          />
        )}
        {tableHeaderContent && (
          <TableHeaderContent tableHeaderContent={tableHeaderContent} />
        )}
        <DndProvider backend={HTML5Backend}>
          {isLoading ? (
            <Box
              sx={{
                padding: "10px",
              }}
            >
              <Loader />
            </Box>
          ) : (
            <TableContainer
              component={Paper}
              sx={{ borderRadius: "0 0 4px 4px", overflow: "hidden" }}
            >
              <Table>
                {isTableHead && (
                  <TableHead>
                    {table.getHeaderGroups().map((headerGroup) => (
                      <TableRow key={headerGroup.id}>
                        {!disableDrag && (
                          <TableCell padding="checkbox"></TableCell>
                        )}
                        {headerGroup.headers.map((header) => {
                          return (
                            <TableCell
                              key={header.id}
                              colSpan={header.colSpan}
                              align={"right"}
                              sx={{
                                padding: "10px 8px",
                                backgroundColor: "#F5F8FC",
                              }}
                            >
                              {header.isPlaceholder ? null : (
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "4px",
                                    justifyContent:
                                      header?.column?.columnDef?.align ===
                                      "right" && "flex-end",
                                  }}
                                  {...{
                                    className: header.column.getCanSort()
                                      ? "cursor-pointer select-none"
                                      : "",
                                    onClick:
                                      header.column.getToggleSortingHandler(),
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontWeight: 600,
                                      fontSize: "12px",
                                      lineHeight: "14px",
                                    }}
                                  >
                                    {flexRender(
                                      header.column.columnDef.header,
                                      header.getContext()
                                    )}
                                  </Typography>

                                  {{
                                    asc: (
                                      <img
                                        alt="asc"
                                        src={ascendingIcon}
                                        width={14}
                                        height={14}
                                      />
                                    ),
                                    desc: (
                                      <img
                                        alt="desc"
                                        src={descendingIcon}
                                        width={14}
                                        height={14}
                                      />
                                    ),
                                  }[header.column.getIsSorted()] ?? null}
                                </Box>
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    ))}
                  </TableHead>
                )}
                <TableBody>
                  {table.getFilteredRowModel().rows.length > 0 ? (
                    table.getRowModel().rows.map((row, index) => {
                      return (
                        <Row
                          key={row.id}
                          index={index}
                          disableDrag={disableDrag}
                          row={row}
                          moveRow={moveRow}
                        // {...row.getRowProps()}
                        />
                      );
                    })
                  ) : !hideEmptyContent ? (
                    <EmptyComponent />
                  ) : (
                    <></>
                  )}
                </TableBody>
              </Table>
              {isPagination && table.getFilteredRowModel().rows.length > 0 && (
                <TablePagination
                  rowsPerPageOptions={[8, 10, 20, 30, 40, 50]}
                  component="div"
                  count={table.getFilteredRowModel().rows.length}
                  rowsPerPage={table.getState().pagination.pageSize}
                  page={table.getState().pagination.pageIndex}
                  onPageChange={(e, page) => {
                    table.setPageIndex(page);
                  }}
                  onRowsPerPageChange={(e) => {
                    table.setPageSize(Number(e.target.value));
                  }}
                />
              )}
              {tableFooter && <>{tableFooter}</>}
            </TableContainer>
          )}
        </DndProvider>
      </Stack>
    </>
  );
};

const TableHeader = ({
  setGlobalFilter,
  headerText,
  rightContent,
  isHeaderStyleChange,
  searchText
}) => {
  return (
    <Stack
      sx={{
        padding: "16px 12px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        bgcolor: isHeaderStyleChange && "#111C2B",
        color: isHeaderStyleChange && "#fff",
      }}
    >
      <Typography
        sx={{
          fontWeight: 500,
          fontSize: "18px",
        }}
      >
        {headerText}
      </Typography>
      <OutlinedInput
        onChange={(e) => setGlobalFilter(e.target.value)}
        value={searchText}
        sx={{
          height: "36px",
          borderRadius: "10px",
          width: "200px",
          backgroundColor: isHeaderStyleChange ? "#313C4C" : "#F2F7FC",
          borderColor: isHeaderStyleChange && "#485364",
          "&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: isHeaderStyleChange && "#485364",
          },
          "& input::placeholder": {
            color: isHeaderStyleChange && "#ccc",
          },
          "& input": {
            p: " 12.5px 14px 12.5px 4px !important",
          },
        }}
        placeholder="Search"
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon size="small" />
          </InputAdornment>
        }
        endAdornment={
          searchText && <InputAdornment sx={{ cursor: "pointer" }} position="end">
            <CloseIcon size="large" onClick={(e) => setGlobalFilter("")} />
          </InputAdornment>
        }
      />
      {rightContent}
    </Stack>
  );
};

const EmptyComponent = () => {
  return (
    <TableRow>
      <TableCell
        align="center"
        colSpan={20}
        height={162}
        sx={{ py: 2, padding: "32px 0" }}
      >
        <Stack alignItems="center" gap="8px">
          <img src={emptyIcon} alt="empty" height={56} width={56} />
          <Typography
            sx={{ fontWeight: 400, fontSize: "14px", color: "#485364" }}
          >
            No data
          </Typography>
        </Stack>
      </TableCell>
    </TableRow>
  );
};

const TableHeaderContent = ({ tableHeaderContent }) => {
  return <Box p="8px 16px">{tableHeaderContent}</Box>;
};

const DND_ITEM_TYPE = "row";

const Row = ({ row, index, moveRow, disableDrag }) => {
  const dropRef = React.useRef(null);
  const dragRef = React.useRef(null);

  const [, drop] = useDrop({
    accept: DND_ITEM_TYPE,
    hover(item, monitor) {
      if (!dropRef.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = dropRef.current.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveRow(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: DND_ITEM_TYPE,
    item: () => ({ index }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;

  preview(drop(dropRef));
  drag(dragRef);
  return (
    <TableRow ref={dropRef} style={{ opacity }}>
      {!disableDrag && (
        <TableCell
          padding={"normal"}
          sx={{
            borderBottom: "1px solid #E6E6E6",
            p: "2px",
          }}
          ref={dragRef}
        >
          <DragIndicatorIcon sx={{ height: 20 }} />
        </TableCell>
      )}
      {row.getVisibleCells().map((cell, index) => {
        return (
          <TableCell
            key={cell.id}
            padding={cell.column.padding || "normal"}
            sx={{
              borderBottom: "1px solid #DFE8F2",
              p: !cell.column.padding && "4px",
            }}
            align={cell.column.id === "actions" ? "right" : "left"}
          >
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </TableCell>
        );
      })}
    </TableRow>
  );
};
