import { Routes, Route } from "react-router-dom";
import {
  DashboardLayout,
  PageNotFound,
  ProtectedRoute,
  UnAuthorised,
} from "../../shared";
import {
  MasterData,
  Recipe,
  Ingredients,
  Templates,
  ProductionChart,
  Devices,
  TipsAndTricks,
  RolesAndPermissions,
  ManageUsers,
  Store,
  RecipeDashboard,
} from "../../modules";

export function SuperAdminDashboard() {
  return (
    <div id="app">
      <DashboardLayout>
        <div id="main-content">
          <Routes>
            <Route
              element={<ProtectedRoute allowedRoute={["dashboard-view"]} />}
            >
              <Route path="/" element={<RecipeDashboard />} />
            </Route>
            <Route
              element={
                <ProtectedRoute
                  allowedRoute={[
                    "batchgroup-view",
                    "wastage-chart-view",
                    "unit-view",
                    "category-view",
                    "subcategory-view",
                  ]}
                />
              }
            >
              <Route path="/master-data" element={<MasterData />} />
            </Route>

            <Route element={<ProtectedRoute allowedRoute={["recipe-view"]} />}>
              <Route path="/recipe/*" element={<Recipe />} />
            </Route>
            <Route
              element={<ProtectedRoute allowedRoute={["ingredient-view"]} />}
            >
              <Route path="/ingredients/*" element={<Ingredients />} />
            </Route>
            <Route
              element={
                <ProtectedRoute allowedRoute={["production-chart-view"]} />
              }
            >
              <Route
                path="/production-charts/*"
                element={<ProductionChart />}
              />
            </Route>
            <Route
              element={<ProtectedRoute allowedRoute={["template-view"]} />}
            >
              <Route path="/templates/*" element={<Templates />} />
            </Route>
            <Route element={<ProtectedRoute allowedRoute={["device-view"]} />}>
              <Route path="/devices/*" element={<Devices />} />
            </Route>
            <Route
              element={<ProtectedRoute allowedRoute={["Tips&Tricks-view"]} />}
            >
              <Route path="/tips-tricks/*" element={<TipsAndTricks />} />
            </Route>

            <Route element={<ProtectedRoute allowedRoute={["role-view"]} />}>
              <Route
                path="/roles-and-permissions/*"
                element={<RolesAndPermissions />}
              />
            </Route>
            <Route element={<ProtectedRoute allowedRoute={["store-view"]} />}>
              <Route path="/store/*" element={<Store />} />
            </Route>
            <Route element={<ProtectedRoute allowedRoute={["user-view"]} />}>
              <Route path="/manage-users/*" element={<ManageUsers />} />
            </Route>
            <Route exact element={<UnAuthorised />} path={"/unauthorized"} />
            <Route exact element={<PageNotFound />} path={"/404"} />
            <Route exact element={<PageNotFound />} path={"*"} />
          </Routes>
        </div>
      </DashboardLayout>
    </div>
  );
}
