import { Breadcrumbs, Stack, Link } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useLocation, useNavigate } from "react-router-dom";
import HomeIcon from "../../assets/images/Icons/Home.svg";

export const CustomBreadcrumbs = ({ breadcrumbs, showHomeIcon }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isStoreAdmin = pathname.includes("store-admin");
  return (
    <>
      {showHomeIcon && (
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            py: "11px",
            px: "16px",
            height: "40px",
            bgcolor: "#fff",
          }}
        >
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            <Link
              underline="hover"
              color="inherit"
              onClick={(e) =>
                isStoreAdmin ? navigate("/store-admin") : navigate("/")
              }
              style={{ display: "flex", alignItems: "center" }}
            >
              <img src={HomeIcon} width={24} height={24} alt="logo" />
            </Link>

            {breadcrumbs}
          </Breadcrumbs>
        </Stack>
      )}
    </>
  );
};
