import { useEffect, useRef, useState } from "react";
import SimpleReactValidator from "simple-react-validator";
import { useImmer } from "use-immer";
import { useCostAndPriceQueries } from "./useCostAndPriceQueries";

export const useCostAndPrice = ({ ingredientQuery, recipeId }) => {
  const [, forceUpdate] = useState();
  const validator = useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },
    })
  );
  const costvalidator = useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },
    })
  );
  const { updateRetailPrice, updateOverHead } = useCostAndPriceQueries({
    recipeId,
  });
  const [state, setState] = useImmer({
    data: {},
    isEditoverHead: false,
    isEditPrice: false,
  });

  useEffect(() => {
    if (ingredientQuery.isSuccess) {
      setState((draft) => {
        draft.data = ingredientQuery.data;
      });
    }
  }, [ingredientQuery.isSuccess]);

  const onRetailPriceChange = (e) => {
    const { name, value } = e.target;
    const taxRate = state.data.recipe_tax;
    const taxAmount = (Number(value) * Number(taxRate)) / 100;
    if (name === "retailPriceExcGst") {
      const incGst = Number(value) + taxAmount;
      setState((draft) => {
        draft.data.retailPriceInGst = incGst;
      });
    } else if (name === "retailPriceInGst") {
      const excGst = Number(value) - taxAmount;
      setState((draft) => {
        draft.data.retailPriceExcGst = excGst;
      });
    }
    setState((draft) => {
      draft.data[name] = value;
    });
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setState((draft) => {
      draft.data[name] = value;
    });
  };
  const onEditClick = (name) => {
    setState((draft) => {
      draft[name] = !draft[name];
    });
  };

  const updateCostAndProfit = (data) => {
    setState((draft) => {
      draft.data.profit_percentage = data.profit_percentage;
      draft.data.cost_percentage = data.cost_percentage;
    });
  };

  const onUpdateRetailPriceClick = () => {
    if (validator.current.allValid()) {
      const payload = {
        retailprice_inc_tax: state.data.retailPriceInGst,
        retailprice_exc_tax: state.data.retailPriceExcGst,
      };
      updateRetailPrice.mutate({
        recipeId,
        payload,
        onEditClick,
        updateCostAndProfit,
      });
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  const onUpdateOverHead = (e) => {
    if (costvalidator.current.allValid()) {
      const payload = {
        overhead_cost: state.data.overhead_cost,
        products_count: state.data.products_count,
      };
      updateOverHead.mutate({
        recipeId,
        payload,
        onEditClick,
        updateCostAndProfit,
      });
    } else {
      costvalidator.current.showMessages();
      forceUpdate(1);
    }
  };

  return {
    state,
    onChange,
    validator,
    onEditClick,
    onUpdateRetailPriceClick,
    onRetailPriceChange,
    costvalidator,
    onUpdateOverHead,
  };
};
