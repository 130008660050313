import { Box, InputAdornment, Stack, Typography } from "@mui/material";
import React, { useMemo, useRef, useState } from "react";
import { CustomTable, InputControl, SelectChip } from "../../../shared";
import { createColumnHelper } from "@tanstack/react-table";
import CloseIcon from "@mui/icons-material/Close";

export const RegenerateTemplate = ({
  categorylistQuery,
  templates,
  handleRecipes,
  onChangeFilter,
  updateTableData,
  onSearchChange,
  handleCustomTableChange,
  searchHandler,
}) => {
  const categoryRef = useRef(null);
  const handleHorizantalScroll = (element, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
    }, speed);
  };
  const TableCell = ({ row, column, table }) => {
    const [value, setValue] = useState(row.original.wastage);

    const onBlur = () => {
      table.options.meta?.updateData(
        row.index,
        column.id,
        value,
        row.original,
        "wastage"
      );
    };
    return (
      !!row.depth && (
        <>
          <InputControl
            type="number"
            sx={{
              selfAlign: "right",
              width: "134px",
            }}
            size="small"
            value={value}
            onChange={(e) => {
              const inputValue = e.target.value;
              if (/^\d*\.?\d*$/.test(inputValue)) {
                setValue(inputValue);
              }
            }}
            onBlur={onBlur}
            onFocus={(e) => {
              if (e.target.value === "0") {
                setValue("");
              }
            }}
          />
        </>
      )
    );
  };
  const TableCell2 = ({ row, column, table }) => {
    const [value, setValue] = useState(row.original.quantity);

    const onBlur = () => {
      table.options.meta?.updateData(
        row.index,
        column.id,
        value,
        row.original,
        "quantity"
      );
    };
    return (
      !!row.depth && (
        <>
          <InputControl
            type="number"
            sx={{
              selfAlign: "right",
              width: "134px",
            }}
            size="small"
            value={value}
            onChange={(e) => {
              const inputValue = e.target.value;
              if (/^\d*\.?\d*$/.test(inputValue)) {
                setValue(inputValue);
              }
            }}
            onBlur={onBlur}
            onFocus={(e) => {
              if (e.target.value === "0") {
                setValue("");
              }
            }}
          />
        </>
      )
    );
  };

  const columnHelper = createColumnHelper(handleRecipes());
  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        header: () => "Product",
        cell: (props) => {
          const { row } = props;
          return !row.depth ? (
            <Typography
              sx={{
                color: "#1760D2",
                textTransform: "uppercase",
              }}
            >
              {row.original.subcategory_name}
            </Typography>
          ) : (
            row.original.name
          );
        },
      }),
      columnHelper.accessor("selling_unit_name", {
        header: () => "Selling Unit",
        align: "right",
        cell: (props) => {
          const { row } = props;
          return !row.depth ? "" : row?.original?.selling_unit_name;
        },
      }),

      columnHelper.display({
        id: "actions",
        header: () => "Quantity",
        align: "right",
        cell: TableCell2,
      }),
      columnHelper.display({
        id: "actions",
        header: () => "Wastage",
        align: "right",
        cell: TableCell,
      }),
    ],
    [templates.datas, templates.filter.search]
  );

  return (
    <>
      <CustomTable
        defaultData={handleCustomTableChange(templates.recipeList)}
        columns={columns}
        subRow={"subRows"}
        allRowsExpanded={true}
        updateTableData={updateTableData}
        isPagination={false}
        showSearchBar={false}
        manualPagination={true}
        tableHeaderStyles={{ padding: "8px 6px" }}
        customRowStyle={(row) => {
          return !row.depth ? { borderBottom: "none" } : null;
        }}
        rightContent={
          <Stack
            direction="row"
            gap="10px"
            marginRight={50}
            alignItems="center"
          >
            <InputControl
              sx={{ width: "200px", borderRadius: "10px" }}
              type="table-search"
              placeholder="Search"
              onChange={(e) => onSearchChange(e.target.value)}
              value={templates.filter.search}
              endAdornment={
                templates.filter.search ? (
                  <InputAdornment sx={{ cursor: "pointer" }} position="end">
                    <CloseIcon
                      size="large"
                      onClick={() => searchHandler({ target: { value: "" } })}
                    />
                  </InputAdornment>
                ) : (
                  ""
                )
              }
            />
            <Box
              height="45px"
              alignContent="center"
              onClick={() =>
                handleHorizantalScroll(categoryRef.current, 50, 100, -10)
              }
            >
              <img alt="details" src="/icons/Intersect.png" />
            </Box>
            <Stack
              direction="row"
              width="725px"
              overflow="auto"
              gap={1}
              className="scroll-hide"
              ref={categoryRef}
            >
              {categorylistQuery?.data?.map((v, i) => (
                <SelectChip
                  key={i}
                  value={v}
                  isSelected={
                    templates.filter.category
                      ? templates.filter.category == v.id
                      : i === 0
                  }
                  onClick={() => onChangeFilter(v)}
                />
              ))}
            </Stack>
            <Box
              height="45px"
              alignContent="center"
              onClick={() =>
                handleHorizantalScroll(categoryRef.current, 50, 100, 10)
              }
            >
              <img alt="details" src="/icons/Intersect1.png" />
            </Box>
          </Stack>
        }
      />
    </>
  );
};
