import React from "react";
import { Route, Routes } from "react-router-dom";
import { AddOrEditDevices, DevicesDetails, DevicesList } from "./containers";

export const Devices = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<DevicesList />} />
        <Route>
          <Route path="/details/:id" element={<DevicesDetails />} />
        </Route>
        <Route>
          <Route path="/create" element={<AddOrEditDevices />} />
        </Route>
        <Route>
          <Route path="/edit/:id" element={<AddOrEditDevices />} />
        </Route>
      </Routes>
    </>
  );
};
