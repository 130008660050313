import { Button, Paper, Stack, Typography } from "@mui/material";
import React from "react";

function AddDeviceDialog({
  handleAddDeviceDialogOpen,
  handlePairingStatus,
  state,
}) {
  return (
    <Paper>
      <Stack position="sticky" bgcolor="#fff">
        <Stack alignItems="center" justifyContent="center" mt={1} flex={1}>
          <img src="/icons/ic_device_tick.svg" />
          <Typography
            fontWeight={500}
            variant="body1"
            padding="15px"
            fontSize={20}
            textAlign="center"
          >
            Device Added Successfully!
          </Typography>
        </Stack>
      </Stack>
      <Stack padding="16px 24px" spacing={2} alignItems="center">
        <Typography fontWeight={600} fontSize={"16px"} component="div">
          Device Code:
        </Typography>
        <Typography variant="h4" sx={{ my: 2 }} letterSpacing={10}>
          {state?.code}
        </Typography>
        <Typography align="center">
          Your device has been successfully added. You can use this code to
          initiate the pairing process.
        </Typography>
        <Stack direction="row" alignSelf="end" gap={1}>
          <Button
            variant="outlined"
            width="78px"
            color="gray2"
            onClick={() => handleAddDeviceDialogOpen(false)}
          >
            Close
          </Button>
          <Button
            variant="contained"
            width="78px"
            disabled={false}
            onClick={() => handlePairingStatus()}
          >
            OK
          </Button>
        </Stack>
      </Stack>
    </Paper>
  );
}

export default AddDeviceDialog;
