import { useQuery, keepPreviousData } from "@tanstack/react-query";
import qs from "qs";
import { useStoreKeys } from "../../Recipe/hooks/useStoreKeys";
import { getAssignedStoreListTemplate } from "../api/TemplatesApi";

export const useAssignedStoreListTemplatesQueries = ({
  templateId,
  debouncedText,
  pagination,
  sorting,
}) => {
  const { storeKeys } = useStoreKeys();

  let params = {};
  if (debouncedText) {
    params = { ...params, search: debouncedText };
  }

  if (sorting?.length > 0) {
    const sortByParams = sorting.reduce(
      (obj, item) =>
        Object.assign(obj, { [item.id]: item.desc ? "desc" : "asc" }),
      {}
    );
    params = {
      ...params,
      sort: sortByParams,
    };
  }

  const query = {
    params,
    paramsSerializer: (params) => qs.stringify(params, { encode: true }),
  };

  //list query
  const assingedStoreListQueryTemplate = useQuery({
    queryKey: storeKeys.storelist({
      query,
      pageIndex: pagination.pageIndex,
      pageSize: pagination.pageSize,
    
    }),
    queryFn: () => getAssignedStoreListTemplate({templateId, query, pagination}),
    placeholderData: keepPreviousData,
  });

  return { assingedStoreListQueryTemplate };
};
