import { Button, Link, Stack, Typography } from "@mui/material";
import React from "react";
import {
  CustomDetailsList,
  CustomeDialog,
  DeleteModal,
  Loader,
  PageListLayout,
} from "../../../shared";
import { useNavigate, useParams } from "react-router-dom";
import UnpairDeviceDialog from "../components/UnpairDeviceDialog";
import { useDevices } from "../hooks";
import AddDeviceDialog from "../components/AddDeviceDialog";

export const DevicesDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    devices,
    onDelete,
    deviceDetails,
    handleOpenDialog,
    handlePairingStatus,
    handleAddDeviceDialogOpen,
    resetDeleteInfo,
    confirmDelete,
    deleteInfo,
    isEditable,
    isDeletable,
    isUnpairDevice,
  } = useDevices({
    deviceId: id,
    isList: false,
  });
  const breadcrumbs = [
    <Link
      underline="none"
      key="1"
      color="#1760D2"
      onClick={(e) => navigate("/devices")}
      sx={{ fontSize: "14px", fontWeight: 400 }}
    >
      Devices
    </Link>,
    <Typography
      key="2"
      color="inherit"
      sx={{ fontSize: "14px", fontWeight: 400 }}
    >
      {devices?.data?.name}
    </Typography>,
  ];
  const columns = [
    {
      title: "Device Name",
      accessor: (val) => (
        <Typography variant="subtitle2" fontWeight={500}>
          {val?.name}
        </Typography>
      ),
    },
    {
      title: "Device Code",
      accessor: (val) => (
        <Typography variant="subtitle2" fontWeight={500}>
          {val?.code}
        </Typography>
      ),
    },
    {
      title: "Store Name",
      accessor: (val) => (
        <Typography variant="subtitle2" fontWeight={500}>
          {val?.store_name}
        </Typography>
      ),
    },
    {
      title: "Pairing Status",
      accessor: (val) => {
        return (
          <Stack direction="row" gap="8px">
            {val?.paird_status === 0 ? (
              <img src="/icons/ic_device_unpair.svg" />
            ) : (
              <img src="/icons/ic_device_pair.svg" />
            )}

            {val?.paird_status ? "Paired" : "Unpaired"}
          </Stack>
        );
      },
    },
  ];
  return (
    <>
      <PageListLayout
        breadcrumbs={breadcrumbs}
        isChanged={true}
        pageContent={
          <>
            <Stack
              direction="row"
              justifyContent="space-between"
              height="62px"
              bgcolor="white"
              sx={{
                position: "sticky",
                zIndex: "99px",
                top: "0px",
              }}
            >
              <Stack alignSelf="center" direction="row" gap={2} ml={3}>
                <Typography variant="h6" fontWeight={600} fontSize="24px">
                  {devices?.data?.name}
                </Typography>
              </Stack>
              <Stack direction="row" gap={2} alignSelf="center" mr={2}>
                {devices?.data?.paird_status === 0
                  ? ""
                  : isUnpairDevice && (
                      <Button
                        sx="small"
                        onClick={(e) => handleOpenDialog(true, id)}
                        style={{
                          borderRadius: "10px",
                          padding: "3px 10px",
                          backgroundColor: "#DFE8F2",
                          color: "#111C2B",
                        }}
                      >
                        Unpair
                      </Button>
                    )}
                {isEditable && (
                  <img
                    alt="edit"
                    onClick={(e) => navigate(`/devices/edit/${id}`)}
                    src="/icons/ic_edit.png"
                    style={{ cursor: "pointer" }}
                  />
                )}
                {isDeletable && (
                  <img
                    alt="delete"
                    onClick={(e) => onDelete(id)}
                    src="/icons/ic_trash_sm.png"
                    style={{ cursor: "pointer" }}
                  />
                )}
              </Stack>
            </Stack>
            {deviceDetails?.isLoading ? (
              <Loader />
            ) : (
              <Stack sx={{ borderRadius: "4px", bgcolor: "#fff", px: "16px" }}>
                <CustomDetailsList
                  columns={columns}
                  data={devices?.data}
                  title="Basic Details"
                />
              </Stack>
            )}
          </>
        }
      />
      <CustomeDialog
        PaperProps={{
          sx: {
            minWidth: "500px",
          },
        }}
        closeIcon={false}
        open={devices?.open}
        title={
          <Stack
            direction="row"
            justifyContent="space-between"
            position="sticky"
            zIndex={999}
            bgcolor="#fff"
            top={0}
          >
            <Stack direction="row" alignItems="center" ml={2}>
              <img src="/icons/ic_pair_dialog.svg" />
              <Typography
                fontWeight={500}
                variant="body1"
                padding="15px"
                fontSize={20}
              >
                Unpair this device?
              </Typography>
            </Stack>
          </Stack>
        }
        content={
          <UnpairDeviceDialog
            state={devices?.data}
            handleOpenDialog={handleOpenDialog}
            handlePairingStatus={handlePairingStatus}
            deviceDetails={deviceDetails}
          />
        }
      />
      <CustomeDialog
        PaperProps={{
          sx: {
            width: "460px",
          },
        }}
        closeIcon={false}
        open={devices?.openDialog}
        content={
          <AddDeviceDialog
            state={devices?.data}
            handleAddDeviceDialogOpen={handleAddDeviceDialogOpen}
            handlePairingStatus={handlePairingStatus}
          />
        }
      />
      <DeleteModal
        open={deleteInfo?.show}
        handleClose={() => resetDeleteInfo()}
        onConfirm={() => confirmDelete()}
      />
    </>
  );
};
