import { useLocation, Link } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { Box, InputAdornment, Stack, TextField } from "@mui/material";
import { InputControl } from "../InputControl";
import { AuthContext } from "../../../store";

export const SAStoreSelection = ({ collapse }) => {
  const { pathname } = useLocation();
  const {
    auth: {
      data: { stores, selectedStoreId },
    },
    setAuth,
  } = useContext(AuthContext);



  const storeList = stores.map((item) => ({
    label: item.name,
    value: item.id,
  }));

  const onStoreChange = (e) => {
    const { value } = e;
    setAuth((draft) => {
      draft.data.selectedStoreId = value;
    });
    window.location.href = pathname;
  };

  return (
    <>
      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "center",
          gap: "8px",
          background: "#00000033",
          m: "0px 12px 16px",
          borderRadius: "8px",
          minHeight: "33px",
          justifyContent: "center",
          overflow:"hidden"
        }}
      >
        {!collapse ? <img alt="store icon" src="/icons/ic_store.svg" /> : (
          <InputControl
            type="dropdown"
            style={{ width: "100%", height: "32px" }}
            options={storeList}
            disableClearable
            value={
              (storeList?.length > 0 &&
                selectedStoreId &&
                storeList.find((item) => item.value === selectedStoreId)) ||
              null
            }
            includeInputInList
            onChange={(e, value) => onStoreChange(value)}
            filterSelectedOptions
            sx={{
              "& input": { px: "0px !important" },
              "& fieldset": { border: "none" },
              "& .MuiOutlinedInput-root": {
                padding: "6px 8px !important",
                height: "32px !important",
              },
            }}
            renderOption={(props, option) => {
              return (
                <Box
                  component="li"
                  {...props}
                >
                  {option.label}
                </Box>
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                sx={{
                  width: "100%",
                  backgroundColor: "rgba(0, 0, 0, 0.03)",
                  borderRadius: "8px",
                  color: "#fff",
                  ".MuiInputBase-root":
                  {
                    height: "44px",
                    color: "#fff"
                  },
                }}
                InputProps={{
                  ...params.InputProps,
                  style: { color: "#fff" },
                  startAdornment: (
                    <InputAdornment position="start">
                      <img alt="store icon" src="/icons/ic_store.svg" />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <img
                        alt="store icon"
                        height="20px"
                        src="/icons/ic_expand_content.png"
                      />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        )}
      </Stack>
    </>
  );
};
