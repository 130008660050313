import { API } from "../../../utils/api";

export const getWastageList = async ({ }) => {
  const res = await API.get(`wastages-list`);
  return res.data.data;
};

export const updateWastage = async (payload) => {
  const res = await API.put(`wastages-update`, payload);
  return res.data;
};


export const getCategoryList = async () => {
  const res = await API.get('categories/category-list');
  return res.data.data;
};