import React, { useCallback, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { Box, Stack } from "@mui/material";
import { Loader } from "./Loader";
import Icon from "../../assets/images/Icons/ic_edit-img.svg";

const baseStyle = {
  height: "86px",
  width: "86px",
  backgroundColor: "#0000000F",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "8px",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

export const StyledDropzone = (props) => {
  const { handleDropzone, imgUrl, loading, removeImage } = props;

  const onDrop = useCallback((acceptedFiles) => {
    handleDropzone(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({ onDrop, accept: { "image/*": [], "video/*": [] } });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  return (
    <div {...getRootProps({ style })}>
      <input {...getInputProps()} />
      {loading ? (
        <Loader />
      ) : imgUrl ? (
        <div className="image-container">
          <img src={imgUrl} alt="Avatar" className="image" />
          <div className="middle">
            <div
              className="text"
              onClick={(e) => {
                e.stopPropagation();
                removeImage();
              }}
            >
              Delete
            </div>
          </div>
        </div>
      ) : (
        <Stack
          sx={{
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box component={"img"} height={34} width={34} src={Icon} />
        </Stack>
      )}
    </div>
  );
};
