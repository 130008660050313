import { useMutation } from "@tanstack/react-query";
import { authentication } from "../api/authApi";
import { useContext } from "react";
import { AuthContext } from "../../../store";
import { ErrorFormatter, errorMessage } from "../../../utils";

export const useAuthQueries = () => {
  const { auth, setAuth } = useContext(AuthContext);

  const login = useMutation({
    mutationFn: authentication,
    onSuccess: (data, variables) => {
      if (data.success) {
        const selectedStoreId =
          data.data.stores.length > 0 ? data.data?.stores[0].id : null;
        setAuth((draft) => {
          draft.data = {
            ...data.data,
            selectedStoreId,
          };
        });
      } else {
        errorMessage(data?.message);
      }
      variables.AfterLogSuccess(data);
    },
    onError: (error) => {
      errorMessage(error.response.data?.message);
    },
  });
  return { login };
};
