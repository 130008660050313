import { Box, Tab, Tabs, Typography } from "@mui/material";
import { useEffect } from "react";
import { CustomTabPanel, PageListLayout, usePermission } from "../../../shared";

import {
  BatchGroup,
  CategoryAndSubCategory,
  Units,
  WastageChart,
} from "../components";
import {
  Navigate,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

const breadcrumbs = [
  <Typography key="3" sx={{ fontSize: "14px", fontWeight: 400 }}>
    Master Data
  </Typography>,
];

export const MasterData = () => {
  const isUnit = usePermission("unit-view");
  const isBatchGroup = usePermission("batchgroup-view");
  const isWastageChart = usePermission("wastage-chart-view");
  const isCategory = usePermission("category-view");
  const navigate = useNavigate();
  const { search } = useLocation();
  const handleChange = (event, newValue) => {
    if (isStoreAdmin) {
      navigate(`/store-admin/master-data/${newValue}`);
    } else {
      navigate(`/master-data/${newValue}`);
    }
  };

  const loc = useLocation();
  const isStoreAdmin = loc.pathname.includes("store-admin");

  useEffect(() => {
    if (
      isStoreAdmin &&
      loc.pathname == "/store-admin/master-data" &&
      loc.search === ""
    ) {
      navigate(`/store-admin/master-data/?batch-group`);
    }
  }, [loc]);

  return (
    <PageListLayout
      breadcrumbs={breadcrumbs}
      headerText="Master Data"
      pageContent={
        <>
          <Box
            className="sticky-Tab"
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              px: "16px",
              bgcolor: "#fff",
              top: "62px",
            }}
          >
            <Tabs value={search} onChange={handleChange}>
              {!isStoreAdmin && isCategory && (
                <Tab value={""} label="Category & Sub" />
              )}
              {!isStoreAdmin && isUnit && (
                <Tab value={"?units"} label="Units" />
              )}
              {isBatchGroup && (
                <Tab value={"?batch-group"} label="Batch-Group" />
              )}
              {isWastageChart && (
                <Tab value={"?wastage"} label="Wastage Chart" />
              )}
            </Tabs>
          </Box>
          <CustomTabPanel value={search} index={""}>
            {!isStoreAdmin && <CategoryAndSubCategory />}
          </CustomTabPanel>
          <CustomTabPanel value={search} index={"?units"}>
            <Units />
          </CustomTabPanel>
          <CustomTabPanel value={search} index={"?batch-group"}>
            <BatchGroup />
          </CustomTabPanel>
          <CustomTabPanel value={search} index={"?wastage"}>
            <WastageChart />
          </CustomTabPanel>
        </>
      }
    />
  );
};
