import { Box, Stack } from "@mui/material";
import React from "react";
import { CustomBreadcrumbs } from "./CustomBreadcrumbs";
import { PageHeader } from "./PageHeader";

export const PageListLayout = (props) => {
  const {
    showHomeIcon = true,
    pageContent,
    breadcrumbs,
    headerText,
    rightContent,
    isChanged = false,
  } = props;
  return (
    <Stack>
      <CustomBreadcrumbs
        breadcrumbs={breadcrumbs}
        showHomeIcon={showHomeIcon}
      />
      {(headerText || rightContent) && (
        <PageHeader
          headerText={headerText}
          rightContent={rightContent}
          isChanged={isChanged}
        />
      )}
      {pageContent}
    </Stack>
  );
};
