import {
  Autocomplete,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React, { forwardRef } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { Visibility, VisibilityOff } from "@mui/icons-material";

export const InputControl = forwardRef((props, ref) => {
  const {
    type,
    control,
    showPassword,
    onMouseDown,
    onClick,
    isDisabledKeys = [],
    ...rest
  } = props;
  let inputElement = null;
  switch (type) {
    case "text":
      inputElement = <TextField ref={ref} {...rest} />;
      break;
    case "number":
      inputElement = (
        <TextField
          ref={ref}
          {...rest}
          onWheel={(e) => e.target.blur()}
          onKeyDown={(evt) =>
            (["e", "+"].includes(evt.key) ||
              isDisabledKeys.includes(evt.key)) &&
            evt.preventDefault()
          }
        />
      );
      break;
    case "switch":
      inputElement = <Switch ref={ref} {...rest} />;
      break;
    case "checkbox":
      inputElement = (
        <FormGroup ref={ref}>
          <FormControlLabel control={control} {...rest} />
        </FormGroup>
      );
      break;
    case "table-search":
      inputElement = (
        <FormGroup fullWidth variant="outlined" ref={ref}>
          <OutlinedInput
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon size="small" />
              </InputAdornment>
            }
            {...rest}
            sx={{
              ...rest.sx,
              height: "36px",
              borderRadius: "10px",
              width: "200px",
              backgroundColor: "#F2F7FC",
            }}
          />
        </FormGroup>
      );
      break;
    case "password":
      inputElement = (
        <FormControl fullWidth variant="outlined">
          <InputLabel id="demo-simple-select-helper-label">
            {rest?.label}
          </InputLabel>
          <OutlinedInput
            type={showPassword ? "text" : "password"}
            id="outlined-adornment-password"
            {...rest}
            ref={ref}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={onClick}
                  onMouseDown={onMouseDown}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
          <FormHelperText sx={{ color: "#C60808" }}>
            {rest.helperText}
          </FormHelperText>
        </FormControl>
      );
      break;
    case "datePicker":
      inputElement = (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            {...rest}
            renderInput={(params) => <TextField {...params} {...rest} />}
          />
        </LocalizationProvider>
      );
      break;
    case "dropdown": {
      let { variant, ...props } = rest;
      variant = props.multiple
        ? props?.value.length > 0
          ? "filter"
          : "outlined"
        : props?.value
        ? "filter"
        : "outlined";
      inputElement = <Autocomplete ref={ref} {...props} variant={variant} />;
      break;
    }
    case "textfieldCheckbox": {
      const { label, sx, value, size } = rest;
      inputElement = (
        <FormControlLabel
          sx={{
            border: "1px solid #DFE8F2",
            p: "4px 10px",
            minWidth: "132px",
            borderRadius: "10px",
            gap: "10px",
            height: size === "small" ? "36px" : "42px",
            ...sx,

            ...(value
              ? {
                  background: "#111C2B",
                  color: "#ffffff",
                }
              : {}),
          }}
          control={control}
          label={
            <Typography
              variant="subtitle1"
              fontWeight={400}
              size={"16.94px"}
              lineHeight="16.94px"
            >
              {label}
            </Typography>
          }
        />
      );
      break;
    }
    default:
      inputElement = <TextField ref={ref} {...rest} type={type} />;
      break;
  }
  return <>{inputElement}</>;
});
