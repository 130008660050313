import { Box, Checkbox, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { InputControl } from "../../../shared/components/InputControl";

export const AddOrEditUnit = (props) => {
  const { onChange, state, validator } = props;
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        padding: "16px 20px !important",
      }}
    >
      <InputControl
        type="text"
        label="Unit Name"
        name="name"
        error={validator.current.message(
          "Unit Name",
          state.data.name,
          "required"
        )}
        helperText={validator.current.message(
          "Unit Name",
          state.data.name,
          "required"
        )}
        onChange={onChange}
        value={state?.data?.name}
        fullWidth
        required
        disabled={state?.data.is_default ? true : false}
      />
      <InputControl
        type="text"
        label="Unit Symbol"
        name="symbol"
        error={validator.current.message(
          "Unit Symbol",
          state.data.symbol,
          "required"
        )}
        helperText={validator.current.message(
          "Unit Symbol",
          state.data.symbol,
          "required"
        )}
        onChange={onChange}
        value={state?.data?.symbol}
        fullWidth
        required
        disabled={state?.data.is_default ? true : false}
      />
      <Stack direction={"row"} gap={"16px"}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
          <Typography
            sx={{ fontWeight: 500, fontSize: "16px", lineHeight: "19px" }}
          >
            Ignore Calculation?
          </Typography>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: "12px",
              lineHeight: "14px",
              color: "#657083",
            }}
          >
            This unit doesn't take to calculate if this option is on. Just for
            the recipe.
          </Typography>
        </Box>
        <InputControl
          type="switch"
          name="ignore"
          onChange={(e) => {
            onChange({
              target: {
                name: "ignore",
                value: e.target.checked,
              },
            });
          }}
          checked={state?.data?.ignore}
          {...{ inputProps: { "aria-label": "Switch demo" } }}
          defaultChecked
          disabled={state?.data.is_default ? true : false}
        />
      </Stack>
      {/* <Stack direction="row" gap="8px">
        <Grid container>
          <Grid item xs={6}>
            <InputControl
              type="checkbox"
              name="include_decimal_places"
              control={<Checkbox />}
              label="No. Of Decimal Places"
              checked={state?.data?.include_decimal_places}
              onChange={(e) => {
                onChange({
                  target: {
                    name: "include_decimal_places",
                    value: e.target.checked,
                  },
                });
              }}
              disabled={(state?.data.is_default)?true:false}
            />
          </Grid>
          <Grid item xs={6}>
            <InputControl
              label="No. Of Decimal Places"
              type="number"
              name="no_of_decimals"
              onChange={onChange}
              value={state?.data?.no_of_decimals}
              fullWidth
              error={validator.current.message(
                "No. Of Decimal Places",
                state.data.no_of_decimals,
                "regex:^[0-9]+$"
              )}
              helperText={validator.current.message(
                "No. Of Decimal Places",
                state.data.no_of_decimals,
                "regex:^[0-9]+$"
              )}
            />
            <Typography
              sx={{
                fontSize: "12px",
                color: "#A1ACC0",
                marginTop: "4px",
              }}
            >
              eg:0.001
            </Typography>
          </Grid>
        </Grid>
      </Stack> */}
    </Box>
  );
};
