import {
  Box,
  Checkbox,
  FormControlLabel,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { createColumnHelper } from "@tanstack/react-table";
import { CustomTable } from "../../../shared";

export const RolePermissionList = (props) => {
  const { state, handleChecked, handleCheckPermission } = props;
  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor("name", {
      header: "Permission Name",
      cell: (props) => {
        const { row } = props;
        return (
          <>
            <Stack direction="row" gap={1} onClick={() => row.toggleExpanded()}>
              {!row.depth ? (
                <>
                  <img
                    alt="forward"
                    src={
                      row.getIsExpanded()
                        ? "/icons/ic_direction_arrows.svg"
                        : "/icons/Intersect.svg"
                    }
                  />
                  <Typography fontWeight={500} fontSize="16px">
                    {row.original.name}
                  </Typography>
                </>
              ) : (
                <Stack direction="row">
                  <Box width="180px">
                    <Typography ml={3} fontSize="14px" fontWeight={400} mt={1}>
                      {row.original.display_name}
                    </Typography>
                  </Box>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={handleChecked(
                          row.depth,
                          row.original.id,
                          row.originalSubRows
                        )}
                        disabled
                        color="primary"
                        sx={{ "& .MuiSvgIcon-root": { fontSize: 24 } }}
                        onChange={(e) => {
                          handleCheckPermission(e, row, "body");
                        }}
                      />
                    }
                  />
                </Stack>
              )}
            </Stack>
          </>
        );
      },
    }),
  ];
  return (
    <>
      <Stack sx={{ p: "10px 10px" }}>
        <Typography variant="subtitle1" fontWeight={600}>
          Permissions
        </Typography>
        <Paper>
          <CustomTable
            columns={columns}
            defaultData={state?.allPermissionsList ?? []}
            isPagination={false}
            showSearchBar={false}
            subRow={"permissions"}
            manualPagination={true}
          />
        </Paper>
      </Stack>
    </>
  );
};
