import {
  Button,
  Grid,
  Stack,
  TextField,
  Typography,
  Link,
} from "@mui/material";
import React from "react";
import { InputControl, Loader, PageListLayout } from "../../../shared";
import { useNavigate, useParams } from "react-router-dom";
import { useDevices } from "../hooks";

export const AddOrEditDevices = () => {
  const { id } = useParams();
  const {
    devices,
    onChange,
    validator,
    storeLists,
    onSubmit,
    createDevices,
    updateDevices,
    deviceDetails,
  } = useDevices({
    isList: false,
    isCreate: true,
    deviceId: id,
  });
  const navigate = useNavigate();

  const breadcrumbs = [
    <Link
      underline="none"
      key="1"
      color="#1760D2"
      onClick={(e) => navigate("/devices")}
      sx={{ fontSize: "14px", fontWeight: 400 }}
    >
      Devices
    </Link>,
    <Typography
      key="2"
      color="inherit"
      sx={{ fontSize: "14px", fontWeight: 400 }}
    >
      {id ? "Edit Device" : "Add New Device"}
    </Typography>,
  ];

  return (
    <>
      <PageListLayout
        breadcrumbs={breadcrumbs}
        headerText={id ? "Edit Device" : "Add New Device"}
        isChanged={false}
        rightContent={
          <Stack direction="row" gap="8px">
            <Button
              type="button"
              size="small"
              variant="contained"
              color="inherit"
              onClick={() => navigate(-1)}
            >
              Cancel
            </Button>
            <Button
              type="button"
              size="small"
              variant="contained"
              onClick={onSubmit}
              disabled={updateDevices?.isPending || createDevices?.isPending}
            >
              {id ? "Update" : "Save"}
            </Button>
          </Stack>
        }
        pageContent={
          <>
            {deviceDetails?.isLoading ? (
              <Loader />
            ) : (
              <Stack sx={{ borderRadius: "4px", bgcolor: "#fff" }}>
                <Stack
                  sx={{
                    py: "32px",
                    borderBottom: "1px solid #DFE8F2",
                    mx: "20px",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <Typography
                        variant="subtitle1"
                        fontWeight={600}
                        sx={{ width: "150px" }}
                      >
                        Device Details
                      </Typography>
                    </Grid>
                    <Grid item sm={8}>
                      <Stack width={"100%"} gap="15px">
                        <InputControl
                          name="name"
                          type="text"
                          label="Device Name"
                          required
                          error={validator.current.message(
                            "Device Name",
                            devices?.data?.name,
                            "required"
                          )}
                          helperText={validator.current.message(
                            "Device Name",
                            devices?.data?.name,
                            "required"
                          )}
                          onChange={onChange}
                          value={devices?.data?.name}
                        />

                        <InputControl
                          required
                          sx={{
                            width: "100%",
                          }}
                          type="dropdown"
                          disableClearable
                          options={storeLists?.data ?? []}
                          getOptionLabel={(option) => option?.name}
                          value={
                            (storeLists?.data?.length > 0 &&
                              devices?.data?.store_id &&
                              storeLists?.data?.find(
                                (c) => c.id === devices?.data?.store_id
                              )) ||
                            null
                          }
                          disabled={id}
                          onChange={(e, newValue) => {
                            onChange({
                              target: {
                                name: "store_id",
                                value: newValue?.id,
                              },
                            });
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Store Name"
                              required
                              error={validator.current.message(
                                "Store Name",
                                devices?.data?.store_id,
                                "required"
                              )}
                              helperText={validator.current.message(
                                "Store Name",
                                devices?.data?.store_id,
                                "required"
                              )}
                            />
                          )}
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                </Stack>
              </Stack>
            )}
          </>
        }
      />
    </>
  );
};
