import { Button, Stack, Typography } from "@mui/material";
import React, { useMemo, useState } from "react";
import { InputControl, SelectChip, CustomTable } from "../../../shared";
import { createColumnHelper } from "@tanstack/react-table";
import { useWastage } from "../hooks";
import { useLocation } from "react-router-dom";

export const WastageChart = () => {
  const {
    state,
    categoriesListQuery,
    onSave,
    onChangeFilter,
    onSearchChange,
    updateTableData,
    validator,
    isWastageEdit,
  } = useWastage();

  const { pathname } = useLocation();
  const isStoreAdmin = pathname.includes("store-admin");

  const { data } = categoriesListQuery;

  const { dataList, isChanged, filter, isLoading } = state;
  const TableCell = ({ row, column, table }) => {
    const [value, setValue] = useState(row.original.wastage);

    const onBlur = () => {
      table.options.meta?.updateData(row.index, column.id, value, row.original);
    };
    return (
      !!row.depth && (
        <>
          <InputControl
            type="number"
            sx={{
              selfAlign: "right",
            }}
            value={value}
            disabled={isStoreAdmin}
            onChange={(e) => {
              const inputValue = e.target.value;
              if (/^\d*\.?\d*$/.test(inputValue)) {
                setValue(inputValue);
              }
            }}
            onBlur={(e) => {
              if (e.target.value === "") {
                setValue("0");
              } else {
                onBlur();
              }
            }}
            onFocus={(e) => {
              if (e.target.value === "0") {
                setValue("");
              }
            }}
            isDisabledKeys={["-"]}
            error={validator.current.message(
              "Wastage",
              value,
              "regex:^[0-9]+$"
            )}
            helperText={validator.current.message(
              "Wastage",
              value,
              "regex:^[0-9]+$"
            )}
          />
        </>
      )
    );
  };

  const columnHelper = createColumnHelper([]);
  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        header: () => "Product",
        cell: (props) => {
          const { row } = props;
          return !row.depth ? (
            <Typography
              sx={{
                color: "#1760D2",
                textTransform: "uppercase",
              }}
            >
              {row.original.subcategory_name}
            </Typography>
          ) : (
            row.original.name
          );
        },
      }),
      columnHelper.accessor("name", {
        header: () => "Selling Unit",
        cell: (props) => {
          const { row } = props;
          return <Typography>{row.original.selling_unit_symbol}</Typography>;
        },
      }),
      columnHelper.display({
        id: "actions",
        header: () => "Wastage",
        align: "right",
        cell: TableCell,
      }),
    ],
    [dataList]
  );

  return (
    <>
      <CustomTable
        emptyTableMessage="There is no wastage chart added yet,You need to add wastage chart first"
        defaultData={dataList}
        columns={columns}
        subRow={"subRows"}
        onSearchChange={onSearchChange}
        searchText={filter.search}
        tableId={"wastageChart"}
        updateTableData={updateTableData}
        headerText="Wastage Chart"
        isLoading={isLoading}
        allRowsExpanded={true}
        customRowStyle={(row) => {
          return !row.depth ? { borderBottom: "none" } : null;
        }}
        // isHeaderStyleChange={isChanged}
        tableHeaderContent={
          <Stack
            direction="row"
            gap="6px"
            sx={{
              overflowX: "auto",
              pb: "4px",
            }}
          >
            {data?.map((v, i) => (
              <SelectChip
                key={i}
                value={v}
                isSelected={filter.category == v.id}
                onClick={() => onChangeFilter(v)}
                countKey={"recipeCount"}
              />
            ))}
          </Stack>
        }
        rightContent={
          isWastageEdit && (
            <Button
              type="button"
              size="small"
              variant="contained"
              disabled={!isChanged}
              onClick={onSave}
            >
              Save
            </Button>
          )
        }
      />
    </>
  );
};
