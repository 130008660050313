import { API } from "../../../utils";

export const onUpdateRetailsPrice = async ({ recipeId, payload }) => {
  const res = await API.put(`recipes/${recipeId}/retail-prices`, payload);
  return res.data.data;
};

export const onUpdateOverHead = async ({ recipeId, payload }) => {
  const res = await API.put(`recipes/${recipeId}/overhead-cost`, payload);
  return res.data.data;
};




