import { useEffect, useState } from "react";
import { useDebounce } from "use-debounce";
import { useImmer } from "use-immer";
import { useAssignedStoreListTemplatesQueries } from "./useAssignedStoreListTemplatesQueries";
import { Loader } from "../../../shared";

export const useAssignedStoreListTemplates = ({
  templateId,
}) => {

  const [assignedStoreState, setAssignedStoreState] = useImmer({
    search: "",
    assignedStoreList: [],
    isLoading: false,
    alreadyAssignedStoreList: [],
  });

  const [pagination, setPagination] = useState({
    pageIndex: 1,
    pageSize: 50,
  });
  const [sorting, setSorting] = useState([]);
  const [debouncedText] = useDebounce(assignedStoreState.search, 1000);
  const { assingedStoreListQueryTemplate } =
    useAssignedStoreListTemplatesQueries({
      templateId,
      debouncedText,
      pagination: pagination,
      sorting,
    });
  
  useEffect(() => {
    setAssignedStoreState((draft) => {
      draft.isLoading = assingedStoreListQueryTemplate.isLoading;
    });
    if (Array.isArray(assingedStoreListQueryTemplate?.data?.templates)) {
      const _assignedStoreList = [];
      assingedStoreListQueryTemplate?.data.templates?.forEach((store) => {
        if (!_assignedStoreList.find((item) => item.id === store.state_id)) {
          _assignedStoreList.push({
            id: store.state_id,
            state: store.state_name,
            subRows: [],
          });
        }
      });

      if (_assignedStoreList) {
        assingedStoreListQueryTemplate.data.templates.forEach((store) => {
          const state = _assignedStoreList.find(
            (item) => item.id === store.state_id
          );
          if (state) {
            state.subRows.push({ id: store.id, storename: store.name });
          }
        });
      }
      const alreadyAssignedStoreIds =
        assingedStoreListQueryTemplate.data.templates.map((item) => {
          return item.id;
        });
      setAssignedStoreState((draft) => {
        draft.assignedStoreList = _assignedStoreList;
        draft.isLoading = assingedStoreListQueryTemplate.isLoading;
        draft.pagination = assingedStoreListQueryTemplate.data.pagination;
        draft.alreadyAssignedStoreList = alreadyAssignedStoreIds;
      });
    }
  }, [assingedStoreListQueryTemplate.data]);

  const onSearchHandler = (search) => {
    setAssignedStoreState((draft) => {
      draft.search = search;
    });
  };

  return {
    assignedStoreState,
    onSearchHandler,
    pagination,
    setPagination,
    assingedStoreListQueryTemplate,
    sorting,
    setSorting,
    setAssignedStoreState
  };
};
