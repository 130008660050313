import { useQuery, keepPreviousData } from "@tanstack/react-query";
import qs from "qs";
import { getBatchGroupList } from "../api/BatchGroupApi";

const batchgroupsKeys = {
  all: ["batchgroups"],
  lists: () => [...batchgroupsKeys.all, "batchgrouplist"],
  batchgrouplist: (filters) => [...batchgroupsKeys.lists(), filters],
};

export const useBatchGroupsQuery = ({ list }) => {
  //list query
  const listQuery = useQuery({
    queryKey: batchgroupsKeys.batchgrouplist(),
    queryFn: () => getBatchGroupList(),
    placeholderData: keepPreviousData,
    enabled: list,
  });
  return {
    listQuery,
  };
};
