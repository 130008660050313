import {
  useQueryClient,
  useQuery,
  useMutation,
  keepPreviousData,
} from "@tanstack/react-query";
import qs from "qs";
import { errorMessage, successMessage } from "../../../utils/helper";
import { useNavigate } from "react-router-dom";
import {
  getAdminUsers,
  saveUser,
  getUserList,
  getUserById,
  removeUser,
  getRolesList,
  removeRoleFromUser,
  changeMacIdSecurity,
} from "../api/adminUsersApi";

const adminUsersKeys = {
  all: ["adminUsers"],
  lists: () => [...adminUsersKeys.all, "adminUserslist"],
  adminUserslist: (filters) => [...adminUsersKeys.lists(), filters],
  userlist: () => [...adminUsersKeys.all, "UsersList"],
  rolelist: () => [...adminUsersKeys.all, "RolesList"],
  detail: (id) => [...adminUsersKeys.adminUserslist(), id],
};

export const useAdminUsersQueries = ({
  isList,
  pagination,
  sorting,
  userId,
  debouncedText,
  filterApplied,
  status,
  isAdd,
}) => {
  const queryClient = useQueryClient();
  let params = {};
  const storeId = filterApplied.map((item) => item?.id);
  const navigate = useNavigate();
  if (debouncedText) {
    params = { ...params, search: debouncedText };
  }
  if (status !== "") {
    params = { ...params, status: status };
  }
  if (storeId) {
    params = { ...params, stores: storeId };
  }

  if (sorting?.length > 0) {
    const sortByParams = sorting.reduce(
      (obj, item) =>
        Object.assign(obj, { [item.id]: item.desc ? "desc" : "asc" }),
      {}
    );
    params = {
      ...params,
      sort: sortByParams,
    };
  }
  const query = {
    params,
    paramsSerializer: (params) => qs.stringify(params, { encode: true }),
  };

  //list query

  const listQuery = useQuery({
    queryKey: adminUsersKeys.adminUserslist({ query, pagination }),
    queryFn: () => getAdminUsers({ query, pagination }),
    placeholderData: keepPreviousData,
    enabled: isList,
  });
  const userListQuery = useQuery({
    queryFn: () => getUserList(),
    queryKey: adminUsersKeys.userlist(),
    placeholderData: keepPreviousData,
    enabled: !!isAdd,
  });

  const rolesListQuery = useQuery({
    queryKey: adminUsersKeys.rolelist(),
    queryFn: () => getRolesList(),
    placeholderData: keepPreviousData,
    enabled: !!isAdd || !!userId,
  });

  const createUser = useMutation({
    mutationFn: ({ _, data, roleID }) => saveUser(roleID, data),
    onError: (error) => {
      errorMessage(error.response.data.errors);
    },
    onSuccess: (res, data) => {
      queryClient.invalidateQueries({
        queryKey: adminUsersKeys.lists(),
      });
      successMessage(res.message);
      navigate(`/manage-users/details/${data?.data?.user_id}`);
      data.handleModal("add-role", false);
    },
  });

  const userDetails = useQuery({
    queryKey: adminUsersKeys.detail(userId),
    queryFn: () => getUserById(userId),
    enabled: userId > 0,
  });

  const deleteUser = useMutation({
    mutationFn: removeUser,
    onError: (error) => {
      errorMessage(error?.response?.data?.error ?? "Error");
    },
    onSuccess: (res, variables) => {
      if (res.success) {
        queryClient.invalidateQueries({
          queryKey: adminUsersKeys.lists(),
        });
        successMessage(res.message);
        navigate(`/manage-users`);
      } else if (res.success === false) {
        errorMessage(res.message);
      }
    },
  });

  const deleteRoleFromUser = useMutation({
    mutationFn: ({ _, data, Id }) => removeRoleFromUser(Id, data),
    onError: (error) => {
      errorMessage(error?.response?.data?.error ?? "Error");
    },
    onSuccess: (res, variables) => {
      if (res.success) {
        queryClient.invalidateQueries({
          queryKey: adminUsersKeys.detail(userId),
        });
        successMessage(res.message);
      } else if (res.success === false) {
        errorMessage(res.message);
      }
      variables.handleModal();
    },
  });

  const changeMacIdSecurityStatus = useMutation({
    mutationFn: ({ id }) => changeMacIdSecurity(id),
    onError: (error) => {
      errorMessage(error?.response?.data?.error ?? "Error");
    },
    onSuccess: (res, variables) => {
      if (res.success) {
        queryClient.invalidateQueries({
          queryKey: adminUsersKeys.detail(userId),
        });
        successMessage(res.message);
      } else if (res.success === false) {
        errorMessage(res.message);
      }
      variables.handleModal();
    },
  });

  return {
    listQuery,
    userListQuery,
    createUser,
    userDetails,
    deleteUser,
    deleteRoleFromUser,
    rolesListQuery,
    changeMacIdSecurityStatus,
  };
};
