import { Typography, Stack, Box } from "@mui/material";
import { CustomTable } from "../../../shared";
import { createColumnHelper } from "@tanstack/react-table";
import StarIcon from "../../../assets/images/Icons/starIcon.svg";
import { formatWeightWithUnits } from "../../../utils";

export const ProductionChartRecipeDetails = (props) => {
  const { data } = props;
  const columnHelper = createColumnHelper();
  const columns2 = [
    columnHelper.accessor("recipe_name", {
      header: () => "Ingredients",
      enableSorting: false,
      cell: (props) => {
        const { row } = props;
        return (
          <Stack direction="row" alignItems="center" gap="10px">
            <Typography variant="subtitle2">
              {row?.original?.recipe_name
                ? row?.original?.recipe_name
                : row?.original?.ingredient_name}
            </Typography>
            {row?.original?.pivot?.is_main_ingredient === 1 && (
              <img
                src={StarIcon}
                alt="StarIcon"
                style={{ width: "13px", height: "14px" }}
              />
            )}
          </Stack>
        );
      },
    }),

    columnHelper.accessor("qty", {
      header: () => "Quantity",
      cell: ({ row }) => {
        return (
          <Typography color="#000000" fontWeight={500} fontSize="14px">
            {formatWeightWithUnits(
              row.original.pivot.base_unit_qty,
              row.original.pivot.base_unit_symbol,
              3
            )}
          </Typography>
        );
      },
      enableSorting: false,
      align: "right",
    }),
  ];

  return (
    <>
      {data.map((data, i) => (
        <Stack
          key={i}
          my={1}
          sx={{
            p: "8px",
            backgroundColor: "#F7FAFC",
            gap: "8px",
          }}
        >
          <Stack
            direction="row"
            sx={{
              gap: "8px",
              alignItems: "center",
            }}
          >
            <Typography fontWeight={500} fontSize="16px" ml={1}>
              {data.recipe_name}
            </Typography>
            <Box
              borderRadius="24px"
              bgcolor={"#DFE8F2"}
              height="20px"
              width="20px"
              alignContent="center"
              textAlign="center"
            >
              <Typography fontSize="12px" fontWeight={500}>
                {data.total_quantity}
              </Typography>
            </Box>
            <Typography color="#657083" variant="subtitle2">
              &#x2022; {data.template_name}
            </Typography>
          </Stack>
          <Box
            sx={{
              boxShadow: "0px 2px 4px 0px #0000000F",
              border: "1px solid #DFE8F2",
              borderRadius: "8px",
              overflow: "hidden",
            }}
          >
            <CustomTable
              defaultData={data.ingredients}
              columns={columns2}
              isPagination={false}
              isTableHeader={false}
              isHeaderColor={true}
            />
          </Box>
        </Stack>
      ))}
    </>
  );
};
