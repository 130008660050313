import { Button, Stack, TextField } from "@mui/material";
import { InputControl } from "../../../shared";
import { useRef, useState } from "react";
import SimpleReactValidator from "simple-react-validator";

export const AddRoleDialog = (props) => {
  const {
    onChange,
    onSubmit,
    state,
    handleClose,
    isDisabled,
    options,
  } = props;

  const [, forceUpdate] = useState();
  const validator = useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
  );


  const submit = () => {
    if (validator.current.allValid()) {
      onSubmit()
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  return (
    <div style={{ marginTop: "16px" }}>
      <InputControl
        required
        sx={{
          width: "100%",
        }}
        type="dropdown"
        disableClearable
        options={options ?? []}
        getOptionLabel={(option) => option?.display_name}
        value={
          (options?.length > 0 &&
            state?.editData?.role_id &&
            options?.find(
              (c) => c.id === state?.editData?.role_id
            )) ||
          null
        }
        onChange={(e, newValue) => {
          onChange({
            target: {
              name: "role_id",
              value: newValue?.id,
            },
          });
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Role"
            required
            error={validator.current.message(
              "Role",
              state?.editData?.role_id,
              "required"
            )}
            helperText={validator.current.message(
              "Role",
              state?.editData?.role_id,
              "required"
            )}
          />
        )}
      />
      <Stack
        direction="row"
        gap="8px"
        padding="30px 2px 10px 10px"
        marginTop="2px"
        sx={{ justifyContent: "flex-end" }}
      >
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={submit} disabled={isDisabled}>
          Save
        </Button>
      </Stack>
    </div>
  );
};
