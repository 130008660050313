import { Button, Stack, Typography, Box } from "@mui/material";
import React from "react";

function ActiveAndInactiveDialog({ errorState, handleClose }) {
  return (
    <Stack padding="16px 24px" height="100%">
      <Box>
        <Typography>{errorState?.errorData?.message}</Typography>
        {errorState?.errorData?.used_in_recipes?.length > 0 && (
          <>
            <Typography mt={2} fontWeight={500}>
              Recipes-
            </Typography>

            <Stack>
              {errorState?.errorData?.used_in_recipes
                ?.slice(0, 3)
                ?.map((template, index) => (
                  <Stack direction="row">
                    <Typography component="span" mr={1} fontSize="14px">
                      •
                    </Typography>
                    <Typography component="span" mr={1} fontSize="14px">
                      {template.name}
                    </Typography>
                  </Stack>
                ))}
              {errorState?.errorData?.used_in_recipes?.length > 3 && (
                <Typography fontWeight={500}>
                  +{errorState?.errorData?.used_in_recipes?.length - 3} More
                  Recipes
                </Typography>
              )}
            </Stack>
          </>
        )}
        {errorState?.errorData?.used_in_templates?.length > 0 && (
          <Typography mt={2} fontWeight={500}>
            Templates -
          </Typography>
        )}
        <Stack>
          {errorState?.errorData?.used_in_templates
            ?.slice(0, 3)
            ?.map((template, index) => (
              <Stack direction="row">
                <Typography component="span" mr={1} fontSize="14px">
                  •
                </Typography>
                <Typography component="span" mr={1} fontSize="14px">
                  {template.name}
                </Typography>
              </Stack>
            ))}
          {errorState?.errorData?.used_in_templates?.length > 3 && (
            <Typography fontWeight={500}>
              +{errorState?.errorData?.used_in_templates?.length - 3} More
              Templates
            </Typography>
          )}
        </Stack>
      </Box>
      <Box>
        {errorState?.errorData?.inactiveRecipes?.length > 0 && (
          <>
            <Typography mt={2} fontWeight={500}>
              Recipes-
            </Typography>

            <Stack>
              {errorState?.errorData?.inactiveRecipes
                ?.slice(0, 3)
                ?.map((template, index) => (
                  <Stack direction="row">
                    <Typography component="span" mr={1} fontSize="14px">
                      •
                    </Typography>
                    <Typography component="span" mr={1} fontSize="14px">
                      {template.name}
                    </Typography>
                  </Stack>
                ))}
              {errorState?.errorData?.inactiveRecipes?.length > 3 && (
                <Typography fontWeight={500}>
                  +{errorState?.errorData?.inactiveRecipes?.length - 3} More
                  Recipes
                </Typography>
              )}
            </Stack>
          </>
        )}
        {errorState?.errorData?.inactiveIngredients?.length > 0 && (
          <Typography mt={2} fontWeight={500}>
            Templates -
          </Typography>
        )}
        <Stack>
          {errorState?.errorData?.inactiveIngredients
            ?.slice(0, 3)
            ?.map((template, index) => (
              <Stack direction="row">
                <Typography component="span" mr={1} fontSize="14px">
                  •
                </Typography>
                <Typography component="span" mr={1} fontSize="14px">
                  {template.name}
                </Typography>
              </Stack>
            ))}
          {errorState?.errorData?.inactiveIngredients?.length > 3 && (
            <Typography fontWeight={500}>
              +{errorState?.errorData?.inactiveIngredients?.length - 3} More
              Templates
            </Typography>
          )}
        </Stack>
      </Box>
      <Box sx={{ mt: 12 }} alignSelf="end">
        <Button variant="outlined" color="gray2" onClick={handleClose}>
          Close
        </Button>
      </Box>
    </Stack>
  );
}

export default ActiveAndInactiveDialog;
