import { useImmer } from "use-immer";
import { useEffect, useRef, useState } from "react";
import { useWastageQuery } from "./UseWastageQuery";
import SimpleReactValidator from "simple-react-validator";
import { usePermission } from "../../../shared";

export const useWastage = () => {
  const isWastageEdit = usePermission("wastage-chart-edit");
  const [state, setState] = useImmer({
    dataList: [],
    allData: [],
    isChanged: false,
    updateValue: [],
    filter: {
      search: "",
      category: null,
    },
    isLoading: true,
  });

  const [, forceUpdate] = useState();

  const { listQuery, categoriesListQuery, updateWastageValues } =
    useWastageQuery({});

  const validator = useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
  );

  const { isFetching, data } = listQuery;

  useEffect(() => {
    if (!isFetching && categoriesListQuery.data) {
      const groupedData = data.reduce((acc, obj) => {
        const {
          category_id,
          subcategory_id,
          subcategory_name,
          subcategory_display_order,
          ...rest
        } = obj;
        acc[subcategory_id] = acc[subcategory_id] || {
          subcategory_id,
          category_id,
          subcategory_name,
          subcategory_display_order,
          subRows: [],
        };
        acc[subcategory_id].subRows.push({
          subcategory_id,
          category_id,
          subcategory_name,
          subcategory_display_order,
          ...rest,
        });
        return acc;
      }, {});

      setState((draft) => {
        draft.dataList = Object.values(groupedData)
          .filter((v, i) => {
            if (!state.filter.category)
              return categoriesListQuery.data[0].id == v.category_id;
            else return state.filter.category == v.category_id;
          })
          .sort(
            (a, b) => a.subcategory_display_order - b.subcategory_display_order
          );
        draft.allData = Object.values(groupedData).sort(
          (a, b) => a.subcategory_display_order - b.subcategory_display_order
        );
        draft.filter.category = draft.filter.category
          ? draft.filter.category
          : categoriesListQuery.data[0].id;
        draft.isLoading = false;
      });
    }
  }, [isFetching, categoriesListQuery.data]);

  const afterOnSave = () => {
    setState((draft) => {
      draft.updateValue = [];
      draft.isChanged = false;
    });
  };

  const onSave = () => {
    if (validator.current.allValid()) {
      updateWastageValues.mutate({ data: state.updateValue, afterOnSave });
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  const onChangeFilter = (e) => {
    setState((draft) => {
      draft.filter.category = e.id;
      draft.dataList = draft.allData.filter((v) => e.id == v.category_id);
    });
  };

  const searchFn = (value) => {
    return state.allData
      .map((data) => {
        return {
          ...data,
          subRows: data.subRows.filter(
            (data) =>
              JSON.stringify(data)
                .toLowerCase()
                .indexOf(value.toLowerCase()) !== -1
          ),
        };
      })
      .filter((v) => v.subRows.length);
  };

  const onSearchChange = (value) => {
    setState((draft) => {
      draft.filter.search = value;
      draft.filter.category = null;
      draft.dataList = searchFn(value);
    });
  };

  const updateTableData = (rowIndex, columnId, value, row) => {
    function updateDatalist(arr) {
      return arr.map((v) => {
        if (v.category_id === row.category_id) {
          return {
            ...v,
            subRows: v.subRows.map((val) => {
              if (val.id === row.id) {
                return { ...val, wastage: value };
              } else return val;
            }),
          };
        } else return v;
      });
    }

    function updateOrAddObject(id, wastageQuantity) {
      let arr = state.updateValue;
      let isHave = arr.some((v) => v.id === id);
      if (isHave) {
        arr = state.updateValue.map((data) => {
          if (data.id === id) {
            return { ...data, wastage_quantity: Number(wastageQuantity) };
          } else return data;
        });
      } else
        arr = [...arr, { id: id, wastage_quantity: Number(wastageQuantity) }];
      return arr;
    }

    setState((draft) => {
      draft.dataList = updateDatalist(draft.dataList);
      draft.allData = updateDatalist(draft.allData);
      draft.updateValue = updateOrAddObject(row.id, value);
      draft.isChanged = true;
    });
  };

  return {
    state,
    listQuery,
    categoriesListQuery,
    onSave,
    onChangeFilter,
    onSearchChange,
    updateTableData,
    validator,
    isWastageEdit,
  };
};
