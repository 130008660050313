import { useEffect, useState } from "react";
import { useDebounce } from "use-debounce";
import { useAssignedStoreListQueries } from "./useAssignedStoreListQueries";
import { useImmer } from "use-immer";

export const useAssignedStoreList = ({recipeId}) =>
{
    const [assignedStoreState,setAssignedStoreState] = useImmer({
        search: "",
        assignedStoreList:[],
        isLoading:true,
        alreadyAssignedStoreList:[]
      });

      const [pagination, setPagination] = useState({
        pageIndex: 1,
        pageSize: 50,
      });
      const [sorting, setSorting] = useState([]);
      const [debouncedText] = useDebounce(assignedStoreState.search, 1000);
      const {assingedStoreListQuery} = useAssignedStoreListQueries({recipeId,debouncedText,pagination:pagination,sorting});
      
      useEffect(() => {
        setAssignedStoreState((draft) => {
          draft.isLoading = assingedStoreListQuery.isLoading;
        });
        if (Array.isArray(assingedStoreListQuery?.data?.stores)) {
          const _assignedStoreList = [];
          assingedStoreListQuery.data.stores.forEach((store) => {
            if (
              !_assignedStoreList.find((item) => item.id === store.state_id)
            ) {
              _assignedStoreList.push({
                id: store.state_id,
                name: store.state_name,
                subRows: [],
              });
            }
          });

          if (_assignedStoreList) {
            assingedStoreListQuery.data.stores.forEach((store) => {
              const state = _assignedStoreList.find(
                (item) => item.id === store.state_id
              );
              if (state) {
                state.subRows.push({ id: store.id, storename: store.name });
              }
            });
          }
          const alreadyAssignedStoreIds =
            assingedStoreListQuery.data.stores.map((item) => {
              return item.id;
            });
          setAssignedStoreState((draft) => {
            draft.assignedStoreList = _assignedStoreList;
            draft.isLoading = assingedStoreListQuery.isLoading;
            draft.pagination = assingedStoreListQuery.data.pagination;
            draft.alreadyAssignedStoreList = alreadyAssignedStoreIds;
          });
        } 
      }, [assingedStoreListQuery.data]);

      const onSearchHandler = (search) =>
      {
          setAssignedStoreState((draft)=>{ draft.search = search; });
      }

      const onClearSearch = () =>
      {
        setAssignedStoreState((draft)=>{ draft.search = ""; });
      }
      return {assignedStoreState,onSearchHandler,pagination,setPagination,assingedStoreListQuery,sorting, setSorting,onClearSearch};
};