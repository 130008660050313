import { Box, Stack, Typography } from "@mui/material";
import { CustomTable } from "../../../shared";
import { createColumnHelper } from "@tanstack/react-table";
import { formatWeightWithUnits } from "../../../utils";

export const RecipeWeight = ({ data, total_weight, total_weight_unit }) => {
  const columnHelper = createColumnHelper(data);
  const columns = [
    columnHelper.accessor("base_unit_qty", {
      header: () => "Base Qty",
    }),
    columnHelper.accessor("base_unit_symbol", {
      header: () => "Base Unit",
    }),
    columnHelper.accessor("id", {
      header: () => "=",
      cell: (info) => "=",
    }),
    columnHelper.accessor("alt_unit_qty", {
      header: () => "Alternative Qty",
    }),
    columnHelper.accessor("alt_unit_symbol", {
      header: () => "Alternative Unit",
    }),
  ];
  return (
    <Stack>
      <Box
        sx={{
          padding: "16px 0px 16px 0px",
        }}
      >
        <Typography
          sx={{
            color: "#111C2B",
            fontWeight: "600",
            fontSize: "18px",
            lineHeight: "21.78px",
          }}
        >
          Recipe Weight & UoM
        </Typography>
      </Box>
      <Stack
        sx={{
          flexDirection: "row",
          justifyContent: "space-between",
          gap: "16px",
          alignItems: "center",
          bgcolor: "#3372E5",
          p: "8px 20px 8px 24px",
        }}
      >
        <Typography variant="subtitle1" color="#fff" fontWeight={600}>
          Recipe Total Weight:
        </Typography>
        <Stack
          sx={{
            flexDirection: "row",
          }}
        >
          <Typography variant="h5" color="#fff" fontWeight={600}>
            {formatWeightWithUnits(total_weight, "kg", 3)}
          </Typography>
        </Stack>
      </Stack>
      {data.length ? (
        <Box
          sx={{
            p: "0 8px 8px",
            bgcolor: "#F5F8FC",
          }}
        >
          <CustomTable
            defaultData={data}
            columns={columns}
            isPagination={false}
            isTableHeader={false}
            hideEmptyContent={true}
          />
        </Box>
      ) : null}
    </Stack>
  );
};
