
import { useQueryClient, useMutation } from "@tanstack/react-query";
import {
  createArchiveRecipe,
} from "../api";
import { errorMessage, successMessage } from "../../../utils/helper";
import { useArchivedRecipeKeys } from "./useArchivedRecipeKeys";
import { useRecipeKeys } from "./useRecipeKeys";

export const useArchiveRecipeQueries = () =>
{
  const {archiveRecipeKeys} = useArchivedRecipeKeys();

  const {recipeKeys} = useRecipeKeys();
  
 const queryClient = useQueryClient();
    //create category
  const mutateCreateArchiveRecipe = useMutation({
    mutationFn: createArchiveRecipe,
    onError: (error) => {
      errorMessage(error.response.data.message);
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: recipeKeys.list(),
      });
      successMessage("Recipe archived successfully");
      variables.handleClose();
    },
  });
  return {mutateCreateArchiveRecipe};
}