import { Stack, Typography } from "@mui/material";
import { CustomTable } from "../../../shared";
import { createColumnHelper } from "@tanstack/react-table";

export const ProductionChartTemplateDialog = (props) => {
  const { handleModal, ColorButton, templateList, onSearchChange } = props;

  const style = {
    position: "sticky",
    top: "100%",
    left: "90%",
    width: 150,
    p: 3,
  };
  const columnHelper = createColumnHelper(templateList);

  const columns = [
    columnHelper.accessor("template_name", {
      header: () => "Template",
    }),
  ];
  return (
    <>
      <CustomTable
        defaultData={templateList}
        columns={columns}
        headerText="Templates"
        subRow={"subRows"}
        allRowsExpanded={true}
        manualPagination={true}
        isPagination={false}
        isSearching={false}
        onSearchChange={onSearchChange}
        customRowStyle={(row) => {
          return !row.depth ? { borderBottom: "none" } : null;
        }}
      />
      <Stack sx={style}>
        <ColorButton
          variant="outlined"
          onClick={(e) => {
            handleModal(false);
          }}
        >
          Close
        </ColorButton>
      </Stack>
    </>
  );
};
