import {
    useQuery,
    keepPreviousData
  } from "@tanstack/react-query";

import qs from "qs";
import { getAssignedStoreList } from "../api";
import { useStoreKeys } from "./useStoreKeys";

export const useAssignedStoreListQueries = ({recipeId,debouncedText,pagination,sorting}) =>
{
  const {storeKeys} = useStoreKeys();
  
    let params = {};
    if (debouncedText) {
        params = { ...params, search: debouncedText };
    }

    if (sorting?.length > 0) {
      const sortByParams = sorting.reduce(
        (obj, item) =>
          Object.assign(obj, { [item.id]: item.desc ? "desc" : "asc" }),
        {}
      );
      params = {
        ...params,
        sort: sortByParams,
      };
    }

    const query = {
        params,
        paramsSerializer: (params) => qs.stringify(params, { encode: true }),
      };

      //list query
      const assingedStoreListQuery = useQuery({
        queryKey: storeKeys.storelist({ query, pageIndex:pagination.pageIndex,pageSize:pagination.pageSize }),
        queryFn: () => getAssignedStoreList({recipeId, query, pagination }),
        placeholderData: keepPreviousData,
      });
      
  return {assingedStoreListQuery};
}