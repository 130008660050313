import { Button, Stack, Typography } from "@mui/material";
import { useRef, useState } from "react";
import SimpleReactValidator from "simple-react-validator";

export const MacidSecurityDialog = (props) => {
  const { onSubmit, handleSecurityDialogOpen, isDisabled } = props;

  const [, forceUpdate] = useState();
  const validator = useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
  );

  const submit = () => {
    if (validator.current.allValid()) {
      onSubmit();
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  return (
    <div>
      <Typography padding="24px">
        Are you sure you want to disable security? Once you perform this action,
        user can access Recipe App from any store without MAC ID.
      </Typography>
      <Stack
        direction="row"
        gap="8px"
        padding="8px 24px 16px 24px"
        marginTop="2px"
        sx={{ justifyContent: "flex-end" }}
      >
        <Button
          variant="outlined"
          onClick={() => handleSecurityDialogOpen(false)}
        >
          Cancel
        </Button>
        <Button variant="contained" onClick={submit} disabled={isDisabled}>
          Disable
        </Button>
      </Stack>
    </div>
  );
};
