import { getRecipeHowToPrepare } from "../api";
import { useRecipeKeys } from "./useRecipeKeys";
import {
    useQuery,
  } from "@tanstack/react-query";

export const useHowToPrepareQuries = ({recipeId}) =>
{
    const {recipeKeys} = useRecipeKeys();
    const howToPrepareQuery = useQuery({
        queryKey: recipeKeys.detailBykEY("how_prepare",recipeId),
        queryFn: () => getRecipeHowToPrepare(recipeId),
        enabled: recipeId > 0,
      });
      return {howToPrepareQuery};
}