import { useMemo } from "react";
import { useImmer } from "use-immer";

export const useSceduleSelect = ({onChangeSceduleHandler}) =>
{
    
    const sceduleOptions = useMemo(()=>
         [{id:1,name:"Scheduled"},{id:0,name:"Not Scheduled"}]
    ,[]);

    const sceduleValueChangeHandler = (option) =>
    {
        const selectedOption = option ? option.id : -1;
        onChangeSceduleHandler(selectedOption);
    }
    return {sceduleOptions,sceduleValueChangeHandler};
}