import {
  useQuery,
  useQueryClient,
  keepPreviousData,
} from "@tanstack/react-query";
import qs from "qs";
import { getRecipeList } from "../api";
import { useRecipeKeys } from "./useRecipeKeys";
import { getTripsAndTricksList } from "../../Tips&Tricks/api/TipsAndTricksApi";

const tipsandtricksKeys = {
  all: ["tipsandtricks"],
  lists: () => [...tipsandtricksKeys.all, "ingredientlist"],
  tipsandtrickslist: (filters) => [...tipsandtricksKeys.lists(), filters],
  detail: (id) => [...tipsandtricksKeys.tipsandtrickslist(), id],
};

export const useRecipeListQueries = ({
  recipelist,
  debouncedText,
  pagination,
  category_id = 0,
  type = "",
  status = -1,
  batch_group = null,
  sorting,
  filterApplied,
}) => {
  const queryClient = useQueryClient();
  const { recipeKeys } = useRecipeKeys();
  const storeId = filterApplied?.map((item) => item?.id);
  let params = {};
  if (debouncedText) {
    params = { ...params, search: debouncedText };
  }
  if (storeId) {
    params = { ...params, store: storeId };
  }

  if (sorting?.length > 0) {
    const sortByParams = sorting.reduce(
      (obj, item) =>
        Object.assign(obj, { [item.id]: item.desc ? "desc" : "asc" }),
      {}
    );
    params = {
      ...params,
      sort: sortByParams,
    };
  }

  if (batch_group) {
    params = { ...params, batch_group: batch_group ? 1 : 0 };
  }
  if (category_id > 0) {
    params = { ...params, category: category_id };
  }
  if (status > -1 && status !== "") {
    params = { ...params, status };
  }
  if (type !== "") {
    params = { ...params, type };
  }

  const query = {
    params,
    paramsSerializer: (params) => qs.stringify(params, { encode: true }),
  };

  //list query
  const listQuery = useQuery({
    queryKey: recipeKeys?.recipelist({
      query,
      category_id,
      type,
      status,
      batch_group,
    }),
    queryFn: () =>
      getRecipeList({
        query,
        category_id,
        type,
        status,
        batch_group,
      }),
    placeholderData: keepPreviousData,
    enabled: !!category_id,
  });

  return { listQuery };
};
