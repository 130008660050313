import {
  Avatar,
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  Stack,
  TextField,
  Typography,
  Link
} from "@mui/material";
import React, { useState } from "react";
import {
  DeleteModal,
  InputControl,
  PageListLayout,
  SimpleMenu,
  SelectChip,
  ServerPaginatedtable,
  Loader,
} from "../../../shared";
import AddIcon from "@mui/icons-material/Add";
import { createColumnHelper } from "@tanstack/react-table";
import { useArchivedRecipeList, useUnArchiveRecipe } from "../hooks";
import { useNavigate, useParams } from "react-router-dom";

export const ArchivedRecipeList = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const {
    recipe,
    onSearchHandler,
    onUnArchiveRecipe,
    archivedRecipeQuery,
    sorting,
    setSorting,
    pagination,
    setPagination,
    onClearSearchHandler
  } = useArchivedRecipeList({ recipelist: true });

  if (archivedRecipeQuery.isLoading) {
    return <Loader />;
  }

  const handleDeleteModal = () => {
    setIsOpen(!isOpen);
  };

  const columnHelper = createColumnHelper(archivedRecipeQuery.data.recipes);

  const columns = [
    columnHelper.accessor("name", {
      header: () => "Recipe Name",
      cell: (props) => {
        const { row } = props;
        return (
          <Stack sx={{ alignItems: "center" }} direction="row" gap="8px">
            <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
            <p>{row.original.name}</p>
          </Stack>
        );
      },
    }),

    columnHelper.accessor("type", {
      header: () => "Type",
      cell: (props) => {
        const { row } = props;
        return row.original.type;
      },
    }),
    columnHelper.display({
      id: "actions",
      cell: (props) => {
        const { row } = props;
        return (
          <Button
            sx={{
              fontSize: "14px",
              lineHeight: "16.9px",
              color: "#1760D2",
            }}
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              onUnArchiveRecipe(row.original.id);
            }}
          >
            Unarchive
          </Button>
        );
      },
    }),
  ];

  const breadcrumbs = [
    <Link
      underline="none"
      key="1"
      color="#1760D2"
      onClick={(e) => navigate("/recipe")}
      sx={{ fontSize: "14px", fontWeight: 400 }}
    >
      Recipe
    </Link>,
    <Typography
      key="2"
      color="inherit"
      sx={{ fontSize: "14px", fontWeight: 400 }}
    >
       Archived Recipes
    </Typography>,
  ];

  return (
    <>
      <PageListLayout
        breadcrumbs={breadcrumbs}
        pageContent={
          <>
            <Stack sx={{ borderRadius: "4px", bgcolor: "#fff" }}>
              <ServerPaginatedtable
                defaultData={archivedRecipeQuery.data.recipes}
                columns={columns}
                headerText="Archived Recipes"
                //subRow={"subRows"}
                isLoading={archivedRecipeQuery.isLoading}
                //allRowsExpanded={true}
                onSearchChange={onSearchHandler}
                pagination={pagination}
                search={recipe.search}
                setPagination={setPagination}
                sorting={sorting}
                setSorting={setSorting}
                customRowStyle={(row) => {
                  return !row.depth ? { borderBottom: "none" } : null;
                }}
                onRowLink={(e) => {
                  return `/recipe/details/${e.id}`;
                }}
                paginationProps={
                  archivedRecipeQuery.data?.pagination || {
                    total: 0,
                    lastPage: 1,
                  }
                }
                tableHeaderContent={
                  <Stack
                    sx={{
                      p: "8px 16px 8px 16px",
                      flexDirection: "row",
                      gap: "6px",
                    }}
                  ></Stack>
                }
                rightContent={<Stack direction="row" gap="8px"></Stack>}
                onClearSearch={onClearSearchHandler}
              />
            </Stack>
          </>
        }
      />

      <DeleteModal
        open={isOpen}
        handleClose={handleDeleteModal}
        onConfirm={handleDeleteModal}
      />
    </>
  );
};
