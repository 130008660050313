import { Box, Stack, Typography } from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { CustomTable, InputControl, Loader, SelectChip } from "../../../shared";
import { createColumnHelper } from "@tanstack/react-table";

export const EditProductionChart = ({
  categorylistQuery,
  productionchart,
  handleRecipes,
  onChangeFilter,
  updateTableData,
  onSearchChange,
  handleCustomTableChange,
}) => {
  const TableCell = ({ getValue, row, column, table }) => {
    const initialValue = getValue();
    const [value, setValue] = useState(initialValue);
    useEffect(() => {
      setValue(row.original.wastage);
    }, [initialValue]);
    const onBlur = () => {
      table.options.meta?.updateData(
        row.index,
        column.id,
        value,
        row.original,
        "wastage"
      );
    };
    return (
      !!row.depth && (
        <>
          <InputControl
            type="number"
            sx={{
              selfAlign: "right",
              width: "134px",
            }}
            size="small"
            value={value}
            onChange={(e) => {
              const inputValue = e.target.value;
              if (/^\d*\.?\d*$/.test(inputValue)) {
                setValue(inputValue);
              }
            }}
            onBlur={onBlur}
            onFocus={(e) => {
              if (e.target.value === "0") {
                setValue("");
              }
            }}
          />
        </>
      )
    );
  };
  const TableCell2 = ({ getValue, row, column, table }) => {
    const initialValue = getValue();
    const [value, setValue] = useState(initialValue);
    useEffect(() => {
      setValue(row.original.quantity);
    }, [initialValue]);
    const onBlur = () => {
      table.options.meta?.updateData(
        row.index,
        column.id,
        value,
        row.original,
        "quantity"
      );
    };
    return (
      !!row.depth && (
        <>
          <InputControl
            type="number"
            sx={{
              selfAlign: "right",
              width: "134px",
            }}
            size="small"
            value={value}
            onChange={(e) => {
              const inputValue = e.target.value;
              if (/^\d*\.?\d*$/.test(inputValue)) {
                setValue(inputValue);
              }
            }}
            onBlur={onBlur}
            onFocus={(e) => {
              if (e.target.value === "0") {
                setValue("");
              }
            }}
          />
        </>
      )
    );
  };

  const columnHelper = createColumnHelper();
  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        header: () => "Product",
        cell: (props) => {
          const { row } = props;
          return !row.depth ? (
            <Typography
              sx={{
                color: "#1760D2",
                textTransform: "uppercase",
              }}
            >
              {row.original.subcategory_name}
            </Typography>
          ) : (
            row.original.name
          );
        },
      }),
      columnHelper.accessor("selling_unit_name", {
        header: () => "Selling Unit",
        align: "right",
        cell: (props) => {
          const { row } = props;
          return !row.depth ? "" : row?.original?.selling_unit_name;
        },
      }),
      columnHelper.accessor("quantity", {
        header: () => "Quantity",
        align: "right",
        cell: TableCell2,
      }),
      columnHelper.accessor("wastage", {
        header: () => "Wastage",
        align: "right",
        cell: TableCell,
      }),
    ],
    [productionchart, productionchart?.dataList]
  );
  const tableData = productionchart?.dataList?.filter(
    (i, index) => index === productionchart?.temp_index
  );
  const categoryRef = useRef(null);
  const handleHorizantalScroll = (element, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
    }, speed);
  };
  return (
    <>
      {false ? (
        <Loader />
      ) : (
        <CustomTable
          defaultData={handleCustomTableChange(
            tableData?.length
              ? productionchart?.dataList[productionchart?.temp_index]
                  ?.recipeList
              : []
          )}
          columns={columns}
          subRow={"subRows"}
          allRowsExpanded={true}
          updateTableData={updateTableData}
          isPagination={false}
          showSearchBar={false}
          manualPagination={true}
          tableHeaderStyles={{ padding: "8px 6px" }}
          customRowStyle={(row) => {
            return !row.depth ? { borderBottom: "none" } : null;
          }}
          rightContent={
            <>
              <Stack direction="row" gap="6px" marginRight={50}>
                <InputControl
                  sx={{ width: "200px", borderRadius: "10px" }}
                  type="text"
                  placeholder="search"
                  onChange={(e) => onSearchChange(e.target.value)}
                  value={productionchart.filter.search}
                />
                <Box
                  height="45px"
                  alignContent="center"
                  onClick={() =>
                    handleHorizantalScroll(categoryRef.current, 50, 100, -10)
                  }
                >
                  <img alt="details" src="/icons/Intersect.png" />
                </Box>
                <Stack
                  direction="row"
                  width="725px"
                  overflow="auto"
                  gap={1}
                  className="scroll-hide"
                  ref={categoryRef}
                >
                  {categorylistQuery?.data?.map((v, i) => (
                    <SelectChip
                      key={i}
                      value={v}
                      isSelected={
                        productionchart.filter.category
                          ? productionchart.filter.category == v.id
                          : i === 0
                      }
                      onClick={() => onChangeFilter(v)}
                    />
                  ))}
                </Stack>
                <Box
                  height="45px"
                  alignContent="center"
                  onClick={() =>
                    handleHorizantalScroll(categoryRef.current, 50, 100, 10)
                  }
                >
                  <img alt="details" src="/icons/Intersect1.png" />
                </Box>
                {/* <Box
                  height="45px"
                  alignContent="center"
                  onClick={() =>
                    handleHorizantalScroll(categoryRef.current, 50, 100, -10)
                  }
                >
                  <img alt="details" src="/icons/Intersect.png" />
                </Box>
                <Stack
                  direction="row"
                  width="725px"
                  overflow="auto"
                  gap={1}
                  className="scroll-hide"
                >
                  {categorylistQuery?.data?.map((v, i) => (
                    <SelectChip
                      key={i}
                      value={v}
                      isSelected={
                        productionchart.filter.category
                          ? productionchart.filter.category == v.id
                          : i === 0
                      }
                      onClick={() => onChangeFilter(v)}
                    />
                  ))}
                </Stack>
                <Box
                  height="45px"
                  alignContent="center"
                  onClick={() =>
                    handleHorizantalScroll(categoryRef.current, 50, 100, 10)
                  }
                >
                  <img alt="details" src="/icons/Intersect1.png" />
                </Box> */}
              </Stack>
            </>
          }
        />
      )}
    </>
  );
};
