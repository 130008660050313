import { useImmer } from "use-immer";
import { useRef, useState, useEffect, useContext } from "react";
import SimpleReactValidator from "simple-react-validator";
import { useDebounce } from "use-debounce";
import {
  useDelete,
  useStoreSelectQueries,
  usePairedStatus,
  usePermission,
} from "../../../shared";
import { useDevicesQueries } from "./useDevicesQueries";
import { AppContext } from "../../../store";

export const useDevices = ({ isList, deviceId }) => {
  const isDeletable = usePermission("device-delete");
  const isCreateVisible = usePermission("device-create");
  const isEditable = usePermission("device-modify");
  const isUnpairDevice = usePermission("device-unpair");
  const url = new URL(window.location);
  const [, forceUpdate] = useState();
  const validator = useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
  );
  const { deleteInfo, onDelete, resetDeleteInfo } = useDelete();
  const moduleName = "devices";
  const {
    setTableState,
    tableData,
    clearAllFilters,
    setSorting,
    setPagination,
    setSearchState,
  } = useContext(AppContext);
  const { sorting, pagination, search } = tableData[moduleName];
  const [devices, setDevices] = useImmer({
    data: { name: "", store_id: "", code: "", store_name: "" },
    open: false,
    id: "",
    filter: {
      search: "",
    },
    openDialog: false,
    selectedStatus: "",
    filters: {
      storefilter: [],
      status: "",
      all: [],
    },
  });
  const [debouncedText] = useDebounce(search, 1000);

  const {
    listQuery,
    createDevices,
    updateDevices,
    deviceDetails,
    deleteDevices,
    editDevicePairingStatus,
  } = useDevicesQueries({
    isList,
    pagination,
    sorting,
    deviceId: devices?.id ? devices?.id : deviceId,
    resetDeleteInfo,
    debouncedText,
    filterApplied: tableData?.devices?.data?.storefilter,
    status: tableData?.devices?.data?.selectedStatus,
    url,
  });
  const onChangePiredHandler = (value) => {
    setTableState({
      tableName: moduleName,
      key: "selectedStatus",
      value: value,
    });
  };
  const { listQuery: storeLists } = useStoreSelectQueries({
    storeList: true,
  });
  const selectStatus = usePairedStatus({ onChangePiredHandler });

  const onChange = (e) => {
    const { name, value } = e.target;
    setDevices((draft) => {
      draft.data[name] = value;
    });
  };

  const onSaveDevices = (data) => {
    const payload = {
      name: devices?.data?.name,
      store_id: devices?.data?.store_id,
    };
    createDevices.mutate({ data: payload, handleAddDeviceDialogOpen });
  };
  const onUpdateDevices = () => {
    updateDevices.mutate({ data: devices.data });
  };
  const onSubmit = () => {
    if (validator.current.allValid()) {
      deviceId ? onUpdateDevices() : onSaveDevices();
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  useEffect(() => {
    if (deviceDetails?.data) {
      setDevices((draft) => {
        draft.data.name = deviceDetails?.data?.name;
        draft.data.store_id = Number(deviceDetails?.data?.store_id);
        draft.data.code = deviceDetails?.data?.code;
        draft.data.store_name = deviceDetails?.data?.store_name;
        draft.data.paird_status = deviceDetails?.data?.paird_status;
      });
    }
  }, [deviceDetails.data]);
  const confirmDelete = () => {
    deleteDevices.mutate({ id: deleteInfo.id, to: deleteInfo.to, isList });
  };
  const handleOpenDialog = (type, id) => {
    setDevices((draft) => {
      draft.open = type;
      draft.id = id;
    });
  };
  const handlePairingStatus = () => {
    editDevicePairingStatus.mutate({
      data: devices.data,
      handleOpenDialog,
      handleAddDeviceDialogOpen,
    });
  };
  const onSearchHandler = (search) => {
    setSearchState({
      tableName: moduleName,
      key: "search",
      value: search,
    });
  };
  const StoreValuesHandler = (event, option) => {
    const item = option?.map((item) => {
      return { ...item };
    });
    setTableState({
      tableName: moduleName,
      key: "storefilter",
      value: item,
    });
    setDevices((draft) => {
      draft.filters.status = "";
    });
  };

  const handleAddDeviceDialogOpen = (type) => {
    if (type === false) {
      setDevices((draft) => {
        draft.openDialog = type;
      });
      url.search = "";
      window.history.replaceState({}, "", url);
    } else {
      setDevices((draft) => {
        draft.openDialog = type;
      });
    }
  };
  useEffect(() => {
    if (url?.search) {
      setDevices((draft) => {
        draft.openDialog = true;
      });
    }
  }, [url?.search]);

  const onClearFilter = () => {
    clearAllFilters({ tableName: moduleName });
  };
  const disableclearButton =
    (tableData.devices.data.selectedStatus == null ||
      tableData.devices.data.selectedStatus === "") &&
    Array.isArray(tableData.devices.data.storefilter) &&
    tableData.devices.data.storefilter.length === 0
      ? true
      : false;

  return {
    devices,
    pagination,
    setPagination,
    sorting,
    setSorting,
    listQuery,
    onChange,
    validator,
    onSubmit,
    onDelete,
    deleteInfo,
    resetDeleteInfo,
    confirmDelete,
    handleOpenDialog,
    handlePairingStatus,
    onSearchHandler,
    storeLists,
    StoreValuesHandler,
    selectStatus,
    onClearFilter,
    handleAddDeviceDialogOpen,
    disableclearButton,
    updateDevices,
    createDevices,
    deviceDetails,
    isCreateVisible,
    isEditable,
    isDeletable,
    isUnpairDevice,
    tableData,
    moduleName,
    search,
  };
};
