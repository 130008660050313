import * as React from "react";
import { Typography, Card, Stack, Divider } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import StarIcon from "../../../assets/images/Icons/starIcon.svg";
import { formatWeightWithUnits } from "../../../utils";

export const Batchcards = (props) => {
  const { data } = props;
  return (
    <Stack direction="column" spacing={1}>
      {data.map((val, i) => (
        <CollapseTable data={val} key={i} />
      ))}
    </Stack>
  );
};

const CollapseTable = ({ data }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <Stack direction="column">
      <Card
        sx={{
          border: "1px solid #DFE8F2",
          boxShadow: "0px 2px 4px 0px #0000000F",
          borderRadius: "4px",
        }}
      >
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            pl: "16px",
            pr: "8px",
            cursor: "pointer",
            height: "40px",
          }}
          display="flex"
          direction="row"
          justifyContent="space-between"
          onClick={() => setIsOpen(!isOpen)}
        >
          <Typography
            variant="subtitle1"
            sx={{
              fontWeight: 500,
              fontSize: "16px",
              display: "flex",
              gap: "6px",
              alignItems: "center",
            }}
          >
            {data.recipe_name}
            <Typography color="#657083" variant="subtitle2">
              &#x2022; {data.template_name}
            </Typography>
          </Typography>

          <Stack direction="row" gap={1} alignItems="center">
            <Typography fontWeight={500} fontSize="16px">
              Need:
            </Typography>
            <Typography fontWeight={500} fontSize="16px" color="#1760D2">
              {formatWeightWithUnits(
                data.total_weight,
                data.total_weight_unit,
                3
              )}
            </Typography>
            <ExpandMoreIcon
              sx={{
                m: "4px",
                // color: "#999999",
                transform: isOpen ? "rotate(180deg)" : "",
              }}
            />
          </Stack>
        </Stack>
        <Divider />
        {isOpen ? (
          ""
        ) : (
          <>
            {!!data?.ingredients?.length && (
              <>
                <Stack
                  sx={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    p: "5.5px 16px",
                    backgroundColor: "#E6EEF7",
                  }}
                >
                  <Typography color="#000000" fontWeight={500} fontSize="14px">
                    Ingredients
                  </Typography>
                  <Typography color="#000000" fontWeight={500} fontSize="14px">
                    Quantity
                  </Typography>
                </Stack>
                <Divider />
                {data.ingredients?.map((data, i) => (
                  <Stack
                    key={i}
                    direction="row"
                    justifyContent="space-between"
                    sx={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      p: "7.5px 16px",
                    }}
                  >
                    <Typography color="#000000" fontSize="14px">
                      <Stack direction="row" gap={1}>
                        {data.recipe_name
                          ? data.recipe_name
                          : data.ingredient_name}
                        {data?.pivot?.is_main_ingredient && (
                          <img
                            src={StarIcon}
                            alt="StarIcon"
                            style={{ width: "13px", height: "14px" }}
                          />
                        )}
                      </Stack>
                    </Typography>

                    <Typography
                      color="#000000"
                      fontWeight={500}
                      fontSize="14px"
                    >
                      {formatWeightWithUnits(
                        data.pivot.base_unit_qty,
                        data.pivot.base_unit_symbol,
                        3
                      )}
                    </Typography>
                  </Stack>
                ))}
                <Divider />
              </>
            )}
          </>
        )}
      </Card>
    </Stack>
  );
};
