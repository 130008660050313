import { useQueryClient, useMutation } from "@tanstack/react-query";
import { successMessage } from "../../../utils/helper";
import { updateRecipeStatus } from "../api";
import { useRecipeKeys } from "./useRecipeKeys";
import { useNavigate } from "react-router-dom";
import { useImmer } from "use-immer";

export const useRecipeStatusUpdateQueries = ({ recipeId }) => {
  const [errorState, setErrorState] = useImmer({
    isOpen: false,
    errorData: {},
  });
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { recipeKeys } = useRecipeKeys();
  const updateRecipeStatusMuate = useMutation({
    mutationFn: updateRecipeStatus,
    onError: (error) => {
      setErrorState((draft) => {
        draft.isOpen = true;
        draft.errorData = error?.response?.data;
      });
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: recipeKeys.list(),
      });
      successMessage(_.message);
      navigate(`/recipe/details/${recipeId}/?basic-details`, { replace: true });
    },
  });
  return { updateRecipeStatusMuate, errorState, setErrorState };
};
