import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React from "react";

export const CustomModal = ({
  open,
  content,
  title,
  isEdit,
  onSubmit,
  handleClose,
  isDisableSubmit,
  PaperProps,
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={PaperProps}
      sx={{
        ".MuiPaper-root": {
          borderRadius: "20px",
          width: PaperProps ? PaperProps : "482px",
        },
      }}
    >
      <DialogTitle sx={{ padding: "20px", height: "52px" }}>
        {title}
      </DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions sx={{ height: "64px" }}>
        <Button color="gray2" variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          disabled={isDisableSubmit}
          onClick={onSubmit}
          variant="contained"
        >
          {isEdit ? "Update" : "Save"}
          {isDisableSubmit}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
