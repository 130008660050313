import React from "react";
import { Stack, Typography } from "@mui/material";
import { Loader, PageListLayout } from "../../../shared";
import { useDashboard } from "../hooks";
import { StatsCards } from "../components/StatsCards";

export const DashboardUi = () => {
  const { listQuery } = useDashboard({ isList: true });
  return (
    <>
      <PageListLayout
        pageContent={
          <Stack spacing={3}>
            <Stack
              direction="row"
              justifyContent="space-between"
              height="62px"
              bgcolor="white"
              sx={{
                position: "sticky",
                zIndex: "99px",
                top: "0px",
              }}
            >
              <Stack alignSelf="center" direction="row" ml={3}>
                <Typography fontWeight={600} fontSize="24px">
                  Dashboard
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        }
      />
      <Stack ml={2} spacing={4}>
        {listQuery.isPending ? (
          <Loader />
        ) : (
          <StatsCards data={listQuery?.data?.data} />
        )}
      </Stack>
    </>
  );
};
