import { API } from "../../../utils/api";

export const getAdminUsers = async ({ query, pagination }) => {
  const { pageIndex, pageSize } = pagination;
  const res = await API.get(
    `added_user_list?page=${pageIndex}&per_page=${pageSize}`,
    query
  );
  return res.data.data;
};

export const getUserList = async () => {
  const res = await API.get("user_list");
  return res.data.data;
};

export const getRolesList = async () => {
  const res = await API.get("role-list");
  return res.data.data;
};

export const getUserById = async (id) => {
  const res = await API.get(`user_details/${id}`);
  return res.data.data;
};

export const saveUser = async (id, payload) => {
  const res = await API.post(`user/${id}/assign_role`, payload);
  return res.data;
};

export const removeUser = async ({ id }) => {
  const res = await API.get(`deleteuser/${id}`);
  return res.data;
};

export const removeRoleFromUser = async (id, payload) => {
  const res = await API.post(`roles/${id}/delete_user`, payload);
  return res.data;
};
export const changeMacIdSecurity = async (id) => {
  const res = await API.get(`change-mac-security-status/${id}`);
  return res.data;
};
