import { Button, Chip, Stack, TextField } from "@mui/material";
import { InputControl } from "../../../shared";
import { useRef, useState } from "react";
import SimpleReactValidator from "simple-react-validator";
import CloseIcon from "@mui/icons-material/Close";

export const AddMacDialog = (props) => {
  const {
    onChange,
    handleAddMacId,
    handleDeleteMacTag,
    onSubmit,
    state,
    handleClose,
  } = props;

  const [, forceUpdate] = useState();
  const validator = useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
  );


  const OnAdd = () => {
    console.log("state.macId, ", state.macId, validator.current);

    if (validator.current.allValid()) {
      console.log("state.macId, ", state.macId);

      handleAddMacId()
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  return (
    <div style={{ marginTop: "16px" }}>
      <Stack
        sx={{
          flexDirection: "row",
          gap: "8px",
          alignItems: "baseline",
        }}
      >
        <InputControl
          name="name"
          type="text"
          label="MAC ID"
          required
          sx={{ width: "100%" }}
          error={state.macId && validator.current.message(
            "MAC ID",
            state.macId,
            ['required', { regex: /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})|([0-9a-fA-F]{4}\\.[0-9a-fA-F]{4}\\.[0-9a-fA-F]{4})$/ }]
          )}
          helperText={state.macId && validator.current.message(
            "MAC ID",
            state.macId,
            ['required', { regex: /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})|([0-9a-fA-F]{4}\\.[0-9a-fA-F]{4}\\.[0-9a-fA-F]{4})$/ }]
          )}
          onChange={onChange}
          value={state.macId}
        />
        <Button
          variant="contained"
          onClick={OnAdd}
          sx={{
            height: "40px",
          }}
          disabled={!state.macId}
        >
          ADD
        </Button>
      </Stack>
      <Stack
        sx={{
          flexDirection: "row",
          gap: "8px",
          flexWrap: "wrap",
          py: "8px",
        }}
      >
        {state.addMacs.map((data) => (
          <Chip
            variant="outlined"
            color="gray"
            label={data}
            deleteIcon={
              <CloseIcon sx={{ height: "16px", color: "#000 !important" }} />
            }
            onDelete={() => {
              handleDeleteMacTag(data);
            }}
            sx={{
              width: "fit-content",
              "& .MuiChip-deleteIcon": {
                color: "#CCCCCC",
              },
            }}
          />
        ))}
      </Stack>
      <Stack
        direction="row"
        gap="8px"
        padding="30px 2px 10px 10px"
        marginTop="2px"
        sx={{ justifyContent: "flex-end" }}
      >
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={onSubmit}
          disabled={!state.addMacs.length > 0}
        >
          Save
        </Button>
      </Stack>
    </div>
  );
};
