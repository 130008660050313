import React from "react";
import { Routes, Route } from "react-router-dom";
import { StoreAdminDashboard, SuperAdminDashboard } from "../master";
import { ErrorBoundary } from "react-error-boundary";
import {
  AdminProtectedRoute,
  ErrorFallback,
  Theme,
  UnAuthorisedMaster,
} from "../shared";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Authentication } from "../modules/Authentication";
import { AppScopeProvider, AuthProvider } from "../store";

const queryClient = new QueryClient();

export function Master() {
  return (
    <>
      <ErrorBoundary
        fallbackRender={ErrorFallback}
        onReset={(details) => {
          // Reset the state of your app so the error doesn't happen again
        }}
      >
        <ThemeProvider theme={Theme}>
          <CssBaseline />
          <QueryClientProvider client={queryClient}>
            <AuthProvider>
              <AppScopeProvider>
                <Routes>
                  <Route
                    element={<Authentication />}
                    path={"/authentication/*"}
                  />
                  <Route element={<AdminProtectedRoute allowedRole="admin" />}>
                    <Route
                      exact
                      element={<SuperAdminDashboard />}
                      path={"/*"}
                    />
                  </Route>

                  <Route
                    element={<AdminProtectedRoute allowedRole="store-admin" />}
                  >
                    <Route
                      exact
                      element={<StoreAdminDashboard />}
                      path={"/store-admin/*"}
                    />
                  </Route>
                  <Route
                    exact
                    element={<UnAuthorisedMaster />}
                    path={"/unauth"}
                  />
                </Routes>
              </AppScopeProvider>
            </AuthProvider>
          </QueryClientProvider>
        </ThemeProvider>
      </ErrorBoundary>
    </>
  );
}
