import { Stack, Modal, Typography, Button, Box } from "@mui/material";
import React from "react";
import warning_icon from "../../../assets/images/Icons/icAlert.svg";
import { useNavigate } from "react-router-dom";
import { navigateBasedOnUserType } from "../../../utils";

const style = {
  position: "absolute",
  top: "65%",
  left: "50%",
  transform: "translate(-50%, -100%)",
  width: 550,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  borderRadius: "16px",
  outline: "none",
};
export const DeleteAlertDialog = (props) => {
  const { id, open, handleClose, isStoreAdmin } = props;
  const navigate = useNavigate();

  const AlertHandleClose = () => {
    handleClose();
    navigateBasedOnUserType(
      `/templates/details/${id}?settings`,
      isStoreAdmin,
      navigate
    );
  };

  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Stack
            direction="row"
            gap="10px"
            alignItems="center"
            lineHeight="34.2px"
            marginBottom={3}
          >
            <Box
              sx={{
                width: "30px",
                height: "30px",
                borderRadius: "35px",
                bgcolor: "#F044381F",
              }}
            >
              <img src={warning_icon} style={{ padding: "6px" }} />
            </Box>
            <Typography
              sx={{
                fontWeight: "600",
                fontSize: "25px",
                lineHeight: "24.2px",
              }}
            >
              Unable to Delete
            </Typography>
          </Stack>
          <Typography id="modal-modal-title" fontWeight="400" fontSize="18px">
            Template cannot be deleted because:
          </Typography>
          <ul
            style={{
              padding: "20px",
              marginLeft: "10px",
              lineHeight: "24.2px",
              fontWeight: "400",
              fontSize: "18px",
            }}
          >
            <li>Some stores are assigned to this template </li>
            <li>Production dates have been assigned to this template.</li>
          </ul>
          <Stack direction="row" gap="12px" justifyContent="flex-end">
            <Button variant="outlined" onClick={handleClose}>
              Close
            </Button>

            <Button onClick={AlertHandleClose} variant="contained">
              View Settings
            </Button>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
};
