import {
  Box,
  Button,
  Chip,
  Grid,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography,
  createFilterOptions,
  Link,
  FormHelperText,
} from "@mui/material";
import React, { useRef } from "react";
import {
  BasicMuiTable,
  CustomTable,
  InputControl,
  Loader,
  PageListLayout,
  UseUoMHelper,
} from "../../../shared";
import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { useIngredients } from "../hooks/UseIngredient";
import { useNavigate, useParams } from "react-router-dom";
import { useUnits } from "../../MasterData/hooks/UseUnits";
import { createColumnHelper } from "@tanstack/react-table";

export const AddOrEditIngredient = () => {
  const { id } = useParams();
  const {
    ingredient,
    onChange,
    validator,
    optionsTaxGroup,
    optionsTypes,
    updateIngredient,
    createIngredient,
    onTableChange,
    handleAddUnits,
    handleDelete,
    onSubmit,
    onTablehandleChange,
    tagsListQuery,
    handleProductInputChange,
    onDeleteTagSelect,
    globalData,
    onChangeTax,
    handleDropzonePdf,
    state,
    createPdfUrl,
    handleDeletePdf,
    unitValidator,
  } = useIngredients({
    detId: id,
    altunitlist: true,
    isIngredientlist: false,
    isTaxgrouplist: true,
    isTaglist: true,
  });
  const { getUniqueUnitIds, filterUnits, filterAlternativeUnits } =
    UseUoMHelper();
  const addpdfHandler = () => {
    pdfRef.current.click();
  };
  const navigate = useNavigate();
  const filter = createFilterOptions();

  const { listQueryData } = useUnits({ list: true });

  const pdfRef = useRef();
  const optionsUnits =
    listQueryData?.data?.units
      ?.filter((i) => i?.ignore === 0)
      ?.map((item) => ({
        name: item.symbol,
        id: item.id,
      })) || [];
  const unitIds = getUniqueUnitIds(ingredient.data.units);
  let buyingUnits = ingredient.data.hasUom
    ? optionsUnits?.filter((item) => unitIds.includes(item.id))
    : optionsUnits?.filter((item) => item.name === "kg");
  buyingUnits.push(optionsUnits.find((item) => item.name === "gm"));

  const optionsTags = tagsListQuery?.data?.map((item) => item.name);
  const breadcrumbs = [
    <Link
      underline="none"
      key="1"
      color="#1760D2"
      onClick={(e) => navigate("/ingredients")}
      sx={{ fontSize: "14px", fontWeight: 400 }}
    >
      Ingredients
    </Link>,
    <Typography
      key="2"
      color="inherit"
      sx={{ fontSize: "14px", fontWeight: 400 }}
    >
      {id ? ingredient?.data?.name : "Create New Ingredient"}
    </Typography>,
  ];
  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor("pdf", {
      header: () => "Spec Sheet",
      cell: (info) => {
        const pdfUrl = info?.row?.original;

        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "50%",
            }}
          >
            <img src="/icons/ic_file.jpg" />
            <Typography
              variant="body1"
              sx={{
                whiteSpace: "nowrap",
                flexGrow: 1,
                ml: 1,
              }}
            >
              {pdfUrl}
            </Typography>
          </Box>
        );
      },
    }),

    columnHelper.accessor("id", {
      header: () => "",
      cell: (info) => {
        return (
          <Button
            type="button"
            size="small"
            variant="text"
            color="error"
            onClick={() => handleDeletePdf(info.row.index)}
          >
            remove
          </Button>
        );
      },
    }),
  ];
  const columns2 = [
    {
      header: "Base Qty",
      cell: (row, index) => {
        return (
          <InputControl
            name="base_unit_qty"
            placeholder="Base Qty"
            type="text"
            error={unitValidator.current.message(
              `Base Qty ${index}`,
              row.base_unit_qty,
              "required|customNumeric"
            )}
            helperText={unitValidator.current.message(
              `Base Qty ${index}`,
              row.base_unit_qty,
              "required|customNumeric"
            )}
            onChange={(e) => onTablehandleChange(e, index)}
            value={row.base_unit_qty}
            disabled={ingredient.data.units.length - 1 !== index}
            sx={{
              width: "80px",
            }}
          />
        );
      },
    },
    {
      header: "Base Unit",
      cell: (row, index) => {
        const unitIds = getUniqueUnitIds(ingredient.data.units);
        const baseUnitOptions = filterUnits(listQueryData?.data?.units, [
          "kg",
          "ltr",
          "ml",
        ]).filter((i) => unitIds.includes(i.id));
        return (
          <InputControl
            type="dropdown"
            fullWidth
            options={baseUnitOptions ?? []}
            disabled={index < 1 || ingredient.data.units.length - 1 !== index}
            getOptionLabel={(option) => option?.name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={
              (baseUnitOptions.length > 0 &&
                row.base_unit_id &&
                baseUnitOptions.find((c) => c.id === row.base_unit_id)) ||
              null
            }
            onChange={(e, newValue) =>
              onTableChange(
                {
                  target: {
                    name: "base_unit_id",
                    value: newValue?.id,
                  },
                },
                "base_unit_id",
                index
              )
            }
            sx={{
              width: "150px",
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Unit"
                error={unitValidator.current.message(
                  `Base Unit ${index}`,
                  row.base_unit_id,
                  "required"
                )}
                helperText={unitValidator.current.message(
                  `Base Unit ${index}`,
                  row.base_unit_id,
                  "required"
                )}
              />
            )}
          />
        );
      },
    },
    {
      header: "=",
      cell: (info) => "=",
    },
    {
      header: "Alternative Qty",
      cell: (row, index) => {
        return (
          <InputControl
            type="text"
            name="alt_unit_qty"
            placeholder="Alt Qty"
            sx={{
              width: "80px",
            }}
            error={unitValidator.current.message(
              `Alt Qty ${index}`,
              row.alt_unit_qty,
              "required|customNumeric"
            )}
            helperText={unitValidator.current.message(
              `Alt Qty ${index}`,
              row.alt_unit_qty,
              "required|customNumeric"
            )}
            onChange={(e) => onTablehandleChange(e, index)}
            value={row.alt_unit_qty}
            disabled={ingredient.data.units.length - 1 !== index}
          />
        );
      },
    },
    {
      header: "Alternative Unit",
      cell: (row, index) => {
        let altUnitOptions = filterAlternativeUnits(
          filterUnits(listQueryData?.data?.units, ["ltr", "ml"]),
          index,
          ingredient.data.units
        );
        const mlId = listQueryData?.data?.units?.find(
          (i) => i.symbol === "ml"
        )?.id;
        const ltrId = listQueryData?.data?.units?.find(
          (i) => i.symbol === "ltr"
        )?.id;
        const hasMl = ingredient.data.units.some(
          (item, i) => i !== index && item.alt_unit_id === mlId
        );
        const hasLtr = ingredient.data.units.some(
          (item, i) => i !== index && item.alt_unit_id === ltrId
        );
        if (hasLtr) {
          altUnitOptions = altUnitOptions.filter((i) => i.id !== mlId);
        } else if (hasMl) {
          altUnitOptions = altUnitOptions.filter((i) => i.id !== ltrId);
        }

        return (
          <>
            <InputControl
              type="dropdown"
              fullWidth
              disabled={ingredient.data.units.length - 1 !== index}
              options={altUnitOptions ?? []}
              getOptionLabel={(option) => option?.name}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              value={
                (altUnitOptions?.length > 0 &&
                  row.alt_unit_id &&
                  altUnitOptions?.find((c) => c.id === row.alt_unit_id)) ||
                null
              }
              onChange={(e, newValue) =>
                onTableChange(
                  {
                    target: {
                      name: "alt_unit_id",
                      value: newValue?.id,
                    },
                  },
                  "alt_unit_id",
                  index,
                  newValue
                )
              }
              sx={{
                width: "150px",
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Unit"
                  error={unitValidator.current.message(
                    `Alt Unit ${index}`,
                    row?.alt_unit_id,
                    "required"
                  )}
                  helperText={unitValidator.current.message(
                    `Alt Unit ${index}`,
                    row?.alt_unit_id,
                    "required"
                  )}
                />
              )}
            />
          </>
        );
      },
    },
    {
      header: () => "",
      cell: (row, index) => {
        return (
          <RemoveCircleIcon
            onClick={() => {
              index === ingredient?.data?.units.length - 1 &&
                index !== 0 &&
                handleDelete(row.base_unit_qty, index);
            }}
            sx={{
              color:
                index === ingredient?.data?.units.length - 1 && index !== 0
                  ? "#B42318"
                  : "#BCC7DB",
              cursor:
                index === ingredient?.data?.units.length - 1 && index !== 0
                  ? "pointer"
                  : "",
            }}
          />
        );
      },
    },
  ];

  return (
    <>
      {validator.current.purgeFields()}
      {unitValidator.current.purgeFields()}
      <PageListLayout
        breadcrumbs={breadcrumbs}
        headerText={id ? ingredient?.data?.name : "Create New Ingredient"}
        isChanged={false}
        rightContent={
          <Stack direction="row" gap="8px">
            <Button
              type="button"
              size="small"
              variant="contained"
              color="inherit"
              onClick={() => navigate(-1)}
            >
              Cancel
            </Button>
            <Button
              type="button"
              size="small"
              variant="contained"
              onClick={onSubmit}
              disabled={
                updateIngredient.isPending || createIngredient.isPending
              }
            >
              {id ? "Update" : "Save"}
            </Button>
          </Stack>
        }
        pageContent={
          <>
            {listQueryData?.isLoading ? (
              <Loader />
            ) : (
              <Stack sx={{ borderRadius: "4px", bgcolor: "#fff" }}>
                <Stack
                  sx={{
                    py: "32px",
                    borderBottom: "1px solid #DFE8F2",
                    mx: "20px",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <Typography
                        variant="subtitle1"
                        fontWeight={600}
                        sx={{ width: "150px" }}
                      >
                        Basic Details
                      </Typography>
                    </Grid>
                    <Grid item sm={8}>
                      <Stack width={"100%"} gap="15px">
                        <InputControl
                          name="name"
                          type="text"
                          label="Ingredient Name"
                          required
                          error={validator.current.message(
                            "Ingredient Name",
                            ingredient?.data?.name,
                            "required"
                          )}
                          helperText={validator.current.message(
                            "Ingredient Name",
                            ingredient?.data?.name,
                            "required"
                          )}
                          onChange={onChange}
                          value={ingredient?.data?.name}
                        />
                        <Stack direction="row" gap="16px">
                          <InputControl
                            label="Tax Group"
                            sx={{
                              width: "100%",
                            }}
                            type="dropdown"
                            disableClearable
                            required
                            options={optionsTaxGroup ?? []}
                            getOptionLabel={(option) => option?.label}
                            value={
                              (optionsTaxGroup?.length > 0 &&
                                ingredient?.data?.tax_group_id &&
                                optionsTaxGroup?.find(
                                  (c) =>
                                    c.value === ingredient?.data?.tax_group_id
                                )) ||
                              null
                            }
                            onChange={(e, newValue) => {
                              onChangeTax({
                                target: {
                                  name: "tax_group_id",
                                  value: newValue?.value,
                                },
                              });
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Tax Group"
                                required
                                error={validator.current.message(
                                  "Tax Group",
                                  ingredient?.data?.tax_group_id,
                                  "required"
                                )}
                                helperText={validator.current.message(
                                  "Tax Group",
                                  ingredient?.data?.tax_group_id,
                                  "required"
                                )}
                              />
                            )}
                          />
                          <InputControl
                            label="Type"
                            required
                            sx={{
                              width: "100%",
                            }}
                            type="dropdown"
                            disableClearable
                            options={optionsTypes ?? []}
                            getOptionLabel={(option) => option?.label}
                            value={
                              (optionsTypes?.length > 0 &&
                                ingredient?.data?.type &&
                                optionsTypes?.find(
                                  (c) => c.value === ingredient?.data?.type
                                )) ||
                              null
                            }
                            onChange={(e, newValue) => {
                              onChange({
                                target: {
                                  name: "type",
                                  value: newValue?.value,
                                },
                              });
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Type"
                                required
                                error={validator.current.message(
                                  "Type",
                                  ingredient?.data?.type,
                                  "required"
                                )}
                                helperText={validator.current.message(
                                  "Type",
                                  ingredient?.data?.type,
                                  "required"
                                )}
                              />
                            )}
                          />
                        </Stack>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          width="50%"
                          px="14px"
                        >
                          <Typography variant="subtitle1" fontWeight={600}>
                            Status
                          </Typography>
                          <Switch
                            name="status"
                            checked={
                              ingredient?.data?.status === 1 ? true : false
                            }
                            value={ingredient?.data?.status}
                            onChange={(e) =>
                              onChange({
                                target: {
                                  value: e.target.checked === true ? 1 : 0,
                                  name: "status",
                                },
                              })
                            }
                          />
                        </Stack>
                        <Stack direction="row" alignItems="center">
                          <Typography variant="subtitle2" fontWeight={600}>
                            Category Tags
                          </Typography>
                          <Tooltip
                            placement="bottom"
                            arrow
                            title="Must add one category tag, this tags only used to filter category."
                          >
                            <InfoIcon
                              sx={{ height: "13px", color: "#485364" }}
                            />
                          </Tooltip>
                        </Stack>
                        <Stack
                          sx={{
                            gap: "8px",
                          }}
                        >
                          <InputControl
                            value={ingredient?.data?.tags}
                            type="dropdown"
                            name="tags"
                            label="Category Tags"
                            multiple
                            className="tags-input"
                            isOptionEqualToValue={(option, value) =>
                              option === value
                            }
                            onChange={(event, newValue) => {
                              if (typeof newValue === "string") {
                                handleProductInputChange({
                                  target: {
                                    name: "tags",
                                    value: newValue,
                                  },
                                });
                              } else if (newValue && newValue.inputValue) {
                                // Create a new value from the user input
                                handleProductInputChange({
                                  target: {
                                    name: "tags",
                                    value: newValue.inputValue,
                                  },
                                });
                              } else {
                                handleProductInputChange({
                                  target: {
                                    name: "tags",
                                    value: newValue,
                                  },
                                });
                              }
                            }}
                            options={optionsTags ?? []}
                            filterOptions={(options, params) => {
                              const filtered = filter(options, params);
                              const { inputValue } = params;
                              // Suggest the creation of a new value
                              const isExisting = options.some(
                                (option) => inputValue === option
                              );
                              if (inputValue !== "" && !isExisting) {
                                filtered.push(inputValue);
                              }
                              return filtered;
                            }}
                            selectOnFocus
                            clearOnBlur
                            handleHomeEndKeys
                            id="free-solo-with-text-demo"
                            renderTags={(value, getTagProps) =>
                              value.map((option, index) => <></>)
                            }
                            getOptionLabel={(option) => {
                              // Value selected with enter, right from the input
                              if (typeof option === "string") {
                                return option;
                              }
                              // Add "xxx" option created dynamically
                              if (option.inputValue) {
                                return option.inputValue;
                              }
                              // Regular option
                              return option;
                            }}
                            renderOption={(props, option) => (
                              <li {...props}>{option}</li>
                            )}
                            freeSolo
                            renderInput={(params) => (
                              <TextField {...params} label="Tags" />
                            )}
                          />
                          <Stack
                            sx={{
                              flexDirection: "row",
                              alignItems: "center",
                              flexWrap: "wrap",
                              gap: "12px",
                              marginTop: 1,
                            }}
                          >
                            {ingredient?.data?.tags?.map((data, index) => (
                              <Chip
                                variant="outlined"
                                color="gray"
                                label={data}
                                deleteIcon={<CloseIcon />}
                                onDelete={() => {
                                  onDeleteTagSelect(data);
                                }}
                                sx={{
                                  width: "115px",
                                  "& .MuiChip-deleteIcon": {
                                    color: "#CCCCCC",
                                  },
                                }}
                              />
                            ))}
                          </Stack>
                        </Stack>
                      </Stack>
                    </Grid>
                  </Grid>
                </Stack>
                <Stack
                  sx={{
                    py: "32px",
                    borderBottom: "1px solid #DFE8F2",
                    mx: "20px",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item sm={4}>
                      <Typography
                        variant="subtitle1"
                        fontWeight={600}
                        sx={{ width: "150px" }}
                      >
                        Alternative UoM?
                      </Typography>
                    </Grid>
                    <Grid item sm={8}>
                      <Switch
                        name="hasUom"
                        checked={ingredient.data.hasUom}
                        sx={{ marginLeft: "-12px" }}
                        onChange={(e) =>
                          onChange({
                            target: {
                              value: e.target.checked ? 1 : 0,
                              name: "hasUom",
                            },
                          })
                        }
                      />
                      {ingredient.data.hasUom ? (
                        <Box
                          sx={{
                            p: "0 8px 8px",
                            bgcolor: "#F5F8FC",
                          }}
                        >
                          <BasicMuiTable
                            data={ingredient?.data?.units}
                            columns={columns2}
                            tableFooter={
                              <>
                                <Button
                                  type="button"
                                  size="small"
                                  variant="text"
                                  startIcon={<AddIcon />}
                                  onClick={() =>
                                    handleAddUnits(
                                      ingredient?.data?.units?.length
                                    )
                                  }
                                >
                                  Add Unit
                                </Button>
                              </>
                            }
                          />
                        </Box>
                      ) : null}
                    </Grid>
                  </Grid>
                </Stack>
                <Stack
                  sx={{
                    py: "32px",
                    borderBottom: "1px solid #DFE8F2",
                    mx: "20px",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <Typography
                        variant="subtitle1"
                        fontWeight={600}
                        sx={{ width: "150px" }}
                      >
                        Cost per Unit
                      </Typography>
                      <Typography variant="subtitle2" color="#657083">
                        Add cost per UoM, eg: $10/kg
                      </Typography>
                    </Grid>
                    <Grid item sm={8}>
                      <Stack width={"100%"} gap="15px" direction="row">
                        <InputControl
                          label="Buying Unit"
                          required
                          sx={{
                            width: "292px",
                          }}
                          type="dropdown"
                          disableClearable
                          options={buyingUnits ?? []}
                          getOptionLabel={(option) => option?.name}
                          value={
                            (buyingUnits?.length > 0 &&
                              ingredient?.data?.buying_unit_id &&
                              buyingUnits?.find(
                                (c) => c.id === ingredient?.data?.buying_unit_id
                              )) ||
                            null
                          }
                          onChange={(e, newValue) => {
                            onChange({
                              target: {
                                name: "buying_unit_id",
                                value: newValue?.id,
                              },
                            });
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Buying Unit"
                              required
                              error={validator.current.message(
                                "Type",
                                ingredient?.data?.buying_unit_id,
                                "required"
                              )}
                              helperText={validator.current.message(
                                "Type",
                                ingredient?.data?.buying_unit_id,
                                "required"
                              )}
                            />
                          )}
                        />
                        <InputControl
                          name="cost_exc_tax"
                          type="text"
                          label="Cost Ex GST"
                          required
                          disabled={!ingredient?.data?.tax_group_id}
                          error={validator.current.message(
                            "Cost Ex GST",
                            ingredient?.data?.cost_exc_tax,
                            "required"
                          )}
                          helperText={validator.current.message(
                            "Cost Ex GST",
                            ingredient?.data?.cost_exc_tax,
                            "required"
                          )}
                          onChange={onChange}
                          value={ingredient?.data?.cost_exc_tax}
                        />
                        <InputControl
                          name="cost_inc_tax"
                          type="text"
                          label="Cost In GST"
                          required
                          disabled={!ingredient?.data?.tax_group_id}
                          error={validator.current.message(
                            "Cost In GST",
                            ingredient?.data?.cost_inc_tax,
                            "required"
                          )}
                          helperText={validator.current.message(
                            "Cost In GST",
                            ingredient?.data?.cost_inc_tax,
                            "required"
                          )}
                          onChange={onChange}
                          value={ingredient?.data?.cost_inc_tax}
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                </Stack>
                <Stack
                  sx={{
                    py: "32px",
                    borderBottom: "1px solid #DFE8F2",
                    mx: "20px",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <Typography
                        variant="subtitle1"
                        fontWeight={600}
                        sx={{ width: "150px" }}
                      >
                        Spec Sheet
                      </Typography>
                    </Grid>
                    <Grid item sm={8}>
                      <Stack width={"100%"} gap="15px">
                        <Box
                          sx={{
                            p: "0 8px 8px",
                            bgcolor: "#F5F8FC",
                          }}
                        >
                          <CustomTable
                            defaultData={state.data.pdf ?? []}
                            columns={columns}
                            isPagination={false}
                            isTableHeader={false}
                            isLoading={createPdfUrl.isPending}
                            tableFooter={
                              <Stack
                                direction="row"
                                alignItems="center"
                                gap={2}
                              >
                                <div>
                                  <Button
                                    type="button"
                                    size="small"
                                    variant="text"
                                    startIcon={<AddIcon />}
                                    onClick={(event) =>
                                      addpdfHandler(
                                        event,
                                        state.data.pdf.length
                                      )
                                    }
                                  >
                                    Add Files
                                  </Button>
                                  <input
                                    id="contained-button-file"
                                    type="file"
                                    onChange={(e) => handleDropzonePdf(e)}
                                    style={{ display: "none" }}
                                    ref={pdfRef}
                                  />
                                </div>
                              </Stack>
                            }
                          />
                        </Box>
                      </Stack>

                      <Stack direction="row" mt={1}>
                        <InfoIcon
                          sx={{ height: "13px", color: "#485364", mt: 0.5 }}
                        />
                        <Typography
                          color="#485364"
                          fontSize="14px"
                          fontWeight={400}
                        >
                          Supported format: PDF
                        </Typography>
                      </Stack>
                    </Grid>
                  </Grid>
                </Stack>
              </Stack>
            )}
          </>
        }
      />
    </>
  );
};
