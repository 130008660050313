import React from "react";

import {
  Stack,
  Card,
  Typography,
  Divider,
  Box,
} from "@mui/material";
import emptyIcon from "../../assets/images/Icons/search-table.png";


export const CustomDetailsList = (props) => {
  const {
    columns,
    data,
    title,
    headerRightContent = null,
    marginBottom = "0px",
    style = {},
    emptyTableMessage,
    isEmpty = false,
  } = props;

  const prepareData = columns?.map((val) => {
    if (typeof val.accessor === "string") {
      return {
        ...val,
        accessor: data[val.accessor],
        hidden: val.hidden ? val.hidden(data) : false,
      };
    } else {
      return {
        ...val,
        accessor: val.accessor(data),
        hidden: val.hidden ? val.hidden(data) : false,
      };
    }
  });

  return (
    <Stack sx={{ mb: marginBottom, ...style }}>
      {title && (
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          p="25px 24px 13px 0px"
        >
          <Typography
            variant="h6"
            fontWeight={600}
            lineHeight="19px"
            minWidth="160px"
          >
            {title}
          </Typography>
          {headerRightContent}
        </Stack>
      )}
      <Divider />
      {isEmpty ? (
        <Stack alignItems="center" gap="8px" py="32px">
          <img src={emptyIcon} alt="empty" height={56} width={56} />
          <Typography
            sx={{ fontWeight: 400, fontSize: "14px", color: "#485364" }}
          >
            {emptyTableMessage
              ? emptyTableMessage
              : "No matches found"}
          </Typography>
        </Stack>
      ) : (
        <Stack sx={{
          bgcolor: "#F7FAFC",
          flexDirection: "row",
          p: "8px",
          mt: "8px"
        }}>
          <Box>
            {prepareData?.map((data, index) => {
              return (
                <>
                  <Stack
                    minHeight="48px"
                    justifyContent="center"
                    p="10px"
                    sx={{ display: data.hidden ? "none" : "" }}
                  >
                    <Typography
                      variant="subtitle2"
                      fontWeight={600}
                      minWidth="160px"
                      color="#111C2B"
                    >
                      <>{data.title}</>
                    </Typography>
                  </Stack>
                </>
              );
            })}
          </Box>
          <Stack
            sx={{
              bgcolor: '#fff',
              width: "100%",
              border: "1px solid #DFE8F2",
              borderRadius: "4px",
              boxShadow: "0px 2px 4px 0px rgba(0,0,0,0.06)",

            }}
          >
            {prepareData?.map((data, index) => {
              return (
                <>
                  <Stack
                    minHeight="48px"
                    justifyContent="center"
                    p="10px"
                    width="100%"
                    borderBottom="1px solid #DFE8F2"
                    sx={{ display: data.hidden ? "none" : "" }}
                  >
                    <Typography
                      variant="subtitle2"
                      fontWeight={400}
                      color="#000"
                    >
                      {data.accessor}
                    </Typography>
                  </Stack>
                </>
              );
            })}
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};
