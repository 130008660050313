import { Box, Button, Stack } from "@mui/material";
import Logo from "../../assets/images/Logo/new-logo.png";

export const ErrorFallback = ({ error, resetErrorBoundary }) => {
  return (
    <Box
      sx={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Stack direction="column" alignItems="center">
        <img
          src={Logo}
          alt="logo"
          style={{
            width: "100px",
            height: "100px",
          }}
        />
        <p>Something went wrong</p>
        <Button
          type="button"
          size="medium"
          variant="text"
          color="info"
          onClick={() => window.location.reload()}
        >
          Retry
        </Button>
      </Stack>
    </Box>
  );
};
