import React from "react";
import { Route, Routes } from "react-router-dom";
import {
  AddOrEditRecipe,
  RecipeDetails,
  RecipeList,
  ArchivedRecipeList,
} from "./containers";
import { ProtectedRoute } from "../../shared";

export const Recipe = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<RecipeList />} />
        <Route element={<ProtectedRoute allowedRoute={["recipe-view"]} />}>
          <Route path="/details/:id" element={<RecipeDetails />} />
        </Route>
        <Route element={<ProtectedRoute allowedRoute={["recipe-create"]} />}>
          <Route path="/create" element={<AddOrEditRecipe />} />
        </Route>
        <Route element={<ProtectedRoute allowedRoute={["recipe-modify"]} />}>
          <Route path="/edit/:id" element={<AddOrEditRecipe />} />
        </Route>

        <Route element={<ProtectedRoute allowedRoute={["template-view"]} />}>
          <Route path="/archived-recipe" element={<ArchivedRecipeList />} />
        </Route>
      </Routes>
    </>
  );
};
