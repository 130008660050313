import { useMemo } from "react";
import { useAppScope } from "./useAppScope";

export const useTypeSelect = ({ onChangeTypeHandler }) => {

  const { globalData } = useAppScope();
  const typeOptions = globalData?.Recipe_types;

  const typeValueChangeHandler = (option) => {
    const selectedOption = option ? option.value : "";
    onChangeTypeHandler(selectedOption);
  };

  return { typeOptions, typeValueChangeHandler };
};
