import { API } from "../../../utils/api";

export const getProductionChartList = async ({ query, pagination }) => {
  const { pageIndex, pageSize } = pagination;
  const res = await API.get(
    `production_chart?page=${pageIndex}&per_page=${pageSize}`,
    query
  );
  return res.data.data;
};

export const getFutureChartList = async ({ payload, pagination }) => {
  const { pageIndex, pageSize } = pagination;
  const res = await API.post(
    `future_chart?page=${pageIndex}&per_page=${pageSize}`,
    payload
  );
  return res.data.data;
};

export const getProductionChart = async (payload) => {
  const res = await API.get(`production-chart-detail`, { params: payload });
  return res.data.data;
};

export const getProductionChartTemplateList = async (date) => {
  const res = await API.get(`template-list-date?date=${date}`);
  return res.data.data;
};
export const getStores = async (date) => {
  const res = await API.get(`stores`);
  return res.data.data;
};
export const editTemplates = async (payload) => {
  const res = await API.put(`edit-production-chart`, payload);
  return res.data;
};
