import { useEffect } from "react";
import { useImmer } from "use-immer";

export const useAuthenticationScope = () => {
  const [auth, setAuth] = useImmer({
    isInitialLoad: true,
    data: {
      id: null,
      name: "",
      email: "",
      userType: null,
      token: "",
      stores: [],
      permissions: [],
      roles: [],
    },
  });

  useEffect(() => {
    setAuth((draft) => {
      draft.isInitialLoad = false;
    });
    const localStorageData = JSON.parse(
      localStorage.getItem("recipe-user-data")
    );
    if (localStorageData !== null) {
      setAuth((draft) => {
        draft.data = localStorageData;
      });
    }
  }, []);

  useEffect(() => {
    if (auth.data.id) {
      localStorage.setItem("recipe-user-data", JSON.stringify(auth.data));
    }
  }, [auth]);

  return { auth, setAuth };
};
