import {
  CustomTabPanel,
  DeleteModal,
  Loader,
  PageListLayout,
} from "../../../shared";
import { Tab, Tabs, Box, Stack, Typography, Link } from "@mui/material";
import { useLocation, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { RoleMembersList } from "../components/RoleMembersList";
import { RolePermissionList } from "../components/RolePermissionList";
import { useRolesAndPermissions } from "../hooks";

export const RolesAndPermissionDetails = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  let { id } = useParams();
  const {
    rolesAndPermissions,
    handleChecked,
    handleCheckPermission,
    onDelete,
    deleteInfo,
    resetDeleteInfo,
    confirmDelete,
    confirmDeleteUser,
    handleClickOpen,
    HandleFilterUsers,
    isSelectedUser,
    handleUsersSelect,
    AssignUsersToRolesList,
    handleSelectAll,
    usersListQuery,
    deleteUserInfo,
    onUserDelete,
    resetUserDeleteInfo,
    rolesAndPermissionsDetails,
    isEditable,
    isDeletable,
    isCreateVisible,
  } = useRolesAndPermissions({
    isPermissionList: true,
    isList: false,
    roleId: id,
  });
  const handleChange = (event, newValue) => {
    navigate(`/roles-and-permissions/details/${id}${newValue}`);
  };
  if (rolesAndPermissionsDetails?.isPending) {
    return <Loader />;
  }
  const breadcrumbs = [
    <Link
      underline="none"
      key="1"
      color="#1760D2"
      onClick={(e) => navigate("/roles-and-permissions")}
      sx={{ fontSize: "14px", fontWeight: 400 }}
    >
      Roles & Permissions
    </Link>,
    <Typography
      key="2"
      color="inherit"
      sx={{ fontSize: "14px", fontWeight: 400 }}
    >
      {rolesAndPermissions?.data?.name}
    </Typography>,
  ];

  return (
    <>
      <PageListLayout
        breadcrumbs={breadcrumbs}
        headerText={
          <>
            <Stack sx={{ alignItems: "center" }} direction="row" gap="8px">
              <Typography
                key="3"
                sx={{
                  fontSize: "24px",
                  fontWeight: 600,
                  lineHeight: "29.05px",
                  color: "#111C2B",
                }}
              >
                {rolesAndPermissions?.data?.name}
              </Typography>
            </Stack>
          </>
        }
        rightContent={
          rolesAndPermissions.details.level === 0 && (
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
            >
              {isEditable && (
                <img
                  alt="edit"
                  onClick={(e) => navigate(`/roles-and-permissions/edit/${id}`)}
                  src="/icons/ic_edit.png"
                  style={{ cursor: "pointer" }}
                />
              )}
              {isDeletable && (
                <img
                  alt="delete"
                  onClick={(e) => onDelete(id)}
                  src="/icons/ic_trash_sm.png"
                  style={{ cursor: "pointer" }}
                />
              )}
            </Stack>
          )
        }
        pageContent={
          <>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                px: "0 16px 0 16",
                bgcolor: "#fff",
                gap: "20px",
              }}
            >
              <Tabs
                sx={{ px: "0 16px 0 16", gap: "20px" }}
                value={search}
                onChange={handleChange}
              >
                <Tab label="Permissions" value={""} />
                <Tab label="Members" value={"?members"} />
              </Tabs>
            </Box>
            <CustomTabPanel value={search} index={""}>
              <RolePermissionList
                state={rolesAndPermissions}
                handleChecked={handleChecked}
                handleCheckPermission={handleCheckPermission}
              />
            </CustomTabPanel>
            <CustomTabPanel value={search} index={"?members"}>
              <RoleMembersList
                state={rolesAndPermissions}
                onDelete={onUserDelete}
                confirmDeleteUser={confirmDeleteUser}
                resetDeleteInfo={resetUserDeleteInfo}
                deleteInfo={deleteUserInfo}
                handleClickOpen={handleClickOpen}
                HandleFilterUsers={HandleFilterUsers}
                isSelectedUser={isSelectedUser}
                handleUsersSelect={handleUsersSelect}
                handleAssignUser={AssignUsersToRolesList}
                handleSelectAll={handleSelectAll}
                usersListQuery={usersListQuery}
                isCreateVisible={isCreateVisible}
                isDeletable={isDeletable}
              />
            </CustomTabPanel>
          </>
        }
      />
      <DeleteModal
        open={deleteInfo?.show}
        handleClose={() => resetDeleteInfo()}
        onConfirm={() => confirmDelete()}
      />
    </>
  );
};
