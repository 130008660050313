import { Box, Grid, Stack, Tab, Tabs, Typography } from "@mui/material";
import React, { useState } from "react";
import { CustomTabPanel } from "../../../shared";
import arrowIcon from "../../../assets/images/Icons/Vector (9).svg";
import { Batches, Recipes } from "../components";
import { formatWeightWithUnits } from "../../../utils";

export const CommonTab = ({
  state,
  templates,
  handleOptionChangeRecipe,
  handleOptionChangeRecipeBatch,
}) => {
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getBatchesLengthStyles = () => {
    if (value === 0) {
      return {
        backgroundColor: "white",
        color: "black",
        padding: "2px 6px",
        borderRadius: "10px",
      };
    } else {
      return {
        backgroundColor: "black",
        color: "white",
        padding: "2px 6px",
        borderRadius: "10px",
      };
    }
  };
  const batchesLength = state?.data?.batches?.length || 0;
  const recipesLength = state?.data?.other_recipes?.length || 0;
  const getRecipeLengthStyles = () => {
    if (value === 1) {
      return {
        backgroundColor: "white",
        color: "black",
        padding: "2px 6px",
        borderRadius: "10px",
      };
    } else {
      return {
        backgroundColor: "black",
        color: "white",
        padding: "2px 6px",
        borderRadius: "10px",
      };
    }
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={5} md={4}>
          <Box
            sx={{
              borderRadius: "4px",
              py: "6px",
              px: "6px",
            }}
          >
            <Tabs
              className="styled-tabs"
              value={value}
              onChange={handleChange}
              sx={{
                marginLeft: "0",
              }}
            >
              <Tab
                label={
                  <Stack direction="row" gap="10px">
                    Batches
                    <span style={getBatchesLengthStyles()}>
                      {batchesLength}
                    </span>
                  </Stack>
                }
              />
              <Tab
                label={
                  <Stack direction="row" gap="10px">
                    Recipes
                    <span style={getRecipeLengthStyles()}>{recipesLength}</span>
                  </Stack>
                }
              />
            </Tabs>
          </Box>

          {value === 0 && (
            <>
              <Stack
                direction="row"
                justifyContent="space-between"
                mt={1}
                ml={1}
              >
                <Typography fontWeight={600} fontSize="12px">
                  Recipe Name
                </Typography>
                <Typography fontWeight={600} fontSize="12px">
                  Quantity
                </Typography>
              </Stack>
              {state?.data?.batches?.map((item, index) => (
                <>
                  <Stack
                    height="36px"
                    ml={1}
                    border="1px solid #DFE8F2"
                    color={
                      index === templates.indexColumn ? "#FFFFFF" : "#263141"
                    }
                    bgcolor={
                      index === templates.indexColumn ? "#1760D2" : "#FFFFFF"
                    }
                    onClick={() => handleOptionChangeRecipeBatch(item, index)}
                  >
                    <Stack flexDirection="row" justifyContent="space-between">
                      <Stack direction="row" gap={1} ml={1}>
                        <Typography
                          fontWeight={400}
                          fontSize="14px"
                          alignContent="center"
                          mt={1}
                        >
                          {item?.recipe_name}
                        </Typography>
                      </Stack>
                      <Stack
                        direction="row"
                        gap={1}
                        alignItems="center"
                        alignContent="center"
                        mr={1}
                        mt={1}
                      >
                        <Typography fontSize="12px" fontWeight={500}>
                          {formatWeightWithUnits(
                            item?.total_weight,
                            item?.total_weight_unit,
                            3
                          )}
                        </Typography>

                        <img
                          alt="details"
                          src={arrowIcon}
                          style={{
                            height: "10px",
                          }}
                        />
                      </Stack>
                    </Stack>
                  </Stack>
                </>
              ))}
            </>
          )}

          {value === 1 && (
            <>
              <Stack
                direction="row"
                justifyContent="space-between"
                mt={1}
                ml={1}
              >
                <Typography fontWeight={600} fontSize="12px">
                  Recipe Name
                </Typography>
                <Typography fontWeight={600} fontSize="12px">
                  Quantity
                </Typography>
              </Stack>
              {state?.data?.other_recipes?.map((item, index) => (
                <>
                  <Stack
                    height="36px"
                    ml={1}
                    border="1px solid #DFE8F2"
                    color={
                      index === templates?.indexColumn ? "#FFFFFF" : "#263141"
                    }
                    bgcolor={
                      index === templates?.indexColumn ? "#1760D2" : "#FFFFFF"
                    }
                    onClick={() => handleOptionChangeRecipe(item, index)}
                  >
                    <Stack flexDirection="row" justifyContent="space-between">
                      <Stack direction="row" gap={1} ml={1}>
                        <Typography
                          fontWeight={400}
                          fontSize="14px"
                          alignContent="center"
                          mt={1}
                        >
                          {item?.recipe_name}
                        </Typography>
                      </Stack>
                      <Stack
                        direction="row"
                        gap={1}
                        alignItems="center"
                        alignContent="center"
                        mr={1}
                        mt={1}
                      >
                        <Typography>
                          {formatWeightWithUnits(
                            item?.total_quantity,
                            item?.selling_unit_name,
                            3
                          )}
                        </Typography>

                        <img
                          alt="details"
                          src={arrowIcon}
                          style={{
                            height: "10px",
                          }}
                        />
                      </Stack>
                    </Stack>
                  </Stack>
                </>
              ))}
            </>
          )}
        </Grid>
        <Grid item xs={6} md={8}>
          <CustomTabPanel value={value} index={0}>
            <Batches selectedOption={templates?.templateBatchItems} />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <Recipes selectedOptionRecipe={templates?.templateItems} />
          </CustomTabPanel>
        </Grid>
      </Grid>
    </>
  );
};
