import { useLocation, Link, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Collapse,
  Divider,
  Popover,
  Stack,
  Typography,
} from "@mui/material";
import { AuthContext } from "../../../store";

export const ProfileCard = (props) => {
  const { collapse } = props;
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { auth } = useContext(AuthContext);
  const isStoreAdmin = pathname.includes("store-admin");
  const isSuperAdminPermission = auth?.data?.roles?.find(
    (item) => item.name === "admin"
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClickMenu = (event, index) => {
    !anchorEl && setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const isStoreAdminPermission =
    auth?.data?.roles?.find((item) => item.name === "store-admin") &&
    auth.data.stores.length;

  const navigateSwitchDependingOnUser = () => {
    isStoreAdmin ? navigate("/") : navigate("/store-admin/");
  };

  const handleLogout = async () => {
    localStorage.clear();
    sessionStorage.clear();
    window.location.pathname = "/authentication/login";
  };

  return (
    <Box
      sx={{
        m: collapse ? "0px 12px 16px" : "0px 10.5px 16px",
      }}
    >
      <Box
        sx={{
          borderRadius: "8px",
          overflow: "hidden",
        }}
      >
        <Stack
          sx={{
            flexDirection: "row",
            alignItems: "center",
            gap: "8px",
            backgroundColor: collapse ? "#FFFCEB" : "",
            transition: "background-color .7s ease",
            p: "4px 8px 4px 6px",
            textAlign: "left",
          }}
        >
          <Avatar alt="user" sx={{ height: "32px", width: "32px" }} />
          {collapse ? (
            <>
              <Typography
                variant="subtitle2"
                sx={{
                  fontWeight: 500,
                  color: "#000",
                  width: "100%",
                  whiteSpace: "nowrap",
                }}
              >
                {auth?.data?.first_name + " " + auth?.data?.last_name}
              </Typography>

              <img
                src={
                  open
                    ? "/icons/ic_direction_arrows.svg"
                    : "/icons/ic_more_horizontal.svg"
                }
                alt="menu"
                style={{
                  cursor: "pointer",
                }}
                onClick={(e) => {
                  handleClickMenu(e);
                }}
              />
            </>
          ) : (
            ""
          )}
        </Stack>

        <Popover
          open={collapse && openMenu}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          className="profile-popover"
        >
          <Box
            sx={{
              background: "#fff",
            }}
          >
            <Stack
              sx={{
                flexDirection: "row",
                padding: "12px 8px",
                gap: "8px",
              }}
            >
              <Avatar alt="user" sx={{ height: "40px", width: "40px" }} />
              <Stack gap="2px">
                <Typography variant="subtitle1" lineHeight="19px">
                  {auth?.data?.first_name + " " + auth?.data?.last_name}
                </Typography>
                <Typography variant="caption" color="#636A75" lineHeight="15px">
                  {auth?.data.email}
                </Typography>
                <Typography variant="caption" color="#636A75" lineHeight="15px">
                  +{auth?.data.country_code} {auth.data.phone}
                </Typography>
              </Stack>
            </Stack>
            <Divider sx={{ m: "0 -8px" }} />
            <Box
              sx={{
                p: "8px",
                textAlign: "left",
              }}
            >
              {/* <Stack sx={{
              flexDirection: "row",
              p: "6px",
              gap: "8px",
              cursor: "pointer"
            }}>
              <img alt="profile" src="/icons/ic_user.svg" />
              <Typography variant="subtitle2" sx={{
                fontWeight: 500,
                color: "#000",
                width: "100%"
              }}>
                Edit Profile
              </Typography>
            </Stack> */}
              {(!isStoreAdmin && isStoreAdminPermission) ||
              (isStoreAdmin && isSuperAdminPermission) ? (
                <Stack
                  sx={{
                    flexDirection: "row",
                    p: "6px",
                    gap: "8px",
                    cursor: "pointer",
                    alignItems: "center",
                  }}
                  onClick={() => navigateSwitchDependingOnUser()}
                >
                  <img alt="SA" src="/icons/Repeat.svg" />
                  <Typography
                    variant="subtitle2"
                    sx={{
                      fontWeight: 500,
                      color: "#000",
                      width: "100%",
                    }}
                  >
                    Switch to {isStoreAdmin ? "Super Admin" : "Store Admin"}
                  </Typography>
                </Stack>
              ) : (
                ""
              )}
            </Box>
            <Divider sx={{ m: "0 -8px" }} />
            <Stack
              sx={{
                p: "8px 8px 12px",
                cursor: "pointer",
              }}
              onClick={handleLogout}
            >
              <Typography
                variant="subtitle2"
                sx={{
                  fontWeight: 500,
                  color: "#000",
                  width: "100%",
                  textAlign: "center",
                  fontWeight: "600",
                }}
              >
                Log Out
              </Typography>
            </Stack>
          </Box>
        </Popover>
      </Box>
    </Box>
  );
};
