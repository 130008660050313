import { useMutation, useQueryClient } from "@tanstack/react-query";

import { errorMessage, successMessage } from "../../../utils/helper";
import { onUpdateOverHead, onUpdateRetailsPrice } from "../api";
import { useRecipeKeys } from "./useRecipeKeys";

export const useCostAndPriceQueries = ({ recipeId }) => {
  const queryClient = useQueryClient();
  const { recipeKeys } = useRecipeKeys();
  const updateRetailPrice = useMutation({
    mutationFn: onUpdateRetailsPrice,
    onError: (error) => {
      errorMessage(error.response.data.message);
    },
    onSuccess: (data, variables) => {
      successMessage("Cost updated succesfully");
      variables.onEditClick("isEditPrice");
      variables.updateCostAndProfit(data);
    },
  });

  const updateOverHead = useMutation({
    mutationFn: onUpdateOverHead,
    onError: (error) => {
      errorMessage(error.response.data.message);
    },
    onSuccess: (data, variables) => {
      successMessage("Overhead cost updated successfully");
      variables.onEditClick("isEditoverHead");
      variables.updateCostAndProfit(data);

      queryClient.refetchQueries({
        queryKey: recipeKeys.detailBykEY("ingredients", recipeId),
        type: "active",
        exact: true,
      });
    },
  });
  return { updateRetailPrice, updateOverHead };
};
