import React, { useEffect, useRef } from "react";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { DateRangePicker } from "react-date-range";
import { Button, Popper, Stack, Typography } from "@mui/material";
import format from "date-fns/format";
import {
  addDays,
  isSameDay,
  startOfDay,
  endOfDay,
  differenceInCalendarDays,
} from "date-fns";
import enUS from "date-fns/locale/en-US"; // or your locale

import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { useImmer } from "use-immer";

export const DashBoardDateRangePicker = (props) => {
  const {
    onChangeDate,
    range,
    showRange,
    isOpen,
    handleIsOpen,
    handleDateChangeApply,
    isList,
    text,
    isInverted = false,
    buttonText,
    maxDate,
    minDate,
    isResetButton = false,
  } = props;
  // get the target element to toggle
  const refOne = useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);

  useEffect(() => {
    document.addEventListener("keydown", hideOnEscape, true);
    document.addEventListener("click", hideOnClickOutside, true);
    return () => {
      document.addEventListener("keydown", hideOnEscape, true);
      document.addEventListener("click", hideOnClickOutside, true);
    };
  }, [refOne]);

  // hide dropdown on ESC press
  const hideOnEscape = (e) => {
    if (e.key === "Escape") {
      handleIsOpen(false);
      setAnchorEl(e.currentTarget);
    }
  };

  // Hide dropdown on outside click
  const hideOnClickOutside = (e) => {
    if (refOne.current && !refOne.current.contains(e.target)) {
      handleIsOpen(false);
      setAnchorEl(e.currentTarget);
    }
  };
  const defineds = {
    startOfToday: startOfDay(new Date()),
    endOfToday: endOfDay(new Date()),
  };

  const defaultInputRanges = [
    {
      label: "days up to today",
      range(value) {
        return {
          startDate: addDays(
            defineds.startOfToday,
            (Math.max(Number(value), 1) - 1) * -1
          ),
          endDate: defineds.endOfToday,
        };
      },
      getCurrentValue(range) {
        if (!isSameDay(range.endDate, defineds.endOfToday)) return "-";
        if (!range.startDate) return "∞";
        return (
          differenceInCalendarDays(defineds.endOfToday, range.startDate) + 1
        );
      },
    },
  ];

  return (
    <div className="calendarWrap">
      <Stack
        sx={{
          display: "flex",
          flexDirection: isInverted ? "row" : "row-reverse",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "8px",
          border: "1px solid #DFE8F2",
          height: "36px",
          borderRadius: "10px",
          padding: "8px 12px",
          minWidth: "110px",
        }}
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
          handleIsOpen(!isOpen);
        }}
      >
        <Typography variant="subtitle2" fontWeight={500}>
          {showRange?.length > 0
            ? `${format(showRange[0].startDate, "MMM dd, yyyy")} - ${format(
                showRange[0].endDate,
                "MMM dd, yyyy"
              )}`
            : text ?? "Date"}
        </Typography>
        {!isList && (
          <img
            alt="calendar"
            src="/icons/ic_calendar.png"
            style={{ height: "24px" }}
          />
        )}
        {isList && (
          <CalendarTodayIcon
            sx={{
              color: "rgba(0, 0, 0, 0.54)",
              width: "24px",
              height: "24px",
            }}
          />
        )}
      </Stack>
      <Popper
        className="calendarElement"
        ref={refOne}
        open={isOpen}
        anchorEl={anchorEl}
        sx={{
          zIndex: "9999",
          border: "none",
          borderRadius: "8px",
          boxShadow: "0px 1px 45px rgb(0 0 0 / 16%)",
          "&.rdrCalendarWrapper": {
            borderRadius: "8px",
            "&.rdrMonthAndYearWrapper": {
              paddingTop: 0,
            },
          },
        }}
        placement="auto"
      >
        <Stack>
          <DateRangePicker
            // color="#1560D4"
            onChange={(item) => onChangeDate([item.selection])}
            // editableDateInputs={true}
            // moveRangeOnFirstSelection={false}
            ranges={range}
            months={isList ? 1 : 2}
            direction="horizontal"
            inputRanges={[]}
            // renderStaticRangeLabel={<></>}
            staticRanges={[]}
            // showDateDisplay={false}
            locale={enUS}
            maxDate={maxDate}
            minDate={minDate}
          />
          <Stack
            sx={{
              p: "8px 16px",
              flexDirection: "row",
              gap: "16px",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="contained"
              size="small"
              onClick={() => handleDateChangeApply()}
            >
              {buttonText || "Apply"}
            </Button>
            {isResetButton && (
              <Button
                variant="outlined"
                size="small"
                onClick={() => onChangeDate([])}
                sx={{
                  position: "absolute",
                  bottom: "10px",
                  left: "100px",
                  height: "34px",
                }}
              >
                Reset
              </Button>
            )}
          </Stack>
        </Stack>
      </Popper>
    </div>
  );
};
