import {
  Box,
  Button,
  Chip,
  Link,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import React from "react";
import {
  CustomDetailsList,
  CustomeDialog,
  CustomTable,
  DeleteModal,
  Loader,
  PageListLayout,
} from "../../../shared";
import { useNavigate, useParams } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { useAdminUsers } from "../hooks";
import { createColumnHelper } from "@tanstack/react-table";
import {
  AddRoleDialog,
  DeleteRoleFromUserModal,
  MacidSecurityDialog,
} from "../components";

export const AdminUsersDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    state,
    onDelete,
    userDetails,
    resetDeleteInfo,
    confirmDelete,
    deleteInfo,
    handleModal,
    removeRoleFromUser,
    onRoleAssign,
    onChangeRole,
    rolesListQuery,
    handleDisableMacid,
    handleSecurityDialogOpen,
    onSubmitMacidSecurity,
    isDeletable,
    isRoleCreateVisible,
    isRoleDeletable,
    isRoleEditable,
  } = useAdminUsers({
    userId: id,
    isList: false,
  });
  const breadcrumbs = [
    <Link
      underline="none"
      key="1"
      color="#1760D2"
      onClick={(e) => navigate("/manage-users")}
      sx={{ fontSize: "14px", fontWeight: 400 }}
    >
      Manage Users
    </Link>,
    <Typography
      key="2"
      color="inherit"
      sx={{ fontSize: "14px", fontWeight: 400 }}
    >
      {state?.data?.name}
    </Typography>,
  ];

  const columnHelper = createColumnHelper();

  const columns = [
    {
      title: "Name",
      accessor: "name",
    },
    {
      title: "Email",
      accessor: "email",
    },
    {
      title: "Time Zone",
      accessor: "timezone",
    },
  ];

  const columns2 = [
    columnHelper.accessor("display_name", {
      header: () => "Store Name",
    }),
  ];
  const columns3 = [
    columnHelper.accessor("display_name", {
      header: () => "Role Name",
    }),
    columnHelper.accessor("actions", {
      header: () => "",
      align: "right",
      cell: ({ row }) => {
        return (
          <Stack
            justifyContent="end"
            sx={{ cursor: "pointer", width: "fit-content", ml: "auto" }}
            onClick={() =>
              handleModal(
                "delete-role",
                true,
                row.original.id,
                row.original.display_name
              )
            }
          >
            {isRoleDeletable && <img alt="delete" src="/icons/ic_trash.svg" />}
          </Stack>
        );
      },
    }),
  ];

  return (
    <>
      <PageListLayout
        breadcrumbs={breadcrumbs}
        isChanged={true}
        pageContent={
          <>
            <Stack
              direction="row"
              justifyContent="space-between"
              height="62px"
              bgcolor="white"
              sx={{
                position: "sticky",
                zIndex: "99px",
                top: "0px",
              }}
            >
              <Stack alignSelf="center" direction="row" gap={0.5} ml={3}>
                <Typography variant="h6" fontWeight={600} fontSize="24px">
                  {userDetails?.data?.name}
                </Typography>
                <Box mt={0.5}>
                  {userDetails.data?.status ? (
                    <Chip
                      size="small"
                      color="primary"
                      variant="filled"
                      label="ACTIVE"
                    />
                  ) : (
                    <Chip size="small" color="gray" label="INACTIVE" />
                  )}
                </Box>
              </Stack>
              <Stack direction="row" gap={2} alignSelf="center" mr={2}>
                {isDeletable && (
                  <img
                    alt="delete"
                    onClick={(e) => onDelete(id)}
                    src="/icons/ic_trash.svg"
                    style={{ cursor: "pointer", width: "24px" }}
                  />
                )}
              </Stack>
            </Stack>
            {userDetails?.isLoading ? (
              <Loader />
            ) : (
              <Stack
                sx={{
                  borderRadius: "4px",
                  bgcolor: "#fff",
                  px: "16px",
                  gap: "20px",
                }}
              >
                <CustomDetailsList
                  columns={columns}
                  data={userDetails?.data}
                  title="Basic Details"
                />

                <Box
                  sx={{
                    padding: "16px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderRadius: "8px",
                    bgcolor: "#F5F8FC",
                  }}
                >
                  <Box display="flex" alignItems="center" gap={2}>
                    <Box>
                      <Typography fontWeight={600} fontSize="16px">
                        MAC ID Security
                      </Typography>
                      <Typography fontWeight={400} fontSize="14px">
                        Disabling MAC ID security will allow this user to access
                        the Recipe App from any MAC ID.
                      </Typography>
                    </Box>
                  </Box>

                  <Switch
                    color="primary"
                    checked={state?.mac_security}
                    onClick={(e) =>
                      state?.mac_security
                        ? handleSecurityDialogOpen(true)
                        : handleDisableMacid(e, true)
                    }
                    disabled={!isRoleEditable}
                  />
                </Box>

                <CustomTable
                  defaultData={userDetails?.data?.role}
                  columns={columns3}
                  isPagination={false}
                  headerText="Roles"
                  showSearchBar={false}
                  emptyTableMessage={"No roles assigned"}
                  rightContent={
                    <>
                      {isRoleCreateVisible && (
                        <Button
                          sx={{
                            border: "1px solid #BCC7DB",
                            bgcolor: "#F5F8FC",
                          }}
                          type="button"
                          size="small"
                          variant="outlined"
                          color="gray"
                          startIcon={<AddIcon />}
                          onClick={() => handleModal("add-role", true)}
                        >
                          Role
                        </Button>
                      )}
                    </>
                  }
                />
                <CustomTable
                  defaultData={userDetails?.data?.stores}
                  columns={columns2}
                  isPagination={false}
                  headerText="Stores"
                  rightContent=" "
                />
              </Stack>
            )}
          </>
        }
      />
      <CustomeDialog
        title="Add Role"
        PaperProps={{
          sx: {
            minWidth: "482px",
            minHeight: "200px",
            padding: "20px 16px 20px 16px",
          },
        }}
        open={state?.addRoleOpen}
        CloseIcons={false}
        content={
          <AddRoleDialog
            onChange={onChangeRole}
            onSubmit={onRoleAssign}
            state={state}
            options={rolesListQuery.data ?? []}
            handleClose={() => handleModal("add-role", false)}
          />
        }
      />
      <DeleteModal
        open={deleteInfo?.show}
        handleClose={() => resetDeleteInfo()}
        onConfirm={() => confirmDelete()}
      />
      <DeleteRoleFromUserModal
        open={state?.deleteRoleOpen}
        handleClose={() => handleModal("delete-role", false, null)}
        onConfirm={() => removeRoleFromUser()}
        userName={userDetails?.data?.name}
        display_name={state?.display_name}
      />
      <CustomeDialog
        PaperProps={{
          sx: {
            width: "500px",
          },
        }}
        closeIcon={false}
        open={state?.isDialogOpen}
        title={
          <Stack
            direction="row"
            justifyContent="space-between"
            position="sticky"
            zIndex={999}
            bgcolor="#fff"
            top={0}
          >
            <Stack direction="row" alignItems="center" ml={2}>
              <img src="/icons/ic_pair_dialog.svg" />
              <Typography
                fontWeight={500}
                variant="body1"
                padding="15px"
                fontSize={20}
              >
                Disable MAC ID Security?
              </Typography>
            </Stack>
          </Stack>
        }
        content={
          <MacidSecurityDialog
            onSubmit={onSubmitMacidSecurity}
            handleSecurityDialogOpen={handleSecurityDialogOpen}
          />
        }
      />
    </>
  );
};
