import {
  Button,
  Grid,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import { InputControl } from "../../../shared";
import { useParams } from "react-router";
import { DropzoneComponent } from "../../../shared/components/DropzoneComponent";
import InfoIcon from "@mui/icons-material/Info";
import { arraySort } from "../../../utils";

export const BasicDetails = (props) => {
  const { id } = useParams();

  const {
    basicdetails,
    basicDetailsHandleDropzone,
    validator,
    categoryIds,
    CategoryChangeHandler,
    SubcategoryChangeHandler,
    typeData,
    TypeHandler,
    basicDetailsOnSwitchChange,
    onHandleChange,
    handleRemoveImage,
    basicDetailsOnChange,
    createImageUrl,
    pluOptions,
    optionsTaxGroup,
  } = props;

  const {
    name,
    type,
    status,
    is_batch_group,
    bowl_capacity,
    image,
    subcategories,
    plu,
    tax_group_id,
  } = basicdetails.data;

  const { media } = basicdetails;

  return (
    <Stack sx={{ borderRadius: "4px", bgcolor: "#fff" }}>
      {validator.current.purgeFields()}
      <Stack sx={{ borderBottom: "1px solid #DFE8F2" }} py="14px">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Typography
              variant="subtitle1"
              fontWeight={600}
              sx={{ width: "150px" }}
            >
              Basic Details
            </Typography>
          </Grid>
          <Grid item sm={8}>
            <Stack width={"100%"} gap="15px">
              <Stack direction="row" gap="530px">
                <Typography
                  variant="subtitle1"
                  fontWeight={600}
                  sx={{ width: "150px" }}
                >
                  Recipe Image
                </Typography>

                <Button
                  sx={{
                    width: "150px",
                  }}
                  color="error"
                  onClick={handleRemoveImage}
                >
                  Remove Image
                </Button>
              </Stack>

              <DropzoneComponent
                loading={createImageUrl?.isPending}
                handleDropzone={basicDetailsHandleDropzone}
                imgUrl={media ? URL.createObjectURL(media) : id ? image : ""}
              />
              <Tooltip
                placement="bottom"
                arrow
                title="Upload an image with 1:1 ratio and less than 1mb"
              >
                <InfoIcon sx={{ height: "13px", color: "#485364" }} />
              </Tooltip>

              <InputControl
                sx={{
                  width: "100%",
                }}
                type="text"
                name="name"
                label="Recipe Name"
                required
                value={name}
                onChange={basicDetailsOnChange}
                error={validator.current.message("name", name, "required")}
                helperText={validator.current.message("name", name, "required")}
              />
              <Stack direction="row" gap="16px">
                <InputControl
                  type="dropdown"
                  fullWidth
                  options={arraySort(categoryIds, "label")}
                  value={
                    categoryIds.find(
                      (value) => value.value === basicdetails.data.category_id
                    ) || null
                  }
                  onChange={(event, option) =>
                    CategoryChangeHandler(event, option)
                  }
                  getOptionLabel={(option) => option?.label || ""}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  sx={{
                    minWidth: "130px",
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Category"
                      name="category_id"
                      required
                      error={validator.current.message(
                        "category_id",
                        basicdetails.data.category_id,
                        "required"
                      )}
                      helperText={validator.current.message(
                        "category_id",
                        basicdetails.data.category_id,
                        "required"
                      )}
                    />
                  )}
                />
                <InputControl
                  type="dropdown"
                  fullWidth
                  options={arraySort(subcategories, "name") || []}
                  value={
                    subcategories?.find(
                      (value) => value.id === basicdetails.data.subcategory_id
                    ) || null
                  }
                  onChange={(event, option) =>
                    SubcategoryChangeHandler(event, option)
                  }
                  getOptionLabel={(option) => option?.name || ""}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  sx={{
                    minWidth: "130px",
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Sub Category"
                      required
                      error={validator.current.message(
                        "subcategory_id",
                        basicdetails.data.subcategory_id,
                        "required"
                      )}
                      helperText={validator.current.message(
                        "subcategory_id",
                        basicdetails.data.subcategory_id,
                        "required"
                      )}
                    />
                  )}
                />
              </Stack>
              <Stack direction="row" gap="16px">
                <InputControl
                  type="dropdown"
                  fullWidth
                  name="type"
                  options={typeData}
                  value={
                    typeData?.find(
                      (value) => value.value === basicdetails?.data?.type
                    ) || null
                  }
                  onChange={(event, option) =>
                    TypeHandler(event, option, "type")
                  }
                  getOptionLabel={(option) => option?.label || ""}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  sx={{
                    minWidth: "130px",
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Type"
                      error={validator.current.message(
                        "type",
                        type,
                        "required"
                      )}
                      helperText={validator.current.message(
                        "type",
                        type,
                        "required"
                      )}
                      required
                    />
                  )}
                />

                <InputControl
                  type="dropdown"
                  fullWidth
                  name="PLU Code"
                  options={pluOptions ?? []}
                  disabled={type !== "finishedproduct"}
                  value={
                    pluOptions?.find(
                      (value) => value.value === basicdetails?.data?.plu
                    ) || null
                  }
                  onChange={(event, option) =>
                    TypeHandler(event, option, "plu")
                  }
                  getOptionLabel={(option) => option?.label || ""}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  sx={{
                    minWidth: "130px",
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Type PLU Code here"
                      label={
                        type === "finishedproduct" ? "PLU Code" : "PLU Code"
                      }
                    />
                  )}
                  filterOptions={(options, { inputValue }) =>
                    options.filter((option) =>
                      option.product
                        .toLowerCase()
                        .includes(inputValue.toLowerCase())
                    )
                  }
                  renderOption={(props, option) => (
                    <li {...props}>
                      <div>
                        {option.label}
                        <div style={{ fontSize: "12px", color: "#666" }}>
                          {option.product}
                        </div>
                      </div>
                    </li>
                  )}
                />
              </Stack>
              <Stack direction="row" gap="16px">
                <InputControl
                  type="dropdown"
                  fullWidth
                  name="Tax Group"
                  options={optionsTaxGroup ?? []}
                  value={
                    optionsTaxGroup?.find(
                      (value) =>
                        value.value === basicdetails?.data?.tax_group_id
                    ) || null
                  }
                  onChange={(event, option) =>
                    TypeHandler(event, option, "tax_group_id")
                  }
                  getOptionLabel={(option) => option?.label || ""}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  sx={{
                    minWidth: "130px",
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Tax Group"
                      label="Tax Group"
                      error={validator.current.message(
                        "Tax Group",
                        tax_group_id,
                        "required"
                      )}
                      helperText={validator.current.message(
                        "Tax Group",
                        tax_group_id,
                        "required"
                      )}
                      required
                    />
                  )}
                />
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Stack>
      <Stack sx={{ py: "62px", borderBottom: "1px solid #DFE8F2" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Typography
              variant="subtitle1"
              fontWeight={600}
              sx={{ width: "150px" }}
            >
              Batch Settings
            </Typography>
          </Grid>
          <Grid item sm={8}>
            <Stack
              sx={{
                p: "16px",
                borderRadius: "12px",
                border: "1px solid #DFE8F2",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Stack>
                <Typography
                  variant="subtitle1"
                  fontWeight={600}
                  size={"16px"}
                  lineHeight={"19.36px"}
                >
                  Is It a Batch-Group?
                </Typography>
                <Typography
                  size={"14px"}
                  lineHeight={"16.px"}
                  fontWeight={400}
                  variant="subtitle2"
                  color="#485364"
                >
                  If the recipe is also a batch-group, then it will be batched
                  in production charts.
                </Typography>
              </Stack>
              <Switch
                name="is_batch_group"
                checked={is_batch_group ? true : false}
                value={is_batch_group}
                onChange={onHandleChange}
              />
            </Stack>

            {is_batch_group === 1 && (
              <Stack width={"100%"} gap="15px">
                <Stack
                  sx={{
                    p: "16px",
                    borderRadius: "12px",
                    border: "1px solid #DFE8F2",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography alignItems="center" variant="subtitle1">
                    Bowl Capacity
                  </Typography>
                  <InputControl
                    sx={{
                      width: "552px",
                      borderRadius: "10px",
                      minHeight: "32px",
                    }}
                    type="number"
                    name="bowl_capacity"
                    label="Capacity"
                    placeholder="Capacity(in kg)"
                    value={bowl_capacity}
                    onChange={basicDetailsOnChange}
                  />
                </Stack>
              </Stack>
            )}
          </Grid>
        </Grid>
      </Stack>
    </Stack>
  );
};
