import { useEffect, useRef, useState } from "react";
import SimpleReactValidator from "simple-react-validator";
import { useDebounce } from "use-debounce";
import { useImmer } from "use-immer";
import { useDelete, usePermission } from "../../../shared";
import { useCategoryQueries } from "./useCategoryQueries";

export const useCategory = ({ categorylist = false }) => {
  const isDeletable = usePermission("category-delete");
  const isCreateVisible = usePermission("category-create");
  const isEditable = usePermission("category-modify");
  const isSubCategoryCreateVisible = usePermission("subcategory-create");
  const isSubCategoryEditable = usePermission("subcategory-modify");
  const isSubCategoryDeletable = usePermission("subcategory-delete");
  const [, forceUpdate] = useState();
  const validator = useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
  );

  const { deleteInfo, onDelete, resetDeleteInfo } = useDelete();
  const [category, setCategory] = useImmer({
    isProductDialog: false,
    subcategoryId: "",
    item: [],
    data: {
      id: 0,
      name: "",
      parent_category_id: null,
    },
    isOpen: false,
    id: 0,
    search: "",
    selectedItem: {
      subData: [],
      id: 0,
      name: "",
    },
    isSubItem: false,
    categoryDropDownValue: "",
    recipeListItems: [],
    allrecipeProducts: [],
    searchKey: "",
  });

  const detailsIds = category.id;

  const [debouncedText] = useDebounce(category.search, 1000);
  const onSelectItem = (data, isSubItem) => {
    if (!isSubItem) {
      setCategory((draft) => {
        draft.selectedItem = data;
        draft.categoryDropDownValue = data.id;
      });
    }
  };
  console.log("selectedItem", category.selectedItem);

  const {
    listQuery,
    updateCategory,
    createCategory,
    deleteCategory,
    categoryDetails,
    sortOrderCategoryList,
    sortOrderSubCategoryList,
    recipeListQuery,
    sortOrderCategoryProductList,
  } = useCategoryQueries({
    categorylist: categorylist || category.categorylist,
    detailsIds,
    debouncedText,
    resetDeleteInfo,
    category_id: category.subcategoryId,
    onSelectItem,
    subcategoryId: category.subcategoryId,
  });

  const handleClickOpen = (isOpen, id, isSubItem) => {
    if (isSubItem) {
      setCategory((draft) => {
        draft.isOpen = isOpen;
        draft.id = id;
        draft.isSubItem = isSubItem;
      });
    } else {
      setCategory((draft) => {
        draft.isOpen = isOpen;
        draft.id = id;
        draft.isSubItem = isSubItem;
      });
    }
  };

  useEffect(() => {
    if (categoryDetails?.data) {
      setCategory((draft) => {
        draft.data = categoryDetails.data;
      });
    }
  }, [categoryDetails?.data]);

  const handleClose = (data) => {
    setCategory((draft) => {
      draft.isOpen = data;
      draft.id = 0;
      draft.data = {
        id: 0,
        name: "",
      };
      draft.isSubItem = false;
      draft.categoryDropDownValue = draft.selectedItem.id;
    });
    validator.current.hideMessages();
  };

  const onChange = (e) => {
    const { value } = e.target;
    setCategory((draft) => {
      draft.data.name = value;
    });
  };
  const onSubmit = () => {
    if (validator.current.allValid()) {
      const payload = {
        id: category?.data.id,
        name: category?.data.name,
        parent_category_id: null,
      };
      if (category.isSubItem) {
        payload.parent_category_id = category.categoryDropDownValue;
      }
      let selectedData = category?.item.find(
        (i) => i.id === category.categoryDropDownValue
      );
      detailsIds
        ? updateCategory.mutate({ detailsIds, payload, handleClose })
        : createCategory.mutate({ payload, handleClose, selectedData });
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  const confirmDelete = () => {
    deleteCategory.mutate(deleteInfo.id);
  };

  const searchHandler = (e) => {
    setCategory((draft) => {
      draft.search = e.target.value;
      draft.selectedItem = {
        subData: [],
        id: 0,
        name: "",
      };
    });
  };

  const onDeleteAction = (id, isSubItem) => {
    onDelete(id);
  };
  useEffect(() => {
    if (listQuery.data) {
      setCategory((draft) => {
        draft.item = listQuery.data;
      });
    }
  }, [listQuery.data]);
  useEffect(() => {
    if (listQuery.data) {
      if (category.selectedItem.id > 0) {
        listQuery.data.map((item) => {
          if (item.id === category.selectedItem.id) {
            setCategory((draft) => {
              draft.selectedItem = item;
            });
          }
          return null;
        });
      } else {
        setCategory((draft) => {
          draft.selectedItem = listQuery.data[0];
          draft.categoryDropDownValue = listQuery.data[0]?.id || "";
        });
      }
    }
  }, [listQuery.data]);

  const CategoryValuesHandler = (event, option) => {
    const genderOptions = option ? option.id : "";
    setCategory((draft) => {
      draft.categoryDropDownValue = genderOptions;
    });
  };
  const onDragEnd = (result) => {
    const { destination, source } = result;

    if (!destination) {
      return;
    }

    const reorderedData = Array.from(category?.item);
    const [movedItem] = reorderedData.splice(source.index, 1);
    reorderedData.splice(destination.index, 0, movedItem);
    const filterreorderedData = reorderedData?.map((v) => {
      return { display_order: v.display_order, id: v.id };
    });
    sortOrderCategoryList.mutate(filterreorderedData);
    setCategory((draft) => {
      draft.item = reorderedData;
    });
  };

  const onSubCategoryDragEnd = (result) => {
    let selectedcategoryId = category?.selectedItem?.id;
    if (!result.destination) {
      return;
    }
    const reorder = (list, startIndex, endIndex) => {
      const result = Array.from(list);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);
      return result;
    };
    const items = reorder(
      category.selectedItem.subData,
      result.source.index,
      result.destination.index
    );
    setCategory((draft) => {
      draft.selectedItem.subData = items;
    });
    const filterItems = items?.map((v) => {
      return { display_order: v.display_order, id: v.id };
    });
    sortOrderSubCategoryList.mutate({ filterItems, selectedcategoryId });
  };

  const handleProductDialogOpen = (type, id) => {
    setCategory((draft) => {
      draft.isProductDialog = type;
      draft.subcategoryId = id;
    });
  };

  const onCategoryProductOrderChange = async (data, index) => {
    setCategory((draft) => {
      draft.recipeListItems = data;
      draft.allrecipeProducts = data;
    });
  };
  const handleProductSortOrder = () => {
    const filterreorderedData = category?.recipeListItems?.map((v, index) => {
      return { display_order: index, id: v.id };
    });
    sortOrderCategoryProductList.mutate({
      filterreorderedData,
      handleProductDialogOpen,
    });
  };
  const onSearchChange = (string) => {
    setCategory((draft) => {
      draft.searchKey = string;
      draft.recipeListItems = draft.allrecipeProducts.filter((o) =>
        Object.keys(o).some((k) =>
          o["name"].toLowerCase().includes(string.toLowerCase())
        )
      );
    });
  };
  return {
    listQuery,
    handleClickOpen,
    category,
    onChange,
    handleClose,
    onSubmit,
    confirmDelete,
    onDeleteAction,
    deleteInfo,
    resetDeleteInfo,
    onSelectItem,
    searchHandler,
    validator,
    createCategory,
    updateCategory,
    CategoryValuesHandler,
    onDragEnd,
    onSubCategoryDragEnd,
    handleProductDialogOpen,
    onCategoryProductOrderChange,
    handleProductSortOrder,
    onSearchChange,
    recipeListQuery,
    deleteCategory,
    isCreateVisible,
    isSubCategoryCreateVisible,
    isSubCategoryEditable,
    isSubCategoryDeletable,
    isEditable,
    isDeletable,
  };
};
