import {
  Button,
  Checkbox,
  Chip,
  Stack,
  TextField,
  Typography,
  createFilterOptions,
} from "@mui/material";
import React, { useMemo } from "react";
import {
  DeleteModal,
  InputControl,
  LimitedTag,
  PageListLayout,
  ServerPaginatedtable,
  SimpleMenu,
} from "../../../shared";
import AddIcon from "@mui/icons-material/Add";
import { createColumnHelper } from "@tanstack/react-table";
import { useNavigate } from "react-router-dom";
import { useIngredients } from "../hooks/UseIngredient";

export const IngredientsList = () => {
  const {
    listQuery: { isLoading, data },
    listQuery,
    ingredient,
    deleteInfo,
    onDelete,
    resetDeleteInfo,
    confirmDelete,
    onSearchChange,
    setPagination,
    pagination,
    sorting,
    setSorting,
    selectStatus,
    disableclearButton,
    onClearFilter,
    globalData,
    typeValuesHandler,
    optionsTagsList,
    tagValuesHandler,
    isCreateVisible,
    isEditable,
    isDeletable,
    tableData,
    moduleName,
    search,
  } = useIngredients({
    isIngredientlist: true,
    isTaxgrouplist: false,
    isTaglist: true,
    isList: true,
  });

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    stringify: (option) => option.label,
  });
  const navigate = useNavigate();
  const columnHelper = createColumnHelper(data?.ingredient ?? []);
  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        header: () => "Ingredient Name",
      }),
      columnHelper.accessor("tax_group_name", {
        header: () => "Tax Groups",
      }),
      columnHelper.accessor("type", {
        header: () => "Type",
        cell: (info) => info.renderValue(),
      }),
      columnHelper.accessor("status", {
        header: "Status",
        cell: ({ row }) => {
          return row?.original.status ? (
            <Chip
              size="small"
              color="primary"
              variant="filled"
              label="ACTIVE"
            />
          ) : (
            <Chip size="small" color="gray" label="INACTIVE" />
          );
        },
      }),
      columnHelper.accessor("tags", {
        header: "Category Tags",
        enableSorting: false,
        cell: ({ row }) => {
          return (
            <Stack direction="row" gap="8px">
              {row?.original?.tags?.map((data) => (
                <Chip
                  size="small"
                  variant="outlined"
                  color="gray"
                  label={data}
                />
              ))}
            </Stack>
          );
        },
      }),

      columnHelper.display({
        id: "actions",
        cell: (props) => {
          const { row } = props;
          return (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
              gap="8px"
              mr={-1}
            >
              {(isDeletable || isEditable) && (
                <SimpleMenu
                  row={props.row}
                  editAction={(e) => navigate(`edit/${e.id}`)}
                  handleDeleteModal={(e) => {
                    onDelete(e.id);
                  }}
                  isEditable={isEditable}
                  isDeletable={isDeletable}
                />
              )}
              <img
                alt="details"
                src="/icons/arrow_forward.png"
                onClick={(e) =>
                  navigate(`/ingredients/details/${props.row.original.id}`)
                }
              />
            </Stack>
          );
        },
      }),
    ],
    [listQuery?.data?.ingredient]
  );

  const breadcrumbs = [
    <Typography key="1" sx={{ fontSize: "14px", fontWeight: 400 }}>
      Ingredients
    </Typography>,
  ];

  return (
    <>
      <PageListLayout
        breadcrumbs={breadcrumbs}
        pageContent={
          <Stack sx={{ borderRadius: "4px", background: "#ffffff" }}>
            <ServerPaginatedtable
              defaultData={listQuery?.data?.ingredients ?? []}
              columns={columns}
              search={search}
              onSearchChange={onSearchChange}
              sorting={sorting}
              setSorting={(callback) =>
                setSorting({ tableName: moduleName, callback })
              }
              pagination={pagination}
              paginationProps={
                listQuery.data?.pagination || { total: 0, lastPage: 1 }
              }
              setPagination={(callback) =>
                setPagination({ tableName: moduleName, callback })
              }
              headerText="Ingredients"
              isLoading={isLoading}
              onRowLink={(e) => {
                return `details/${e.id}`;
              }}
              tableHeaderContent={
                <Stack
                  sx={{
                    flexDirection: "row",
                    gap: "8px",
                    mb: 2,
                  }}
                >
                  <InputControl
                    type="dropdown"
                    variant="filter"
                    size="small"
                    multiple={true}
                    options={globalData?.Ingredient_types ?? []}
                    value={tableData.ingredients.data.types || []}
                    disableCloseOnSelect
                    disableClearable={true}
                    onChange={(event, option) =>
                      typeValuesHandler(event, option)
                    }
                    getOptionLabel={(option) => option.label || ""}
                    isOptionEqualToValue={(option, value) =>
                      option.value === value.value
                    }
                    renderTags={(value) => (
                      <LimitedTag
                        value={value}
                        labelKey="label"
                        autocompleteWidth="230px"
                      />
                    )}
                    renderOption={(props, option, { selected }) => (
                      <li {...props} key={option.id}>
                        <Checkbox
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.label}
                      </li>
                    )}
                    filterOptions={filterOptions}
                    sx={{ width: "230px" }}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Type" />
                    )}
                  />
                  <InputControl
                    type="dropdown"
                    variant="filter"
                    size="small"
                    options={selectStatus.statusOptions}
                    getOptionLabel={(option) => option?.name || ""}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    sx={{
                      width: "180px",
                      height: "36px",
                      mr: "18px",
                    }}
                    value={
                      selectStatus?.statusOptions?.find(
                        (option) =>
                          option.id ===
                          tableData.ingredients.data.selectedStatus
                      ) || null
                    }
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Status" />
                    )}
                    onChange={(event, option) =>
                      selectStatus.statusValueChangeHandler(option)
                    }
                  />

                  <InputControl
                    type="dropdown"
                    multiple={true}
                    variant="filter"
                    size="small"
                    options={optionsTagsList ?? []}
                    value={tableData.ingredients.data.tagslist || []}
                    disableCloseOnSelect
                    disableClearable={true}
                    onChange={(event, option) =>
                      tagValuesHandler(event, option)
                    }
                    getOptionLabel={(option) => option.label || ""}
                    isOptionEqualToValue={(option, value) =>
                      option.value === value.value
                    }
                    renderTags={(value) => (
                      <LimitedTag
                        value={value}
                        labelKey="label"
                        autocompleteWidth="230px"
                      />
                    )}
                    renderOption={(props, option, { selected }) => (
                      <li {...props} key={option.value}>
                        <Checkbox
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.label}
                      </li>
                    )}
                    filterOptions={filterOptions}
                    sx={{ width: "230px" }}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Category Tags" />
                    )}
                  />
                  <Button
                    type="button"
                    size="small"
                    variant="text"
                    disabled={disableclearButton}
                    onClick={onClearFilter}
                  >
                    Clear All
                  </Button>
                </Stack>
              }
              rightContent={
                isCreateVisible && (
                  <Button
                    type="button"
                    size="medium"
                    variant="contained"
                    startIcon={<AddIcon />}
                    onClick={() => navigate("create")}
                  >
                    Ingredient
                  </Button>
                )
              }
            />
          </Stack>
        }
      />
      <DeleteModal
        open={deleteInfo.show}
        handleClose={() => resetDeleteInfo()}
        onConfirm={() => confirmDelete()}
      />
    </>
  );
};
