import InfiniteScroll from "react-infinite-scroll-component";
import {
  List,
  ListItem,
  ListItemText,
  Typography,
  FormControl,
  Button,
  Stack,
  InputAdornment,
  OutlinedInput,
  Box,
  Checkbox,
  ListItemIcon,
  ListItemButton,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useState } from "react";
import { Loader } from "./Loader";
import { useStoreSelectDialog } from "../hooks/useStoreSelectDialog";
import { CustomeDialog } from "./CustomeDialog";
import { StoreUpdateDialog } from "../../modules/Templates/components/StoreUpdateDialog";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";

export const TemplateStoreSelectDialog = (props) => {
  const {
    onSelect,
    isSelected,
    disabled,
    onSubmit,
    isCheckIndeterminate,
    selectedStores,
    alreadySelectedStores,
    search = "",
    open,
    handleClickOpen,
    handleClose,
    templates,
    onChangeStoreEffectiveDate,
    setTemplates,
    assignedStoreState,
    id,
    assingedStoreListQueryTemplate,
  } = props;

  const {
    listQuery: { data, isLoading },
    state,
    HandleSearchFilter,
  } = useStoreSelectDialog();

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Stack
        justifyContent="space-between"
        height="100%"
        gap="8px"
        maxHeight={"calc(100% - 24px)"}
      >
        <FormControl
          size="small"
          fullWidth={true}
          sx={{
            pt: "24px",
          }}
        >
          <OutlinedInput
            placeholder="Search"
            sx={{
              height: "48px",
              ".MuiOutlinedInput-input": {
                pl: "0px !important",
              },
            }}
            size="small"
            value={state.search}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon size="large" />
              </InputAdornment>
            }
            endAdornment={
              state.search && (
                <InputAdornment
                  sx={{ cursor: "pointer" }}
                  onClick={(e) => {
                    HandleSearchFilter("initial", "");
                  }}
                  position="end"
                >
                  <CloseIcon size="large" />
                </InputAdornment>
              )
            }
            fullWidth
            onChange={(e) => {
              HandleSearchFilter("search", e.target.value);
            }}
          />
        </FormControl>
        <Box
          id="scrollableDiv"
          sx={{ height: "100%", overflow: "auto" }}
          fullWidth
        >
          <InfiniteScroll
            scrollableTarget="scrollableDiv"
            dataLength={data?.length}
            next={null}
            hasMore={null}
            loader={
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                Loading...
              </Box>
            }
          >
            {data.length > 0 ? (
              <List sx={{ width: "100%", py: 0, bgcolor: "background.paper" }}>
                {data.map((value) => {
                  const labelId = `checkbox-list-label-${value}`;
                  return (
                    <>
                      {value.stores && value.stores.length === 0 ? (
                        ""
                      ) : (
                        <ListOfState
                          value={value}
                          labelId={labelId}
                          onSelect={onSelect}
                          isSelected={isSelected}
                          isCheckIndeterminate={isCheckIndeterminate}
                          selectedStores={selectedStores}
                          alreadySelectedStores={alreadySelectedStores}
                        />
                      )}
                    </>
                  );
                })}
              </List>
            ) : (
              <Stack justifyContent="center" alignItems="center" height="100%">
                <Typography
                  sx={{ display: "inline" }}
                  component="span"
                  variant="caption"
                  color="text.secondary"
                  fontWeight={400}
                >
                  No stores Added yet
                </Typography>
              </Stack>
            )}
          </InfiniteScroll>
        </Box>
        <Stack
          direction="row"
          gap="6px"
          padding="6px, 12px, 6px, 12px"
          sx={{ justifyContent: "flex-end" }}
        >
          <Button variant="outlined" width="78px" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            sx={{ width: "78px" }}
            variant="contained"
            disabled={disabled}
            onClick={() => handleClickOpen(true, id)}
          >
            Save
          </Button>
        </Stack>
      </Stack>
      {open === true && (
        <CustomeDialog
          PaperProps={{
            sx: {
              minWidth: "570px",
              minHeight: "280px",
              borderRadius: "10px",
            },
          }}
          open={open}
          // handleClose={handleClose}
          title={
            <Typography
              sx={{
                padding: "20px 20px 8px 20px",
                fontWeight: "500",
                fontSize: "20px",
                lineHeight: "24.2px",
                width: "590px",
              }}
            >
              When do you want to change this update?
            </Typography>
          }
          content={
            <StoreUpdateDialog
              onSubmit={onSubmit}
              templates={templates}
              onChangeStoreEffectiveDate={onChangeStoreEffectiveDate}
              setTemplates={setTemplates}
              handleClose={() => {
                handleClickOpen(false, id);
                // handleClose()
              }}
              state={assignedStoreState}
              isDisabled={assingedStoreListQueryTemplate?.isPending}
            />
          }
        />
      )}
    </>
  );
};

function ListOfState({
  value,
  labelId,
  onSelect,
  selectedStores,
  alreadySelectedStores,
}) {
  const [open, setOpen] = useState(false);

  const handleExpandClick = (e) => {
    e.stopPropagation();
    setOpen((prev) => !prev);
  };

  const isSelectedCheck = (value) => {
    if (value.stores) {
      const subArray = value?.stores?.map((e, i) => {
        return e.id;
      });
      const commonIDs1 = selectedStores.filter((val) => subArray.includes(val));
      return subArray.every((val) => commonIDs1.includes(val));
    } else {
      return isSelected(value.id);
    }
  };
  const isSelected = (id) => {
    if (selectedStores.includes(id)) {
      return true;
    } else {
      return selectedStores.includes(id);
    }
  };
  const isDisabled = (value) => {
    return alreadySelectedStores.includes(value);
  };
  const isDisable = (value) => {
    if (value.stores) {
      const subArray = value?.stores?.map((e, i) => {
        return e.id;
      });
      const commonIDs1 = selectedStores.map((val) => subArray.includes(val));
      return subArray.every((val) => commonIDs1.includes(val));
    } else {
      return selectedStores.includes(value.id);
    }
  };
  return (
    <Box
      sx={{
        backgroundColor: open ? "rgba(21, 96, 212, 0.08)" : "",
        borderRadius: "8px",
      }}
    >
      <ListItem key={value} disablePadding>
        <ListItemButton
          role={undefined}
          dense
          sx={{
            "&:hover": {
              backgroundColor: "rgba(21, 96, 212, 0.08)",
              borderRadius: "8px",
            },
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: "32px",
            }}
          >
            <Checkbox
              edge="start"
              disabled={isDisable(value)}
              checked={isSelectedCheck(value)}
              onChange={(e) => {
                onSelect(value, e);
                setOpen(true);
              }}
              disableRipple
              inputProps={{ "aria-labelledby": labelId }}
            />
          </ListItemIcon>
          <ListItemText
            id={labelId}
            primary={value.name}
            onClick={() => setOpen(!open)}
          />
          <ExpandMoreIcon
            sx={{
              color: "#999999",
              transform: open ? "rotate(180deg)" : "",
              transition: "transform 0.3s",
            }}
            onClick={handleExpandClick}
          />
        </ListItemButton>
      </ListItem>
      {open &&
        value.stores.map((v) => {
          return (
            <ListItemButton
              role={undefined}
              dense
              sx={{
                "&:hover": {
                  backgroundColor: "rgba(21, 96, 212, 0.08)",
                  borderRadius: "8px",
                },
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: "32px",
                  marginLeft: "12px",
                }}
              >
                <Checkbox
                  edge="start"
                  checked={isSelected(v.id)}
                  disabled={isDisabled(v.id)}
                  onChange={() => onSelect(v)}
                  disableRipple
                />
              </ListItemIcon>
              <ListItemText primary={v.name} />
            </ListItemButton>
          );
        })}
    </Box>
  );
}
