import { Routes, Route } from "react-router-dom";
import {
  DashboardLayout,
  PageNotFound,
  ProtectedRoute,
  UnAuthorised,
} from "../../shared";
import {
  MasterData,
  Templates,
  ProductionChart,
  RecipeDashboard,
} from "../../modules";

export function StoreAdminDashboard() {
  return (
    <div id="app">
      <DashboardLayout>
        <div id="main-content">
          <Routes>
            <Route
              element={<ProtectedRoute allowedRoute={["dashboard-view"]} />}
            >
              <Route path="/" element={<RecipeDashboard />} />
            </Route>
            <Route
              element={
                <ProtectedRoute
                  allowedRoute={[
                    "batchgroup-view",
                    "wastage-chart-view",
                    "unit-view",
                    "category-view",
                    "subcategory-view",
                  ]}
                />
              }
            >
              <Route path="/master-data" element={<MasterData />} />
            </Route>
            <Route
              element={<ProtectedRoute allowedRoute={["template-view"]} />}
            >
              <Route path="/templates/*" element={<Templates />} />
            </Route>

            <Route
              element={
                <ProtectedRoute allowedRoute={["production-chart-view"]} />
              }
            >
              <Route
                path="/production-charts/*"
                element={<ProductionChart />}
              />
            </Route>
            <Route exact element={<UnAuthorised />} path={"/unauthorized"} />
            <Route exact element={<PageNotFound />} path={"/404"} />
            <Route exact element={<PageNotFound />} path={"*"} />
          </Routes>
        </div>
      </DashboardLayout>
    </div>
  );
}
