import {
    useQuery,
  } from "@tanstack/react-query";
import { useRecipeKeys } from "./useRecipeKeys";
import { getRecipeBasicDetails } from "../api/RecipeApi";
export const useBasicDetailsQueries = ({recipeId}) =>
{
  const { recipeKeys } = useRecipeKeys();
  const basicDetailsQuery = useQuery({
    queryKey: recipeKeys.detailBykEY("basic_details", recipeId),
    queryFn: () => getRecipeBasicDetails(recipeId),
    enabled: recipeId > 0,
  });

  return { basicDetailsQuery };
}