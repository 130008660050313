import { useImmer } from "use-immer";
import { useCategoryQueries } from "../../modules/MasterData/hooks/useCategoryQueries";
import { useEffect } from "react";

export const useCategorySelectChip = ({
  categorylist,
  onChangeCategoryHandler,
}) => {
  const [chipCategory, setChipCategory] = useImmer({
    chipCategoryOptions: [],
    isLoading: true,
    initialSelectedItem: 0,
  });

  const chipCategoryValuesHandler = (option) => {
    const selectedOption = option ? option.id : 0;
    onChangeCategoryHandler(selectedOption);
  };
  const { listQuery, categoryDetails } = useCategoryQueries({
    categorylist,
  });

  useEffect(() => {
    setChipCategory((draft) => {
      draft.isLoading = listQuery.isLoading;
    });
    if (listQuery.data) {
      const chipData = listQuery.data.map((item) => {
        return {
          id: item.id,
          name: item.name,
          subcategoryCount: item.subcategories,
          recipeCount: item.recipeCount,
          recipeListCount: item.recipeListCount || 0,
        };
      });
      setChipCategory((draft) => {
        draft.chipCategoryOptions = chipData;
        draft.isLoading = listQuery.isLoading;
        draft.initialSelectedItem =
          Array.isArray(chipData) && chipData.length > 0 ? chipData[0].id : 0;
      });
    }
  }, [listQuery.data]);

  return { chipCategory, chipCategoryValuesHandler };
};
