import React from "react";
import IconButton from "../../assets/images/Icons/IconButton.png";
import editIcon from "../../assets/images/Icons/editIcon.png";
import trashIcon from "../../assets/images/Icons/TrashIcon.png";
import { Box, Menu, MenuItem } from "@mui/material";
import copyIcon from "../../assets/images/Icons/copyImage.svg";

export const SimpleMenuTempDelete = ({
  row,
  handleDeleteModal,
  editAction,
  copyAction,
  isEditable,
  isDeletable,
  isCopyTemplate,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Box
        component="img"
        sx={{ cursor: "pointer" }}
        src={IconButton}
        height={21}
        width={21}
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        sx={{ padding: "8px", gap: "2px", borderRadius: "10px" }}
      >
        {editAction && isEditable && (
          <MenuItem
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              editAction(row.original);
              handleClose();
            }}
            sx={{
              width: "172px",
              padding: "6px 8px",
              gap: "8px",
              display: "flex",
              alignItems: "center",
              fontSize: "14px",
            }}
          >
            <Box component="img" src={editIcon} height={20} width={20} />
            Edit
          </MenuItem>
        )}
        {copyAction && isCopyTemplate && (
          <MenuItem
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              copyAction(row.original);
              handleClose();
            }}
            sx={{
              width: "172px",
              padding: "6px 8px",
              gap: "8px",
              display: "flex",
              alignItems: "center",
              fontSize: "14px",
            }}
          >
            <Box component="img" src={copyIcon} height={20} width={20} />
            Copy
          </MenuItem>
        )}
        {handleDeleteModal && isDeletable && (
          <MenuItem
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              handleDeleteModal(row.original);
              handleClose();
            }}
            sx={{
              width: "172px",
              padding: "6px 8px",
              gap: "8px",
              display: "flex",
              alignItems: "center",
              fontSize: "14px",
            }}
          >
            <Box component="img" src={trashIcon} height={20} width={20} />
            Delete
          </MenuItem>
        )}
      </Menu>
    </>
  );
};
