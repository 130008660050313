import { Typography, Stack } from "@mui/material";
import { Batchcards } from "../components/Batchcards";

export const ProductionChartBatchesDetails = (props) => {
  const { data } = props;

  let result = data?.reduce(function (r, a) {
    r[a.priority] = r[a.priority] || [];
    r[a.priority].push(a);
    return r;
  }, Object.create(null));

  return (
    <>
      <Stack
        mt={1}
        sx={{
          p: "8px",
          backgroundColor: "#F7FAFC",
          height: "496px",
        }}
      >
        {Object?.keys(result)?.map(function (key, index) {
          return (
            <Stack key={index}>
              <Stack p="11px 16px 5px">
                <Typography color="#1760D2" fontWeight={500} fontSize="16px">
                  PRIORITY {key}
                </Typography>
              </Stack>
              <Batchcards data={result[key]} />
            </Stack>
          );
        })}
      </Stack>
    </>
  );
};
