import { Box, Chip, Link, Paper, Stack, Typography } from "@mui/material";
import React from "react";
import {
  CustomDetailsList,
  CustomTable,
  DeleteModal,
  Loader,
  PageListLayout,
} from "../../../shared";
import { createColumnHelper } from "@tanstack/react-table";
import { useIngredients } from "../hooks/UseIngredient";
import { useNavigate, useParams } from "react-router-dom";
import { useUnits } from "../../MasterData/hooks";

export const IngredientDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    ingredient,
    confirmDelete,
    onDelete,
    deleteInfo,
    resetDeleteInfo,
    state,
    isEditable,
    isDeletable,
  } = useIngredients({
    detId: id,
    isIngredientlist: false,
    isTaxgrouplist: false,
    isTaglist: false,
    isList: false,
  });
  const { listQueryData } = useUnits({ list: true });
  const optionsUnits = listQueryData?.data?.units
    ?.filter((i) => i?.ignore === 0)
    ?.map((item) => ({
      name: item.symbol,
      id: item.id,
    }));
  const breadcrumbs = [
    <Link
      underline="none"
      key="1"
      color="#1760D2"
      onClick={(e) => navigate("/ingredients")}
      sx={{ fontSize: "14px", fontWeight: 400 }}
    >
      Ingredients
    </Link>,
    <Typography
      key="2"
      color="inherit"
      sx={{ fontSize: "14px", fontWeight: 400 }}
    >
      {ingredient?.data?.name}
    </Typography>,
  ];
  const columns = [
    {
      title: "Ingredient Name",
      accessor: (val) => (
        <Typography variant="subtitle2" fontWeight={500}>
          {val.name}
        </Typography>
      ),
    },
    {
      title: "Tax Group",
      accessor: "tax_group_name",
    },
    {
      title: "Type",
      accessor: (val) => (
        <Typography variant="subtitle2" fontWeight={500}>
          {val.type?.charAt(0).toUpperCase() + val.type.slice(1)}
        </Typography>
      ),
    },
    {
      title: "Category tags",
      accessor: (val) => {
        return (
          <Stack direction="row" gap="8px">
            {val?.tags?.map((data) => (
              <Chip size="small" variant="outlined" color="gray" label={data} />
            ))}
          </Stack>
        );
      },
    },
    {
      title: "Buying Unit",
      accessor: (val) => (
        <Typography variant="subtitle2" fontWeight={500}>
          {optionsUnits?.find((c) => c?.id === val?.buying_unit_id)?.name}
        </Typography>
      ),
    },
    {
      title: "Cost (ex GST)",
      accessor: (val) => (
        <Typography variant="subtitle2" fontWeight={500}>
          ${Number(val?.cost_exc_tax)?.toFixed(3)}
        </Typography>
      ),
    },
    {
      title: "Cost (in GST)",
      accessor: (val) => (
        <Typography variant="subtitle2" fontWeight={500}>
          ${Number(val?.cost_inc_tax)?.toFixed(3)}
        </Typography>
      ),
    },
  ];

  const columnHelper = createColumnHelper();

  const columns2 = [
    columnHelper.accessor("base_unit_qty", {
      header: () => "Base Qty",
    }),
    columnHelper.accessor("base_unit_name", {
      header: () => "Base Unit",
    }),
    columnHelper.accessor("id", {
      header: () => "=",
      cell: (info) => "=",
    }),
    columnHelper.accessor("alt_unit_qty", {
      header: () => "Alternative Unit",
      cell: (row) => {
        return (
          <Stack direction="row" gap={1}>
            <Typography>{row.row.original.alt_unit_qty}</Typography>
            <Typography>{row.row.original.alt_unit_name}</Typography>
          </Stack>
        );
      },
    }),
  ];

  return (
    <>
      <PageListLayout
        breadcrumbs={breadcrumbs}
        isChanged={true}
        pageContent={
          <>
            <Stack
              direction="row"
              justifyContent="space-between"
              height="62px"
              bgcolor="white"
              sx={{
                position: "sticky",
                zIndex: "99px",
                top: "0px",
              }}
            >
              <Stack alignSelf="center" direction="row" gap={2} ml={3}>
                <Typography variant="h6" fontWeight={600} fontSize="24px">
                  {ingredient?.data?.name}
                </Typography>
                <Box mt={0.5}>
                  {ingredient?.data?.status ? (
                    <Chip
                      size="small"
                      color="primary"
                      variant="filled"
                      label="ACTIVE"
                      sx={{ height: "22px" }}
                    />
                  ) : (
                    <Chip size="small" color="gray" label="INACTIVE" />
                  )}
                </Box>
              </Stack>
              <Stack direction="row" gap={3} alignSelf="center" mr={3}>
                {isEditable && (
                  <img
                    alt="edit"
                    onClick={(e) => navigate(`/ingredients/edit/${id}`)}
                    src="/icons/ic_edit.png"
                    style={{ cursor: "pointer" }}
                  />
                )}
                {isDeletable && (
                  <img
                    alt="delete"
                    onClick={() => onDelete(id, "/ingredients")}
                    src="/icons/ic_trash_sm.png"
                    style={{ cursor: "pointer" }}
                  />
                )}
              </Stack>
            </Stack>
            {ingredient.isLoading ? (
              <Loader />
            ) : (
              <Stack sx={{ borderRadius: "4px", bgcolor: "#fff", px: "16px" }}>
                <CustomDetailsList
                  columns={columns}
                  data={ingredient?.data}
                  title="Basic Details"
                />
                {ingredient.data.hasUom ? (
                  <Stack>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      p="25px 24px 13px 0px"
                    >
                      <Typography
                        variant="h6"
                        fontWeight={600}
                        lineHeight="19px"
                        minWidth="160px"
                      >
                        Alternate Unit Of Measure
                      </Typography>
                    </Stack>
                    <Box
                      sx={{
                        p: "0 8px 8px",
                        bgcolor: "#F5F8FC",
                      }}
                    >
                      <CustomTable
                        defaultData={ingredient?.data?.units}
                        columns={columns2}
                        isPagination={false}
                        isTableHeader={false}
                      />
                    </Box>
                  </Stack>
                ) : null}
                <Stack>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    p="25px 24px 13px 0px"
                  >
                    <Typography
                      variant="h6"
                      fontWeight={600}
                      lineHeight="19px"
                      minWidth="160px"
                    >
                      Spec Sheet
                    </Typography>
                  </Stack>
                  <Box
                    sx={{
                      p: "0 8px 8px",
                      bgcolor: "#F5F8FC",
                    }}
                  >
                    <Box sx={{ width: "100%", padding: "16px" }}>
                      <Stack direction="row" spacing={2}>
                        {state.data.pdf.length ? (
                          state.data.pdf.map((file, index) => (
                            <Paper
                              key={index}
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                padding: "8px",
                                minWidth: "150px",
                              }}
                            >
                              <img src="/icons/ic_file.jpg" />
                              <Box
                                sx={{ marginLeft: "8px", overflow: "hidden" }}
                              >
                                <Typography
                                  variant="body2"
                                  noWrap
                                  component="a"
                                  href={file}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  sx={{
                                    marginTop: "20px",
                                    color: "black",
                                    cursor: "pointer",
                                    textDecoration: "none",
                                  }}
                                >
                                  {file}
                                </Typography>
                              </Box>
                            </Paper>
                          ))
                        ) : (
                          <Stack
                            width="100%"
                            minHeight="100px"
                            alignItems="center"
                            justifyContent="center"
                            sx={{ textAlign: "center" }}
                          >
                            <Typography
                              fontWeight={500}
                              fontSize="14px"
                              color="#485364"
                            >
                              No spec sheet added
                            </Typography>
                          </Stack>
                        )}
                      </Stack>
                    </Box>
                  </Box>
                </Stack>
              </Stack>
            )}
          </>
        }
      />
      <DeleteModal
        open={deleteInfo.show}
        handleClose={() => resetDeleteInfo()}
        onConfirm={() => confirmDelete()}
      />
    </>
  );
};
