import { useImmer } from "use-immer";

const initDeleteInfo = {
  show: false,
  id: null,
  to: "",
};

export const useUserDelete = () => {
  const [deleteUserInfo, setDeleteUserInfo] = useImmer(initDeleteInfo);

  const onUserDelete = (id, to = "") => {
    setDeleteUserInfo((draft) => {
      draft = { show: !draft?.show, id, to };
      return draft;
    });
  };

  const resetUserDeleteInfo = () => {
    setDeleteUserInfo((draft) => {
      draft = initDeleteInfo;
      return draft;
    });
  };

  return {
    deleteUserInfo,
    onUserDelete,
    resetUserDeleteInfo,
  };
};
