import { useImmer } from "use-immer";
import { useEffect } from "react";
import { useDelete } from "../../../shared";
import { useAssignedStoreListTemplates } from "./useAssgnedStoreListTemplates";
import { useAssignedStoreTemplateUpdate } from "./useAssignedStoreTemplateUpdate";
import { useDeleteStoreFromTemplatesQueries } from "./useDeleteStoreFromTemplatesQueries";
import moment from "moment/moment";

export const useStoreDetailsTemplates = ({ templateId }) => {
  const {
    assignedStoreState,
    onSearchHandler,
    pagination,
    setPagination,
    assingedStoreListQueryTemplate,
    sorting,
    setSorting,
    setAssignedStoreState,
  } = useAssignedStoreListTemplates({ templateId });
  const { updateTemplateStore } = useAssignedStoreTemplateUpdate({
    setAssignedStoreState,
  });

  const {
    deleteInfo: storesDeleteInfo,
    onDelete: handleDeleteStores,
    resetDeleteInfo: clearStoresDeleteInfo,
  } = useDelete();
  const { deleteTemplateStores } = useDeleteStoreFromTemplatesQueries({
    clearStoresDeleteInfo,
  });

  const [state, setState] = useImmer({
    selectStoreDialogDetails: {
      isOpenStoreDialog: false,
      selectedStores: [],
      alreadySelectedStores: [],
    },
    effective_date: "",
    alert_radio: "",
    isOpen: false,
    id: "",
  });
  const onChangeStoreEffectiveDate = (selectedDate) => {
    const formattedDate = moment(selectedDate).format("YYYY-MM-DD");
    setState((draft) => {
      draft.effective_date = formattedDate;
    });
  };

  useEffect(() => {
    if (assignedStoreState?.alreadyAssignedStoreList) {
      setState((draft) => {
        draft.selectStoreDialogDetails.selectedStores =
          assignedStoreState.alreadyAssignedStoreList;
        draft.selectStoreDialogDetails.alreadySelectedStores =
          assignedStoreState.alreadyAssignedStoreList;
      });
    }
  }, [assignedStoreState?.alreadyAssignedStoreList]);

  const handleModal = (type, state, data) => {
    switch (type) {
      case "store-dialog-add":
        if(!state){
          setState((draft) => {
            draft.alert_radio = "";
            draft.effective_date = "";
          });
        }
        setState((draft) => {
          draft.selectStoreDialogDetails.isOpenStoreDialog = state;
        });
        break;
    }
  };

  const handleStoreDialogSelect = (v, e) => {
    if (v.stores) {
      const subRowsID = v.stores?.map((e, i) => {
        return e.id;
      });
      if (e.target.checked) {
        setState((draft) => {
          draft.selectStoreDialogDetails.selectedStores = [
            ...new Set([
              ...state.selectStoreDialogDetails.selectedStores,
              ...subRowsID,
            ]),
          ];
        });
      } else {
        setState((draft) => {
          draft.selectStoreDialogDetails.selectedStores =
            draft.selectStoreDialogDetails.selectedStores.filter(
              (val) => !subRowsID.includes(val)
            );
        });
      }
    } else {
      const isSelcted = state.selectStoreDialogDetails.selectedStores.includes(
        v.id
      );
      if (isSelcted) {
        setState((draft) => {
          draft.selectStoreDialogDetails.selectedStores =
            draft.selectStoreDialogDetails.selectedStores.filter(
              (val) => val !== v.id
            );
        });
      } else {
        setState((draft) => {
          draft.selectStoreDialogDetails.selectedStores = [
            ...draft.selectStoreDialogDetails.selectedStores,
            v.id,
          ];
          return draft;
        });
      }
    }
  };

  const handleStoreToRecipe = () => {
    setAssignedStoreState((draft) => {
      draft.isLoading = true;
    });
    const loadingValue = assignedStoreState.isLoading;
    const { effective_date } = state;
    const { selectedStores } = state.selectStoreDialogDetails;
    const source = "web";
    const payload = {
      selectedStores,
      effective_date,
      source,
    };
    updateTemplateStore.mutate({
      templateId: templateId,
      payload,
      handleModal,
      loadingValue,
    });
  };

  const confirmDeleteStrores = () => {
    const store_id = [state.id];
    const data = { store_id: store_id, effective_date: state?.effective_date };
    deleteTemplateStores.mutate({ templateId, data });
    clearStoresDeleteInfo(false);
    handleStoreDeleteDialog(false);
  };
  const handleStoreDeleteDialog = (type, id) => {
    setState((draft) => {
      draft.isOpen = type;
      draft.id = id;
    });
  };

  const onChangeEffectiveDates = (selectedDate) => {
    const formattedDate = moment(selectedDate).format("YYYY-MM-DD");
    setState((draft) => {
      draft.effective_date = formattedDate
        ? formattedDate
        : moment().format("YYYY-MM-DD");
    });
  };
  return {
    state,
    setState,
    assignedStoreState,
    handleModal,
    handleStoreDialogSelect,
    handleStoreToRecipe,
    onSearchHandler,
    pagination,
    setPagination,
    assingedStoreListQueryTemplate,
    confirmDeleteStrores,
    storesDeleteInfo,
    handleDeleteStores,
    clearStoresDeleteInfo,
    sorting,
    setSorting,
    isLoading: assingedStoreListQueryTemplate?.isLoading,
    onChangeStoreEffectiveDate,
    handleStoreDeleteDialog,
    onChangeEffectiveDates,
  };
};
