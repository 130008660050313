import { Button, Stack, Typography } from "@mui/material";
import React from "react";
import AddIcon from "@mui/icons-material/Add";
import { createColumnHelper } from "@tanstack/react-table";
import DeleteIcon from "../../../assets/images/Icons/TrashIcon.png";
import {
  CustomeDialog,
  DeleteModal,
  Loader,
  ServerPaginatedtable,
} from "../../../shared";
import { StoreSelectDialog } from "../../../shared/components";
import { useStoreDetails } from "../hooks";

export const StoreDetails = ({ recipeId }) => {
  const {
    state,
    assignedStoreState,
    handleModal,
    handleStoreDialogSelect,
    handleStoreToRecipe,
    onSearchHandler,
    pagination,
    setPagination,
    assingedStoreListQuery,
    deleteInfo,
    onDelete,
    resetDeleteInfo,
    confirmDelete,
    sorting,
    setSorting,
    isLoading,
    onClearSearch,
    isStoreAssign,
  } = useStoreDetails({ recipeId });

  const { isOpenStoreDialog, selectedStores, alreadySelectedStores } =
    state.selectStoreDialogDetails;

  if (isLoading) {
    return <Loader />;
  }

  const columnHelper = createColumnHelper(assignedStoreState.assignedStoreList);
  const columns = [
    columnHelper.accessor("name", {
      header: "Store Name",
      cell: (props) => {
        const { row } = props;

        return (
          <>
            <Typography
              sx={{
                color: "#1760D2",
                textTransform: "uppercase",
              }}
            >
              {row.original.name}
            </Typography>
            {row.original.storename ? (
              <Stack direction="row" justifyContent="space-between">
                <Typography>{row.original.storename}</Typography>
              </Stack>
            ) : (
              []
            )}
          </>
        );
      },
    }),
    columnHelper.display({
      id: "actions",
      cell: (props) => {
        const { row } = props;
        return row.depth ? (
          <Button
            onClick={(e) => {
              onDelete(row.original.id);
            }}
          >
            <img src={DeleteIcon} />
          </Button>
        ) : (
          ""
        );
      },
    }),
  ];

  return (
    <>
      <ServerPaginatedtable
        defaultData={assignedStoreState.assignedStoreList}
        columns={columns}
        subRow={"subRows"}
        headerText="Assigned Store"
        onSearchChange={onSearchHandler}
        sorting={sorting}
        setSorting={setSorting}
        isLoading={isLoading}
        search={assignedStoreState.search}
        pagination={pagination}
        paginationProps={
          assingedStoreListQuery.data?.pagination || { total: 0, lastPage: 1 }
        }
        setPagination={setPagination}
        rightContent={
          isStoreAssign && (
            <Button
              type="button"
              color="inherit"
              variant="outlined"
              onClick={() => handleModal("store-dialog-add", true)}
              sx={{ backgroundColor: "#F5F8FC", borderColor: "#BCC7DB" }}
              startIcon={<AddIcon />}
            >
              Store
            </Button>
          )
        }
        allRowsExpanded={true}
        onClearSearch={onClearSearch}
      />
      <CustomeDialog
        open={isOpenStoreDialog}
        PaperProps={{
          sx: { width: "423px", height: "491px", p: "16px" },
        }}
        content={
          <StoreSelectDialog
            selectedStores={selectedStores}
            alreadySelectedStores={alreadySelectedStores}
            onSelect={handleStoreDialogSelect}
            disabled={
              JSON.stringify(selectedStores) ===
              JSON.stringify(alreadySelectedStores)
            }
            isSelected={(id) => selectedStores.includes(id)}
            onSubmit={handleStoreToRecipe}
            handleClose={() => handleModal("store-dialog-add", false)}
          />
        }
      />
      <DeleteModal
        open={deleteInfo.show}
        handleClose={() => resetDeleteInfo()}
        onConfirm={() => confirmDelete()}
      />
    </>
  );
};
